import React from 'react';
import { Loader, Table, TableError, TableHeading, TableRow } from '../../../../atoms';
import { StyledTableContainer } from '../CompanyDirectory/styles';
import { StyledColumnHeader, StyledContainer, StyledInput, StyledTableLoaderContainer } from './styles';
import CDAccountRow from '../../../../containers/CDAccountRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCDAccountList, fetchCDAccountListByField } from '../../../../../redux/slices/CDAccountSlice/thunk';
import AddCDAccountModal from '../../../../containers/AddCdAccountModal';
import { concatenatePolicyTypeSuffix, fetchStateData, navigateToCdLedger } from './utils';
import { formatCurrencyElseDefault } from '../../../../../utils/constants/Endorsement';
import { DateFormatUtils, TableEmptyState, Typography } from '@loophealth/loop-ui-web-library';
import { isEndoToolingEnabled } from '../../../../../utils/featureFlags';
import { StyledEmptyStateContainer } from '../CDLedger/styles';

const CDAccount: React.FunctionComponent = () => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState('');
    const [isVisible, setIsVisible] = React.useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cdAccounts, searchedCDAccounts, companies } = fetchStateData();
    const accountsList = debouncedSearchTerm ? searchedCDAccounts : cdAccounts;
    /** Map the object to create a object to be rendered on the page */
    const updatedCdAccountsArray = accountsList.data?.map((cdAccountEntry) => {
        const company = companies?.filter((companyDoc) => companyDoc.id == cdAccountEntry.companyId)[0];
        return {
            id: cdAccountEntry.id || '-',
            accountNumber: cdAccountEntry.accountNumber || '-',
            companyName: company?.name || '-',
            policyType: cdAccountEntry.policyData.map((p) => concatenatePolicyTypeSuffix(p)).toString() || '-',
            insurer: cdAccountEntry.insurer || '-',
            balance: formatCurrencyElseDefault(cdAccountEntry.balance),
            updatedAt: new DateFormatUtils().formatDateString(cdAccountEntry.updatedAt)
        };
    });

    const navigateToLedger = (id: string) => navigateToCdLedger(navigate, id);

    const tableHeaders = ['Account ID', 'Company Name', 'Policy Type', 'Insurer', 'Balance till date', 'Last Updated'];

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchTerm]);
    React.useEffect(() => {
        if (!debouncedSearchTerm) {
            dispatch(fetchCDAccountList());
            return;
        }
        dispatch(
            fetchCDAccountListByField({
                searchField: 'accountNumber',
                searchFieldValue: debouncedSearchTerm
            })
        );
    }, [debouncedSearchTerm]);

    const searchAccountPlaceholder= isEndoToolingEnabled ? 'Search by Account ID or Company name'
        : 'Search by Account ID';

    const TableErrorState = () => {
        return isEndoToolingEnabled ? (
            <StyledEmptyStateContainer>
                <TableEmptyState message="No result found" />
            </StyledEmptyStateContainer>
        ) : <TableError>No results</TableError>;
    };
    return (
        <StyledContainer>
            <StyledTableContainer>
                <StyledColumnHeader>
                    <Typography variant='title2' weight='medium'>CD Accounts</Typography>
                    <StyledInput
                        name="text"
                        placeholder={searchAccountPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchTerm(e.target.value);
                        }}
                    />
                    {/* BP-1629*/}
                    {/* <StyledButton onClick={handleAddCdAccount}>*/}
                    {/*    <b>Create a new CD account</b>*/}
                    {/* </StyledButton>*/}
                </StyledColumnHeader>
                {(accountsList.loading ?
                    <StyledTableLoaderContainer>
                        <Loader data-testid="loading-spinner"/>
                    </StyledTableLoaderContainer> :
                    <Table data-testid="table">
                        <TableRow>
                            {tableHeaders.map((header, index) => (
                                <TableHeading key={index}>{header}</TableHeading>
                            ))}
                        </TableRow>
                        {updatedCdAccountsArray && updatedCdAccountsArray.length ?
                            updatedCdAccountsArray.map((cdAccount, index) => (
                                <CDAccountRow
                                    cdAccount={cdAccount}
                                    key={index}
                                    onClick={navigateToLedger}
                                    data-testid={`table-row`}
                                />
                            )): <TableErrorState/>}
                    </Table>)}
            </StyledTableContainer>
            <AddCDAccountModal setIsVisible={setIsVisible} isVisible={isVisible}/>
        </StyledContainer>
    );
};

export default CDAccount;
