import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const applicationState = createSlice({
    name: 'applicationState',
    initialState,
    reducers: {
        resetState(state) {
            state.showWelcomeScreen = initialState.showWelcomeScreen;
        },
        setShowWelcomeScreen(state, { payload }) {
            state.showWelcomeScreen = payload.showWelcomeScreen;
        }
    },
    extraReducers: (builder) => {
        builder.addCase('common/cleanup', (state) => {
            state.showWelcomeScreen = initialState.showWelcomeScreen;
            state = initialState;
        });
    }
});
export default applicationState.reducer;
export const {
    setShowWelcomeScreen,
    resetState
} = applicationState.actions;
