import { IPolicyType } from '../../../redux/slices/PolicySlice/types';
export type IFieldType = 'input' | 'dropdown' | 'date' | 'radioButton';
export type IInputType = 'string' | 'number' | 'Date' | 'boolean';
export interface IPlacement {
    isMandatory: boolean;
    label: string;
    name: string;
    validation?: string;
    type: IFieldType;
    isDisabled?: boolean;
    fullWidth?: boolean;
    availability?: IPolicyType[];
    inputType: IInputType;
    display: boolean;
}

// TODO: Regex to be added
export const placementDetails: IPlacement[] = [
    {
        isMandatory: true,
        label: 'Insurer Name',
        name: 'insurerName',
        type: 'dropdown',
        fullWidth: true,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: true,
        label: 'TPA Name',
        name: 'tpaName',
        type: 'dropdown',
        fullWidth: true,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: true,
        label: 'Policy Start Date',
        name: 'policyStartDate',
        type: 'date',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'Date',
        display: true
    },
    {
        isMandatory: true,
        label: 'Policy End Date',
        name: 'policyEndDate',
        type: 'date',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'Date',
        display: true
    },
    {
        isMandatory: true,
        label: 'Policy Type',
        name: 'policyType',
        type: 'input',
        isDisabled: true,
        fullWidth: true,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: false,
        label: 'Policy Number',
        name: 'policyNumber',
        type: 'input',
        fullWidth: true,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: true,
        label: 'Number Of Employees',
        name: 'numberOfEmployees',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Number Of Dependents',
        name: 'numberOfDependents',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Total Lives',
        name: 'totalLives',
        type: 'input',
        isDisabled: true,
        fullWidth: true,
        availability: ['GMC', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Final Agreed Rate',
        name: 'finalAgreedRate',
        type: 'input',
        fullWidth: false,
        availability: ['GPA', 'GTL'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Net Premium',
        name: 'netPremium',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: false,
        label: 'GST',
        name: 'gst',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Final Premium',
        name: 'finalPremium',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: false,
        label: 'CD Amount(If any)',
        name: 'cdAmount',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: false,
        label: 'Bank Name',
        name: 'bankName',
        type: 'input',
        fullWidth: true,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: false,
        label: 'Payment UTR Number / Cheque No',
        name: 'paymentUtrNumber',
        type: 'input',
        fullWidth: true,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: false,
        label: 'Payment Date',
        name: 'paymentDate',
        type: 'date',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'Date',
        display: true
    },
    {
        isMandatory: true,
        label: 'Transferred Amount',
        name: 'transferredAmount',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'GPA', 'GTL', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Installment Premium',
        name: 'installmentPremium',
        type: 'dropdown',
        fullWidth: true,
        availability: ['GMC', 'COVID'],
        inputType: 'string',
        display: true
    },
    {
        isMandatory: true,
        label: 'First Installment Amount',
        name: 'firstInstallmentAmount',
        type: 'input',
        fullWidth: true,
        availability: ['GMC', 'COVID'],
        inputType: 'number',
        display: true
    },
    {
        isMandatory: true,
        label: 'Second Installment Amount',
        name: 'secondInstallmentAmount',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'COVID'],
        inputType: 'number',
        display: false
    },
    {
        isMandatory: true,
        label: 'Third Installment Amount',
        name: 'thirdInstallmentAmount',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'COVID'],
        inputType: 'number',
        display: false
    },
    {
        isMandatory: true,
        label: 'Fourth Installment Amount',
        name: 'fourthInstallmentAmount',
        type: 'input',
        fullWidth: false,
        availability: ['GMC', 'COVID'],
        inputType: 'number',
        display: false
    },
    {
        isMandatory: true,
        label: 'Policy Nickname',
        name: 'nickName',
        type: 'input',
        fullWidth: true,
        availability: ['GMC', 'COVID', 'GPA', 'GTL'],
        inputType: 'string',
        display: true
    }
];
