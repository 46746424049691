import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxState } from '../../../redux';
import { createCDAccount } from '../../../redux/slices/CDAccountSlice/thunk';
import { ICDAccount, ICRUDCDAccount } from '../../../redux/slices/CDAccountSlice/types';
import { ICompanyList } from '../../../redux/slices/CompanySlice/types';
import { IPolicy } from '../../../redux/slices/PolicySlice/types';
import { CDAccountFormInput, cdAccountValidations } from './types';

export const createLegerService = (
    cdAccountEntryObject: CDAccountFormInput,
    policy: { id: string; value: string; label: string }[],
    dispatch: Dispatch<any>,
    setCdAccountEntryObject: React.Dispatch<React.SetStateAction<CDAccountFormInput>>,
    handleFormNavigation: (param?: any) => void
) => {
    return (): void => {
        /** Validate incoming request before the API call */
        const validatedData = validateData(
            { ...cdAccountEntryObject, policies: { data: policy, error: '' } } as Record<string, unknown>,
            setCdAccountEntryObject
        );

        /** If even a single field has an error return the function */
        if (
            Object.keys(validatedData).filter((key) => (validatedData[key] as { data: any; error: string })?.error)
                .length
        ) {
            return;
        }
        /** Create Request body */
        const cdAccount = {
            accountNumber: cdAccountEntryObject.accountNumber.data,
            companyId: cdAccountEntryObject.companyId.data,
            insurer: cdAccountEntryObject.insurer.data,
            balance: cdAccountEntryObject.balance.data ? parseFloat(cdAccountEntryObject.balance.data) : 0,
            policies: policy.map((p) => p.id).filter((anyValue) => typeof anyValue !== 'undefined'),
            paymentProof: cdAccountEntryObject.paymentProof?.data ? [cdAccountEntryObject.paymentProof?.data] : []
        };
        /** Call the API if successful validation pass */
        dispatch(createCDAccount({ cdAccount: cdAccount as Partial<ICDAccount>, handleFormNavigation }));
    };
};

export const validateData = (
    data: Record<string, unknown>,
    setCdAccountEntryObject: React.Dispatch<React.SetStateAction<CDAccountFormInput>>
): Record<string, unknown> => {
    Object.keys(data).map((key: string) => {
        const fieldObject = cdAccountValidations.filter((fieldObj) => fieldObj.name == key)[0];
        let error = fieldObject.isMandatory
            ? (data[key] as { data: any; error: string }).data.toString().length
                ? ''
                : fieldObject.error
            : '';
        if (!error) {
            error = fieldObject.regex
                ? fieldObject.regex?.test((data[key] as { data: any; error: string }).data)
                    ? ''
                    : fieldObject.error
                : '';
        }
        const value = (data[key] as { data: any; error: string }).data;
        data[key] = {
            data: value,
            error: error
        };
    });
    /** Set the form data along with validation errors */
    setCdAccountEntryObject({ ...(data as unknown as CDAccountFormInput) });
    return data;
};

export const constructPolicyOptions = (
    activePolicyList: { policies: IPolicy[] } & { loading: boolean },
    insurer: string
): {
    id: string;
    value: string;
    label: string;
}[] => {
    return activePolicyList.policies
        .filter((policy) => policy.insurerName == insurer)
        .map((policy) => {
            return {
                id: policy.id,
                value:
                    `${policy.policyType}` +
                    `${policy.isTopUpPolicy ? '(Top-up)' : policy.isParentalPolicy ? '(Parental)' : ''}`,
                label:
                    `${policy.policyType}` +
                    `${policy.isTopUpPolicy ? '(Top-up)' : policy.isParentalPolicy ? '(Parental)' : ''}`
            };
        });
};

export const fetchStateData = (): {
    companyList: ICompanyList;
    cdAccountCrud: ICRUDCDAccount;
} => {
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList);
    const cdAccountCrud = useSelector((state: ReduxState) => state.cdAccountSlice.crudCdAccount);
    return { companyList, cdAccountCrud };
};
