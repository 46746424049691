import styled from 'styled-components';

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        align-items: flex-start;
    }
`;

export const StyledInsuranceImage = styled.img`
    overflow: hidden;
    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    min-height: 50px;
    min-width: 50px;
`;
export const StyledInsuranceNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
`;

export const StyledInsuranceName = styled.div(
    (p) => `
    max-width: 400px;
    color: ${p.theme.colors.beyondGrey};
    font-size: ${p.theme.fontSizes.subHeading};
    line-height: 24px;
    font-weight: 600;
    margin-right: 20px;
    border-radius: 12px;
    letter-spacing: 0.2px;
     @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`
);

export const StyledInsuranceTitle = styled.div(
    (p) => `
    max-width: 400px;
    color: ${p.theme.colors.beyondGrey};
    font-size: ${p.theme.fontSizes.body};
    font-weight: 400;
    margin-right: 20px;
    line-height: 18px;
    letter-spacing: 0.2px;
     @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`
);
