import React from 'react';
import {
    StyledInsuranceCategory,
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledNameContainer,
    StyledInsuranceNameAlignment,
    StyledIDTag
} from './styles';
import { DefaultInsuranceIcon } from '../../../assets/img';
import { ICompanyIdentity } from './types';
import Label from '../Label';

const CompanyIdentity: React.FunctionComponent<ICompanyIdentity> = ({
    companyLogo,
    companyName,
    companyId,
    companyStatus
}) => {
    const logoUrl = companyLogo
        ? `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/company/${companyLogo}`
        : '';
    return (
        <StyledNameContainer>
            <StyledInsuranceImage src={logoUrl || DefaultInsuranceIcon} alt={'Insurance Image'} />
            <StyledInsuranceNameContainer>
                <StyledInsuranceNameAlignment>
                    <StyledInsuranceName>{companyName}</StyledInsuranceName>
                    <Label isActive={companyStatus}>{companyStatus ? '' : 'IN'}ACTIVE</Label>
                </StyledInsuranceNameAlignment>
                <StyledInsuranceCategory>
                    <StyledIDTag>ID: </StyledIDTag>
                    {companyId}
                </StyledInsuranceCategory>
            </StyledInsuranceNameContainer>
        </StyledNameContainer>
    );
};

export default CompanyIdentity;
