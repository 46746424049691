import React from 'react';
import { IDynamicForms } from './types';
import { DynamicField } from '../../atoms';
import { StyledContainer, StyleFormWrapper, StyledFormTitleContainer, StyledDynamicFormWrapper } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledNicknameContainer,
    StyledNicknameHelperContainer,
    StyledNicknameHelperContainerHeader,
    StyledNicknameHelperDescriptionContainer,
    StyledNicknameHelperPolicyDescriptionItem,
    StyledNicknameHelperPolicyDescriptionItemData,
    StyledNicknameHelperPolicyDescriptionItemDivider,
    StyledNicknameHelperPolicyDescriptionItemHeader
} from '../../pages/SPAContainer/pages/PlacementForm/styles';
import { InfoIconGray } from '../../../assets/img';
import { NICKNAME_HELPER_DATA } from '../../pages/SPAContainer/pages/PlacementForm/constants';

const DynamicForms: React.FunctionComponent<IDynamicForms> = ({
    customFieldData,
    formData,
    title,
    onChange,
    validationErrorList,
    options
}) => {
    const [formChanges, setFormChanges] = React.useState<Record<string, string>>({});

    React.useEffect(() => {
        if (Object.keys(formChanges).length) {
            onChange(formChanges);
        }
    }, [formChanges]);

    return (
        <StyledContainer>
            <StyledFormTitleContainer>
                <Typography variant='large' weight='medium'>{title}</Typography>
            </StyledFormTitleContainer>
            <StyleFormWrapper>
                {formData &&
                    (customFieldData || []).map((field, index) => {
                        return (
                            <StyledDynamicFormWrapper
                                key={index}
                                $fullWidth={field.fullWidth ? field.fullWidth : false}
                            >
                                <DynamicField
                                    field={field}
                                    value={
                                        (
                                            formData as unknown as
                                            Record<string, string | number | Date | boolean | null>
                                        )?.[
                                            field.name
                                        ] || null
                                    }
                                    formChanges={(change: Record<string, string>) => setFormChanges(change)}
                                    options={options(field.type, field.name)}
                                    validationError={validationErrorList?.[field.name] || ''}
                                />
                                {
                                    field.name === 'nickName' && <StyledNicknameContainer>
                                        <StyledNicknameHelperContainer>
                                            <StyledNicknameHelperContainerHeader>
                                                <img src={InfoIconGray} />
                                                <Typography
                                                    variant='small'
                                                    weight='medium'
                                                >
                                                    Guidelines for Policy Nickname
                                                </Typography>
                                            </StyledNicknameHelperContainerHeader>
                                            <StyledNicknameHelperDescriptionContainer>
                                                {
                                                    NICKNAME_HELPER_DATA.map((nicknameHelperItem, index) => (
                                                        <>
                                                            <StyledNicknameHelperPolicyDescriptionItem
                                                                key={nicknameHelperItem.headerTitle}
                                                            >
                                                                <StyledNicknameHelperPolicyDescriptionItemHeader>
                                                                    <img src={nicknameHelperItem.headerIcon} />
                                                                    <Typography variant='small' weight='medium'>
                                                                        {nicknameHelperItem.headerTitle}
                                                                    </Typography>
                                                                </StyledNicknameHelperPolicyDescriptionItemHeader>
                                                                <StyledNicknameHelperPolicyDescriptionItemData>
                                                                    <Typography variant='small'>Format</Typography>
                                                                    <Typography variant='small' weight='semiBold'>
                                                                        {nicknameHelperItem.format}
                                                                    </Typography>
                                                                </StyledNicknameHelperPolicyDescriptionItemData>
                                                                <StyledNicknameHelperPolicyDescriptionItemData>
                                                                    <Typography variant='small'>Example</Typography>
                                                                    <Typography variant='small' weight='semiBold'>
                                                                        {nicknameHelperItem.example}
                                                                    </Typography>
                                                                </StyledNicknameHelperPolicyDescriptionItemData>
                                                            </StyledNicknameHelperPolicyDescriptionItem>
                                                            {
                                                                index !== (NICKNAME_HELPER_DATA.length - 1) &&
                                                                <StyledNicknameHelperPolicyDescriptionItemDivider />
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </StyledNicknameHelperDescriptionContainer>
                                        </StyledNicknameHelperContainer>
                                    </StyledNicknameContainer>
                                }
                            </StyledDynamicFormWrapper>
                        );
                    })}
            </StyleFormWrapper>
        </StyledContainer>
    );
};

export default DynamicForms;
