import React from 'react';
import { emptySpaceStyle, spanHeadCellStyle } from './styles';
import { ILivesCostTable } from './types';
import FreshdeskInfoTable from './TableInfo';

const FreshDeskLivesCostTable: React.FC<ILivesCostTable> = ({ details, header, footers }) => {
    const { Table, Row, HeadCell, DataCell } = FreshdeskInfoTable;
    return (
        <Table>
            <Row>
                <HeadCell></HeadCell>
                <HeadCell colSpan={6}>{header}</HeadCell>
            </Row>
            <Row>
                <HeadCell></HeadCell>
                <HeadCell colSpan={2}>Add</HeadCell>
                <HeadCell colSpan={2}>Edit</HeadCell>
                <HeadCell colSpan={2}>Delete</HeadCell>
            </Row>
            <Row>
                <HeadCell></HeadCell>
                <HeadCell>Lives</HeadCell>
                <HeadCell>Cost</HeadCell>
                <HeadCell>Lives</HeadCell>
                <HeadCell>Cost</HeadCell>
                <HeadCell>Lives</HeadCell>
                <HeadCell>Cost</HeadCell>
            </Row>
            {details.map((detail, index) => (
                <Row key={detail.policyType + index}>
                    <DataCell>
                        <span style={spanHeadCellStyle}>{detail.policyType}</span>
                    </DataCell>
                    <DataCell>{detail.ADD.lives}</DataCell>
                    <DataCell>{detail.ADD.costOfEndorsement}</DataCell>
                    <DataCell>{detail.EDIT.lives}</DataCell>
                    <DataCell>{detail.EDIT.costOfEndorsement}</DataCell>
                    <DataCell>{detail.DELETE.lives}</DataCell>
                    <DataCell>{detail.DELETE.costOfEndorsement}</DataCell>
                </Row>
            ))}
            <Row>
                <DataCell colSpan={7}>
                    <div style={emptySpaceStyle} />
                </DataCell>
            </Row>
            {footers.map((footer, index) => (
                <Row key={'footer' + index}>{footer}</Row>
            ))}
        </Table>
    );
};

export default FreshDeskLivesCostTable;
