import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { SPAContainer } from '.';
import firebase from 'firebase';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES, IRouteName } from '../../utils/constants/Routes';
import { LoginPanel } from '../atoms';
import LoginContainer from './LoginContainer';
import { getUserDetails } from './LoginContainer/redux/slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isUserAuthorised } from '../../adapters';

const ExternalRouter: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state.user.userData);

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            const email = user?.email;
            if (!user || !email) {
                // handle signout
                // unregistered user was getting authenticated technically by firebase,
                // but the error was not persistent on screen as state was reset on logout.
                // Hence no need of logout for unregistered user.
                navigate(EXTERNAL_ROUTES.LOGIN);
            } else {
                dispatch(getUserDetails({ key: 'email', value: email }));
            }
        });
    }, []);

    React.useEffect(() => {
        if (userData && userData.data) {
            (async () => {
                try {
                    const isAuthorised = await isUserAuthorised(userData.data.roles, process.env.REACT_APP_NAME || '');
                    if (!isAuthorised) navigate(EXTERNAL_ROUTES.LOGIN);
                } catch (e) {
                    navigate(EXTERNAL_ROUTES.LOGIN);
                }
            })();
        }
    }, [userData]);

    return (
        <Routes>
            <Route index element={<Navigate to={EXTERNAL_ROUTES.APP} />} />
            <Route
                path={EXTERNAL_ROUTES.LOGIN}
                element={
                    <LoginContainer
                        title={'Login to access'}
                        subTitle={'Genome: A better way to manage Loop.'}
                        PanelComponent={LoginPanel}
                        redirectTo={() => navigate(EXTERNAL_ROUTES.APP)}
                    />
                }
            >
                <Route path="*" element={<Navigate to={EXTERNAL_ROUTES.LOGIN} />} />
            </Route>
            <Route path={EXTERNAL_ROUTES.APP} element={<SPAContainer />}>
                {/* Default Route */}
                <Route index element={<INTERNAL_ROUTES.COMPANY_DIRECTORY.Component />} />
                {/* Map of all other routes */}
                {Object.keys(INTERNAL_ROUTES).map((internalRoute, index) => {
                    const Page = INTERNAL_ROUTES[internalRoute as unknown as IRouteName].Component;
                    return (
                        <Route
                            key={index}
                            path={INTERNAL_ROUTES[internalRoute as unknown as IRouteName].route}
                            element={<Page />}
                        />
                    );
                })}
                {/* Unknown route -> redirect */}
                <Route path="*" element={<Navigate to={INTERNAL_ROUTES.COMPANY_DIRECTORY.route} />} />
            </Route>
            <Route path="/" element={<Navigate to={EXTERNAL_ROUTES.APP} />} />
            {/* Need to handle 404 page here */}
            <Route path="*" element={<Navigate to={EXTERNAL_ROUTES.APP} />} />
        </Routes>
    );
};

export default ExternalRouter;
