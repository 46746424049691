export const TABLE_TEXTS = {
    CORRECT: {
        REGULAR: {
            totalCost: 'Total Cost of Endorsement for New Correct Lives in'
        },
        REFUND: {
            totalCost: 'Total Refund for New Correct Lives in'
        }
    },
    INCORRECT: {
        REGULAR: {
            totalCost: 'Total Estimated Cost of Endorsement for New Incorrect Lives in',
            requiredOrNew: 'Amount Required'
        },
        REFUND: {
            totalCost: 'Total Estimated Refund for New Incorrect Lives in',
            requiredOrNew: 'Estimated New CD Balance'
        }
    },
    ENDO: {
        REGULAR: {
            totalCost: 'Total Cost of Endorsement for',
            requiredOrNew: 'Amount Required'
        },
        REFUND: {
            totalCost: 'Total Endorsement Refund for',
            requiredOrNew: 'New CD Balance'
        }
    },
    OVERALL: {
        REGULAR: {
            totalCost: 'Total Estimated Endorsement Cost for',
            requiredOrNew: 'Amount Required'
        },
        REFUND: {
            totalCost: 'Estimated Endorsement Refund for',
            requiredOrNew: 'Estimated New CD Balance'
        }
    }
};
