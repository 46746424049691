import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;
export const StyledTitle = styled.h3`
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: ${(p) => p.theme.fontSizes.title};
    min-width: 350px;
    display: flex;
`;
