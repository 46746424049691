import React from 'react';
import { StyledTitle, StyledSubTitle } from './styles';

export interface ITypography {
    variant: 'title' | 'subtitle';
    children?: React.ReactNode;
}

const Typography: React.FunctionComponent<ITypography> = ({
    variant,
    children
}) => {
    if (variant === 'title') {
        return <StyledTitle>{children}</StyledTitle>;
    }
    if (variant === 'subtitle') {
        return <StyledSubTitle>{children}</StyledSubTitle>;
    }
    return <>{children}</>;
};

export default Typography;
