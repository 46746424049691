import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 0px 0px;
    }
`;

export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    gap: 50px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 0px;
    }
`;

export const StyledIdentityContainer = styled.div`
    margin: 5px;
`;

export const StyledFormContainer = styled.div`
    margin: 0px 50px;
    width: 30%;
    @media (max-width: 768px) {
        margin: 0px 30px;
    }
`;

export const StyledCompanyContainer = styled.div`
    margin: 0px 50px;
    width: 50%;
`;

export const StyledFormTitleContainer = styled.div`
    margin-top: 20px;
`;

export const StyledFormTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.subHeading};
    font-weight: bold;
    padding: 20px 20px 0px 0px;
  `
);

export const StyledRowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledFieldContainer = styled.div<{ fixedWidth?: boolean }>`
    width: ${({ fixedWidth }) => fixedWidth ? '50%' : 'fit-content'};
    max-width: 50%;
    
`;

export const StyledTabContainer = styled.div(
    (p) => `
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background-color: ${p.theme.colors.primary};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `
);

export const StyledGreyTabContainer = styled.div(
    (p) => `
    width: 100%;
    height: 110px;
    margin-top: 28px;
    border-radius: 8px;
    background-color: ${p.theme.colors.white};
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    row-gap: 10px;
    border: 1px solid ${p.theme.colors.defaultBorder};
  `
);

export const StyledContainerText = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    `
);

export const StyledContainerIcons = styled.img`
  overflow: hidden;
  height: 15px;
  width: 15px;
  margin: -2px 5px;
  &:hover {
    text-decoration: underline;
    cursor:pointer;
}
`;
export const StyledTermsTab = styled.div<{ $isSelected: boolean }>(
    (p) => `
    width: 50%;
    height: 70%;
    border-radius: 5px;
    background-color: ${p.$isSelected ? p.theme.colors.white : p.theme.colors.primary};
    margin: 7px;
    cursor: pointer;
  `
);

export const StyledSlabsTab = styled.div<{ $isSelected: boolean }>(
    (p) => `
    width: 50%;
    height: 70%;
    border-radius: 5px;
    background-color: ${p.$isSelected ? p.theme.colors.primary : p.theme.colors.white};
    margin: 7px;
    cursor: pointer;
  `
);

export const StyledTermsTabText = styled.div<{ $isSelected: boolean }>(
    (p) => `
    color:  ${p.$isSelected ? p.theme.colors.black : p.theme.colors.white};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  `
);

export const StyledSlabsTabText = styled.div<{ $isSelected: boolean }>(
    (p) => `
    color: ${p.$isSelected ? p.theme.colors.white : p.theme.colors.black};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  `
);

export const StyledPolicyDetailsContainer = styled.section`
  margin: 0;
  width: 40%;
`;

export const StyledPolicyTermsContainer = styled.section`
  margin: 0;
  width: 50%;
`;

export const StyledPolicyDetails = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledPolicyTable = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDivider = styled.div`
  width: calc(100% + 40px);
  transform: translateX(-20px);
  height: 1px;
  background-color: ${({ theme }) => theme.colors.defaultBorder};
  margin-top: 32px;

  @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledCapitalizeText = styled.span`
  text-transform: uppercase;
`;

export const StyledPolicyTerms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledDocumentMissingPrompt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  margin-top: 32px;
  border: 1px solid ${({ theme }) => theme.colors.defaultBorder};

  > span {
    flex: 1;
    width: 100%;
  }
`;

export const StyledWarningIcon = styled.div`
  width: 32px !important;
  height: 32px !important;
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.planRed};
`;

export const StyledUploadButtonContainer = styled.div`
  min-width: 150px;
`;

export const StyledUploadedDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;

export const StyledUploadedDocumentHeader = styled.header`
  display: flex;

  > span {
    flex: 1;
    width: 100%;
  }
`;

export const StyledUploadedDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
