import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 500px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px 30px;
    }
`;

export const StyledRowAlignment = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 16px;
`;

export const StyledSubTitle = styled.span(
    (p) => `
    width: 100%;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    margin: 15px 0px;
`
);

export const StyledFilter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 15px;
    .leadpage-input {
        width: 100%;
        box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
        border-radius: 8px;
        padding: 9px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #3d4144;
        background: #edeff3;
        border: 0px;
    }
    .react-date-picker__wrapper {
        border: 0px;
    }
`;

export const StyledButtonRow = styled.div`
    margin: 15px;
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const StyledUploadPillWrapper = styled.div`
    width: 400px;
    margin: 30px 0px 0px 0px;
`;

export const StyledButton = styled.button`
    padding: 15px 20px;
    border-radius: 10px;
    margin: 0px 5px 0px 5px;
    font-family: 'Work Sans', 'Inter', Sans-Serif;
    border: none;
    background: ${(p) => p.theme.colors.coverUpGreen};
    color: ${(p) => p.theme.colors.primary};
    &:disabled {
        /* background: ${(p) => p.theme.colors.disabledGrey}; */
        /* color: ${(p) => p.theme.colors.black}; */
        opacity: 0.5;
    }
`;

export const StyledMultiSelectWrapper = styled.div`
    width: 100%;
`;

export const StyledError = styled.div`
    padding: 15px 0px;
`;
