import React, { useEffect, useState } from 'react';
import { fetchCompanyDetails, fetchEnrolmentConfigById } from '../../../../../adapters';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfigsEntity } from '../../../../../redux/slices/PolicySlice/types';
import {
    BodyContainer,
    CardContainer,
    CardContentContainer,
    CardHeader,
    CompanyName,
    Heading,
    HeadingContainer,
    MainContainer
} from './styles';
import { BackIcon } from '../../../../../assets/img';
import { Loader, Typography } from '@loophealth/loop-ui-web-library';
import EnrolmentConfigExtensionModal from '../../../../containers/EnrolmentConfigExtensionModal';
import SendConfirmationEmailsModal from '../../../../containers/SendConfirmationEmailsModal';
import { configObject } from './utils';
import { differenceInDays } from 'date-fns';

const EditEnrolmentConfig: React.FunctionComponent = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [enrolmentConfigData, setEnrolmentConfigData] = useState<ConfigsEntity | null>();
    const [isEnrolmentExtenstionModalOpen, setIsEnrolmentExtenstionModalOpen] = useState<boolean>(false);
    const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>('');

    const enrolmentId = params.get('id') as string;

    const isCompletedTwoDaysAgo =
        differenceInDays(
            new Date(),
            enrolmentConfigData?.enrolmentEndDate ? new Date(enrolmentConfigData.enrolmentEndDate) : new Date()
        ) > 2;

    const fetchEnrolmentConfig = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await fetchEnrolmentConfigById(enrolmentId);
            const response = await fetchCompanyDetails(data.companyId);
            setCompanyName(response.data.company.name);

            if (data && status === 200) {
                setEnrolmentConfigData(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleExtendEnrolmentConfig: () => void = () => setIsEnrolmentExtenstionModalOpen(true);

    const handleSendEmail: () => void = () => setIsSendEmailModalOpen(true);

    const configData = configObject(
        handleExtendEnrolmentConfig,
        enrolmentConfigData?.enrolmentStartDate,
        enrolmentConfigData?.enrolmentEndDate,
        enrolmentConfigData?.joiningCycleStartDate,
        enrolmentConfigData?.joiningCycleEndDate,
        enrolmentConfigData?.recipients,
        enrolmentConfigData?.carbon_copy,
        enrolmentConfigData?.policyIds,
        isCompletedTwoDaysAgo,
        handleSendEmail,
        enrolmentConfigData?.completionEmailsSent,
        enrolmentConfigData?.completed
    );

    useEffect(() => {
        fetchEnrolmentConfig();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <MainContainer>
                <HeadingContainer>
                    <Heading>
                        <img src={BackIcon} height={'12px'} width={'16px'} onClick={() => navigate(-1)} />
                        <Typography variant="title3" color="primary" weight="medium">
                            Enrolment Setup
                        </Typography>
                    </Heading>
                    <CompanyName>
                        <Typography variant="large" color="secondary" weight="regular">
                            {companyName}
                        </Typography>
                    </CompanyName>
                </HeadingContainer>
                <BodyContainer>
                    {configData.map((config) => {
                        return (
                            <CardContainer key={config.id} status={config.status}>
                                <CardHeader>
                                    <img src={config.icon} height={'52px'} width={'52px'} />
                                    <CardContentContainer>
                                        <Typography variant="medium" color="primary" weight="semiBold">
                                            {config.name}
                                        </Typography>
                                        <Typography variant="small" color="primary" weight="regular">
                                            {config.description}
                                        </Typography>
                                    </CardContentContainer>
                                </CardHeader>
                                <config.ChildComponent {...config.props} />
                            </CardContainer>
                        );
                    })}
                </BodyContainer>
            </MainContainer>
            <EnrolmentConfigExtensionModal
                isOpen={isEnrolmentExtenstionModalOpen}
                configData={enrolmentConfigData}
                setIsOpen={setIsEnrolmentExtenstionModalOpen}
            />
            <SendConfirmationEmailsModal isOpen={isSendEmailModalOpen} setIsOpen={setIsSendEmailModalOpen} />
        </>
    );
};

export default EditEnrolmentConfig;
