import React from 'react';
import {
    StyledCDBalanceTableRow,
    StyledCDBalanceOverviewContainer,
    StyledCDBalanceCorrectionRow,
    StyledCDBalanceHeader,
    StyledCDBalanceAmountWrapper,
    StyledDashedSpacer,
    StyledLedgerButtonWrapper,
    StyledRefreshLink,
    StyledRefreshSVG
} from './styles';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { useNavigate } from 'react-router-dom';
import HorizontalSpacer from '../../../../atoms/HorizontalSpacer';
import { transformCDBalanceOverviewForUI } from '../../../../../utils/constants/Endorsement/transforms';
import { ICDBalanceOverviewProps, ICDBalanceOverviewUIProps } from './types';
import { useToast } from '../../../../hooks/useToast';
import { LoopBadge, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { parseResponse } from '../../../../../utils/common';
import { recalculatePremiums } from '../../../../../adapters';
import { isRecalculatePremiumsTriggerEnabled } from '../../../../../utils/featureFlags';
import { RefreshIcon } from '../../../../../assets/img';

const CDBalanceOverview: React.FunctionComponent<ICDBalanceOverviewProps> = (cdBalanceOverview) => {
    const toast = useToast();
    const navigate = useNavigate();
    const cdBalanceOverviewData: ICDBalanceOverviewUIProps = transformCDBalanceOverviewForUI(cdBalanceOverview);

    const [isRecalculating, setIsRecalculating] = React.useState<boolean>(false);

    const navigateToCDLedger = () => {
        if (!cdBalanceOverview?.cdAccountId) {
            toast?.displayToast('error', 'Unable to process', 'No linked CD Account found for this endorsement entity');
            return;
        }
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.CD_LEDGER.route}?id=${cdBalanceOverview?.cdAccountId}`);
    };

    const onRefreshPremiumCalculations = async () => {
        setIsRecalculating(true);
        if (cdBalanceOverview.endorsementId) {
            const [error] = await parseResponse(
                recalculatePremiums(cdBalanceOverview.endorsementId)
            );
            if (error) {
                toast?.displayToast('error', 'Unable to recalculate premiums at the moment', error.toString(), 5000);
                setIsRecalculating(false);
                return;
            }
            window.location.reload();
        } else {
            setIsRecalculating(false);
        }
    };

    const isRefund = (cdBalanceOverview.netCostOfEndorsement || 0) > 0;

    return (
        <StyledCDBalanceOverviewContainer>
            <StyledCDBalanceHeader>
                <Typography variant="medium" weight="medium">
                        CD Balance Overview
                </Typography>
                <StyledRefreshLink>
                    {cdBalanceOverview.isPreProcessStates && isRecalculatePremiumsTriggerEnabled && (
                        <StyledRefreshSVG
                            src={RefreshIcon}
                            onClick={onRefreshPremiumCalculations}
                            isRecalculating={isRecalculating}
                        />
                    )}
                    {cdBalanceOverview.isPreProcessStates && (
                        <LoopBadge
                            variant="overflow"
                            badgeType={cdBalanceOverviewData.balanceSufficient ? 'success' : 'error'}
                            text={cdBalanceOverviewData.balanceSufficient ? 'Sufficient' : 'Low'}
                        />
                    )}
                </StyledRefreshLink>

            </StyledCDBalanceHeader>
            <HorizontalSpacer margin="16px 0 24px" />
            <StyledCDBalanceTableRow>
                <Typography variant="small" weight="semiBold">
                    Current CD Balance
                </Typography>
                <StyledCDBalanceAmountWrapper>
                    <Typography variant="small" weight="bold">
                        {cdBalanceOverviewData.cdBalance}
                    </Typography>
                </StyledCDBalanceAmountWrapper>
            </StyledCDBalanceTableRow>
            <StyledDashedSpacer $margin='0 0 16px'/>
            <StyledCDBalanceCorrectionRow>
                <Typography variant="small" color="secondary">Additions</Typography>
                <Typography variant="small" weight="medium" color='error'>
                    {cdBalanceOverviewData.additionCost}
                </Typography>
            </StyledCDBalanceCorrectionRow>
            <StyledCDBalanceCorrectionRow>
                <Typography variant="small" color="secondary">Edits</Typography>
                <Typography variant="small" weight="medium"
                    color={(cdBalanceOverview.editsCost || 0) > 0 ? 'error' : 'emerald'}
                >
                    {cdBalanceOverviewData.editsCost}
                </Typography>
            </StyledCDBalanceCorrectionRow>
            <StyledCDBalanceCorrectionRow>
                <Typography variant="small" color="secondary">
                    Deletions (Refund)
                </Typography>
                <Typography variant="small" weight="medium" color="emerald">
                    {cdBalanceOverviewData.deleteCost}
                </Typography>
            </StyledCDBalanceCorrectionRow>
            <StyledDashedSpacer $margin='12px 0 16px'/>
            <StyledCDBalanceTableRow>
                <Typography variant="small" color="secondary">
                    Net Endorsement Cost
                </Typography>
                <Typography
                    variant="small"
                    weight="medium"
                    color={isRefund ? 'error' : 'emerald'}>
                    {cdBalanceOverviewData.netCostOfEndorsement}
                </Typography>
            </StyledCDBalanceTableRow>
            {(cdBalanceOverview.shouldShowEstimatedBalance && (
                <StyledCDBalanceTableRow>
                    <Typography variant="small" color="secondary">
                        Estimated CD Balance
                    </Typography>
                    <Typography variant="small" weight="medium">
                        {cdBalanceOverviewData.estimatedBalancePostEndo}
                    </Typography>
                </StyledCDBalanceTableRow>
            )) || <></>}
            <StyledLedgerButtonWrapper>
                <LoopButton variant='outline' onClick={navigateToCDLedger}>View CD Ledger</LoopButton>
            </StyledLedgerButtonWrapper>
        </StyledCDBalanceOverviewContainer>
    );
};
export default CDBalanceOverview;
