import React from 'react';
import Loader from '../Loader';
import {
    StyledTable,
    StyledTableData,
    StyledTableHeading,
    StyledTableRow,
    StyledTableError,
    StyledTableLoading
} from './styles';
import { ITableData, ITableHeading } from './types';

const Table: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children, ...rest }) => {
    return <StyledTable {...rest}>{children}</StyledTable>;
};
const TableRow: React.FunctionComponent<{
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ children, onClick, ...rest }) => {
    return (
        <StyledTableRow onClick={onClick} {...rest}>
            {children}
        </StyledTableRow>
    );
};
const TableError: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
    return <StyledTableError>{children}</StyledTableError>;
};
const TableLoading: React.FunctionComponent<{ backgroundColor?: string }> = ({ backgroundColor }) => {
    return (
        <StyledTableLoading $backgroundColor={backgroundColor}>
            <Loader />
        </StyledTableLoading>
    );
};

const TableHeading: React.FunctionComponent<ITableHeading> = ({ children, index = false, align = 'left' }) => {
    return (
        <StyledTableHeading $index={index} $align={align}>
            {children}
        </StyledTableHeading>
    );
};
const TableData: React.FunctionComponent<ITableData> = ({ children, index = false, align = 'left' }) => {
    return (
        <StyledTableData $index={index} $align={align}>
            {children}
        </StyledTableData>
    );
};

export { Table, TableRow, TableHeading, TableData, TableError, TableLoading };
