import { LoopButton, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import {
    downloadHRMappings,
    setupHrdUsingGSheet
} from '../../../../../adapters';
import { excelDownloadUsingBlob } from '../../../../../utils/common';
import { Title } from '../../../../atoms';
import generateHRAccessErrorsFile from './ErrorExcel';
import {
    StyledContainer,
    StyledSheetDescription,
    StyledWrapper
} from './styles';

const HrAddition: React.FunctionComponent = () => {
    const toast = Toast.useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const onHrAddition = async () => {
        setIsPersistanceLoading(true);
        try {
            const result = await setupHrdUsingGSheet();
            const errorsCount = result.data.reduce(
                (prev, sheetData) => prev + sheetData.errors.length,
                0
            );
            if (errorsCount) {
                toast?.error(`${errorsCount} errors found.`, 'HRD Mapping');
                await generateHRAccessErrorsFile(result.data, 'HRD_Mapping_Errors.xlsx');
            } else {
                toast?.success('No errors found.', 'HRD Mapping');
            }
        } catch (e) {
            toast?.error((e as Error).message, 'Error adding HRD Mapping');
        } finally {
            setIsPersistanceLoading(false);
        }
    };
    const url = process.env.REACT_APP_GS_HRD_SETUP_AND_ACCESS_MANAGEMENT;

    const onHrMappings = async () => {
        setIsDownloading(true);
        try {
            const getRsp = await downloadHRMappings();
            excelDownloadUsingBlob(getRsp, 'HR_Mappings.xlsx');
        } catch (e) {
            toast?.error((e as Error).message, 'Error downloading HR Mappings');
        } finally {
            setIsDownloading(false);
        }
    };
    return (
        <StyledContainer>
            <Title>HRD Setup and Access Management</Title>
            <div>
                <LoopButton
                    variant="filled"
                    onClick={onHrAddition}
                    isLoading={isPersistanceLoading}
                >
                    Add Hr Details
                </LoopButton>
            </div>
            <StyledSheetDescription>
                &#40;
                <Typography variant="medium" weight="bold">
                    NOTE:&nbsp;
                    <Typography variant="medium">
                        Above button will invoke Hr addition script which will read data
                        from&nbsp;
                        <a href={url} target="_blank" rel="noreferrer">
                            excel
                        </a>
                        &nbsp; and store it in firebase database
                    </Typography>
                </Typography>
                &#41;
            </StyledSheetDescription>
            <StyledWrapper>
                <LoopButton
                    variant="outline"
                    onClick={onHrMappings}
                    isLoading={isDownloading}
                >
                    Download HR Mappings
                </LoopButton>
            </StyledWrapper>
        </StyledContainer>
    );
};

export default HrAddition;
