import styled from 'styled-components';

export const StyledModalWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    gap: 16px;
    border-radius: 12px;
    background: ${(p) => p.theme.colors.secondaryBG};
`;

export const StyledRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 48px;
`;

export const StyledRowElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

export const StyledButtonRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: 32px;
`;
