import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 56px;
  gap: 24px;
`;
export const StyledStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-radius: 20px;
  gap: 16px;
  background-color: ${(p) => p.theme.colors.secondary};
`;
export const StyledDropdownWrapper = styled.div`
  width: 60%;
`;
export const StyledStepHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const StyledConfigTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const SelectCompanyPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 162px;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledEnrolmentPolicyListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const StyledPolicyScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const StyledEnrolmentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0 8px;
`;
export const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  max-width: 800px;
  width: 80%;

  div {
    flex: 1;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 32px 32px 32px;
  border-radius: 16px;
  background: white;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  width: 40%;
`;

export const StyledChipContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 16px;
  background: #F8F9FA;
  border-radius: 1000px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledIcon = styled.img`
`;

export const StyledCloseIcon = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
`;

export const StyledModalBackdrop = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const StyledModalBody = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 6px 12px 0px #0000001C;
  max-width: 400px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
