import { IUserPolicySlice } from './types';

export const initialState: IUserPolicySlice = {
    userPolicies: {
        data: null,
        error: null,
        loading: false
    },
    fetchUserPolicies: {
        data: null,
        error: null,
        loading: false
    }
};
