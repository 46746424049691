import React from 'react';

const useDebounce = <T>(value: T, cb: (value: T) => void): void => {
    const [debounceTimer, setDebounceTimer] = React.useState<ReturnType<typeof setTimeout> | null>(null);
    React.useEffect(() => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(
            setTimeout(() => {
                cb(value);
            }, 350)
        );
    }, [value]);
};

export default useDebounce;
