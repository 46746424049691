import React from 'react';
import { IRawTableCell, IRawTableRow } from './types';
import { dataCellStyle, headCellStyle, spanDataCellStyle, spanHeadCellStyle, tableStyle } from './styles';

const Table: React.FC = ({ children }) => {
    return (
        <table style={tableStyle}>
            <tbody>{children}</tbody>
        </table>
    );
};
const Row: React.FC<IRawTableRow> = ({ children, ...rowProps }) => {
    return (
        <tr {...rowProps}>
            {children}
        </tr>
    );
};
const HeadCell: React.FC<IRawTableCell> = ({ children, ...headProps }) => {
    return (
        <th style={headCellStyle} {...headProps}>
            <span style={spanHeadCellStyle}> {children}</span>
        </th>
    );
};
const DataCell: React.FC<IRawTableCell> = ({ children, ...dataProps }) => {
    return (
        <td style={dataCellStyle} {...dataProps}>
            <span style={spanDataCellStyle}>{children}</span>
        </td>
    );
};

export default { Table, Row, HeadCell, DataCell };
