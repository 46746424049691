import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    StyledEditSVG,
    StyledEndorsementHeader,
    StyledEndorsementOverviewContainer,
    StyledEndorsementTableRow
} from './styles';
import { CheckmarkCircleIcon, EditIcon, LinkIcon } from '../../../../../assets/img';
import HorizontalSpacer from '../../../../atoms/HorizontalSpacer';
import { IEndorsementOverview } from './types';
import { Input } from '../../../../atoms';
import { parseResponse, showApiFailureToast } from '../../../../../utils/common';
import { updateSupportTicketId } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import RenameEndoModal from '../../../../containers/RenameEndoModal';
import { Typography } from '@loophealth/loop-ui-web-library';
import { isEndoToolingEnabled } from '../../../../../utils/featureFlags';

const EndorsementOverview: React.FunctionComponent<IEndorsementOverview> = ({
    endorsementId,
    endorsementNumber,
    endorsementCycle,
    nickName,
    insurerName,
    policyNumber,
    policyType,
    policyId,
    supportTicketId,
    supportTicketOwner,
    currentStatus,
    fetchEndorsementDetails
}) => {
    const toast = useToast();
    const [isSupportTicketEditMode, setSupportTicketEditMode] = useState<boolean>(false);
    const [ticketId, setTicketId] = useState<string>(supportTicketId || '');
    const [isEndoRenameModalVisible, setEndoRenameModalVisible] = useState<boolean>(false);

    useEffect(() => {
        setTicketId(supportTicketId || '');
    }, [supportTicketId]);

    const onFreshDeskTicketClicked = () => {
        if (!supportTicketId) return;
        window.open(`https://loophealth.freshdesk.com/a/tickets/${supportTicketId}`);
    };

    const onTicketIdChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setTicketId(e.target.value);
    };

    const toggleSupportTicketEditMode = async () => {
        if (!endorsementId) return;
        if (isSupportTicketEditMode) {
            if (!ticketId) {
                showApiFailureToast(toast, `Ticket Id can't be empty`);
                return;
            }
            const [error] = await parseResponse(updateSupportTicketId(endorsementId, { supportTicketId: ticketId }));
            if (error) {
                showApiFailureToast(toast, error.toString());
                return;
            }
        }
        setSupportTicketEditMode((isSupportTicketEditMode) => !isSupportTicketEditMode);
    };
    const toggleEndoRenameModal = () => {
        setEndoRenameModalVisible((isEndoRenameModalVisible) => !isEndoRenameModalVisible);
    };

    const onEndoRenamed = () => {
        setEndoRenameModalVisible(false);
        fetchEndorsementDetails();
    };

    return (
        <StyledEndorsementOverviewContainer>
            {endorsementId && isEndoToolingEnabled && (
                <RenameEndoModal
                    isVisible={isEndoRenameModalVisible}
                    handleCloseModal={onEndoRenamed}
                    endorsementId={endorsementId}
                    endorsementCycle={endorsementCycle}
                />
            )}
            <StyledEndorsementHeader>
                <Typography data-testid="endorsement-overview" variant="medium" weight="semiBold">
                    Endorsement Overview
                </Typography>
            </StyledEndorsementHeader>
            <HorizontalSpacer margin="20px 0" />
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Insurer:
                </Typography>
                <Typography variant="small" weight="semiBold" data-testid="insurer-name">
                    {insurerName}
                </Typography>
            </StyledEndorsementTableRow>
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Policy ID:
                </Typography>
                <Typography variant="small" weight="semiBold" data-testid="policy-id">
                    {policyId}
                </Typography>
            </StyledEndorsementTableRow>
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Policy Type:
                </Typography>
                <Typography variant="small" weight="semiBold" data-testid="policy-type">
                    {policyType}
                </Typography>
            </StyledEndorsementTableRow>
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Endorsement Number:
                </Typography>
                <Typography variant="small" weight="semiBold" data-testid="endorsement-no">
                    {endorsementNumber}
                </Typography>
            </StyledEndorsementTableRow>
            {isEndoToolingEnabled && (
                <StyledEndorsementTableRow>
                    <Typography variant="small" weight="medium" color="secondary">
                        Endo Cycle:
                    </Typography>
                    <Typography variant="small" weight="semiBold" data-testid="endorsement-cycle">
                        {endorsementCycle}
                        <StyledEditSVG src={EditIcon} onClick={toggleEndoRenameModal} />
                    </Typography>
                </StyledEndorsementTableRow>
            )}
            {isEndoToolingEnabled && (
                <StyledEndorsementTableRow>
                    <Typography variant="small" weight="medium" color="secondary">
                        Policy Nickname:
                    </Typography>
                    <Typography variant="small" weight="semiBold" data-testid="policy-nickname">
                        {nickName}
                    </Typography>
                </StyledEndorsementTableRow>
            )}
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Policy Number:
                </Typography>
                <Typography variant="small" weight="semiBold" data-testid="policy-number">
                    {policyNumber}
                </Typography>
            </StyledEndorsementTableRow>
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Freshdesk Ticket:
                </Typography>
                {
                    currentStatus === 'DRAFT' ? (
                        '-'
                    ) : (
                        <a>
                            {isSupportTicketEditMode ? (
                                <Input
                                    name="ticketId"
                                    placeholder="Enter id"
                                    value={ticketId}
                                    onChange={onTicketIdChanged}
                                    errorText={''}
                                />
                            ) : (
                                <span onClick={onFreshDeskTicketClicked}>
                                    {supportTicketId ? ticketId : 'Link Now'}
                                </span>
                            )}
                            {isSupportTicketEditMode ? (
                                <StyledEditSVG src={CheckmarkCircleIcon} onClick={toggleSupportTicketEditMode} />
                            ) : supportTicketId ? (
                                <StyledEditSVG src={EditIcon} onClick={toggleSupportTicketEditMode} />
                            ) : (
                                <img src={LinkIcon} onClick={toggleSupportTicketEditMode} alt="link-now" />
                            )}
                        </a>
                    )
                }
            </StyledEndorsementTableRow>
            <StyledEndorsementTableRow>
                <Typography variant="small" weight="medium" color="secondary">
                    Freshdesk Owner:
                </Typography>
                <Typography variant="small" weight="semiBold">
                    {supportTicketOwner}
                </Typography>
            </StyledEndorsementTableRow>
        </StyledEndorsementOverviewContainer>
    );
};
export default EndorsementOverview;
