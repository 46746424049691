import React from 'react';
import { fetchUserGroup } from '../../../adapters';
import { IUserGroup } from '../../../adapters/types';
import { HookResponse } from '../types';

const useFetchUserGroup =
    (props: { companyId: string, userId?: never } | { companyId?: never, userId: string }):
        HookResponse<IUserGroup | undefined> => {
        const [data, setData] = React.useState<IUserGroup>();
        const [isLoading, setIsLoading] = React.useState(false);
        const [error, setIsError] = React.useState('');
        React.useEffect(() => {
            setIsLoading(true);
            fetchUserGroup(props)
                .then((res) => {
                    setData(res.data);
                    setIsError('');
                    setIsLoading(false);
                })
                .catch((error) => {
                    setData(undefined);
                    setIsError((error as Error).message);
                    setIsLoading(false);
                });
        }, [props?.userId, props?.companyId]);

        return {
            data,
            error,
            isLoading
        };
    };

export default useFetchUserGroup;
