import React from 'react';
import { Typography, useTable } from '@loophealth/loop-ui-web-library';
import { StyledSpaceBetween, StyledValidatorSummaryTable } from '../styles';
import LivesCostTable from './LivesCostTable';
import { formatCurrencyElseDefault } from '../utils';
import { IIncorrectTable } from '../types';
import { TABLE_TEXTS } from './constants';

export const IncorrectTable: React.FC<IIncorrectTable> = ({
    incorrectEntries,
    totalLives
}) => {
    const { DataCell } = useTable('info');

    const incorrectTableFooters = incorrectEntries.operations.map((op) => {
        const incorrectTexts = TABLE_TEXTS.INCORRECT[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return [
            <DataCell colSpan={7} key={op.policyId + op.policyType}>
                <StyledSpaceBetween>
                    <Typography variant="small" weight="medium">
                        {incorrectTexts.totalCost}&nbsp;{op.policyType}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {formatCurrencyElseDefault(op.policyCost)}
                    </Typography>
                </StyledSpaceBetween>
            </DataCell>,
            <React.Fragment key={op.policyId + op.policyType + 'cdBalance'}>
                <DataCell
                    colSpan={op.requiredOrNew ? 4 : 7}
                >
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            {op.policyType}&nbsp;Current CD Balance&nbsp;(
                            {op.isCdSufficient ? 'Sufficient' : 'Low'})
                        </Typography>
                        <Typography
                            variant="small"
                            color={op.isCdSufficient ? 'secondary' : 'error'}
                        >
                            {op.isCdMissing ? 'Missing' : formatCurrencyElseDefault(op.cdBalance)}
                        </Typography>
                    </StyledSpaceBetween>
                </DataCell>
                {
                    !!op.requiredOrNew &&
                    <DataCell colSpan={3}>
                        <StyledSpaceBetween>
                            <Typography variant="small" weight="medium">
                                {op.policyType}&nbsp;{incorrectTexts.requiredOrNew}
                            </Typography>
                            <Typography variant="small" color="secondary">
                                {formatCurrencyElseDefault(op.requiredOrNew)}
                            </Typography>
                        </StyledSpaceBetween>
                    </DataCell>
                }
            </React.Fragment>
        ];
    }).flat();

    return (
        <StyledValidatorSummaryTable>
            <LivesCostTable
                details={incorrectEntries?.operations || []}
                header={
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            Total New Incorrect Lives
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {incorrectEntries?.totalLives}/{totalLives}
                        </Typography>
                    </StyledSpaceBetween>
                }
                footers={incorrectTableFooters}
            />
        </StyledValidatorSummaryTable>
    );
};
