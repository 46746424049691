import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { bulkMoveCRToNextState } from '../../../../../redux/slices/ChangeRequestSlice/thunks';
import { updateChangeRequestsAfterMoveCR } from '../../../../../redux/slices/ChangeRequestSlice';
import {
    IinValidEntries,
    IStatus,
    IChangeRequest,
    IcrListItem
} from '../../../../../redux/slices/ChangeRequestSlice/types';
import { Chip } from '../../../../atoms';
import {
    getStatusColor,
    getStatusLabel,
    whiteColor
} from '../../../../../utils/constants/ChangeRequest/PossibleValues';
import { excelDownload, getDisplayTextFromUpper } from '../../../../../utils/common';
import { CloseIcon } from '../../../../../assets/img';
import { getPrevCRPossibleState } from '../../../../../utils/constants/ChangeRequest/PossibleValues';
import {
    StyledAbsLoading,
    StyledActionWrapper,
    StyledSuccessWrapper,
    StyledFailWrapper,
    StyledRowContainedWrap,
    StyledRowContainedWrapSpaceBw,
    StyledRowSide,
    StyledButtonWrapper,
    StyledBtn,
    StyledInvertedBtn,
    StyledAnchorBtn,
    StyledChipButtonWrapper,
    StyledCloseIcon,
    StyledSearchLabel
} from './styles';

const updateChangeRequestsWithCrData = (
    fetchCrdata: IChangeRequest[],
    crUpdates: IcrListItem[],
    validStatuses: IStatus[]
) => {
    return fetchCrdata
        .map((cr) => {
            const update = crUpdates.find((update) => update.crId === cr.id);
            return update ? { ...cr, status: update.state } : cr;
        })
        .filter((cr) => validStatuses.includes(cr.status));
};

interface ICRStatusMoveSlider {
    validStatuses: IStatus[];
    closeSlider(): void;
    possibleMoveStates: IStatus[];
}
const CRStatusMoveSlider: React.FunctionComponent<ICRStatusMoveSlider> = ({
    validStatuses,
    closeSlider,
    possibleMoveStates = []
}) => {
    // hooks
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('cid');

    // redux states
    const selectedChangeRequests = useSelector((state: ReduxState) => state.changeRequestSlice.selectedChangeRequests);
    const changeRequestData = useSelector((state: ReduxState) => state.changeRequestSlice.fetchChangeRequests.data);
    const moveCRToNextStateSelector = useSelector(
        (state: ReduxState) => state.changeRequestSlice.bulkMoveCRToNextState
    );
    const inValidEntries = moveCRToNextStateSelector.data?.data?.inValidEntries;

    // local states
    const [moveStage, setMoveStage] = React.useState<'initial' | 'confirmAwait' | 'done'>('initial');
    const [nextStatus, setNextStatus] = React.useState<IStatus>('IN_PROGRESS');

    useEffect(() => {
        if (moveCRToNextStateSelector.error === null && moveCRToNextStateSelector.loading === false) {
            if (inValidEntries && moveCRToNextStateSelector.crList?.length > 0) {
                const invalidCrIds = (inValidEntries as IinValidEntries[]).map((entry) => entry.crId);
                const validCrsWithState = moveCRToNextStateSelector.crList
                    .filter((cr) => !invalidCrIds.includes(cr.crId));
                dispatch(
                    updateChangeRequestsAfterMoveCR(
                        updateChangeRequestsWithCrData(changeRequestData, validCrsWithState, validStatuses)
                    )
                );
            }
        }
    }, [moveCRToNextStateSelector.data]);

    useEffect(() => {
        if (!selectedChangeRequests.length) {
            closeSlider();
        }
    }, [selectedChangeRequests]);

    const moveCRToNextState = (status: IStatus) => {
        const prevCRState = getPrevCRPossibleState(nextStatus);
        setMoveStage('done');
        const crList = selectedChangeRequests
            .filter(({ status }) => status === prevCRState)
            .map((cr) => ({ crId: cr.id, state: status }));
        dispatch(bulkMoveCRToNextState(crList));
    };
    const downloadFailedChangeRequests = () => {
        const invalidCRDownload = (inValidEntries as IinValidEntries[]).map((entry) => {
            const cr = changeRequestData.find((c) => c.id === entry.crId);
            return { ...cr, nextStatus: entry.state, error: entry.error };
        });
        excelDownload(invalidCRDownload, `failedChangeRequests${companyId ? `-${companyId}` : ''}`);
    };

    if (moveStage === 'initial') {
        return (
            <StyledActionWrapper>
                <StyledRowContainedWrap>
                    <StyledRowSide>
                        <StyledSearchLabel>Move selected to :</StyledSearchLabel>
                        <StyledChipButtonWrapper>
                            {possibleMoveStates.map((value, i) => (
                                <Chip
                                    key={i}
                                    color={getStatusColor(value)}
                                    backgroundColor={whiteColor}
                                    borderRadius={8}
                                    label={getDisplayTextFromUpper(value)}
                                    variant="outlined"
                                    onClick={() => {
                                        setMoveStage('confirmAwait');
                                        setNextStatus(value);
                                    }}
                                />
                            ))}
                        </StyledChipButtonWrapper>
                        <StyledButtonWrapper>
                            <StyledCloseIcon
                                onClick={() => closeSlider()}
                                src={CloseIcon}
                                alt="close change request update"
                            />
                        </StyledButtonWrapper>
                    </StyledRowSide>
                </StyledRowContainedWrap>
            </StyledActionWrapper>
        );
    }
    if (moveStage === 'confirmAwait') {
        const prevCRState = getPrevCRPossibleState(nextStatus);
        let isAllOfSelectedCRInCorrectState = !prevCRState;
        if (prevCRState) {
            isAllOfSelectedCRInCorrectState = selectedChangeRequests.every(({ status }) => status === prevCRState);
        }
        return (
            <StyledActionWrapper>
                <StyledRowContainedWrapSpaceBw>
                    <StyledRowSide>
                        <StyledSearchLabel>
                            {isAllOfSelectedCRInCorrectState ?
                                'Are you sure you want to change the status of selected member(s) to:' :
                                (`Are you sure you to move the rows with the status '${
                                    getStatusLabel(prevCRState as IStatus)
                                }' to '${
                                    getStatusLabel(nextStatus as IStatus)
                                }', while the rest are retained in selection`)
                            }
                        </StyledSearchLabel>
                        {isAllOfSelectedCRInCorrectState ? (
                            <StyledChipButtonWrapper>
                                <Chip
                                    color={getStatusColor(nextStatus)}
                                    borderRadius={8}
                                    backgroundColor={whiteColor}
                                    label={getDisplayTextFromUpper(nextStatus ?? '-')}
                                    variant="outlined"
                                />
                            </StyledChipButtonWrapper>
                        ): null}
                        <StyledSearchLabel>?</StyledSearchLabel>
                    </StyledRowSide>
                    <StyledRowSide>
                        <StyledButtonWrapper>
                            <StyledBtn onClick={() => moveCRToNextState(nextStatus)}>Confirm</StyledBtn>
                            <StyledInvertedBtn onClick={() => setMoveStage('initial')}>Cancel</StyledInvertedBtn>
                        </StyledButtonWrapper>
                    </StyledRowSide>
                </StyledRowContainedWrapSpaceBw>
            </StyledActionWrapper>
        );
    }
    if (moveStage === 'done') {
        if (moveCRToNextStateSelector.loading) {
            return <StyledAbsLoading>Loading...</StyledAbsLoading>;
        }
        if (moveCRToNextStateSelector.error) {
            return (
                <StyledActionWrapper>
                    <StyledRowContainedWrapSpaceBw>
                        <StyledRowSide>
                            <StyledSearchLabel>Oops! There was some error moving the members to</StyledSearchLabel>
                            <StyledChipButtonWrapper>
                                <Chip
                                    color={getStatusColor(nextStatus)}
                                    borderRadius={8}
                                    backgroundColor={whiteColor}
                                    label={getDisplayTextFromUpper(nextStatus ?? '-')}
                                    variant="outlined"
                                />
                            </StyledChipButtonWrapper>
                            <StyledInvertedBtn onClick={() => moveCRToNextState(nextStatus)}>
                                Try Again
                            </StyledInvertedBtn>
                            <StyledButtonWrapper>
                                <StyledCloseIcon
                                    onClick={() => setMoveStage('initial')}
                                    src={CloseIcon}
                                    alt="close change request update"
                                />
                            </StyledButtonWrapper>
                        </StyledRowSide>
                    </StyledRowContainedWrapSpaceBw>
                </StyledActionWrapper>
            );
        }
        if (moveCRToNextStateSelector?.data) {
            if (inValidEntries && inValidEntries?.length > 0) {
                return (
                    <StyledFailWrapper>
                        <StyledRowContainedWrapSpaceBw>
                            <StyledRowSide>
                                <StyledSearchLabel>Some of selected member(s) could not be moved to</StyledSearchLabel>
                                <StyledChipButtonWrapper>
                                    <Chip
                                        color={getStatusColor(nextStatus)}
                                        borderRadius={8}
                                        backgroundColor={whiteColor}
                                        label={getDisplayTextFromUpper(nextStatus ?? '-')}
                                        variant="outlined"
                                    />
                                </StyledChipButtonWrapper>
                                <StyledButtonWrapper>
                                    <StyledAnchorBtn onClick={downloadFailedChangeRequests}>
                                        Download the failed change requests
                                    </StyledAnchorBtn>
                                </StyledButtonWrapper>
                                <StyledButtonWrapper>
                                    <StyledCloseIcon
                                        onClick={() => closeSlider()}
                                        src={CloseIcon}
                                        alt="close change request update"
                                    />
                                </StyledButtonWrapper>
                            </StyledRowSide>
                        </StyledRowContainedWrapSpaceBw>
                    </StyledFailWrapper>
                );
            } else {
                const areSelectedCrsLeftOnSuccess = selectedChangeRequests.length > 0;
                return (
                    <StyledSuccessWrapper>
                        <StyledRowContainedWrapSpaceBw>
                            <StyledRowSide>
                                {areSelectedCrsLeftOnSuccess ? (<>
                                    <StyledSearchLabel>
                                        {`Selected member(s) could not be moved${
                                            getStatusLabel(nextStatus as IStatus) === '' ?
                                                '.' :
                                                ` to '${getStatusLabel(nextStatus as IStatus)}'.`
                                        }`}
                                    </StyledSearchLabel>
                                    <StyledButtonWrapper>
                                        <StyledBtn onClick={() => setMoveStage('initial')}>
                                            Retry
                                        </StyledBtn>
                                    </StyledButtonWrapper>
                                </>
                                ) : (<>
                                    <StyledSearchLabel>
                                        Selected member(s) are successfully moved to
                                    </StyledSearchLabel>
                                    <StyledChipButtonWrapper>
                                        <Chip
                                            color={getStatusColor(nextStatus)}
                                            borderRadius={8}
                                            backgroundColor={whiteColor}
                                            label={getDisplayTextFromUpper(nextStatus ?? '-')}
                                            variant="outlined"
                                        />
                                    </StyledChipButtonWrapper>
                                </>)}
                                <StyledButtonWrapper>
                                    <StyledCloseIcon
                                        onClick={() => closeSlider()}
                                        src={CloseIcon}
                                        alt="close change request update"
                                    />
                                </StyledButtonWrapper>
                            </StyledRowSide>
                        </StyledRowContainedWrapSpaceBw>
                    </StyledSuccessWrapper>
                );
            }
        }
    }
    return null;
};

export default CRStatusMoveSlider;
