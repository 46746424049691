import styled from 'styled-components';

export const StyledInput = styled.input`
    display: none;
`;
export const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
