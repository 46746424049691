import { Column } from 'exceljs';

export const SKF_ONBOARDED_MEMBERS_HEADERS: Partial<Column>[] = [
    {
        header: 'S. No',
        key: 'srNo',
        width: 20
    },
    {
        header: 'Can Delete Parent',
        key: 'canDeleteParent',
        width: 20
    },
    {
        header: 'Risk Role',
        key: 'riskRole',
        width: 20
    },
    {
        header: 'Employee Id',
        key: 'employeeId',
        width: 20
    },
    {
        header: 'Name',
        key: 'name',
        width: 20
    },
    {
        header: 'Gender',
        key: 'gender',
        width: 20
    },
    {
        header: 'Mobile',
        key: 'mobile',
        width: 20
    },
    {
        header: 'Dob',
        key: 'dob',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Joining Date',
        key: 'joiningDate',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Email',
        key: 'email',
        width: 20
    },
    {
        header: 'Ctc',
        key: 'ctc',
        width: 20
    },
    {
        header: 'Enrolment Deadline',
        key: 'enrolmentDeadline',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Updated At',
        key: 'updatedAt',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'User Id',
        key: 'userId',
        width: 20
    },
    {
        header: 'Gmc Topup Amount',
        key: 'gmcTopupAmount',
        width: 20
    },
    {
        header: 'Gmc Topup Premium',
        key: 'gmcTopupPremium',
        width: 20
    },
    {
        header: 'Parental Addition Cost',
        key: 'parentalAdditionCost',
        width: 20
    },
    {
        header: 'Gpa Sum Insured',
        key: 'gpaSumInsured',
        width: 20
    },
    {
        header: 'Gpa Topup Amount',
        key: 'gpaTopupAmount',
        width: 20
    },
    {
        header: 'Gpa Topup Premium',
        key: 'gpaTopupPremium',
        width: 20
    },
    {
        header: 'Gtl Sum Insured',
        key: 'gtlSumInsured',
        width: 20
    },
    {
        header: 'Gtl Topup Amount',
        key: 'gtlTopupAmount',
        width: 20
    },
    {
        header: 'Gtl Topup Premium',
        key: 'gtlTopupPremium',
        width: 20
    },
    {
        header: 'Opd Value',
        key: 'opdValue',
        width: 20
    },
    {
        header: 'Opd Premium',
        key: 'opdPremium',
        width: 20
    },
    {
        header: 'Annual Premium',
        key: 'annualPremium',
        width: 20
    }
];
