import {
    IHrMappingErrorTypes,
    IHrMappingHeaderInfoType,
    IHrMappingSheetNames,
    IHrMappingSheetsResponse
} from '../types';
import ExcelJs, { Worksheet } from 'exceljs';
import {
    HR_MAPPING_SHEET_HEADERS_DEF,
    HR_MAPPING_SHEET_HEADERS_EXTRA_INFO,
    HR_MAPPING_SHEET_HEADERS_LIST,
    HR_MAPPING_INFO_HEADERS_BG
} from './constants';
import {
    capitalizeFirstLetter,
    excelDownloadUsingBlob
} from '../../../../../../utils/common';

const generateHRAccessErrorsFile = async (
    sheetsData: IHrMappingSheetsResponse,
    fileName: string
): Promise<void> => {
    const workbook = new ExcelJs.Workbook();
    sheetsData.forEach((sheetsData) => {
        const worksheet = workbook.addWorksheet(sheetsData.sheet);
        insertErrorDataIntoWorksheet(
            worksheet,
            sheetsData.sheet,
            sheetsData.errors
        );
        formatWorksheet(worksheet, sheetsData.sheet);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const file = new File([buffer], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    excelDownloadUsingBlob(file, fileName);
};

const insertErrorDataIntoWorksheet = (
    worksheet: Worksheet,
    sheetName: IHrMappingSheetNames,
    errors: IHrMappingErrorTypes[]
): void => {
    worksheet.columns = HR_MAPPING_SHEET_HEADERS_LIST[sheetName].map(
        (headerKey) => HR_MAPPING_SHEET_HEADERS_DEF[headerKey]
    );
    worksheet.addRows(errors);
    worksheet.insertRow(
        1,
        HR_MAPPING_SHEET_HEADERS_LIST[sheetName].map(
            (headerKey) => HR_MAPPING_SHEET_HEADERS_EXTRA_INFO[headerKey]
        )
    );
};

const formatWorksheet = (
    worksheet: Worksheet,
    sheetName: IHrMappingSheetNames
): void => {
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.font = {
            name: 'Calibri',
            family: 2,
            size: rowNumber === 1 ? 12 : 14,
            bold: rowNumber === 2,
            italic: rowNumber === 1,
            color: { argb: '000000' }
        };
        row.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true
        };
        row.eachCell((cell) => {
            row.height = rowNumber <= 2
                ? 20
                : Math.max(20, row.height, cell.value?.toString().trim().length || 0);
            cell.border = {
                left: { style: 'thin' },
                right: { style: 'thin' },
                top: rowNumber === 1 ? { style: 'thin' } : undefined,
                bottom: rowNumber === 2 ? { style: 'thin' } : undefined
            };
            if (rowNumber === 1) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: HR_MAPPING_INFO_HEADERS_BG[
                          cell.value as IHrMappingHeaderInfoType
                        ]
                    }
                };
            } else if (rowNumber === 2) {
                if (cell.text === 'Errors') {
                    cell.font = { ...cell.font, color: { argb: 'EB5757' } };
                }
            } else {
                if (
                    HR_MAPPING_SHEET_HEADERS_LIST[sheetName][Number(cell.col) - 1] === 'errors'
                ) {
                    cell.font = { ...cell.font, color: { argb: 'EB5757' } };
                    cell.value = Object.entries(cell.value as IHrMappingErrorTypes['errors'])
                        .reduce(
                            (prev, [key, value]) =>
                                `${prev}${capitalizeFirstLetter(key)}: ${capitalizeFirstLetter(
                                    value
                                )}\n`,
                            ''
                        )
                        .trim();
                }
            }
        });
    });
};

export default generateHRAccessErrorsFile;
