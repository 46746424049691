import {
    formatCurrency,
    groupArrayOfObjectsByKey
} from '../../../utils/common';
import { IEndoResultData } from '../../pages/SPAContainer/pages/ValidationSummary/types';
import { IPolicyOperations, ITableEntry } from './types';

export const formatCurrencyElseDefault = (
    currency?: number,
    depend: unknown = true,
    inverse = false
): string =>
    currency === undefined || currency === null || !depend
        ? '-'
        : currency < 0 || inverse
            ? `+₹${formatCurrency(Math.abs(currency))}`
            : `₹${formatCurrency(currency)}`;

export const textPlaceholder = (string?: string | number): string =>
    !string ? '-' : `${string}`;

export const getEndoTableEntries = (
    endoResult: IEndoResultData
): ITableEntry => {
    const { endorsements } = endoResult;

    let totalEndoLives = 0;
    const endoCorrectTableEntries: IPolicyOperations[] = endorsements.map(
        ({ cdAccount, endorsement }) => {
            const operations = groupArrayOfObjectsByKey(
                endorsement.memberData || [],
                (endo) => endo.operationType
            );
            const uniqueEditionLives = new Set<string>;

            const additionCost = operations.ADD?.reduce(
                (prev, current) => prev + current.totalProRatePremium,
                0
            ) || 0;
            const editionCost = operations.EDIT?.reduce((prev, current) => {
                uniqueEditionLives.add(current.userId);
                return prev + current.totalProRatePremium;
            }, 0) || 0;
            const deletionCost = operations.DELETE?.reduce(
                (prev, current) => prev + current.totalProRatePremium,
                0
            ) || 0;

            totalEndoLives +=
              (operations.ADD?.length || 0) +
              uniqueEditionLives.size +
              (operations.DELETE?.length || 0);

            const currentEndoCost =
              additionCost +
              editionCost -
              deletionCost;

            const cdBalance = cdAccount?.balance || 0;
            const isCdSufficient = !!cdAccount && cdBalance - currentEndoCost >= 0;
            const requiredOrNew = isCdSufficient
                ? currentEndoCost < 0
                    ? cdBalance + currentEndoCost
                    : 0
                : currentEndoCost - cdBalance;

            return {
                policyId: endorsement.policyId,
                policyType: endorsement.policyType,
                ADD: {
                    lives: textPlaceholder(operations.ADD?.length),
                    costOfEndorsement: formatCurrencyElseDefault(additionCost, operations?.ADD?.length)
                },
                EDIT: {
                    lives: textPlaceholder(uniqueEditionLives.size),
                    costOfEndorsement: formatCurrencyElseDefault(editionCost, uniqueEditionLives.size)
                },
                DELETE: {
                    lives: textPlaceholder(operations.DELETE?.length),
                    costOfEndorsement: formatCurrencyElseDefault(deletionCost, operations.DELETE?.length, true)
                },
                cdBalance,
                isCdSufficient,
                isCdMissing: !cdAccount,
                policyCost: currentEndoCost,
                requiredOrNew
            };
        }
    );
    return {
        totalLives: `${totalEndoLives}/${totalEndoLives}`,
        operations: endoCorrectTableEntries
    };
};

export const getCorrectBucketEntries = (
    endoResult: IEndoResultData
): ITableEntry => {
    const { endorsements, correctEntries } = endoResult;

    let totalBucketCorrectLives = 0;
    const correctBucketTableEntries: IPolicyOperations[] = endorsements.map(
        ({ cdAccount, endorsement, contextMembers }) => {
            const operations = groupArrayOfObjectsByKey(
                endorsement.memberData || [],
                (endo) => endo.operationType
            );

            const contextOperations = groupArrayOfObjectsByKey(
                contextMembers,
                (endo) => endo.operationType
            );

            let additionCost = 0;
            let editionCost = 0;

            const addEntries =
              operations.ADD?.filter((addEntry) => {
                  const entry = correctEntries.ADD.find(
                      (correctEntry) =>
                          correctEntry.employee_id === addEntry.employeeId &&
                    (
                      correctEntry.relationship_to_account_holders as string
                    )?.toLowerCase() ===
                      (addEntry.relationship as string)?.toLowerCase()
                  );
                  if (entry) {
                      additionCost += addEntry.totalProRatePremium;
                      return true;
                  }
                  return false;
              }) || [];

            const uniqueEditionLives = contextOperations.EDIT?.reduce(
                (acc: Set<string>, editEntry) => {
                    editionCost += editEntry.totalProRatePremium;
                    acc.add(editEntry.userId);
                    return acc;
                },
                new Set<string>()
            );

            const deletionCost = contextOperations.DELETE?.reduce((prevCost, currentEntry) => {
                return prevCost + currentEntry.totalProRatePremium;
            }, 0) || 0;

            const currentBucketCost = additionCost + editionCost - deletionCost;

            totalBucketCorrectLives +=
              addEntries.length +
              (uniqueEditionLives?.size || 0) +
              (contextOperations.DELETE?.length || 0);

            const cdBalance = cdAccount?.balance || 0;
            const isCdSufficient = !!cdAccount && cdBalance - currentBucketCost >= 0;
            const requiredOrNew = isCdSufficient
                ? currentBucketCost < 0
                    ? cdBalance + currentBucketCost
                    : 0
                : currentBucketCost - cdBalance;
            return {
                policyId: endorsement.policyId,
                policyType: endorsement.policyType,
                ADD: {
                    lives: textPlaceholder(addEntries.length),
                    costOfEndorsement: formatCurrencyElseDefault(additionCost, addEntries.length)
                },
                EDIT: {
                    lives: textPlaceholder(uniqueEditionLives?.size),
                    costOfEndorsement: formatCurrencyElseDefault(editionCost, uniqueEditionLives?.size || 0)
                },
                DELETE: {
                    lives: textPlaceholder(contextOperations.DELETE?.length),
                    costOfEndorsement: formatCurrencyElseDefault(deletionCost, contextOperations.DELETE?.length, true)
                },
                cdBalance,
                isCdSufficient,
                isCdMissing: !cdAccount,
                policyCost: currentBucketCost,
                requiredOrNew
            };
        }
    );

    return {
        totalLives: totalBucketCorrectLives,
        operations: correctBucketTableEntries
    };
};

export const getOverallCostingForBucket = (
    endoEntries: ITableEntry,
    incorrectEntries: ITableEntry
): IPolicyOperations[] => {
    const uniquePolicies: Record<string, IPolicyOperations> =
    endoEntries.operations.reduce(
        (prev, current) => ({
            ...prev,
            [current.policyId]: current
        }),
        {}
    );
    incorrectEntries.operations.forEach((incorrectOp) => {
        const currentOp = uniquePolicies[incorrectOp.policyId];
        if (currentOp) {
            const totalCost = currentOp.policyCost + incorrectOp.policyCost;
            const isCdSufficient = !currentOp.isCdMissing && currentOp.cdBalance - totalCost >= 0;
            const requiredOrNew = isCdSufficient
                ? totalCost < 0
                    ? currentOp.cdBalance + totalCost
                    : 0
                : totalCost - currentOp.cdBalance;
            uniquePolicies[incorrectOp.policyId] = {
                ...currentOp,
                policyCost: totalCost,
                isCdSufficient,
                requiredOrNew
            };
        } else {
            uniquePolicies[incorrectOp.policyId] = incorrectOp;
        }
    });
    return Object.values(uniquePolicies);
};
