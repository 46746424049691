import React, { useEffect, useMemo } from 'react';
import {
    BackButtonContainer,
    ButtonContainer,
    CloseButton,
    ConfirmButtonContainer,
    DatePickerContainer,
    DurationChangeDateContainer,
    ModalContainer,
    ModalContent,
    ModalContentSecondStep,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NewDateContainer,
    PreviousDateContainer,
    StatusContainer,
    StyledGrayCard,
    TwoButtonContainer,
    StyledLabelAndDateContainer
} from './styles';
import { Form, LoopButton, Toast, Typography } from '@loophealth/loop-ui-web-library';
import { AddCalendarDisabledIcon, ChevronRightIcon } from '../../../assets/img';
import { max, format, addDays, differenceInDays, isBefore, isAfter, min } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extendEnrolmentConfigAPI } from '../../../adapters';
import { IProps } from './types';
import usePolicies from '../../hooks/business-hooks/usePolicies';

const EnrolmentConfigExtensionModal: React.FC<IProps> = ({ isOpen = true, configData, setIsOpen }) => {
    const [params] = useSearchParams();
    const toast = Toast.useToast();
    const navigate = useNavigate();

    const [policyList] = usePolicies(configData?.policyIds || []);

    const [date, setDate] = React.useState<Date | null>(null);
    const [dateChangeConfirmation, setDateChangeConfirmation] = React.useState<boolean>(false);

    const enrolmentId = params.get('id') as string;

    const selectedDate = date && format(date, 'do MMM');

    const prevDuration =
        configData?.enrolmentStartDate && configData?.enrolmentEndDate
            ? `${format(new Date(configData?.enrolmentStartDate), 'do MMM')} -
                ${format(new Date(configData?.enrolmentEndDate), 'do MMM')}`
            : '';

    const currentDuration =
        configData?.enrolmentStartDate && selectedDate
            ? `${format(new Date(configData?.enrolmentStartDate), 'do MMM')} -
                ${format(date, 'do MMM')}`
            : '';
    const minDate = max([
        new Date(),
        configData?.enrolmentEndDate ? addDays(new Date(configData?.enrolmentEndDate), 1) : new Date()
    ]);

    const minMaxDate = useMemo(() => {
        let minDate: Date | undefined = policyList?.[0]?.policyStartDate;
        let maxDate: Date | undefined = policyList?.[0]?.policyEndDate;

        if (!policyList.length) {
            return { minDate, maxDate };
        }

        if (minDate && maxDate) {
            policyList.forEach((selectedPolicy) => {
                if (selectedPolicy.policyStartDate && minDate) {
                    minDate = isBefore(selectedPolicy?.policyStartDate, minDate)
                        ? selectedPolicy.policyStartDate
                        : minDate;
                }
                if (selectedPolicy.policyEndDate && maxDate) {
                    maxDate = isAfter(selectedPolicy.policyEndDate, maxDate) ? selectedPolicy.policyEndDate : maxDate;
                }
            });
        }
        return { minDate, maxDate };
    }, [policyList]);

    const maxDate = min([
        addDays(configData?.enrolmentStartDate ? new Date(configData?.enrolmentStartDate) : new Date(), 30),
        minMaxDate.maxDate
    ]);

    const validations = () => {
        const today: Date = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        if (configData?.enrolmentEndDate && date) {
            // ED2 >= RD2
            if (isBefore(date, new Date(configData?.enrolmentEndDate))) {
                return toast?.error(
                    'Member registration end date cannot be before Enrolment cycle end date',
                    'Error occurred'
                );
            }
        }

        // ED1 > RD1
        if (
            configData?.joiningCycleStartDate &&
            date &&
            configData?.enrolmentStartDate &&
            configData?.enrolmentEndDate
        ) {
            if (minMaxDate.minDate && minMaxDate.maxDate) {
                const { maxDate }: { minDate: Date | undefined; maxDate: Date | undefined } = minMaxDate;

                if (isAfter(date, maxDate)) {
                    return toast?.error('Enrolment Cycle end date cannot exceed the policy end date');
                }
            }
        }
    };

    const extendEnrolmentConfig = async () => {
        if (!date) return;
        try {
            const { status } = await extendEnrolmentConfigAPI(enrolmentId, date);
            if (status === 204) {
                toast?.success(null, 'Enrolment due date has been successfully updated', {
                    expires: 3000,
                    closeOnClick: true,
                    showCloseIcon: true
                });
                setIsOpen(false);
                navigate(-1);
            } else {
                throw new Error();
            }
        } catch (error) {
            toast?.error('Failed to extend enrolment');
        }
    };

    const dateDifferenceInDays: number =
        date && configData?.enrolmentEndDate ? differenceInDays(date, new Date(configData?.enrolmentEndDate)) : 0;

    useEffect(() => {
        validations();
    }, [date]);

    if (!isOpen) return <></>;

    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalHeader>
                    <div>
                        <img src={AddCalendarDisabledIcon} />
                        <Typography variant="large" weight="bold">
                            Extend Enrolment
                        </Typography>
                    </div>
                    <CloseButton onClick={() => setIsOpen(false)}>&times;</CloseButton>
                </ModalHeader>
                {dateChangeConfirmation ? (
                    <ModalContentSecondStep>
                        <Typography weight="semiBold" variant="medium" color="secondary">
                            Are you sure you want to extend this enrolment?
                        </Typography>
                        <DurationChangeDateContainer>
                            <PreviousDateContainer>
                                <img src={AddCalendarDisabledIcon} height={'24px'} width={'24px'} />
                                <Typography variant="extraSmall" color="secondary">
                                    Previous duration
                                </Typography>
                                <Typography weight="bold" variant="small" color="secondary">
                                    {prevDuration}
                                </Typography>
                            </PreviousDateContainer>
                            <img src={ChevronRightIcon} height={'24px'} width={'24px'} alt="right-arrow" />
                            <NewDateContainer>
                                <img src={AddCalendarDisabledIcon} height={'24px'} width={'24px'} />
                                <Typography variant="extraSmall" color="secondary">
                                    New duration
                                </Typography>
                                <Typography weight="bold" variant="small" color="emerald">
                                    {currentDuration}
                                </Typography>
                            </NewDateContainer>
                        </DurationChangeDateContainer>
                        <Typography weight="regular" variant="small" color="secondary">
                            Enrolment will be extended by {dateDifferenceInDays}{' '}
                            {dateDifferenceInDays > 1 ? 'days' : 'day'}
                        </Typography>
                    </ModalContentSecondStep>
                ) : (
                    <>
                        <ModalContent>
                            <StatusContainer>
                                <Typography variant="small" color="secondary">
                                    CURRENT ENROLMENT STATUS
                                </Typography>
                                <StyledGrayCard>
                                    <Typography variant="medium">Completed Enrolment</Typography>
                                    <Typography variant="small">{prevDuration}</Typography>
                                </StyledGrayCard>
                            </StatusContainer>
                        </ModalContent>
                        <DatePickerContainer>
                            <Typography variant="small" color="secondary">
                                CHANGE DUE DATE
                            </Typography>
                            <StyledLabelAndDateContainer>
                                <label>Select due date</label>
                                <Form.DatePicker
                                    value={date}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    iconOrder="left"
                                    onChange={setDate}
                                />
                            </StyledLabelAndDateContainer>
                        </DatePickerContainer>
                    </>
                )}
                <ModalFooter>
                    {dateChangeConfirmation ? (
                        <TwoButtonContainer>
                            <BackButtonContainer>
                                <LoopButton variant="outline" onClick={() => setDateChangeConfirmation(false)}>
                                    Back
                                </LoopButton>
                            </BackButtonContainer>
                            <ConfirmButtonContainer>
                                <LoopButton variant="filled" onClick={extendEnrolmentConfig}>
                                    Confirm Extension
                                </LoopButton>
                            </ConfirmButtonContainer>
                        </TwoButtonContainer>
                    ) : (
                        <ButtonContainer>
                            <LoopButton
                                variant={date ? 'filled' : 'disabled'}
                                onClick={() => setDateChangeConfirmation(true)}
                            >
                                Next
                            </LoopButton>
                        </ButtonContainer>
                    )}
                </ModalFooter>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default EnrolmentConfigExtensionModal;
