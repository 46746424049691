import React from 'react';
import { ICustomBottomNavigator } from './types';
import { StyledNavigator, StyledNavigatorContainer, StyledRightButton } from './styles';
import { LoopButton } from '@loophealth/loop-ui-web-library';

const CustomBottomNavigator: React.FunctionComponent<ICustomBottomNavigator> = ({
    rightButtonClick,
    leftButtonClick,
    activateNext,
    rightButtonLabel,
    leftButtonLabel
}) => {
    return (
        <StyledNavigatorContainer>
            <StyledNavigator>
                <div>
                    <LoopButton variant={'outline'} size="small" onClick={leftButtonClick}>
                        {leftButtonLabel}
                    </LoopButton>
                </div>
                <StyledRightButton>
                    <LoopButton variant={activateNext ? 'filled' : 'disabled'} size="small" onClick={rightButtonClick}>
                        {rightButtonLabel}
                    </LoopButton>
                </StyledRightButton>
            </StyledNavigator>
        </StyledNavigatorContainer>
    );
};

export default CustomBottomNavigator;
