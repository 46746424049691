import { WorkBook, utils, write } from 'xlsx-js-style';
import {
    ErrorSheetColumnNamesADD,
    ErrorSheetColumnNamesOFFBOARD,
    IExcelSheet,
    IMemberDataForExcel,
    IUserAttributeNames
} from './types';

export const downloadErrorExcel = (
    blob: Blob,
    fileName: string
): void => {
    const fileLink = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = fileName;
    link.click();
    link.remove();
};

const errorSheetDownloader = (sheets: IExcelSheet<Record<IUserAttributeNames | 'Errors', unknown>>[]): Blob => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const workbook = utils.book_new();
    // for each sheet
    sheets.map((sheet) => {
        switch (sheet.sheetName) {
        case 'ADD':
        case 'EDIT':
            createRegistrationWorksheet(workbook, sheet);
            break;
        case 'OFFBOARD':
            createOffboardWorkSheet(workbook, sheet);
            break;
        default:
            throw new Error('Invalid Sheet Requested.');
        }
    });
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const exelBlob = new Blob([excelBuffer], { type: fileType });
    return exelBlob;
};

export const getErrorExcelFile = (data: IMemberDataForExcel) : Blob => {
    return errorSheetDownloader([
        {
            sheetName: 'ADD',
            rows: data.ADD
        },
        {
            sheetName: 'EDIT',
            rows: data.EDIT
        },
        {
            sheetName: 'OFFBOARD',
            rows: data.OFFBOARD
        }
    ]);
};

const createOffboardWorkSheet = (
    workbook: WorkBook,
    sheetData: IExcelSheet<Record<IUserAttributeNames | 'Errors', unknown>>
) => {
    const row0 = ErrorSheetColumnNamesOFFBOARD.map((key) => ({
        v: key,
        t: 's',
        s: {
            font: { bold: true, sz: 14 },
            alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
            fill: { fgColor: { rgb: 'E9E9E9' } },
            border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        }
    }));
    const rows: any[] = [row0];
    const BDR = {
        top: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } }
    };
    sheetData.rows.forEach((row) => {
        const { data, props } = row;
        const transform = ErrorSheetColumnNamesOFFBOARD.map((key) => ({
            // big ts issue. need to fix it
            v: (data as Record<string, unknown>)[key] || '',
            t: 's',
            s: {
                font: { name: 'Courier', sz: 12, color: { rgb: props[key]?.fontColour || '000000' } },
                alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
                fill: {
                    fgColor: {
                        rgb: props[key]?.cellColour || 'FFFFFF'
                    }
                },
                border: BDR
            }
        }));
        rows.push(transform);
    });
    const worksheet = utils.aoa_to_sheet(rows, {
        cellDates: true,
        dateNF: 'dd mmm yyyy'
    });
    worksheet['!cols'] = [{ width: 25 }, { width: 25 }, { width: 25 }, { width: 25 }, { width: 40 }];
    utils.book_append_sheet(workbook, worksheet, sheetData.sheetName);
};

const createRegistrationWorksheet = (
    workbook: WorkBook,
    sheetData: IExcelSheet<Record<IUserAttributeNames | 'Errors', unknown>>
) => {
    // create the header
    const row0 = ErrorSheetColumnNamesADD.map((key) => ({
        v: key,
        t: 's',
        s: {
            font: { bold: true, sz: 14 },
            alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
            fill: { fgColor: { rgb: 'E9E9E9' } },
            border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        }
    }));
    const rows: any[] = [row0];
    const BDR = {
        top: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } }
    };
    sheetData.rows.forEach((row) => {
        const { data, props } = row;
        const transform = ErrorSheetColumnNamesADD.map((key) => ({
            v: data[key] || '',
            t: 's',
            s: {
                font: { name: 'Courier', sz: 12, color: { rgb: props[key]?.fontColour || '000000' } },
                alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
                fill: {
                    fgColor: {
                        rgb: props[key]?.cellColour || 'FFFFFF'
                    }
                },
                border: BDR
            }
        }));
        rows.push(transform);
    });
    const worksheet = utils.aoa_to_sheet(rows, {
        cellDates: true,
        dateNF: 'dd mmm yyyy'
    });
    worksheet['!cols'] = [
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 50 }
    ];
    utils.book_append_sheet(workbook, worksheet, sheetData.sheetName);
};

export default errorSheetDownloader;
