import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import ChangeRequestTab from './ChangeRequestTab';
import { ArrowLeftIcon } from '../../../../../assets/img';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import {
    StyledVWContainer,
    StyledRowContainedWrap,
    StyledRow,
    StyledRowSide,
    StyledTitle,
    StyledTitleIcon,
    StyledTabs,
    StyledTab,
    StyledDownloadBtn
} from './styles';
import { excelDownload } from '../../../../../utils/common';
import { useToast } from '../../../../hooks/useToast';
import { TABS } from './constants';
import {
    transformChangeRequestsParams,
    transformExcelRow
} from '../../../../../utils/constants/ChangeRequest/transforms';
import { fetchAllChangeRequests } from '../../../../../redux/slices/ChangeRequestSlice/thunks';
import { IChangeRequestOptions, IStatus } from '../../../../../redux/slices/ChangeRequestSlice/types';
import { resetFetchAllChangeRequestsData } from '../../../../../redux/slices/ChangeRequestSlice';

const DownloadBtn = ({
    disabled = false,
    onClick,
    text
}: {
    disabled: boolean;
    onClick: (event: React.SyntheticEvent) => void;
    text: string;
}) => {
    return (
        <StyledDownloadBtn onClick={onClick} disabled={disabled}>
            {text}
        </StyledDownloadBtn>
    );
};

const ChangeRequestsOfOneCompany: React.FunctionComponent = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('cid');
    const companyName = searchParams.get('name');
    const [tab, setTab] = useState<string>(TABS.OPEN_REQUESTS);
    const changeRequestsSlice = useSelector((state: ReduxState) => state.changeRequestSlice.fetchChangeRequests);
    const selectedChangeRequests = useSelector((state: ReduxState) => state.changeRequestSlice.selectedChangeRequests);
    const allChangeRequestsSlice = useSelector((state: ReduxState) => state.changeRequestSlice.fetchAllChangeRequests);
    const [isAllCRsDownloading, setIsAllCRsDownloading] = useState(false);

    if (!companyId) {
        return <div>Could not find the Company</div>;
    }
    const disabled = !changeRequestsSlice.data || changeRequestsSlice.data?.length === 0;
    const status = tab === TABS.OPEN_REQUESTS ? (['PENDING', 'IN_PROGRESS'] as IStatus[]) : undefined;

    useEffect(() => {
        dispatch(resetFetchAllChangeRequestsData());
    }, [companyId, status]);

    useEffect(() => {
        if (isAllCRsDownloading && !allChangeRequestsSlice.loading && allChangeRequestsSlice.error == null) {
            setIsAllCRsDownloading(false);
            if (!!companyId && allChangeRequestsSlice.data.length !== 0) {
                toast?.displayToast('success', 'moving change requests to excel', '');
                excelDownload(allChangeRequestsSlice.data.map(transformExcelRow), `all-ChangeRequests-${companyId}`);
            } else {
                toast?.displayToast('warning', 'No change requests!', '');
            }
        }
    }, [isAllCRsDownloading, allChangeRequestsSlice.loading]);

    const handleDownloadData = () => {
        if (selectedChangeRequests.length === 0) {
            setIsAllCRsDownloading(true);
            if (
                allChangeRequestsSlice.data.length === 0 &&
                allChangeRequestsSlice.error === null &&
                !allChangeRequestsSlice.loading
            ) {
                if (!!companyId) {
                    const params = transformChangeRequestsParams({
                        companyId,
                        status
                        // selectFilters,
                        // dateCreated,
                    });
                    toast?.displayToast('warning', 'Downloading change requests!', 'This might take some time.');
                    dispatch(fetchAllChangeRequests(params as IChangeRequestOptions));
                }
            }
        } else {
            excelDownload(selectedChangeRequests.map(transformExcelRow), `selected-ChangeRequests-${companyId}`);
        }
    };
    const getTabNameFromConstant = (name: string) => {
        return name.charAt(0) + name.slice(1).toLowerCase().replace('_', ' ');
    };
    return (
        <StyledVWContainer>
            <StyledRowContainedWrap>
                <StyledRow>
                    <StyledRowSide>
                        <StyledTitleIcon
                            src={ArrowLeftIcon}
                            alt={'Back Icon'}
                            onClick={() =>
                                navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.CHANGE_REQUESTS_COMPANIES.route)
                            }
                        />
                        {companyName ? <StyledTitle>{companyName}</StyledTitle> : null}
                        <StyledTabs>
                            <StyledTab active={TABS.OPEN_REQUESTS === tab} onClick={() => setTab(TABS.OPEN_REQUESTS)}>
                                {getTabNameFromConstant(TABS.OPEN_REQUESTS)}
                            </StyledTab>
                            <StyledTab active={TABS.ALL_REQUESTS === tab} onClick={() => setTab(TABS.ALL_REQUESTS)}>
                                {getTabNameFromConstant(TABS.ALL_REQUESTS)}
                            </StyledTab>
                        </StyledTabs>
                    </StyledRowSide>
                    <StyledRowSide>
                        <DownloadBtn
                            disabled={disabled}
                            onClick={handleDownloadData}
                            text={`Download data${
                                selectedChangeRequests.length > 0 ? ` (${selectedChangeRequests.length})` : ''
                            }`}
                        />
                    </StyledRowSide>
                </StyledRow>
            </StyledRowContainedWrap>
            <ChangeRequestTab
                status={status}
                actionable={tab === TABS.OPEN_REQUESTS}
                selectedChangeRequests={selectedChangeRequests}
            />
        </StyledVWContainer>
    );
};

export default ChangeRequestsOfOneCompany;
