import React from 'react';
import { LoopLogo } from '../../../assets/img';
import FloatingNavigation from '../FloatingNavigation';
import { NavigationItemsContainer, StyledContainer, StyledImage } from './styles';
import { INavigationPanel } from './types';

const NavigationPanel: React.FunctionComponent<INavigationPanel> = ({ isVisible, setIsVisible, children }) => {
    const [startLeaveAnimation, setStartLeaveAnimation] = React.useState(false);
    return (
        <FloatingNavigation
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            startLeaveAnimation={startLeaveAnimation}
            setStartLeaveAnimation={setStartLeaveAnimation}
        >
            <StyledContainer $startLeaveAnimation={startLeaveAnimation}>
                <StyledImage src={LoopLogo} />
                <NavigationItemsContainer>{children}</NavigationItemsContainer>
            </StyledContainer>
        </FloatingNavigation>
    );
};

export default NavigationPanel;
