import styled from 'styled-components';

export const StyledContainer = styled.div`
    max-width: 1000px;
    width: 100%;
    height: 100%;
`;
export const StyledTitle = styled.h3`
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: ${(p) => p.theme.fontSizes.title};
`;
export const StyledSubTitle = styled.span(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    margin: 15px 0px;
`
);
