import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    padding: 16px;
    gap: 8px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    width: 50%;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    flex: 1;
`;

export const ButtonContainer = styled.div`
    height: 36px;
    width: fit-content;
`;
