import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchPolicy, createPolicy, updatePolicy } from './thunk';
import { IPolicy } from './types';

const policySlice = createSlice({
    name: 'policySlice',
    initialState,
    reducers: {
        updatePolicyId(state, { payload }) {
            const { policyId } = payload;
            const currentState = state.placementFormDetails;
            if (currentState) {
                const updatedObject: IPolicy = { ...currentState, id: policyId };
                state.placementFormDetails = updatedObject;
            }
        },
        updatePolicyList(state, { payload }) {
            const { updatedPolicy, policyId } = payload;
            const currentState = state.policyList.data;
            const selectedPolicy = currentState?.filter((pol) => pol.id === policyId)[0];
            const filteredPolicies = currentState?.filter((pol) => pol.id !== policyId) || [];

            const newPolicyObject = { ...updatedPolicy, insurerLogo: selectedPolicy?.insurerLogo };

            filteredPolicies.push(newPolicyObject);
            state.policyList.data = filteredPolicies;
        },
        resetState(state) {
            state.policyList = initialState.policyList;
        },
        setPlacementFormDetails(state, { payload }) {
            state.placementFormDetails = payload.placementFormDetails;
        },
        resetCRUDState(state) {
            state.crudPolicy = initialState.crudPolicy;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPolicy.pending as any, (state) => {
                state.policyList.loading = true;
            })
            .addCase(fetchPolicy.rejected as any as any, (state, { error }) => {
                state.policyList.error = error;
                state.policyList.loading = false;
            })
            .addCase(fetchPolicy.fulfilled as any, (state, { payload }) => {
                state.policyList.error = null;
                state.policyList.loading = false;
                state.policyList.data = payload.policyList;
            })
            .addCase(createPolicy.pending as any, (state) => {
                state.crudPolicy.loading = true;
            })
            .addCase(createPolicy.rejected as any, (state, { error }) => {
                state.crudPolicy.error = error;
                state.crudPolicy.loading = false;
                state.crudPolicy.data = null;
            })
            .addCase(createPolicy.fulfilled as any, (state, { payload }) => {
                state.crudPolicy.error = null;
                state.crudPolicy.loading = false;
                state.crudPolicy.data = payload.crudPolicy.data;
            })
            .addCase(updatePolicy.pending as any, (state) => {
                state.crudPolicy.loading = true;
            })
            .addCase(updatePolicy.rejected as any, (state, { error }) => {
                state.crudPolicy.error = error;
                state.crudPolicy.loading = false;
                state.crudPolicy.data = null;
            })
            .addCase(updatePolicy.fulfilled as any, (state, { payload }) => {
                state.crudPolicy.error = null;
                state.crudPolicy.loading = false;
                state.crudPolicy.data = payload.crudPolicy.data;
            })
            .addCase('common/cleanup', (state) => {
                state.policyList = initialState.policyList;
            });
    }
});
export default policySlice.reducer;
export const { resetState, setPlacementFormDetails, updatePolicyId, resetCRUDState, updatePolicyList } =
    policySlice.actions;
