import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { CloseDarkIcon, PenIcon } from '../../../../../assets/img';
import {
    StyledIcon,
    StyledModalBackdrop,
    StyledModalBody,
    StyledContent,
    StyledButtonContainer,
    StyledCloseIcon
} from './styles';
import React from 'react';

const EnrolmentCreateConfirmationModal: React.FC<{
    isOpen: boolean,
    setIsOpen: (x: boolean) => void,
    onSubmit: () => void
}> = (
    { isOpen = false, setIsOpen, onSubmit }
) => {
    return (
        <StyledModalBackdrop isOpen={isOpen}>
            <StyledModalBody>
                <StyledCloseIcon onClick={() => setIsOpen(false)} src={CloseDarkIcon} alt='close-icon' />
                <StyledIcon width={'72px'} height={'72px'} src={PenIcon} alt='pen-icon' />
                <StyledContent>
                    <Typography variant='medium' weight='semiBold'>
                        Are you sure you want to complete this enrolment?
                    </Typography>
                    <Typography variant='small' weight='regular'>
                        Please make sure all the details about policy, setup dates and stakeholder
                        emails are correct. <strong>These cannot be changed once created.</strong>
                    </Typography>
                </StyledContent>
                <StyledButtonContainer>
                    <LoopButton variant='filled' onClick={onSubmit} >
                        Confirm and create
                    </LoopButton>
                    <LoopButton variant='outline' onClick={() => setIsOpen(false)} >
                        Go back and review
                    </LoopButton>
                </StyledButtonContainer>
            </StyledModalBody>
        </StyledModalBackdrop>
    );
};

export default EnrolmentCreateConfirmationModal;
