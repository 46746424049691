import styled from 'styled-components';

export const StyledEnrolmentPolicyContainer = styled.div(
    (p) => `
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    padding: 16px;
    min-width: 415px;
    box-sizing: border-box;
    align-items: center;
    background-color: ${p.theme.colors.surface};
    cursor: pointer;
`
);

export const StyledPolicyInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 48px;
`;

export const StyledPolicyContainer = styled.div`
    display: flex;
    flex: 1;
`;

export const StyledPolicyDetailsContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 400px;
`;

export const StyledInsurerLogo = styled.img`
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.consultViolet};
`;

export const StyledPolicyDurationContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;
