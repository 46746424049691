import styled from 'styled-components';

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
`;

export const ModalContent = styled.div`
    background-color: white;
    padding: 42px;
    gap: 42px;
    border-radius: 8px;
    min-width: 794px;
    position: relative;
`;

export const StyledCloseIcon = styled.img`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;

export const ModalTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const ModalSubtitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 16px 0 0;
    color: ${(p)=> p.theme.colors.beyondGrey}
`;
