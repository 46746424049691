import React from 'react';
import { StyledCompanyDetails, StyledCompanyDetailsValue } from './styles';
import { ICompanyDetailsRow } from './types';

const CompanyDetailsRow: React.FunctionComponent<ICompanyDetailsRow> = ({ field, value }) => {
    return (
        <StyledCompanyDetails data-testid="company-details">
            {field} : <StyledCompanyDetailsValue data-testid="company-details-value">
                {value}
            </StyledCompanyDetailsValue>
        </StyledCompanyDetails>
    );
};

export default CompanyDetailsRow;
