import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 48px 56px;
`;

export const StyledTitleContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const StyledSubTitle = styled.div`
    margin-top: 8px;
`;

export const StyledTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px dashed ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledChipContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 154px;
`;

export const StyledSpacingContainer = styled.div`
    padding-bottom: 60px;
    width: 100%;
`;
