import React from 'react';
import {
    StyledContainer,
    StyledMenuIcon,
    StyledLeftPanel,
    StyledName,
    StyledHighlight
} from './styles';
import { CollapseMenuIcon } from '../../../assets/img';
import { IHeader } from './types';
import { NavigationItem, NavigationPanel } from '../../atoms';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES, IRouteName } from '../../../utils/constants/Routes';
import { TopNavigation } from '@loophealth/loop-ui-web-library';

const Header: React.FunctionComponent<IHeader> = ({ signout, goHome, name }) => {
    const navigate = useNavigate();
    const baseUrl = EXTERNAL_ROUTES.APP + '/';
    const [isVisible, setIsVisible] = React.useState(false);
    const [routeName, setRouteName] = React.useState(INTERNAL_ROUTES.COMPANY_DIRECTORY.name);
    const handleNavClick = () => {
        setIsVisible(true);
    };
    const handleRouteChange = (route: string, name: string) => {
        navigate(route);
        setRouteName(name);
    };
    return (
        <StyledContainer>
            <StyledLeftPanel>
                <StyledMenuIcon src={CollapseMenuIcon} onClick={handleNavClick} />
                <StyledName>
                    Welcome,&nbsp;<StyledHighlight>{name}</StyledHighlight>
                </StyledName>
                <NavigationPanel isVisible={isVisible} setIsVisible={setIsVisible}>
                    {Object.keys(INTERNAL_ROUTES).map((internalRoute, index) => {
                        const isActive = routeName === INTERNAL_ROUTES[internalRoute as unknown as IRouteName].name;
                        return (
                            (INTERNAL_ROUTES[internalRoute as unknown as IRouteName].isVisible && (
                                <NavigationItem
                                    icon={INTERNAL_ROUTES[internalRoute as unknown as IRouteName].icon}
                                    IconComponent={
                                        INTERNAL_ROUTES[internalRoute as unknown as IRouteName].IconComponent
                                    }
                                    key={index}
                                    isActive={isActive}
                                    onClick={() =>
                                        handleRouteChange(
                                            baseUrl + INTERNAL_ROUTES[internalRoute as unknown as IRouteName].route,
                                            INTERNAL_ROUTES[internalRoute as unknown as IRouteName].name
                                        )
                                    }
                                >
                                    {INTERNAL_ROUTES[internalRoute as unknown as IRouteName].name}
                                </NavigationItem>
                            )) ||
                            ''
                        );
                    })}
                </NavigationPanel>
            </StyledLeftPanel>
            <TopNavigation
                onLogoClick={goHome}
                showProfile={true}
                user={{ imageURL: '', name }}
                buttons="secondary"
                secondaryButtonText="Sign out"
                handleSecondaryButtonClick={signout}
                showMenuButton={true}
                handleMenuButtonClick={handleNavClick}
            />
        </StyledContainer>
    );
};

export default Header;
