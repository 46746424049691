import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserPoliciesByUserAPI } from '../../../adapters';

const USERPOLICYSLICE_ACTION_TYPES = {
    FETCH_USER_POLICIES_BY_USER: 'FETCH_USER_POLICIES_BY_USER'
};
export const fetchUserPoliciesByUser = createAsyncThunk(
    USERPOLICYSLICE_ACTION_TYPES.FETCH_USER_POLICIES_BY_USER,
    async ({ userId }: { userId: string }) => {
        const userPolicies = await fetchUserPoliciesByUserAPI(userId);
        return {
            userPolicies
        };
    }
);
