import React from 'react';
import { IFirebaseTimestamp } from '../../../redux/types';
import { useDispatch } from 'react-redux';
import {
    StyledContainer,
    StyledRowContainer,
    StyledLoaderContainer,
    StyledZeroState,
    StyledSchemaError,
    StyledPolicyIdentityContainer
} from './styles';
import { IPolicyCard } from './types';
import { PolicyIdentity, PolicyCardDetail, Loader } from '../../atoms';
import usePolicies from '../../hooks/business-hooks/usePolicies';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { setPlacementFormDetails } from '../../../redux/slices/PolicySlice';
import { getPolicyType } from '../../../utils/common';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const PolicyCard: React.FunctionComponent<IPolicyCard> = ({
    userId,
    policyId,
    companyId,
    displayEcard,
    slabId,
    sumInsured,
    userPolicyEndDate
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [policies, loading] = usePolicies([policyId]);
    const policyEndDate = policies[0]?.policyEndDate;
    const formattedDate = userPolicyEndDate ?
        new DateFormatUtils().formatDateTime((userPolicyEndDate as IFirebaseTimestamp)._seconds)
        : new DateFormatUtils().formatDate(policyEndDate);

    const onClick = (mode: 'Edit' | 'View' | 'Ecard') => {
        dispatch(setPlacementFormDetails({ placementFormDetails: policies[0] }));

        if (mode === 'Edit') {
            navigate(
                EXTERNAL_ROUTES.APP +
                    '/' +
                    INTERNAL_ROUTES.PLACEMENT_FORM.route +
                    '?id=' +
                    companyId +
                    '&policyId=' +
                    policies[0].id
            );
        } else if (mode === 'View') {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.POLICY_DETAILS.route + '?id=' + policies[0].id);
        }
    };

    return (
        <StyledContainer>
            {loading ? (
                <StyledLoaderContainer>
                    <Loader />
                </StyledLoaderContainer>
            ) : policies[0]?.policyPlacementStatus !== 'Draft' && policies[0]?.error && policies[0]?.error?.length ? (
                <StyledSchemaError>
                    <div>{policies[0].error}</div>
                    <StyledZeroState>{policies[0].id}</StyledZeroState>
                </StyledSchemaError>
            ) : (
                <>
                    {policies[0] ? (
                        <StyledRowContainer>
                            <StyledPolicyIdentityContainer>
                                <PolicyIdentity
                                    policyCategory={getPolicyType(
                                        policies[0].policyType,
                                        policies[0].isTopUpPolicy,
                                        policies[0].isParentalPolicy
                                    )}
                                    policyImage={policies[0].insurerLogo}
                                    policyName={policies[0].insurerName}
                                    policyId={policies[0].id}
                                    policyStatus={policies[0].policyPlacementStatus}
                                />
                            </StyledPolicyIdentityContainer>

                            <PolicyCardDetail
                                policyEndDate={formattedDate}
                                policyStatus={policies[0].policyPlacementStatus}
                                onClick={(mode: 'Edit' | 'View' | 'Ecard') => onClick(mode)}
                                displayEcard={displayEcard}
                                slabId={slabId}
                                sumInsured={sumInsured}
                                userId={userId}
                                policyId={policyId}
                            />
                        </StyledRowContainer>
                    ) : (
                        <StyledZeroState>Policy Information Missing</StyledZeroState>
                    )}
                </>
            )}
        </StyledContainer>
    );
};

export default PolicyCard;
