import { IStatus, IPolicyType, ISource } from '../../../../../redux/slices/ChangeRequestSlice/types';

export const STATUSES:IStatus[] = ['PENDING', 'IN_PROGRESS', 'ACCEPTED', 'REJECTED'];
export const POLICY_TYPES:IPolicyType[] = ['GMC', 'GPA', 'GTL', 'COVID', 'SUPER_TOP_UP'];
export const SOURCES:ISource[] = ['genome', 'enrolment', 'hrdesk', 'hrms'];

export const TABS = {
    OPEN_REQUESTS: 'OPEN_REQUESTS',
    ALL_REQUESTS: 'ALL_REQUESTS'
};
