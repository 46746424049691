import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.theme.colors.surface};
`;

export const StyledCentralContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    background-color: ${(p) => p.theme.colors.white};
    margin-bottom: 40px;
`;

export const StyledTitleContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const StyledSubTitle = styled.div`
    margin-top: 8px;
`;

export const StyledCardContainer = styled.div`
    display: flex;
    border-top: 1px dashed ${(p) => p.theme.colors.defaultBorder};
    border-bottom: 1px dashed ${(p) => p.theme.colors.defaultBorder};
    margin: 40px 0px;
`;

export const StyledChipContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 154px;
`;

export const StyledMarkAllContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
    width: 100%;
`;

export const StyledMarkAllChipContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 280px;
`;

