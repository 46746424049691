import React from 'react';
import { StyledContainer } from './styles';
import { GoogleIcon } from '../../../assets/img';
import { Button } from '../../atoms';
import { ILoginOAuth } from './types';

const LoginOAuth: React.FunctionComponent<ILoginOAuth> = ({ signInWithOAuth }) => {
    return (
        <StyledContainer>
            <Button type="OAuth" iconSrc={GoogleIcon} onClick={() => signInWithOAuth('google')}>
                Continue with Google
            </Button>
        </StyledContainer>
    );
};

export default LoginOAuth;
