import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 300px;
    height: 100%;
    min-height: 135px;
    max-height: 150px;
    background-color: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.white};
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
