import styled from 'styled-components';

export const StyledEnrolmentPolicyListingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledPolicyScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;
