import styled from 'styled-components';

export const StyledContainer = styled.div`
  border: 1px solid ${(p) => p.theme.colors.mutedTransparent};
  border-radius: 16px;
  overflow-x: auto;
  padding-top: 20px;
  margin: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const StyledRowContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: 30px;
  flex-direction: row;
  margin: 10px 20px 20px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
`;

export const StyledInsuranceNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
export const StyledValue = styled.div(
    (p) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    margin-bottom: 20px;
    text-align: center;
`
);
export const StyledIcons = styled.img`
  overflow: hidden;
  height: 30px;
  width: 30px;
`;

export const StyledLoaderContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledZeroState = styled.div(
    (p) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    align-items: center;
    color: ${p.theme.colors.muted};
`
);

export const StyledSchemaError = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: ${p.theme.colors.error};
    `
);

export const StyledPolicyIdentityContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;
