/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import {
    Table,
    TableHeading,
    TableRow,
    TableLoading,
    FloatingPlusButton,
    TableError,
    PageHeader
} from '../../../../atoms';
import { CompanyDirectoryRow } from '../../../../containers';
import {
    PlusButtonContainer,
    StyledContainer,
    StyledTableContainer
} from './styles';

const CompanyDirectory: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const companies = useSelector((state: ReduxState) => state.companySlice.companyList);
    const [searchedText, setSearchedText] = React.useState('');
    const [searchedCompanies, setSearchedCompanies] = React.useState<ICompanyData[]>([]);
    const handleAddCompanyClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.ADD_COMPANY.route);
    };

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        containerRef.current?.scrollIntoView();
    }, []);

    return (
        <StyledContainer ref={containerRef}>
            <StyledTableContainer>
                <PageHeader
                    name="search"
                    placeholder="Search Companies"
                    list={
                        ((companies.data || []).map((c) => ({ name: c.name, id: c.id })) as unknown as Record<
                            string,
                            unknown
                        >[]) || []
                    }
                    setSearchResults={(results) => {
                        const sResults = results.map((c) => (companies.data || []).filter((com) => c.id === com.id)[0]);
                        setSearchedCompanies(sResults);
                    }}
                    setSearchText={setSearchedText}
                >
                    Onboarded Companies
                </PageHeader>
                <Table>
                    <TableRow>
                        <TableHeading>#</TableHeading>
                        <TableHeading index>Name</TableHeading>
                        <TableHeading align="center">Status</TableHeading>
                        <TableHeading align="right">Actions</TableHeading>
                    </TableRow>
                    {(companies.error && !companies.loading && <TableError>Something went wrong!</TableError>) || ''}
                    {companies.loading && <TableLoading />}
                    {(searchedText.length &&
                        (searchedCompanies || []).map((company, index) => (
                            <CompanyDirectoryRow company={company} index={index} key={index} />
                        ))) || <></>}
                    {(!searchedText.length &&
                        (companies.data || []).map((company, index) => (
                            <CompanyDirectoryRow company={company} index={index} key={index} />
                        ))) || <></>}
                    {(searchedText.length && searchedCompanies.length === 0 && (
                        <TableError>No results matching search.</TableError>
                    )) || <></>}
                    {(!(companies.data || []).length && !companies.loading && (
                        <TableError>Please add atleast one company first.</TableError>
                    )) || <></>}
                </Table>
            </StyledTableContainer>
            <PlusButtonContainer>
                <FloatingPlusButton onClick={handleAddCompanyClick} />
            </PlusButtonContainer>
        </StyledContainer>
    );
};

export default CompanyDirectory;
