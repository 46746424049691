import { IPolicySlice } from './types';

export const initialState: IPolicySlice = {
    policyList: {
        data: null,
        error: null,
        loading: false
    },
    placementFormDetails: null,
    crudPolicy: {
        data: null,
        error: null,
        loading: false
    }
};
