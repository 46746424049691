import React, { useState } from 'react';
import {
    StyledNotesEdit,
    StyledNotesHeader,
    StyledNotesOverviewContainer,
    StyledNotesListContainer,
    StyledNotesItem
} from './styles';
import { AddNotesIcon } from '../../../../../assets/img';
import HorizontalSpacer from '../../../../atoms/HorizontalSpacer';
import { AddNoteModal } from '../../../../containers/AddNoteModal';
import { INotesContainerProps, INotesItemProps } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';


const NotesContainer: React.FunctionComponent<INotesContainerProps> = ({
    notes,
    endorsementId,
    updateEndorsementNotes
}) => {
    const [isAddNoteModalVisible, setAddNoteModalVisible] = useState(false);

    const onAddNoteClicked = () => {
        setAddNoteModalVisible(true);
    };

    const NoteComponent = (props: INotesItemProps) => {
        const { noteInfo } = props;
        return <StyledNotesItem>
            {noteInfo?.description}
        </StyledNotesItem>;
    };
    return (
        <>
            <AddNoteModal isVisible={isAddNoteModalVisible} setIsVisible={setAddNoteModalVisible}
                endorsementId={endorsementId} updateEndorsementNotes={updateEndorsementNotes}/>
            <StyledNotesOverviewContainer>
                <StyledNotesHeader>
                    <Typography variant='medium' weight='semiBold'>Notes</Typography>
                    <StyledNotesEdit onClick={onAddNoteClicked}>
                        <img src={AddNotesIcon} alt="edit-notes"/>
                        <Typography variant='medium' weight='semiBold' color='emerald'>Add Note</Typography>
                    </StyledNotesEdit>
                </StyledNotesHeader>
                <HorizontalSpacer margin="20px"/>
                <StyledNotesListContainer>
                    {notes.length ? notes.map((data, index) => <NoteComponent noteInfo={data} key={index}/>)
                        : 'No note is added yet.'}
                </StyledNotesListContainer>
            </StyledNotesOverviewContainer>
        </>
    );
};
export default NotesContainer;
