import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEnrolmentConfig } from '../../../../../adapters';
import { ICompanyData, IHrEmail } from '../../../../../redux/slices/CompanySlice/types';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import { StyledButtonWrapper } from '../../../../containers/RenameEndoModal/styles';
import { useToast } from '../../../../hooks/useToast';
import AddStakeholderComponent from './AddStakeholderComponent';
import CompanyPoliciesComponent from './CompanyPoliciesComponent';
import DateCycleSelectionComponent from './DateCycleSelectionComponent';
import EnrolmentCreateConfirmationModal from './EnrolmentCreateConfirmationModal';
import { StyledContainer } from './styles';

const EnrolmentConfig: React.FunctionComponent = () => {
    const [selectedCompany, setSelectedCompany] = useState<ICompanyData | null>(null);
    const [selectedPolicies, setSelectedPolicies] = useState<IPolicyPlan[]>([]);
    const [memberRegistrationStartDate, setMemberRegistrationStartDate] = useState<Date | null>(null);
    const [memberRegistrationEndDate, setMemberRegistrationEndDate] = useState<Date | null>(null);
    const [endoCycleStartDate, setEndoCycleStartDate] = useState<Date | null>(null);
    const [endoCycleEndDate, setEndoCycleEndDate] = useState<Date | null>(null);
    const [hrEmails, setHrEmails] = useState<IHrEmail[]>([]);
    const [csmEmails, setCsmEmails] = useState<IHrEmail[]>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [dateError, setDateError] = useState<boolean>(false);

    const toast = useToast();
    const navigate = useNavigate();

    const isShowSubmitEnabled = selectedCompany && selectedPolicies?.length &&
                                !dateError && (hrEmails.length || csmEmails.length);

    const onShowSubmitClicked = async () => {
        if (!selectedCompany) {
            return;
        }
        try {
            await createEnrolmentConfig(
                selectedCompany.id,
                selectedPolicies.map((policy) => policy.id),
                memberRegistrationStartDate,
                memberRegistrationEndDate,
                endoCycleStartDate,
                endoCycleEndDate,
                hrEmails.map((email) => email.email),
                csmEmails.map((email) => email.email)
            );
            setIsOpenModal(false);
            toast?.displayToast('success', 'Enrolment created successfully',
                'Find it in "Active Enrolments"', 10000);
            navigate(-1);
        } catch (e: any) {
            const errors = JSON.parse(e.message);
            Object.keys(errors).map((errType) => {
                toast?.displayToast('error', 'Failed to create config!', `${errType}: ${errors[errType]}`, 10000);
            });
        }
    };

    return (
        <StyledContainer>
            <Typography variant="title2" weight="medium">
                Set up enrolment portal
            </Typography>
            <Typography variant="medium" color="secondary">
                Set up enrolment batches by selecting the policies, the cohort, and enrolment dates
            </Typography>
            <CompanyPoliciesComponent
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                selectedPolicies={selectedPolicies}
                setSelectedPolicies={setSelectedPolicies}
            />
            <DateCycleSelectionComponent
                memberRegistrationStartDate={memberRegistrationStartDate}
                setMemberRegistrationStartDate={setMemberRegistrationStartDate}
                memberRegistrationEndDate={memberRegistrationEndDate}
                setMemberRegistrationEndDate={setMemberRegistrationEndDate}
                endoCycleStartDate={endoCycleStartDate}
                setEndoCycleStartDate={setEndoCycleStartDate}
                endoCycleEndDate={endoCycleEndDate}
                setEndoCycleEndDate={setEndoCycleEndDate}
                setDateError={setDateError}
                selectedPolicies={selectedPolicies}
            />
            <AddStakeholderComponent
                hrEmails={hrEmails}
                csmEmails={csmEmails}
                setHrEmails={setHrEmails}
                setCsmEmails={setCsmEmails}
            />
            <EnrolmentCreateConfirmationModal
                onSubmit={onShowSubmitClicked}
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
            />
            <StyledButtonWrapper>
                <LoopButton variant={isShowSubmitEnabled ? 'filled' : 'disabled'} onClick={() => setIsOpenModal(true)}>
                    Complete Enrolment
                </LoopButton>
            </StyledButtonWrapper>
        </StyledContainer>
    );
};
export default EnrolmentConfig;
