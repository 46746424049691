import { MemberDataEntity } from '../../../redux/slices/EndorsementSlice/types';
import { jsonToExcel } from '../../common';
import {
    transformAddMemberDataExcelRow,
    transformDeleteMemberDataExcelRow,
    transformEditMemberDataExcelRowTypeEdit
} from './transforms';

export const memberDataExcelDownload = (memberData: MemberDataEntity[], fileName: string): void => {
    const adds = memberData?.filter((data) => data.operationType === 'ADD').map(transformAddMemberDataExcelRow);
    const deletes = memberData
        ?.filter((data) => data.operationType === 'DELETE')
        .map(transformDeleteMemberDataExcelRow);
    const edits = memberData
        ?.filter((data) => data.operationType === 'EDIT')
        .map(transformEditMemberDataExcelRowTypeEdit);

    const fileLink = jsonToExcel([
        {
            sheetName: 'All policies - Add',
            data: adds
        },
        {
            sheetName: 'All policies - Delete',
            data: deletes
        },
        {
            sheetName: 'All policies - Correct',
            data: edits
        }
    ]);
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = fileName;
    link.click();
    link.remove();
};

