import React from 'react';
import { BannerToastIcon, BannerToastTextColor, IBannerToastProps } from './types';
import { StyledBannerContainer } from './styles';
import SVG from 'react-inlinesvg';
import { Typography } from '@loophealth/loop-ui-web-library';


const BannerToast: React.FunctionComponent<IBannerToastProps> = ({
    type,
    children
}) => {
    return (
        <StyledBannerContainer type={type}>
            <SVG src={BannerToastIcon[type]}/>
            <Typography variant='small' weight='medium' color={BannerToastTextColor[type]}>{children}</Typography>
        </StyledBannerContainer>
    );
};
export default BannerToast;
