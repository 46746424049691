/* eslint-disable max-len */
import styled from 'styled-components';
import { ITab } from './types';

export const StyledTab = styled.span<{
    variant: ITab['variant'];
    color: ITab['color'];
    borderRadius: ITab['borderRadius'];
}>(
    (p) => `
    ${p.variant === 'filled' ? `
        background-color: ${p.color ? p.color : 'rgba(0, 0, 0, 0.08)'};
        border: 0;
    ` : ''}
    ${p.variant === 'outlined' ? `
        background-color: transparent;
        border: 1px solid ${p.color ? p.color : '#bdbdbd'};
    ` : ''}
    ${p.color ? `color: ${p.color};` : ''}
    ${p.borderRadius ? `border-radius: ${
        typeof p.borderRadius === 'number'? `${p.borderRadius}px` : p.borderRadius};`
        : ''}
    max-width: 100%;
    font-size: 0.8125rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0;
    text-decoration: none;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;
`
);

export const StyledLabel = styled.span<{
    color: ITab['color'];
}>(
    (p) => `
    ${p.color ? `color: ${p.color};` : ''}
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
`
);
