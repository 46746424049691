import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLegerEntryService, getCDBalanceModalData } from './utils';
import { CDLedgerEntryFormInput, IAddAdjustmentModalProps } from './types';
import LoopModal from '../../atoms/LoopModal';
import AddCDLedgerDepositEntry from './AddCDLedgerDepositEntry';
import AdjustCDLedgerEntryComponent from './AdjustCDLedgerEntry';
import { ReduxState } from '../../../redux';
import AddPolicyBookingAmount from './AddPolicyBookingAmount';

export const MAX_PREMIUM_AMOUNT = 100000000;
const AddCdBalanceModal: React.FunctionComponent<IAddAdjustmentModalProps> = ({
    isVisible,
    setIsVisible,
    id,
    type
}) => {
    const dispatch = useDispatch();
    const [isLoading, toggleLoader] = useState(false);
    const [apiError, setApiError] = React.useState<string>('');
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const addedBy = user ? `${user.firstName} ${user.lastName}` : '';
    const handleCloseModal = () => {
        setIsVisible(false);
        setApiError('');
    };
    const onCreate = async (cdLedgerEntryObject: CDLedgerEntryFormInput) => {
        toggleLoader(true);
        await createLegerEntryService(cdLedgerEntryObject, id, addedBy, dispatch, setApiError, setIsVisible);
        toggleLoader(false);
    };
    const renderCDEntryForm = () => {
        const [modalTitle, modalSubTitle] = getCDBalanceModalData(type);
        switch (type) {
        case 'payment':
            return (<LoopModal
                isVisible={isVisible}
                onClose={() => handleCloseModal()}
                title={modalTitle}
                subtitle={modalSubTitle}
            >
                <AddCDLedgerDepositEntry isLoading={isLoading} apiError={apiError} onAddEntry={onCreate} />
            </LoopModal>);
        case 'adjustment':
            return (
                <LoopModal
                    isVisible={isVisible}
                    onClose={() => handleCloseModal()}
                    title={modalTitle}
                    subtitle={modalSubTitle}
                >
                    <AdjustCDLedgerEntryComponent isLoading={isLoading} apiError={apiError} onAddEntry={onCreate} />
                </LoopModal>
            );
        case 'policyBookingAmount':
            return (
                <LoopModal
                    isVisible={isVisible}
                    onClose={() => handleCloseModal()}
                    title={modalTitle}
                    subtitle={modalSubTitle}
                >
                    <AddPolicyBookingAmount isLoading={isLoading} apiError={apiError} onAddEntry={onCreate} />
                </LoopModal>
            );
        default:
            return <></>;
        }
    };

    return renderCDEntryForm();
};

export default AddCdBalanceModal;
