import React, { useEffect, useState } from 'react';
import InsuranceNameCard from '../InsuranceNameCard';
import {
    StyledFieldContainer,
    StyledInputFieldContainer, StyledUploadFieldContainer,
    StyledContainer
} from './styles';
import { FormError, FormLabel, Input, UploadPill, UploadPreview, LoopButton } from '../../atoms';
import { initialState, uploadPaymentProofFile } from './utils';
import { CDAccountFormInput, IAddCDAccountCard } from './types';

const AddCDAccountCard: React.FunctionComponent<IAddCDAccountCard> = ({
    insurer,
    insurerLogo,
    setCDAccountObj,
    handleFormNavigation
}) => {
    const initialFormState: CDAccountFormInput = initialState();
    const [cdAccountEntryObject, setCdAccountEntryObject] = useState(initialFormState);
    const [isFormDataValid, setFormDataValid] = useState(false);
    const [paymentProof, setPaymentProof] = React.useState<{ file: Blob; data: string; name: string } | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedObject = {
            ...cdAccountEntryObject,
            [e.target.id]: { data: e.target.value, error: '' }
        };
        setCdAccountEntryObject(updatedObject);
    };

    useEffect(() => {
        const isAddCDEntryObjValid = cdAccountEntryObject.accountNumber.data &&
            (cdAccountEntryObject.balance.data ? paymentProof?.name : true);
        setFormDataValid(!!isAddCDEntryObjValid);
    }, [cdAccountEntryObject, paymentProof]);

    const onUpload = async (files: Record<string, unknown>[]) => {
        setPaymentProof({
            name: '',
            file: files[0] as unknown as Blob,
            data: URL.createObjectURL(files[0] as unknown as Blob)
        });
        const fileName = await uploadPaymentProofFile(files);
        setPaymentProof({
            name: fileName,
            file: files[0] as unknown as Blob,
            data: URL.createObjectURL(files[0] as unknown as Blob)
        });
    };

    const onCreateAccountClicked = () => {
        setCDAccountObj({
            accountNumber: cdAccountEntryObject.accountNumber.data,
            balance: cdAccountEntryObject.balance.data,
            paymentProof: paymentProof?.name
        });
        handleFormNavigation();
    };
    return (
        <StyledContainer>
            <InsuranceNameCard insurer={insurer} insurerLogo={insurerLogo}/>
            <StyledInputFieldContainer>
                <StyledFieldContainer>
                    <FormLabel isMandatory>Enter Insurer CD Account ID:</FormLabel>
                    <Input
                        id='accountNumber'
                        name="cdAccountId"
                        placeholder="Enter"
                        onChange={handleInputChange}
                        value={cdAccountEntryObject.accountNumber.data || ''}
                        errorText={cdAccountEntryObject.accountNumber.error || ''}
                        isDisabled={false}
                    />
                </StyledFieldContainer>
                <StyledFieldContainer>
                    <FormLabel>Enter Inception Amount (₹)</FormLabel>
                    <Input
                        id='balance'
                        name="amount"
                        type='number'
                        placeholder="Enter"
                        onChange={handleInputChange}
                        value={cdAccountEntryObject.balance.data.toString() || ''}
                        errorText={cdAccountEntryObject.balance.error || ''}
                        isDisabled={false}
                    />
                </StyledFieldContainer>
            </StyledInputFieldContainer>
            {cdAccountEntryObject.balance.data &&
                <StyledUploadFieldContainer>
                    <FormLabel isMandatory>Upload proof for payment:</FormLabel>
                    <UploadPill
                        title={`Upload payment proof`}
                        subTitle="Supports ('.jpeg', '.jpg', '.png')"
                        onUpload={onUpload}
                        formats={['.jpeg', '.jpg', '.png']}
                    >
                        {paymentProof?.file && paymentProof?.data && (
                            <UploadPreview previewUrl={paymentProof.data}
                                previewName={paymentProof.name || ''}
                                onReUpload={() => setPaymentProof(null)}/>
                        )}
                    </UploadPill>
                    <FormError>{cdAccountEntryObject.paymentProof?.error}</FormError>
                </StyledUploadFieldContainer>
            }
            <LoopButton type={isFormDataValid ? 'filled' : 'disabled'}
                onClick={onCreateAccountClicked} width={'fit-content'}>
                Create Account
            </LoopButton>
        </StyledContainer>
    );
};
export default AddCDAccountCard;
