import {
    StyledChangeButtonWrapper,
    StyledDescriptionContainer,
    StyledPreferenceAlertContainer,
    StyledPreferenceContainer,
    StyledPreferenceInfoSection,
    StyledPreferenceItemContainer,
    StyledPreferenceSection
} from './styles';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { useState } from 'react';
import { AnnouncementIcon, CreateEndoPreferenceIcon, UpdateRoasterPreferenceIcon } from '../../../assets/img';
import {
    IBulkRegisterPreferenceComponenteProps,
    IPreferenceDescriptionAlertProps,
    IUploadPreference,
    IUploadPreferenceItemProps,
    IUploadPreferenceProps
} from './types';
import { StyledButtonWrapper } from '../RenameEndoModal/styles';

const UploadPreferenceItem: React.FC<IUploadPreferenceItemProps> = ({
    icon,
    title,
    description,
    isSelected,
    onPreferenceClicked
}) => {
    return (
        <StyledPreferenceItemContainer $isSelected={isSelected} onClick={onPreferenceClicked}>
            <img src={icon} alt="preference" />
            <StyledDescriptionContainer>
                <Typography variant="title3" weight="medium">
                    {title}
                </Typography>
                <Typography variant="small" color="secondary">
                    {description}
                </Typography>
            </StyledDescriptionContainer>
        </StyledPreferenceItemContainer>
    );
};

const PreferenceDescriptionAlert: React.FC<IPreferenceDescriptionAlertProps> = ({
    preference,
    onChangeClicked,
    withEndoAlertMsg,
    withoutEndoAlertMsg
}) => {
    const preferenceDescription = preference === 'ENDO' ? withEndoAlertMsg : withoutEndoAlertMsg;

    return (
        <StyledPreferenceAlertContainer>
            <StyledPreferenceInfoSection>
                <img src={AnnouncementIcon} alt="announcement" />
                <Typography variant="medium" weight="medium">
                    {preferenceDescription}
                </Typography>
            </StyledPreferenceInfoSection>
            <StyledChangeButtonWrapper onClick={onChangeClicked}>
                <Typography variant="medium" weight="medium" color="emerald">
                    Change
                </Typography>
            </StyledChangeButtonWrapper>
        </StyledPreferenceAlertContainer>
    );
};

export const UploadPreferenceComponent: React.FC<IUploadPreferenceProps> = ({
    uploadPreference,
    setPreference,
    endoCardDescription,
    roasterCardDescription,
    withEndoAlertMsg,
    withoutEndoAlertMsg
}) => {
    const [preference, setLocalPreference] = useState<IUploadPreference | null>(null);

    const onProceedClicked = () => {
        if (!preference) return;
        setPreference(preference);
    };
    const onPreferenceChangedClicked = () => {
        setLocalPreference(null);
        setPreference(null);
    };

    return (
        <>
            {uploadPreference && (
                <PreferenceDescriptionAlert
                    preference={uploadPreference}
                    onChangeClicked={onPreferenceChangedClicked}
                    withEndoAlertMsg={withEndoAlertMsg}
                    withoutEndoAlertMsg={withoutEndoAlertMsg}
                />
            )}
            {!uploadPreference && (
                <StyledPreferenceContainer>
                    <Typography variant="title3" weight="medium">
                        How do you want to upload members?
                    </Typography>
                    <StyledPreferenceSection>
                        <UploadPreferenceItem
                            onPreferenceClicked={() => setLocalPreference('ENDO')}
                            isSelected={preference === 'ENDO'}
                            title="Create/update an Endo request"
                            description={endoCardDescription}
                            icon={CreateEndoPreferenceIcon}
                        />
                        <UploadPreferenceItem
                            onPreferenceClicked={() => setLocalPreference('ROSTER')}
                            isSelected={preference === 'ROSTER'}
                            title="Update Active Roster"
                            description={roasterCardDescription}
                            icon={UpdateRoasterPreferenceIcon}
                        />
                    </StyledPreferenceSection>
                    <StyledButtonWrapper>
                        <LoopButton variant={preference ? 'filled' : 'disabled'} onClick={onProceedClicked}>
                            Proceed
                        </LoopButton>
                    </StyledButtonWrapper>
                </StyledPreferenceContainer>
            )}
        </>
    );
};

export const BulkRegisterPreferenceComponent: React.FC<IBulkRegisterPreferenceComponenteProps> = ({
    setPreference,
    uploadPreference
}) => {
    return (
        <UploadPreferenceComponent
            setPreference={setPreference}
            uploadPreference={uploadPreference}
            endoCardDescription="Use this to update member additions and edits"
            roasterCardDescription="Use this to upload data that was used for policy issuance.
                    You can also use this to add/correct any historical member data"
            withEndoAlertMsg="Members uploaded will be part of an endorsement"
            withoutEndoAlertMsg="Members uploaded will not be mapped or included to any endorsement"
        />
    );
};

export const BulkOffboardPreferenceComponent: React.FC<IBulkRegisterPreferenceComponenteProps> = ({
    setPreference,
    uploadPreference
}) => {
    return (
        <UploadPreferenceComponent
            setPreference={setPreference}
            uploadPreference={uploadPreference}
            endoCardDescription="When you offboard members, an Endo request will be created in the system"
            roasterCardDescription="Members offboarded will be removed from the policies without creating an Endo entry"
            withEndoAlertMsg="Members offboarded will be part of an endorsement"
            withoutEndoAlertMsg="Members offboarded will not be mapped or included to any endorsement"
        />
    );
};
