import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { IPolicy, IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import { useToast } from '../../../../hooks/useToast';
import { Card, ErrorContainer, Loader, Select, SelectOption } from '../../../../atoms';
import { StyledContainer, StyledPadding, StyledSubTitle, StyledTitle, StyledZeroState } from './styles';
import { checkPassedDate, excelDownloadUsingBlob, getPolicyType } from '../../../../../utils/common';
import { downloadRaterXlsx, fetchActivePoliciesList, uploadRaterAPI } from '../../../../../adapters';
import { RaterUploader, PolicyCard, ShowRaterModal } from '../../../../containers';
import { DownloadItem } from '@loophealth/loop-ui-web-library';

const PremiumCalculator: React.FunctionComponent = () => {
    const { displayToast } = useToast() || {};
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList);
    const [isRaterVisble, setIsRaterVisible] = React.useState(false);
    const [company, setCompany] = React.useState<ICompanyData | null>(null);
    const [policy, setPolicy] = React.useState<IPolicyPlan | null>(null);
    const [policyList, setPolicyList] = React.useState<{ policies: IPolicy[]; loading: boolean }>({
        policies: [],
        loading: false
    });
    React.useEffect(() => {
        if (company && company.id) {
            setPolicyList({
                ...policyList,
                loading: true
            });
            setPolicy(null);
            fetchActivePolicies(company.id);
        }
    }, [company]);
    const fetchActivePolicies = async (companyId: string) => {
        try {
            const activePolicies = (await fetchActivePoliciesList(companyId)).data;
            setPolicyList({ policies: activePolicies, loading: false });
        } catch (e) {
            // TODO: Handle error state
        }
    };
    const getPolicyOption = (pol: IPolicy) => {
        if (pol.error && pol.error.length) {
            return `(${pol.id + ') (Schema Error)'}`;
        }
        const policyType = getPolicyType(pol.policyType, pol.isTopUpPolicy, pol.isParentalPolicy) || 'N/A';
        return `${pol.insurerName} (${policyType}) (${pol.id || 'N/A'})`;
    };
    const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const comp = e.target.value;
        setCompany((companyList.data || []).filter((c) => c.id === comp)[0]);
    };
    const handleShowRater = () => {
        setIsRaterVisible(true);
    };
    const handlePremiumRaterDownload = async () => {
        try {
            const response = await downloadRaterXlsx(policy?.id as string);
            if ('message' in response) {
                throw new Error(response.message);
            }
            const blob = await response.blob();
            excelDownloadUsingBlob(blob);
        } catch (e) {
            displayToast?.('error', 'Failed to Download', (e as Error).message);
        }
    };
    const handlePolicyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const pId = e.target.value;
        const policy = policyList.policies.filter((p) => p.id === pId)[0];
        if (policy.error && policy.error.length) {
            displayToast && displayToast('error', `Policy Error: ${pId}!`, policy.error);
            return;
        }
        setPolicy(policy);
    };
    const checkRegistrationAllowed = (): string => {
        if (policy?.policyPlacementStatus === 'Draft') {
            return `Policy is in DRAFT Status.
            Bulk Registration not allowed for a DRAFT Policy`;
        } else if (policy?.policyEndDate && checkPassedDate(policy?.policyEndDate)) {
            return `Policy is EXPIRED.
            Bulk Registration not allowed for an EXPIRED Policy`;
        }
        return '';
    };
    const uploadRater = async (rater: Record<string, unknown>) => {
        try {
            displayToast?.('warning', 'Starting to upload', `Uploading ${rater.policyType} rater`);
            const response = await uploadRaterAPI(rater);
            if (response.status === 200) {
                displayToast?.('success', 'Upload Successful', `Uploaded ${rater.policyType} rater successfully`);
            }
        } catch (e) {
            displayToast?.('error', 'Failed to upload', (e as Error).message);
        }
    };
    return (
        <StyledContainer>
            <StyledTitle>Premium Raters</StyledTitle>
            <Card>
                <StyledSubTitle>Select Company:</StyledSubTitle>
                <Select name="company" onChange={handleCompanyChange} value={company?.id || ''}>
                    <SelectOption value={''}>Please select</SelectOption>
                    {(companyList.data || []).map((comp, index) => (
                        <SelectOption key={index} value={comp.id}>
                            {comp.name}
                        </SelectOption>
                    ))}
                </Select>
                {(company && policyList.policies.length && !policyList.loading && (
                    <>
                        <StyledSubTitle>Select Policy:</StyledSubTitle>
                        <Select name="policy" onChange={handlePolicyChange} value={policy?.id || ''}>
                            <SelectOption value={''}>Please select</SelectOption>
                            {policyList.policies.map((pol, index) => (
                                <SelectOption key={index} value={pol.id}>
                                    {getPolicyOption(pol)}
                                </SelectOption>
                            ))}
                        </Select>
                    </>
                )) || <></>}
                <StyledPadding />
                {policyList.loading && <Loader />}
                {company && !policyList.policies.length && !policyList.loading && (
                    <ErrorContainer message="No policies found for this company!" />
                )}
            </Card>
            {company && policy?.id && policy?.policyType && (
                <div>
                    <PolicyCard policyId={policy.id} companyId={company.id} />
                    {checkRegistrationAllowed().length ? (
                        <StyledZeroState>{checkRegistrationAllowed()}</StyledZeroState>
                    ) : (
                        <>
                            {policy.isRaterUploaded && (
                                <DownloadItem
                                    label={`${policy.policyType} Rater`}
                                    variant="underlined"
                                    onTitleClick={handleShowRater}
                                    onDownload={handlePremiumRaterDownload}
                                />
                            )}
                            <br />
                            <StyledContainer>
                                <RaterUploader
                                    policyId={policy.id}
                                    policyType={policy.policyType}
                                    uploadRater={uploadRater}
                                />
                            </StyledContainer>
                            <br />
                            <br />
                            <ShowRaterModal
                                isVisible={isRaterVisble}
                                policyId={policy.id}
                                setIsVisible={setIsRaterVisible}
                            ></ShowRaterModal>
                        </>
                    )}
                </div>
            )}
        </StyledContainer>
    );
};

export default PremiumCalculator;
