import React from 'react';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { StyledToastWrapper, SuccessToastButton, SuccessToastText } from './styles';

interface SuccessToastProps {
    text: React.ReactNode;
    buttonLabel?: string;
    buttonOnClick?: () => void;
}

const SuccessToast: React.FC<SuccessToastProps> = ({
    text,
    buttonLabel,
    buttonOnClick
}: SuccessToastProps) => {
    return (
        <StyledToastWrapper>
            <SuccessToastText fullWidth={!(buttonLabel && buttonOnClick)}>
                <Typography variant="medium" weight="medium" color="tertiary">
                    {text}
                </Typography>
            </SuccessToastText>
            {
                buttonLabel && buttonOnClick && (
                    <SuccessToastButton>
                        <LoopButton
                            onClick={buttonOnClick}
                            variant="text"
                            size="large"
                            textColor="teamTurquoise"
                        >
                            {buttonLabel}
                        </LoopButton>
                    </SuccessToastButton>
                )
            }
        </StyledToastWrapper>
    );
};

export { SuccessToast };
