import { Form, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { EnrolmentPolicyIcon } from '../../../../../assets/img';
import { ReduxState } from '../../../../../redux';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import SelectablePolicyComponent from '../../../../containers/SelectablePolicyComponent/index';
import usePolicies from '../../../../hooks/business-hooks/usePolicies';
import ConfigStepHeader from './ConfigStepHeader';
import {
    SelectCompanyPlaceholderContainer,
    StyledDropdownWrapper,
    StyledEnrolmentPolicyListingContainer,
    StyledPolicyScrollContainer,
    StyledStepsContainer
} from './styles';
import { ICompanyPoliciesComponentProps } from './types';

const CompanyPoliciesComponent: React.FC<ICompanyPoliciesComponentProps> = ({
    selectedCompany,
    setSelectedCompany,
    selectedPolicies,
    setSelectedPolicies
}) => {
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('id') as string;
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList?.data);
    const [policyList, isPolicyListLoading] = usePolicies(selectedCompany?.policyPlans || []);

    const activePolicyList = policyList.filter((p: IPolicyPlan) => p.active);
    const companyDropdownList =
        companyList?.map((companyData) => {
            return { name: companyData.name, value: companyData.id };
        }) || [];

    const onCompanySelect = (companyId: string) => {
        setSelectedCompany(companyList?.find((data) => data.id === companyId) || null);
        setSelectedPolicies([]);
    };
    const onPolicySelected = (policyId: string, isSelected: boolean) => {
        const selectedPolicy = activePolicyList.find((policy) => policy.id === policyId);
        if (!selectedPolicy) return;
        let currentPolicies = [...selectedPolicies];
        if (isSelected) {
            currentPolicies.push(selectedPolicy);
        } else {
            currentPolicies = currentPolicies.filter((policy) => policy.id !== policyId);
        }
        setSelectedPolicies(currentPolicies);
    };

    useEffect(() => {
        if (companyId) {
            onCompanySelect(companyId);
        }
    }, [policyList]);

    return (
        <StyledStepsContainer>
            <ConfigStepHeader
                icon={EnrolmentPolicyIcon}
                title="Step 1: Select policies"
                isRequired={true}
                description="Choose the policies to be added to the enrolment batch"
            />
            {!companyId ? (
                <StyledDropdownWrapper>
                    <Form.Dropdown
                        placeholder="Select Company"
                        onClick={onCompanySelect}
                        listItems={companyDropdownList}
                        selectedItem={selectedCompany?.id || ''}
                    />
                </StyledDropdownWrapper>
            ) : null}
            {selectedCompany && !isPolicyListLoading ? (
                <EnrolmentPolicyListing
                    activePolicyList={activePolicyList}
                    selectedPolicies={selectedPolicies}
                    onPolicySelected={onPolicySelected}
                />
            ) : (
                <SelectCompanyPlaceholderContainer>
                    <Typography variant="medium" weight="medium">
                        {isPolicyListLoading
                            ? 'Loading policies'
                            : 'Select a company to show policies associated with it'}
                    </Typography>
                </SelectCompanyPlaceholderContainer>
            )}
        </StyledStepsContainer>
    );
};

const EnrolmentPolicyListing = ({
    selectedPolicies,
    activePolicyList,
    onPolicySelected
}: {
    selectedPolicies: IPolicyPlan[];
    activePolicyList: IPolicyPlan[];
    onPolicySelected: (policyId: string, isSelected: boolean) => void;
}) => {
    return (
        <StyledEnrolmentPolicyListingContainer>
            <Typography variant={'small'} color={'secondary'}>
                Open enrolment portal for:
            </Typography>
            <StyledPolicyScrollContainer>
                {activePolicyList?.map((data, index) => {
                    const isPolicySelected = !!selectedPolicies.find((policy) => policy.id === data.id);
                    return (
                        <SelectablePolicyComponent
                            key={index}
                            policyId={data.id}
                            isSelected={isPolicySelected}
                            policyType={data.policyType}
                            icon={data.insurerLogo}
                            insurer={data.insurerName}
                            onSelect={onPolicySelected}
                            nickName={data.nickName}
                            startDate={data.policyStartDate}
                            endDate={data.policyEndDate}
                        />
                    );
                })}
            </StyledPolicyScrollContainer>
        </StyledEnrolmentPolicyListingContainer>
    );
};

export default CompanyPoliciesComponent;
