import styled from 'styled-components';

export const StyledButton = styled.button(
    (p) => `
    height: 65px;
    width: 65px;
    border-radius: 50%;
    border: 0px;
    background-color: ${p.theme.colors.primary};
    font-size: 40px;
    color: ${p.theme.colors.white};
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 25px;
    transition: transform .2s;
    &:hover {
        transform: scale(1.3);
    }
`
);
