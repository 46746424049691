import theme from '../../../../../theme';

export const tableStyle = {
    'border': `1px solid ${theme.colors.defaultBorder}`,
    'borderSpacing': '0',
    'width': '100%',
    'borderCollapse': 'collapse' as const,
    'textAlign': 'center' as const
};

export const headCellStyle = {
    'border': `1px solid ${theme.colors.defaultBorder}`,
    'backgroundColor': theme.colors.surface,
    'padding': '10px 22px'
};

export const dataCellStyle = {
    'border': `1px solid ${theme.colors.defaultBorder}`,
    'backgroundColor': theme.colors.white,
    'padding': '10px 22px'
};

export const spanHeadCellStyle = {
    'fontSize': '14px',
    'lineHeight': '20px',
    'letterSpacing': '0.2px',
    'fontFamily': 'Work Sans',
    'fontWeight': '400',
    'color': theme.colors.beyondGrey
};

export const spanDataCellStyle =
{
    'fontSize': '14px',
    'lineHeight': '20px',
    'letterSpacing': '0.2px',
    'fontFamily': 'Work Sans',
    'color': theme.colors.secondaryText
};

export const emptySpaceStyle = {
    'display': 'inline-block'
};

export const validatorSummaryTableStyle = {
    'display': 'flex',
    'flexDirection': 'column' as const,
    'gap': '28px'
};

export const spaceBetweenStyle = {
    'display': 'flex',
    'justifyContent': 'space-between'
};
