import React from 'react';
import { IEAModalProps } from './types';
import SingleDocumentApprovalModal from './SingleDocumentApprovalModal';
import MultiDocumentApprovalModal from './MultiDocumentApprovalModal';

const EndorsementDocumentApprovalModal: React.FC<IEAModalProps> = (props: IEAModalProps) => {
    const documentsCount = props?.kycMember?.kycDocuments?.length as number;
    if (documentsCount > 1) {
        return <MultiDocumentApprovalModal {...props} />;
    } else if (documentsCount === 1) {
        return <SingleDocumentApprovalModal {...props} />;
    } else {
        return null;
    }
};

export default EndorsementDocumentApprovalModal;
