/* eslint-disable max-len */
import * as React from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table';
import { IError } from '../../../../../redux/types';
import { IChangeRequest, IPolicyType, IStatus } from '../../../../../redux/slices/ChangeRequestSlice/types';
import { defaultDateFormat, getDisplayTextFromUpper, capitalizeFirstLetterForEveryWord, arraysEqual } from '../../../../../utils/common';
import {
    getStatusColor,
    getSourceStyles,
    getPolicyTypeLabel,
    getStatusLabel
} from '../../../../../utils/constants/ChangeRequest/PossibleValues';
import { Chip } from '../../../../atoms';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { StyledTableMessage } from './styles';
import styled from 'styled-components';
import { ISource } from '../../../../../redux/slices/ChangeRequestSlice/types';

export const StyledTable = styled.table`
    background-color: #fff;
    width: 100%;
    table-layout: fixed;
    min-width: 501px;
    border-collapse: collapse;
`;
export const StyledNCol = styled.col`
    width: 50px;
`;
export const StyledCol = styled.col`
    width: 200px;
`;
export const StyledTh = styled.th`
    border-collapse: collapse;
    padding: 16px;
    text-align: left;
`;
export const StyledTrHead = styled.tr`
    border-bottom: 1px solid rgba(89, 89, 89, 0.2);
`;
export const StyledTrBody = styled.tr`
    border-bottom: 1px solid rgba(89, 89, 89, 0.2);
    color: ${(p) => p.theme.colors.beyondGrey};
    :hover {
        background: rgba(2, 95, 76, 0.05);
        box-shadow: 0px 7px 10px rgba(42, 78, 70, 0.15);
        border-bottom: 1px solid rgba(42, 78, 70, 0.15);
    }
`;
export const StyledTd = styled.td`
    border-collapse: collapse;
    padding: 16px;
    text-align: left;
    div {
        width: 100%;
        min-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const StyledPaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
    margin-top: 0.5rem;
    padding: 0 1rem;
`;
export const StyledPaginationBtnFirst = styled.button`
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border: 1px solid rgba(89, 89, 89, 0.2);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    position: relative;
    display: block;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &[disabled] {
        color: #a0aec0;
    }
`;
export const StyledPaginationBtnLast = styled.button`
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border: 1px solid rgba(89, 89, 89, 0.2);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    position: relative;
    display: block;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &[disabled] {
        color: #a0aec0;
    }
`;
export const PaginationUl = styled.ul`
    display: flex;
    padding-left: 0;
    list-style: none;
`;
export const PageItemLi = styled.li`
    display: flex;
    align-items: center;

    /* gap-2 */
`;

const columnHelper = createColumnHelper<IChangeRequest>();

const COLUMNS = [
    columnHelper.display({
        id: 'select',
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                }}
            />
        ),
        cell: ({ row }) => (
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler()
                }}
            />
        )
    }),
    columnHelper.accessor('name', {
        header: 'Name',
        cell: (info) => <span>{capitalizeFirstLetterForEveryWord(info.getValue() ?? '-')}</span>
    }),
    columnHelper.accessor('createdAt', {
        header: 'Created on',
        cell: (info) => {
            const value = info.getValue();
            const text = value?._seconds ? defaultDateFormat(value._seconds) : '-';
            return <span>{text}</span>;
        }
    }),
    columnHelper.accessor('source', {
        header: 'Source',
        cell: (info) => {
            const value = info.getValue();
            const { backgroundColor, color, label=getDisplayTextFromUpper(value ?? '') } = getSourceStyles(value?.toLocaleLowerCase() as ISource);
            if (!!value) {
                return <Chip color={color} backgroundColor={backgroundColor} label={label} />;
            }
            return null;
        }
    }),
    columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => {
            const value = info.getValue() as IStatus;
            return (
                <Chip
                    color={getStatusColor(value)}
                    borderRadius={8}
                    label={getStatusLabel(value) ?? '-'}
                    variant="outlined"
                />
            );
        }
    }),
    columnHelper.accessor('operationType', {
        header: 'Operation type',
        cell: (info) => <span>{getDisplayTextFromUpper(info.getValue() ?? '-')}</span>
    }),
    columnHelper.accessor('insurer', {
        header: 'Insurer name',
        cell: (info) => <span>{getDisplayTextFromUpper(info.getValue() ?? '-')}</span>
    }),
    columnHelper.accessor('policyType', {
        header: 'Policy type',
        cell: (info) => <span>{getPolicyTypeLabel(info.getValue() as IPolicyType)}</span>
    }),
    columnHelper.accessor('relationship', {
        header: 'Relationship',
        cell: (info) => <span>{getDisplayTextFromUpper(info.getValue() ?? '-')}</span>
    }),
    columnHelper.accessor('joiningDate', {
        header: 'Date of joining',
        cell: (info) => {
            const value = info.getValue();
            const text = value?._seconds ? defaultDateFormat(value._seconds) : '-';
            return <span>{text}</span>;
        }
    })
];
interface ChangeRequestTableProps {
    loading: boolean;
    error: IError | null;
    data: IChangeRequest[];
    selectedChangeRequests: IChangeRequest[];
    onRowSelectionChange(rows: IChangeRequest[]): void;
    isPrevDisabled: boolean;
    isNextDisabled: boolean;
    onPageChange(isNext: boolean): void;
}

const ChangeRequestTable: React.FunctionComponent<ChangeRequestTableProps> = ({
    loading,
    error,
    data=[],
    selectedChangeRequests=[],
    onRowSelectionChange,
    isPrevDisabled,
    isNextDisabled,
    onPageChange
}) => {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [rowSelection, setRowSelection] = React.useState({});
    const columns = React.useMemo(() => COLUMNS, []);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting
        },
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true
    });

    const getRowSelectionFromSelectedCrs = (selectedChangeRequests: IChangeRequest[]) => {
        const crRowIdsVsTableRowIdsObj:Record<string, number> = data
            .reduce((acc, r, idx) => ({ ...acc, [r.id]: idx }), {});
        const rowsFromSelectedCrs = selectedChangeRequests.reduce((acc: any, cr: { id: string | number; }) => ({
            ...acc,
            [crRowIdsVsTableRowIdsObj[cr.id]]: true
        }), {});
        if (!arraysEqual(Object.keys(rowsFromSelectedCrs), Object.keys(rowSelection))) {
            setRowSelection(rowsFromSelectedCrs);
        }
    };

    React.useEffect(() => {
        const inSelectionNotIntable = selectedChangeRequests
            .filter((cr: any) => data.map((elm: any) => elm.id).indexOf(cr.id) === -1);
        const rows = table.getSelectedRowModel().rows.map((r) => r.original);
        onRowSelectionChange([...inSelectionNotIntable, ...rows]);
    }, [rowSelection]);

    React.useEffect(() => {
        getRowSelectionFromSelectedCrs(selectedChangeRequests);
    }, [data]);

    const headers = table.getHeaderGroups();
    const rows = table.getRowModel().rows;
    return (
        <>
            <StyledTable>
                <colgroup>
                    <StyledNCol />
                    {[...Array(headers.length - 1)].map((_e, i) => (
                        <StyledCol key={i} />
                    ))}
                </colgroup>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <StyledTrHead key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <StyledTh key={header.id}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler()
                                            }}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽'
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    )}
                                </StyledTh>
                            ))}
                        </StyledTrHead>
                    ))}
                </thead>
                <tbody>
                    {!loading ? (
                        error ? (
                            <tr>
                                <StyledTableMessage>{error?.message ?? 'Unable to load!'}</StyledTableMessage>
                            </tr>
                        ) : data?.length === 0 ? (
                            <tr>
                                <StyledTableMessage>
                                No Change Request data. Please change filters or select all requests!
                                </StyledTableMessage>
                            </tr>
                        ) : (
                            rows.map((row) => (
                                <StyledTrBody key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <StyledTd key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </StyledTd>
                                    ))}
                                </StyledTrBody>
                            ))
                        )
                    ) : null}
                </tbody>
            </StyledTable>
            <StyledPaginationWrapper>
                <PaginationUl>
                    <PageItemLi>
                        <StyledPaginationBtnFirst
                            onClick={() => onPageChange(false)}
                            aria-label="previous page"
                            disabled={isPrevDisabled}
                            aria-disabled={isPrevDisabled}
                        >
                        Previous
                        </StyledPaginationBtnFirst>
                    </PageItemLi>
                    <PageItemLi>
                        <StyledPaginationBtnLast
                            onClick={() => onPageChange(true)}
                            aria-label="next page"
                            disabled={isNextDisabled}
                            aria-disabled={isNextDisabled}
                        >
                        Next
                        </StyledPaginationBtnLast>
                    </PageItemLi>
                </PaginationUl>
            </StyledPaginationWrapper>
        </>
    );
};

export default ChangeRequestTable;
