import { Form, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { fetchCompanyDetails, fetchUsersByParamAPI } from '../../../../../adapters';
import { EnvelopeIcon, SearchIcon } from '../../../../../assets/img';
import { IHrEmail } from '../../../../../redux/slices/CompanySlice/types';
import ConfigStepHeader from './ConfigStepHeader';
import EmailChip from './EmailChip';
import { StyledContentContainer, StyledEmailContainer, StyledInputContainer, StyledStepsContainer } from './styles';
import { IAddStockholderArguments } from './types';

const AddStakeholderComponent: React.FC<IAddStockholderArguments> = ({
    hrEmails,
    csmEmails,
    setHrEmails,
    setCsmEmails
}) => {
    const toast = Toast.useToast();
    const [hrEmail, setHrEmail] = React.useState<string>('');
    const [csmEmail, setCsmEmail] = React.useState<string>('');

    const handleRemoveHrEmail = (email: string) => {
        const filteredEmails: IHrEmail[] = hrEmails.filter((hrEmail) => hrEmail.email !== email);
        setHrEmails(filteredEmails);
    };

    const handleRemoveCsmEmail = (email: string) => {
        const filteredEmails = csmEmails.filter((csmEmail) => csmEmail.email !== email);
        setCsmEmails(filteredEmails);
    };

    const isEmailAlreadyAdded = (email: string) => {
        const duplicateHrEmail = hrEmails.find((hrEmail) => hrEmail.email === email);
        const duplicateCsmEmail = csmEmails.find((csmEmail) => csmEmail.email === email);
        return duplicateCsmEmail || duplicateHrEmail;
    };

    const handleAddEmail = async (e: KeyboardEvent) => {
        const emailElement = document?.activeElement as HTMLElement;
        let email = '';
        if (emailElement instanceof HTMLInputElement) {
            email = emailElement.value;
        }
        if (!email) {
            return;
        }

        if (isEmailAlreadyAdded(email)) {
            return toast?.error('Email already added');
        }

        if (e.key === 'Enter' && document?.activeElement?.id === 'hr-email-input') {
            const res = await fetchUsersByParamAPI('email', email);

            if (res?.length) {
                const companyName = (await fetchCompanyDetails(res[0].employer)).data.company.name;
                setHrEmails((prev) => [{ email, companyName }, ...prev]);
                setHrEmail('');
                return;
            } else {
                return toast?.error('Email not found');
            }
        }
        if (e.key === 'Enter' && document?.activeElement?.id === 'csm-email-input') {
            const res = await fetchUsersByParamAPI('email', email);

            if (res?.length) {
                const companyName = (await fetchCompanyDetails(res[0].employer)).data.company.name;
                setCsmEmails((prev) => [{ email, companyName }, ...prev]);
                setCsmEmail('');
                return;
            } else {
                return toast?.error('Email not found');
            }
        }
    };

    useEffect(() => {
        const container = document.getElementById('add-email-container');
        container?.addEventListener('keydown', handleAddEmail);

        return () => container?.removeEventListener('keydown', handleAddEmail);
    }, [handleAddEmail]);

    return (
        <StyledStepsContainer id="add-email-container">
            <ConfigStepHeader
                icon={EnvelopeIcon}
                title="Step 3: Add stakeholders for reporting"
                description="Add CSM(s) and HR(s) email here to automatically loop them into reporting"
                isRequired={true}
            />
            <StyledContentContainer>
                <Typography variant="medium" weight="medium">
                    The automatic reporting emails would be addressed to HR(s). CSM(s) will be added in cc
                </Typography>
                <StyledInputContainer>
                    <StyledEmailContainer>
                        <Form.FormField id="hr-email" label="Add HR email" required={false}>
                            <Form.Input
                                id="hr-email-input"
                                placeholder="Add HR email id"
                                onChange={(e) => setHrEmail(e.target.value)}
                                value={hrEmail}
                                iconSrc={SearchIcon}
                                iconAlign="right"
                            />
                        </Form.FormField>
                        {hrEmails.map((hrEmail) => (
                            <EmailChip
                                key={hrEmail.email}
                                handleRemove={handleRemoveHrEmail}
                                email={hrEmail.email}
                                companyName={hrEmail.companyName}
                            />
                        ))}
                    </StyledEmailContainer>
                    <StyledEmailContainer>
                        <Form.FormField id="csm-email" label="Add CSM email" required={false}>
                            <Form.Input
                                id="csm-email-input"
                                placeholder="Add CSM email id"
                                onChange={(e) => setCsmEmail(e.currentTarget.value)}
                                value={csmEmail}
                                iconSrc={SearchIcon}
                                iconAlign="right"
                            />
                        </Form.FormField>
                        {csmEmails.map((csmEmail) => (
                            <EmailChip
                                key={csmEmail.email}
                                handleRemove={handleRemoveCsmEmail}
                                email={csmEmail.email}
                                companyName={csmEmail.companyName}
                            />
                        ))}
                    </StyledEmailContainer>
                </StyledInputContainer>
            </StyledContentContainer>
        </StyledStepsContainer>
    );
};
export default AddStakeholderComponent;
