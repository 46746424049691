import * as React from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table';
import { Chip } from '../../../../atoms';
import styled from 'styled-components';
import theme from '../../../../../theme/index';
import { ICompanyWiseCRStat } from '../../../../../redux/slices/ChangeRequestSlice/types';
// import { DefaultInsuranceIcon } from '../../../../../assets/img';
import {
    StyledLoading
} from './styles';

export const StyledTable = styled.table`
    background-color: #fff;
    width: 100%;
    margin: 20px auto;
    table-layout: fixed;
    min-width: 501px;
    border-collapse: collapse;
`;
export const StyledWCol = styled.col`
    width: calc(100% - 450px);
`;
export const StyledCol = styled.col`
    width: 200px;
`;
export const StyledNCol = styled.col`
    width: 50px;
`;
export const StyledTh = styled.th`
    border-collapse: collapse;
    padding: 16px;
    text-align: left;
`;
export const StyledTrHead = styled.tr`
    border-bottom: 1px solid rgba(89, 89, 89, 0.2);
`;
export const StyledTrBody = styled.tr`
    border-bottom: 1px solid rgba(89, 89, 89, 0.2);
    :hover{
        background: rgba(2, 95, 76, 0.05);
        box-shadow: 0px 7px 10px rgba(42, 78, 70, 0.15);
        border-bottom: 1px solid rgba(42, 78, 70, 0.15);
    }
`;
export const StyledTd = styled.td`
    border-collapse: collapse;
    padding: 16px;
    text-align: left;
    div {
        width:100%;
        min-width:100px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
`;
// export const StyledLogo = styled.img`
//     overflow: hidden;
//     height: 50px;
//     width: 50px;
//     border-radius: 12px;
// `;
const columnHelper = createColumnHelper<ICompanyWiseCRStat>();
const columns = [
    columnHelper.accessor('name', {
        header: 'Name',
        cell: (props) => {
            const {
                name
                // companyLogo
            } = props.row.original;
            return <span>
                {/* <StyledLogo src={companyLogo || DefaultInsuranceIcon}/> */}
                {name ?? '-'}
            </span>;
        }
    }),
    columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => {
            const status = info.getValue();
            return <Chip
                color={status === 'Active' ? theme.colors.turquoise : theme.colors.disabledGrey}
                borderRadius={8}
                label={status as string}
                variant="outlined"
            />;
        },
        enableSorting: false
    }),
    columnHelper.accessor('activeCrCount', {
        header: 'Pending Requests'
    })
];
interface CompanyTableProps{
    data: ICompanyWiseCRStat[];
    onRowClick(row: ICompanyWiseCRStat): void;
 }

const CompanyTable: React.FunctionComponent<CompanyTableProps> = ({ data, onRowClick }) => {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    });

    const rows = table.getRowModel().rows ?? [];
    return (
        <StyledTable>
            <colgroup>
                <StyledNCol />
                <StyledWCol />
                <StyledCol/>
                <StyledCol/>
            </colgroup>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <StyledTrHead key={headerGroup.id}>
                        <StyledTh/>
                        {headerGroup.headers.map((header) => (
                            <StyledTh key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : <div
                                        {...{
                                            className: header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick: header.column.getToggleSortingHandler()
                                        }}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: ' 🔼',
                                            desc: ' 🔽'
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                }
                            </StyledTh>
                        ))}
                    </StyledTrHead>
                ))}
            </thead>
            {rows.length === 0 ? (
                <StyledLoading>No Results! Try changing filters</StyledLoading>
            ) : (<tbody>{
                rows.map((row) => (
                    <StyledTrBody key={row.id}>
                        <StyledTd/>
                        {row.getVisibleCells().map((cell) => (
                            <StyledTd
                                key={cell.id}
                                onClick={() => onRowClick(cell.row.original)}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </StyledTd>
                        ))}
                    </StyledTrBody>
                ))
            }</tbody>)}
        </StyledTable>
    );
};

export default CompanyTable;
