import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 40px;
    margin-top: 40px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;
