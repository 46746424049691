import styled from 'styled-components';
import { SearchIcon } from '../../../../../assets/img';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyledInput = styled.input`
    border: none;
    margin: 20px 0 20px 20px;
    width: 350px;
    border-radius: 8px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.beyondGrey};
    padding: 12px 18px 12px 40px;
    background: #f0f0f0 url(${SearchIcon}) no-repeat 13px center;
    background-size: 18px 18px;
`;

export const StyledColumnHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const StyledTableLoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 50px;
`;
