import styled from 'styled-components';

export const StyledExceptionsModalContent = styled.div`
    width: 600px;
`;
export const ExceptionsModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: ${(p) => p.theme.colors.surface};
`;
export const ExceptionsModalBody = styled.div`
    padding: 2rem;
    text-align: center;
`;
export const ExceptionsModalCloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const ExceptionsModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-top: 1px solid ${(p) => p.theme.colors.defaultBorder};
    gap: 2rem;
`;
export const ChipsContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 1rem;
`;
export const Chip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: ${(p) => p.theme.colors.planeGreenLight};
    border-radius: 50px;
    gap: 0.5rem;
`;
export const ChipCloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
