import React from 'react';
import { IChecklistCard } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';
import { CheckMark, CrossMark } from '../../../assets/img';
import SVG from 'react-inlinesvg';
import { StyledCard, StyledDetails, StyledIconContainer } from './styles';

const ChecklistCard: React.FunctionComponent<IChecklistCard> = ({ type, title, subtitle }) => {
    return (
        <StyledCard $type={type}>
            <StyledDetails>
                <Typography variant="medium" weight="regular">
                    {title}
                </Typography>

                <Typography variant="large" weight="medium" color={type === 'primary' ? 'emerald' : 'error'}>
                    {subtitle}
                </Typography>
            </StyledDetails>

            <StyledIconContainer>
                <SVG src={type === 'primary' ? CheckMark : CrossMark} />
            </StyledIconContainer>
        </StyledCard>
    );
};

export default ChecklistCard;
