import { Form, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import {
    ChatIcon,
    CheckmarkLightIcon,
    CommentIcon,
    ContentIcon,
    DownloadEmeraldIcon,
    EditIcon,
    ExcelWorkbookIcon,
    UnlockIcon
} from '../../../assets/img';
import {
    StyledEndorsementsList,
    StyledFreshdeskCheckmarkIcon,
    StyledFreshdeskMessage,
    StyledFreshdeskMessageCTA,
    StyledFreshdeskMessagePre,
    StyledSectionContainer,
    StyledHeader,
    StyledHeaderTitle,
    StyledSummaryWrapper,
    StyledValidationSummaryDetails,
    StyledAttachMessage,
    StyledStepperIcon,
    StyleModalWrapper,
    StyledCommentIcon,
    StyledSpacingWrapper,
    StyledInputWrapper,
    StyledButtonWrapper,
    StyledExcelDownloadContainer,
    StyledExcelContent,
    StyledExcelIcon,
    StyledExcelDownloadText,
    StyledHorizontalButtonWrapper,
    StyledTextArea,
    StyledButtonSpacing,
    StyledButtonAlignment
} from './styles';
import { IValidationSummaryDetails } from './types';
import EndorsementCard from '../EndorsementCard';
import { checkAllCdSufficient, fileSizeToString, getDefaultFdMessage } from './utils';
import { getEndoResultType } from '../../pages/SPAContainer/pages/ValidationSummary/utils';
import ValidatorSummaryTable from '../ValidationSummaryTable';
import { addFreshdeskCommentWithAttachment } from '../../../adapters';
import { getCorrectBucketEntries, getEndoTableEntries } from '../ValidationSummaryTable/utils';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../redux/slices/ValidatorSlice';
import { renderToStaticMarkup } from 'react-dom/server';

const ValidationSummaryDetails: React.FC<IValidationSummaryDetails> = ({ result, handleDownloadErrors, errorFile }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const resultType = getEndoResultType(result.countData);
    const endoEntries = getEndoTableEntries(result);
    const correctBucketEntries = getCorrectBucketEntries(result);
    const isCdSufficientForUpload = checkAllCdSufficient(endoEntries, result.incorrectTableEntries);
    const totalLivesCount =
        result.countData.ADD.totalCount + result.countData.EDIT.totalCount + result.countData.OFFBOARD.totalCount;
    const defaultFreshdeskMessage = useMemo(() => getDefaultFdMessage(resultType, isCdSufficientForUpload, result), []);
    const [freshdeskMessage, setFreshdeskMessage] = React.useState(defaultFreshdeskMessage);
    const [isFreshDeskMessageSent, setIsFreshdeskMessageSent] = React.useState(false);
    const loopToast = Toast.useToast();
    const [freshdeskModal, setFreshdeskModal] = React.useState<boolean>(false);
    const [ticketNumber, setTicketNumber] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [editModal, setEditModal] = React.useState<boolean>(false);
    const [updatedFreshdeskMessage, setUpdatedFreshdeskMessage] = React.useState<string>(freshdeskMessage);

    const handleOnEditClick = () => setEditModal(true);
    const handleOnAttachMessageClick = () => setFreshdeskModal(true);
    const handleViewFreshdeskClick = () =>
        window.open(`https://loophealth.freshdesk.com/a/tickets/${ticketNumber}`, '_blank');
    const handleSaveEditMessage = () => {
        setEditModal(false);
        setFreshdeskMessage(updatedFreshdeskMessage);
    };

    const onSendFreshDeskMessage = async () => {
        setIsLoading(true);
        const TableComponentHTML = renderToStaticMarkup(<FreshdeskSummaryTable />);
        const messageToSend = `<pre>${freshdeskMessage}</pre> <br /> ${TableComponentHTML}`;
        addFreshdeskCommentWithAttachment(ticketNumber, messageToSend, errorFile, 'Error_Data.xlsx')
            .then(() => {
                setFreshdeskModal(false);
                setIsLoading(false);
                setIsFreshdeskMessageSent(true);
                loopToast?.success(`We've successfully attached the message to Freshdesk.`, '', {
                    variant: 'dark'
                });
            })
            .catch(() => {
                setIsLoading(false);
                setFreshdeskModal(false);
                setTicketNumber('');
                loopToast?.error('Failed to add comment');
            });
    };

    const onViewDetailsClick = (id: string) => {
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.ENDORSEMENT_DETAILS.route}?id=${id}`);
        dispatch(resetState());
    };

    const SummaryTable = () => (
        <ValidatorSummaryTable
            type={resultType}
            endoResult={result}
            endoEntries={endoEntries}
            correctTableEntries={correctBucketEntries}
            totalLivesCount={totalLivesCount}
        />
    );

    const FreshdeskSummaryTable = () => (
        <ValidatorSummaryTable
            type={resultType}
            endoResult={result}
            endoEntries={endoEntries}
            correctTableEntries={correctBucketEntries}
            totalLivesCount={totalLivesCount}
            isFreshdeskTable={true}
        />
    );

    return (
        <StyledValidationSummaryDetails>
            <StyledSummaryWrapper>
                <StyledSectionContainer>
                    <StyledHeader>
                        <StyledHeaderTitle>
                            <StyledFreshdeskCheckmarkIcon
                                src={isFreshDeskMessageSent ? CheckmarkLightIcon : ChatIcon}
                            />
                            <Typography variant="large" weight="medium">
                                Freshdesk Message
                            </Typography>
                        </StyledHeaderTitle>
                        <StyledFreshdeskMessageCTA>
                            {isFreshDeskMessageSent ? (
                                <LoopButton variant="outline" onClick={handleViewFreshdeskClick}>
                                    Open Freshdesk
                                </LoopButton>
                            ) : (
                                <>
                                    <LoopButton
                                        variant="outline"
                                        onClick={handleOnEditClick}
                                        iconOrder="right"
                                        iconSrc={EditIcon}
                                    />
                                    <LoopButton variant="filled" onClick={handleOnAttachMessageClick}>
                                        Attach Message
                                    </LoopButton>
                                </>
                            )}
                        </StyledFreshdeskMessageCTA>
                    </StyledHeader>
                    {!isFreshDeskMessageSent && (
                        <StyledFreshdeskMessage>
                            <Typography variant="small">
                                <StyledFreshdeskMessagePre>{freshdeskMessage}</StyledFreshdeskMessagePre>
                            </Typography>
                            <SummaryTable />
                            {errorFile && <StyledExcelDownloadContainer>
                                <StyledExcelContent>
                                    <StyledExcelIcon>
                                        <img src={ExcelWorkbookIcon} />
                                    </StyledExcelIcon>
                                    <StyledExcelDownloadText>
                                        <Typography variant="small" weight="medium">
                                            Error Data .xls
                                        </Typography>
                                        <Typography variant="extraSmall" color="secondary">
                                            {fileSizeToString(errorFile.size)}
                                        </Typography>
                                    </StyledExcelDownloadText>
                                </StyledExcelContent>
                                <div>
                                    <LoopButton
                                        variant="text"
                                        onClick={handleDownloadErrors}
                                        size="small"
                                        iconSrc={DownloadEmeraldIcon}
                                    >
                                        Download
                                    </LoopButton>
                                </div>
                            </StyledExcelDownloadContainer>}
                        </StyledFreshdeskMessage>
                    )}
                </StyledSectionContainer>
                {!!result.endorsements.length && (
                    <StyledSectionContainer>
                        <StyledHeader>
                            <StyledHeaderTitle>
                                <StyledStepperIcon
                                    disabled={!isFreshDeskMessageSent}
                                    src={isFreshDeskMessageSent ? ContentIcon : UnlockIcon}
                                />
                                <Typography variant="large" weight="medium">
                                    Correct Lives Endorsement
                                </Typography>
                            </StyledHeaderTitle>
                            {!isFreshDeskMessageSent && (
                                <StyledAttachMessage>
                                    <Typography variant="small" color="secondary">
                                        Attach FD message first to unlock this
                                    </Typography>
                                </StyledAttachMessage>
                            )}
                        </StyledHeader>
                        {isFreshDeskMessageSent && (
                            <StyledEndorsementsList>
                                {result.endorsements.map((endo) => {
                                    const isLowCd =
                                        (endo.cdAccount?.balance || 0) < (endo.endorsement.endorsementCost || 0);
                                    return (
                                        <EndorsementCard
                                            key={endo?.endorsement.id}
                                            endorsement={endo?.endorsement}
                                            onViewDetailsClick={onViewDetailsClick}
                                            isLowCd={isLowCd}
                                        />
                                    );
                                })}
                            </StyledEndorsementsList>
                        )}
                    </StyledSectionContainer>
                )}
            </StyledSummaryWrapper>

            <Modal isVisible={freshdeskModal} setIsVisible={setFreshdeskModal}>
                <StyleModalWrapper>
                    <StyledCommentIcon>
                        <img src={CommentIcon} />
                    </StyledCommentIcon>
                    <Typography variant="large" weight="semiBold" color="primary">
                        Freshdesk Ticket Number
                    </Typography>
                    <StyledSpacingWrapper>
                        <Typography variant="small" weight="regular" color="secondary">
                            Given message will be attached to freshdesk <br></br> ticket number you’ll enter
                        </Typography>
                    </StyledSpacingWrapper>

                    <StyledInputWrapper>
                        <Form.Input
                            placeholder="Enter Ticket Number"
                            onChange={(e) => setTicketNumber(e.currentTarget.value)}
                            value={ticketNumber}
                        />
                    </StyledInputWrapper>

                    <StyledButtonWrapper>
                        <LoopButton
                            size="medium"
                            variant={ticketNumber ? 'filled' : 'disabled'}
                            onClick={onSendFreshDeskMessage}
                            isLoading={isLoading}
                        >
                            Send Message
                        </LoopButton>
                    </StyledButtonWrapper>

                    <LoopButton size="medium" variant={'outline'} onClick={() => setFreshdeskModal(false)}>
                        Cancel
                    </LoopButton>
                </StyleModalWrapper>
            </Modal>

            <Modal isVisible={editModal} setIsVisible={setEditModal}>
                <Typography variant="medium" weight="medium" color="primary">
                    Edit Message
                </Typography>

                <div>
                    <StyledTextArea
                        value={updatedFreshdeskMessage}
                        onChange={(message) => setUpdatedFreshdeskMessage(message.target.value)}
                    />
                </div>

                <StyledHorizontalButtonWrapper>
                    <StyledButtonSpacing>
                        <LoopButton size="medium" variant={'outline'} onClick={() => setEditModal(false)}>
                            Cancel
                        </LoopButton>
                    </StyledButtonSpacing>

                    <StyledButtonAlignment>
                        <LoopButton
                            size="medium"
                            variant={freshdeskMessage !== updatedFreshdeskMessage ? 'filled' : 'disabled'}
                            onClick={handleSaveEditMessage}
                            isLoading={isLoading}
                        >
                            Save
                        </LoopButton>
                    </StyledButtonAlignment>
                </StyledHorizontalButtonWrapper>
            </Modal>
        </StyledValidationSummaryDetails>
    );
};

export default ValidationSummaryDetails;
