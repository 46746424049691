import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ICorrectTable } from './types';
import FreshdeskLivesCostTable from './FreshdeskLivesCostTable';
import { formatCurrencyElseDefault } from '../../utils';
import { TABLE_TEXTS } from '../constants';
import { spaceBetweenStyle, validatorSummaryTableStyle } from './styles';
import FreshdeskInfoTable from './TableInfo';

export const FreshdeskCorrectTable: React.FC<ICorrectTable> = ({ correctEntries, endoEntries, totalLives }) => {
    const { DataCell } = FreshdeskInfoTable;

    const currentEndoBucketFooters = correctEntries.operations.map((op) => {
        const correctTexts = TABLE_TEXTS.CORRECT[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return (
            <DataCell colSpan={7} key={op.policyId + op.policyType}>
                <div style={spaceBetweenStyle}>
                    <Typography variant="small" weight="medium">
                        {correctTexts.totalCost}&nbsp;{op.policyType}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {formatCurrencyElseDefault(op.policyCost)}
                    </Typography>
                </div>
            </DataCell>
        );
    });

    const endoTableFooters = endoEntries.operations
        .map((op) => {
            const endoTexts = TABLE_TEXTS.ENDO[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
            return [
                <DataCell colSpan={7} key={op.policyId + op.policyType}>
                    <div style={spaceBetweenStyle}>
                        <Typography variant="small" weight="medium">
                            {endoTexts.totalCost}&nbsp;{op.policyType}
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {formatCurrencyElseDefault(op.policyCost)}
                        </Typography>
                    </div>
                </DataCell>,
                <React.Fragment key={op.policyId + op.policyType + 'cdAccount'}>
                    <DataCell colSpan={op.requiredOrNew ? 4 : 7}>
                        <div style={spaceBetweenStyle}>
                            <Typography variant="small" weight="medium">
                                {op.policyType}&nbsp;Current CD Balance&nbsp;(
                                {op.isCdSufficient ? 'Sufficient' : 'Low'})
                            </Typography>
                            <Typography variant="small" color={op.isCdSufficient ? 'secondary' : 'error'}>
                                {op.isCdMissing ? 'Missing' : formatCurrencyElseDefault(op.cdBalance)}
                            </Typography>
                        </div>
                    </DataCell>
                    {!!op.requiredOrNew && (
                        <DataCell colSpan={3}>
                            <div style={spaceBetweenStyle}>
                                <Typography variant="small" weight="medium">
                                    {op.policyType}&nbsp;{endoTexts.requiredOrNew}
                                </Typography>
                                <Typography variant="small" color="secondary">
                                    {formatCurrencyElseDefault(op.requiredOrNew)}
                                </Typography>
                            </div>
                        </DataCell>
                    )}
                </React.Fragment>
            ];
        })
        .flat();

    return (
        <div style={validatorSummaryTableStyle}>
            <FreshdeskLivesCostTable
                details={correctEntries.operations}
                header={
                    <div style={spaceBetweenStyle}>
                        <Typography variant="small" weight="medium">
                            Total New Correct Lives
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {correctEntries.totalLives}/{totalLives}
                        </Typography>
                    </div>
                }
                footers={currentEndoBucketFooters}
            />
            <FreshdeskLivesCostTable
                details={endoEntries.operations}
                header={
                    <div style={spaceBetweenStyle}>
                        <Typography variant="small" weight="medium">
                            Total Correct Lives in Endo
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {endoEntries.totalLives}
                        </Typography>
                    </div>
                }
                footers={endoTableFooters}
            />
        </div>
    );
};
