import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 16px 56px;
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const CardContainer = styled.div<{ status: string }>`
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: ${(p) => (p.status === 'Active' ? p.theme.colors.surface : p.theme.colors.disableFill)};
    border-radius: 16px;
    padding: 24px 32px 32px 32px;
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const CardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const CompanyName = styled.div`
    margin-left: 36px;
`;
