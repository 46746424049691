import React from 'react';
import { IUserData } from '../../pages/SPAContainer/pages/MemberDetails/types';
import useDebounce from '../useDebounce';

const useSearchUsers = (
    users: IUserData[]
): {
    handleSearch(e: React.ChangeEvent<HTMLInputElement>): void;
    searchedText: string;
    searchedUsers: IUserData[];
    shouldShowSearch: boolean;
} => {
    const [searchedUsers, setSearchedUsers] = React.useState<IUserData[]>([]);
    const [searchedText, setSearchedText] = React.useState('');
    useDebounce(searchedText, (text) => {
        const result = users.filter((item) =>
            `${item.firstName}${item.lastName}${item.userId}`.toLowerCase().includes(text.toLowerCase())
        );
        setSearchedUsers(result);
    });
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchedText(e.target.value);
    };
    return {
        handleSearch,
        searchedText,
        searchedUsers,
        shouldShowSearch: users.length > 0 && users.length < 1000
    };
};

export default useSearchUsers;
