export type IJSON = {
    [key: string]: any;
};

export interface ISegmentAct {
    name: string;
    properties?: Record<string, unknown>;
    loginStatus?: boolean;
    clickedOn?: boolean;
}

interface ISegmentActions extends IJSON {
    CLICK: Record<string, ISegmentAct>;
}

export const SEGMENT_ACTIONS: ISegmentActions = {
    CLICK: {
        POLICY_DETAILS: {
            name: 'POLICY_DETAILS',
            properties: {
                describe: 'User Clicked on PolicyDetails Page',
                page_category: '',
                login_status: true
            }
        }
    }
};
