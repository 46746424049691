import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;
export const StyledSearchContainer = styled.div`
    width: 100%;
    position: relative;
`;
export const StyledSearchInput = styled.input`
    padding: 10px 60px;
    border-radius: 20px;
    width: 100%;
    border: 1px solid ${(p) => p.theme.colors.primary};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const StyledSearchLabel = styled.div`
    position: absolute;
    background-color: ${(p) => p.theme.colors.primary};
    height: 100%;
    width: 50px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.theme.colors.white};
    left: 0;
    top: 0;
`;
export const StyledSearchButton = styled.button`
    position: absolute;
    height: 100%;
    width: 50px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
`;
export const StyledSearchError = styled.div`
    position: absolute;
    background-color: transparent;
    color: ${(p) => p.theme.colors.error};
    padding-left: 60px;
    margin-top: 5px;
    left: 0;
    bottom: 15;
    font-size: ${(p) => p.theme.fontSizes.label};
`;
export const StyledInputContainer = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justidy-content: center;
    align-items: center;
`;
export const StyledSelectRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 7px;
`;
export const StyledRadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;
export const StyledTableContainer = styled.div`
    padding: 0px 30px;
    margin-top: 100px;
`;
