export interface IExcelSheet<T extends Record<string, unknown>> {
    sheetName: string;
    rows: IExcelRows<T>[];
}

export interface IExcelRows<T extends Record<string, unknown>> {
    data: T;
    props: Record<string, IExcelCellProps>;
}

export interface IExcelCellProps {
    cellColour?: string;
    fontColour?: string;
}

export type IUserAttributeNames =
    | 'User ID'
    | 'Employee ID'
    | 'Relationship to Account Holder'
    | 'Name'
    | 'Slab ID'
    | 'Mobile'
    | 'Email Address'
    | 'Gender'
    | 'CTC'
    | 'Enrolment Due Date'
    | 'Date of Birth'
    | 'Coverage Start Date'
    | 'Exception';

export type IExcelDownloadFormat = IExcelRows<Record<IUserAttributeNames | 'Errors', unknown>>[];

export const KeyErrorNameMap: Record<string, IUserAttributeNames | 'Global'> = {
    userId: 'User ID',
    employeeId: 'Employee ID',
    relationship: 'Relationship to Account Holder',
    firstName: 'Name',
    slabId: 'Slab ID',
    mobile: 'Mobile',
    email: 'Email Address',
    gender: 'Gender',
    ctc: 'CTC',
    enrolmentDueDate: 'Enrolment Due Date',
    dob: 'Date of Birth',
    policyStartDate: 'Coverage Start Date',
    isException: 'Exception',
    global: 'Global'
};

export const ErrorSheetColumnNames: (IUserAttributeNames | 'Errors')[] = [
    'User ID',
    'Employee ID',
    'Relationship to Account Holder',
    'Name',
    'Coverage Start Date',
    'Enrolment Due Date',
    'Slab ID',
    'Mobile',
    'Email Address',
    'Date of Birth',
    'Gender',
    'CTC',
    'Exception',
    'Errors'
];
