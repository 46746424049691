import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    min-width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyleFormWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 40px;
`;

export const StyledFormTitleContainer = styled.div`
    width: 100%;
`;

export const StyledFormTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.title};
    color: ${p.theme.colors.muted};
    font-weight: bold;
    text-transform: uppercase;
  `
);

export const StyledDynamicFormWrapper = styled.div<{
    $fullWidth: boolean;
}>`
    margin-bottom: 10px;
    min-width: ${(p) => (p.$fullWidth ? `90%` : `42.5%`)};
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
