export interface IAddAdjustmentModalProps {
    isVisible: boolean;
    setIsVisible(isVisible: boolean): void;
    id: string;
    type: 'adjustment' | 'payment' | 'policyBookingAmount' | '';
}

export interface CDLedgerEntryFormInput {
    endorsementId: { data: string; error: string };
    premium: { data: string; error: string };
    notes: { data: string; error: string };
    date: { data: Date; error: string };
    transactionType: { data: string; error: string };
    paymentProof?: { data: string; error: string };
}

export interface ICDBalanceEntryProps {
    isLoading: boolean
    apiError: string | undefined
    onAddEntry: (cdLedgerEntryObject: CDLedgerEntryFormInput) => void
}

export interface ICdLedgerEntryValidations {
    isMandatory: boolean;
    label: string;
    name: string;
    type: string;
    inputType: string;
    display: boolean;
    error: string;
    regex?: RegExp;
}

export const cdLedgerValidations: ICdLedgerEntryValidations[] = [
    {
        isMandatory: true,
        label: 'Total Premium',
        name: 'premium',
        type: 'input',
        inputType: 'number',
        display: true,
        error: 'Please enter a valid premium amount',
        regex: /^\d+(\.\d{1,2})?$/
    },
    {
        isMandatory: false,
        label: 'Endo no.',
        name: 'endorsementId',
        type: 'input',
        inputType: 'number',
        display: true,
        error: 'Please enter a valid endorsement number'
    },
    {
        isMandatory: true,
        label: 'Notes',
        name: 'notes',
        type: 'input',
        inputType: 'string',
        display: true,
        error: 'Please enter a valid message'
    },
    {
        isMandatory: true,
        label: 'Entry Date',
        name: 'date',
        type: 'input',
        inputType: 'date',
        display: true,
        error: 'Please enter a valid date'
    },
    {
        isMandatory: true,
        label: 'Transaction Type',
        name: 'transactionType',
        type: 'input',
        inputType: 'string',
        display: true,
        error: 'Please enter a valid transaction type'
    },
    {
        isMandatory: true,
        label: 'Notes',
        name: 'paymentProof',
        type: 'blob',
        inputType: 'string',
        display: true,
        error: 'Please upload payment proof'
    }
];
