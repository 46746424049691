import React from 'react';
import { Dialog, Modal } from '@loophealth/loop-ui-web-library';
import { IDocumentModalProps } from './types';
import { LeaveIcon } from '../../../../assets/img';
import { StyledCancelSingleModalContent } from './styles';

const SingleDocumentCancelModal: React.FC<IDocumentModalProps> = ({
    isVisible,
    setIsVisible,
    onSubmit
}) => {
    const closeModal = () => {
        setIsVisible(false);
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <Dialog
                variant="vertical"
                icon={LeaveIcon}
                buttonVariant="error"
                description="If you reject this document, then this user will be deleted from endorsement."
                title={'Are you sure you want to Reject this document?'}
                primaryButtonText="Yes, Reject"
                onPrimaryButtonClicked={onSubmit}
                onSecondaryButtonClicked={closeModal}
                secondaryButtonText="Go Back"
            >
                <StyledCancelSingleModalContent>
                    HR will be notified to send us correct document
                </StyledCancelSingleModalContent>
            </Dialog>
        </Modal>
    );
};

export default SingleDocumentCancelModal;
