import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { ICDAccountList, ICdAccountPolicyData } from '../../../../../redux/slices/CDAccountSlice/types';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';

export const fetchStateData = (): {
    cdAccounts: ICDAccountList;
    searchedCDAccounts: ICDAccountList;
    companies: ICompanyData[] | null;
} => {
    const cdAccounts = useSelector((state: ReduxState) => state.cdAccountSlice.cdAccountList);
    const searchedCDAccounts = useSelector((state: ReduxState) => state.cdAccountSlice.searchedCDAccountList);
    const companies = useSelector((state: ReduxState) => state.companySlice.companyList.data);
    return { cdAccounts, searchedCDAccounts, companies };
};

export const navigateToCdLedger = (navigate: NavigateFunction, id: string): void => {
    navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.CD_LEDGER.route + '?id=' + id);
};
export const concatenatePolicyTypeSuffix = (policyPlan: ICdAccountPolicyData): string => {
    const policyType = policyPlan.policyType || '';
    return [
        policyType,
        policyPlan.isParentalPolicy && '(Parental)',
        policyPlan.isTopUpPolicy && '(Top-up)'
    ].filter(Boolean).join(' ');
};
