import React, { FC, useState } from 'react';
import { IUploadModalFileProps } from './types';
import { Loader, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledButtonContainer,
    StyledFileIconContainer,
    StyledLoader,
    StyledSelectedFileIcon,
    StyledSelectedFileImageAndTextContainer,
    StyledSelectedFileItem,
    StyledSelectedFileText
} from './styles';
import { FileIcon, LoopEmeraldDownloadIcon, TrashIcon } from '../../../../assets/img';

const UploadModalFile: FC<IUploadModalFileProps> = ({
    fileName,
    fileSize,
    uploading,
    deleting,
    onDelete,
    onDownload
}) => {
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async () => {
        setDownloading(true);
        await onDownload?.();
        setDownloading(false);
    };

    const handleDelete = async () => {
        await onDelete();
    };

    return (
        <StyledSelectedFileItem>
            <StyledSelectedFileImageAndTextContainer>
                <StyledFileIconContainer>
                    <StyledSelectedFileIcon src={FileIcon} />
                </StyledFileIconContainer>
                <StyledSelectedFileText>
                    <Typography variant="small" weight="medium">
                        {fileName}
                    </Typography>
                    {
                        fileSize && <Typography variant="extraSmall" color="secondary">
                            {fileSize}
                        </Typography>
                    }
                </StyledSelectedFileText>
            </StyledSelectedFileImageAndTextContainer>
            {
                uploading
                    ? <StyledLoader>
                        <Loader />
                    </StyledLoader>
                    : <StyledButtonContainer>
                        {
                            onDownload && <LoopButton
                                onClick={handleDownload}
                                variant={'text'}
                                iconSrc={LoopEmeraldDownloadIcon}
                                isLoading={downloading}
                            >
                                Download
                            </LoopButton>
                        }
                        <LoopButton
                            onClick={handleDelete}
                            variant={'text'}
                            iconSrc={TrashIcon}
                            isLoading={deleting}
                        >
                            <Typography variant="small" weight="medium" color="error">
                                Delete
                            </Typography>
                        </LoopButton>
                    </StyledButtonContainer>
            }
        </StyledSelectedFileItem>
    );
};

export default UploadModalFile;
