import styled from 'styled-components';

export const StyledMoveLivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  gap: 30px;
  align-items: end;

  span {
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.beyondGrey};
  }
`;
