import {
    CalendarDisabledIcon,
    EnvelopeDisabledIcon,
    NotesWithBackgroundIcon,
    PolicyDisabledIcon,
    CheckmarkCircleIcon
} from '../../../../../assets/img';
import ActivePolicies from '../../../../containers/ActivePolicies';
import EnrolmentConfigDates from '../../../../containers/EnrolmentConfigDates';
import SelectedPoliciesList from '../../../../containers/SelectedPoliciesList';
import StakeholdersList from '../../../../containers/StakeholdersList';
import CompletedEnrolmentConfig from '../../../../containers/CompletedEnrolmentConfig';
import { IConfigObject } from './types';
import { format } from 'date-fns';

export const configObject = (
    handleExtendEnrolmentConfig: () => void,
    startDate: string | undefined,
    endDate: string | undefined,
    endoCycleStartDate: string | undefined,
    endoCycleEndDate: string | undefined,
    hrEmails: string[] | undefined,
    csmEmails: string[] | undefined,
    policyIds: string[] | null | undefined,
    isCompletedTwoDaysAgo: boolean,
    handleSendEmail: () => void,
    completionEmailsSent: boolean | undefined,
    completed: boolean | undefined
): IConfigObject[] => [
    {
        id: 1,
        name: completed ? 'Completed Enrolment' : 'Active Policies',
        description:
            startDate && endDate
                ? `${format(new Date(startDate), 'do MMM')} - ${format(new Date(endDate), 'do MMM')}`
                : '',
        status: isCompletedTwoDaysAgo || completionEmailsSent ? 'Inactive' : 'Active',
        icon: isCompletedTwoDaysAgo || completionEmailsSent ? CheckmarkCircleIcon : NotesWithBackgroundIcon,
        ChildComponent: isCompletedTwoDaysAgo || completionEmailsSent ? CompletedEnrolmentConfig : ActivePolicies,
        props: {
            handleExtendEnrolmentConfig,
            handleSendEmail,
            completed,
            isCompletedTwoDaysAgo
        }
    },
    {
        id: 2,
        name: 'Selected Policies',
        description: '',
        status: 'Inactive',
        icon: PolicyDisabledIcon,
        ChildComponent: SelectedPoliciesList,
        props: {
            policyIds
        }
    },
    {
        id: 3,
        name: 'Current dates',
        description: '',
        status: 'Inactive',
        icon: CalendarDisabledIcon,
        ChildComponent: EnrolmentConfigDates,
        props: {
            memberRegistrationStartDate: startDate,
            memberRegistrationEndDate: endDate,
            endoCycleStartDate: endoCycleStartDate,
            endoCycleEndDate: endoCycleEndDate
        }
    },
    {
        id: 4,
        name: 'Stakeholders added for reporting',
        description: '',
        status: 'Inactive',
        icon: EnvelopeDisabledIcon,
        ChildComponent: StakeholdersList,
        props: {
            hrEmails,
            csmEmails
        }
    }
];
