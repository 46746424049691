import React from 'react';
import {
    BulkRegisterIcon,
    CompanyDirectoryIcon,
    GlobalMemberSearchIcon,
    ChangeRequestIcon,
    OffboardMembersIcon,
    CdAccountsIcon,
    EndorsementsIcon,
    ValidatorIcon
} from '../../assets/img';

export interface IRouteIconComponent {
    isActive?: boolean;
}

import {
    AddCompany,
    BulkRegister,
    ChangeRequestsCompanies,
    ChangeRequestsOfOneCompany,
    OffboardMembers,
    CompanyDetails,
    CompanyDirectory,
    GlobalMemberSearch,
    MemberDetails,
    PlacementForm,
    PolicyDetails,
    PremiumCalculator,
    Endorsements,
    EndorsementDetails,
    HrAddition,
    AccessManagement,
    SkfOnboarding,
    PreValidation,
    EnrolmentConfig,
    Validator,
    ValidationSummary,
    KycChecklist,
    DnfChecklist,
    BackdateChecklist
} from '../../components/pages/SPAContainer/pages';
import CDAccount from '../../components/pages/SPAContainer/pages/CDAccount';
import CDLedger from '../../components/pages/SPAContainer/pages/CDLedger';
import CustomHRMS from '../../components/pages/SPAContainer/pages/HRMS';
import PreValidationAndHrAddition from '../../components/pages/SPAContainer/pages/PrevalidationAndHrAddition';
import {
    isCdAccountEnabled,
    isPremiumRaterEnabled,
    isHRAdditionEnabled,
    isAccessManagementEnabled,
    isSkfOnboardingEnabled,
    isPreValidationEnabled,
    isPreValidationAndHRAdditionEnabled,
    isChangeRequestEnabled,
    isEnrolmentConfigEnabled,
    isValidatorEnabled,
    isDataDiscrepancyFixEnabled
} from '../featureFlags';
import AddPolicy from '../../components/pages/SPAContainer/pages/AddPolicy';
import DataDiscrepancy from '../../components/containers/DataDiscrepancy';
import EditEnrolmentConfig from '../../components/pages/SPAContainer/pages/EditEnrolmentConfig';

export const EXTERNAL_ROUTES = {
    LOGIN: '/login',
    APP: '/app'
};

export type IRouteName =
    | 'COMPANY_DIRECTORY'
    | 'BULK_REGISTER'
    | 'CHANGE_REQUESTS_COMPANIES'
    | 'CHANGE_REQUESTS_OF_ONE_COMPANY'
    | 'ADD_COMPANY'
    | 'COMPANY_DETAILS'
    | 'ADD_POLICY'
    | 'MEMBER_DETAILS'
    | 'PLACEMENT_FORM'
    | 'POLICY_DETAILS'
    | 'GLOBAL_MEMBER_SEARCH'
    | 'OFFBOARD_MEMBERS'
    | 'CD_ACCOUNTS'
    | 'CD_LEDGER'
    | 'PREMIUM_RATERS'
    | 'ENDORSEMENTS'
    | 'ENDORSEMENT_DETAILS'
    | 'HR_ADDITION'
    | 'SKF_ONBOARDING'
    | 'POLICY_PREVALIDATION'
    | 'ACCESS_MANAGEMENT'
    | 'PREVALIDATION_AND_HR_ADDITION'
    | 'ENROLMENT_CONFIG'
    | 'HRMS'
    | 'VALIDATOR'
    | 'VALIDATION_SUMMARY'
    | 'KYC_CHECKLIST'
    | 'DNF_CHECKLIST'
    | 'BACKDATE_CHECKLIST'
    | 'DATA_DISCREPANCY'
    | 'EDIT_ENROLMENT_CONFIG';

export const INTERNAL_ROUTES: Record<
    IRouteName,
    {
        route: string;
        name: string;
        Component: React.FunctionComponent;
        isVisible: boolean;
        icon?: string;
        IconComponent?: React.FunctionComponent<IRouteIconComponent>;
    }
> = {
    COMPANY_DIRECTORY: {
        route: 'company-directory',
        name: 'Company Directory',
        Component: CompanyDirectory,
        isVisible: true,
        icon: CompanyDirectoryIcon
    },
    EDIT_ENROLMENT_CONFIG: {
        route: 'enrolement-config',
        name: 'Edit Enrolment Config',
        Component: EditEnrolmentConfig,
        isVisible: false,
        icon: ''
    },
    GLOBAL_MEMBER_SEARCH: {
        route: 'global-member-search',
        name: 'Global Member Search',
        Component: GlobalMemberSearch,
        isVisible: true,
        icon: GlobalMemberSearchIcon
    },
    BULK_REGISTER: {
        route: 'bulk-register',
        name: 'Bulk Registeration',
        Component: BulkRegister,
        isVisible: true,
        icon: BulkRegisterIcon
    },
    PREMIUM_RATERS: {
        route: 'premium-raters',
        name: 'Premium Raters',
        Component: PremiumCalculator,
        isVisible: isPremiumRaterEnabled,
        icon: BulkRegisterIcon
    },
    CHANGE_REQUESTS_COMPANIES: {
        route: 'change-request-companies',
        name: 'Change Requests',
        Component: ChangeRequestsCompanies,
        isVisible: isChangeRequestEnabled,
        icon: ChangeRequestIcon
    },
    CHANGE_REQUESTS_OF_ONE_COMPANY: {
        route: 'change-requests',
        name: `Company's Change Requests`,
        Component: ChangeRequestsOfOneCompany,
        isVisible: false,
        icon: ''
    },
    OFFBOARD_MEMBERS: {
        route: 'offboard-members',
        name: 'Offboard Members',
        Component: OffboardMembers,
        isVisible: true,
        icon: OffboardMembersIcon
    },
    ADD_COMPANY: {
        route: 'add-company',
        name: 'Add Company',
        Component: AddCompany,
        isVisible: false,
        icon: ''
    },
    COMPANY_DETAILS: {
        route: 'company-details',
        name: 'Company Details',
        Component: CompanyDetails,
        isVisible: false,
        icon: ''
    },
    ADD_POLICY: {
        route: 'add-policy',
        name: 'Add Policy',
        Component: AddPolicy,
        isVisible: false,
        icon: ''
    },
    MEMBER_DETAILS: {
        route: 'member-details',
        name: 'Member Details',
        Component: MemberDetails,
        isVisible: false,
        icon: ''
    },
    PLACEMENT_FORM: {
        route: 'placement-form',
        name: 'Placement Form',
        Component: PlacementForm,
        isVisible: false,
        icon: ''
    },
    POLICY_DETAILS: {
        route: 'policy-details',
        name: 'Policy Details',
        Component: PolicyDetails,
        isVisible: false,
        icon: ''
    },
    CD_ACCOUNTS: {
        route: 'cd-accounts',
        name: 'CD Accounts',
        Component: CDAccount,
        isVisible: isCdAccountEnabled,
        icon: CdAccountsIcon
    },
    CD_LEDGER: {
        route: 'cd-ledger',
        name: 'CD LEdger',
        Component: CDLedger,
        isVisible: false,
        icon: ''
    },
    ENDORSEMENTS: {
        route: 'endorsements',
        name: 'Endorsements',
        Component: Endorsements,
        isVisible: true,
        icon: EndorsementsIcon
    },
    ENDORSEMENT_DETAILS: {
        route: 'endorsement-details',
        name: 'Endorsements',
        Component: EndorsementDetails,
        isVisible: false,
        icon: EndorsementsIcon
    },
    HR_ADDITION: {
        route: 'hr-addition',
        name: 'HR Addition',
        Component: HrAddition,
        isVisible: isHRAdditionEnabled,
        icon: EndorsementsIcon
    },
    ACCESS_MANAGEMENT: {
        route: 'access-management',
        name: 'Access Management',
        Component: AccessManagement,
        isVisible: isAccessManagementEnabled,
        icon: EndorsementsIcon
    },
    SKF_ONBOARDING: {
        route: 'skf-onboarding',
        name: 'SKF Onboarding',
        Component: SkfOnboarding,
        isVisible: isSkfOnboardingEnabled,
        icon: EndorsementsIcon
    },
    POLICY_PREVALIDATION: {
        route: 'pre-validation',
        name: 'Pre-validation',
        Component: PreValidation,
        isVisible: isPreValidationEnabled,
        icon: EndorsementsIcon
    },
    PREVALIDATION_AND_HR_ADDITION: {
        route: 'pre-validation-and-hr-addition',
        name: 'Pre-validation and HR Addition',
        Component: PreValidationAndHrAddition,
        isVisible: isPreValidationAndHRAdditionEnabled,
        icon: EndorsementsIcon
    },
    ENROLMENT_CONFIG: {
        route: 'enrolment-config',
        name: 'Enrolment Config',
        Component: EnrolmentConfig,
        isVisible: isEnrolmentConfigEnabled,
        icon: EndorsementsIcon
    },
    HRMS: {
        route: 'custom-hrms',
        name: 'HRMS',
        Component: CustomHRMS,
        isVisible: true,
        icon: EndorsementsIcon
    },
    VALIDATOR: {
        route: 'validator',
        name: 'Endorsement Data Validator',
        Component: Validator,
        isVisible: isValidatorEnabled,
        icon: ValidatorIcon
    },
    VALIDATION_SUMMARY: {
        route: 'validation-summary',
        name: 'Validation Summary',
        Component: ValidationSummary,
        isVisible: false,
        icon: ''
    },
    KYC_CHECKLIST: {
        route: 'kyc-checklist',
        name: 'KYC Checklist',
        Component: KycChecklist,
        isVisible: false,
        icon: ''
    },
    DNF_CHECKLIST: {
        route: 'dnf-checklist',
        name: 'DNF Checklist',
        Component: DnfChecklist,
        isVisible: false,
        icon: ''
    },
    BACKDATE_CHECKLIST: {
        route: 'backdate-checklist',
        name: 'Backdate Checklist',
        Component: BackdateChecklist,
        isVisible: false,
        icon: ''
    },
    DATA_DISCREPANCY: {
        route: 'data-discrepancy',
        name: 'Data Discrepancy',
        Component: DataDiscrepancy,
        isVisible: isDataDiscrepancyFixEnabled,
        icon: ValidatorIcon
    }
};
