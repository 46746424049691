import styled from 'styled-components';

export const StyledPreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background: #F8F9FA;
  border-radius: 16px;
`;

export const StyledPreferenceSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const StyledPreferenceItemContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 16px;
  width: 50%;
  padding: 28px 24px;
  border-radius: 12px;
  cursor: pointer;
  border: ${(p) => p.$isSelected ? `1px solid ${p.theme.colors.primary}` : ''};
  background: ${(p) => p.$isSelected ? p.theme.colors.planeGreenLight : p.theme.colors.white};
`;
export const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const StyledPreferenceInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  span {
    white-space: nowrap;
  }
`;

export const StyledChangeButtonWrapper = styled.div`
  cursor: pointer;
`;

export const StyledPreferenceAlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 10px;
  margin-bottom: 24px;
  background: ${(p) => p.theme.colors.planeGreenLight};
`;
