import React from 'react';
import { Card } from '../../../../atoms';
import { StyledContainer, StyledSubTitle, StyledTitle } from './styles';
import { ExcelUploadPill } from '../../../../containers';
import { excelDownload, excelToJson } from '../../../../../utils/common';
import { useToast } from '../../../../hooks/useToast';
import { grantGenomeAccess } from '../../../../../adapters';
import { EXCEL_TEMPLATES } from '../../../../../utils/constants/TemplateFilepaths';

const AccessManagement: React.FunctionComponent = () => {
    const toast = useToast();
    const onSheetUpload = (files: Record<string, unknown>[]) => {
        if (files[0]) {
            excelToJson(files[0] as unknown as Blob, async (json) => {
                if (json.length) {
                    const emails: string[] = (json.map((v) => v['Emails']) as string[]) || [];
                    const errorData = await grantGenomeAccess(emails);
                    const fileName = `ACCESS-${new Date().toLocaleString()}`;
                    excelDownload(errorData, fileName);
                    toast?.displayToast('success', 'Request Processed!', 'Downloading rejected entries', 10000);
                } else {
                    toast?.displayToast('error', 'Failed to upload!', 'No records found in the excel!');
                }
            });
        }
    };
    return (
        <StyledContainer>
            <StyledTitle>Access Management</StyledTitle>
            <Card>
                <ExcelUploadPill
                    onUpload={onSheetUpload}
                    templateUrl={EXCEL_TEMPLATES.GENOME_ACCESS}
                    title="Grant Access to Genome"
                />
                <StyledSubTitle>NOTE: You can only grant access to Loop Employees</StyledSubTitle>
            </Card>
        </StyledContainer>
    );
};

export default AccessManagement;
