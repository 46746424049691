import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: ${(p) => p.theme.colors.white};
`;

export const StyledIconContainer = styled.div`
    display: flex;
    height: 50px;
    width: 50px;
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
    border-radius: 100%;
    justify-content: center;
    align-items: center;
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
`;

export const StyledIcon = styled.img``;
