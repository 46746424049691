import { Loader, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo, useState } from 'react';
import {
    StyledApprovalDocumentScrollBox,
    StyledApprovalDocumentsContainer,
    StyledApprovalImageShareIcon,
    StyledApprovalMainImageContainer,
    StyledApprovalModalBodyContainer,
    StyledApprovalModalCloseIconContainer,
    StyledApprovalModalContent,
    StyledApprovalModalHeader,
    StyledApprovalMultiModalBody,
    StyledApprovalMultiModalFooter,
    StyledApprovalMultiModalPrimaryImage,
    StyledApprovalMutliModalPrimaryImageContainer,
    StyledChip,
    StyledToastWrapper
} from './styles';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { CrossIcon } from '../../../../assets/icons/CrossIcon';
import { ExportIcon } from '../../../../assets/icons/ExportIcon';
import { APPROVAL_STATUS } from '../constants';
import MultiDocumentSingleItem from './MultiDocumentSingleItem';
import { IDocument } from './types';
import PartiallyApprovedModal from './PartiallyApprovedModal';
import MultiDocumentCancelModal from './MultiDocumentCancelModal';
import { IEAModalProps } from '../types';
import { getSignedBlob } from '../../../../utils/constants/Endorsement';
import { updateDocumentVerificationStatus } from '../../../../adapters';

const MultiDocumentApprovalModal: React.FunctionComponent<IEAModalProps> = ({
    endorsementId,
    kycMember,
    setKYCMember,
    updateMemberDetails
}: IEAModalProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeDocument, setActiveDocument] = useState<IDocument|null>(null);
    const [allDocuments, setAllDocuments] = useState<IDocument[]>([]);
    const [isPartiallyApprovedModalVisible, setIsPartiallyApprovedModalVisible] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const toast = Toast.useToast();

    useEffect(() => {
        if (kycMember) {
            setIsVisible(true);
            setIsLoading(true);
            try {
                Promise.all(kycMember.kycDocuments.map(getSignedBlob)).then((files) => {
                    const updatedDocuments = files.map((file, index) => {
                        const doc = kycMember.kycDocuments[index];
                        let fileUrl = '';
                        try {
                            fileUrl = URL.createObjectURL(file as Blob);
                        } catch (e) {
                            toast?.error('Failed to load document');
                        }
                        return {
                            id: (index+1).toString(),
                            title: doc,
                            type: doc.includes('.pdf') ? 'application/pdf' : 'image/jpeg',
                            status: APPROVAL_STATUS.PENDING,
                            fileUrl
                        };
                    }).filter((doc) => doc.fileUrl);
                    setAllDocuments(updatedDocuments);
                    setIsLoading(false);
                });
            } catch (e) {
                toast?.error('Failed to load documents');
                setIsLoading(false);
            }
        } else {
            setIsVisible(false);
        }
    }, [kycMember]);

    useEffect(()=>{
        if (allDocuments.length > 0) {
            setActiveDocument(allDocuments[0]);
        }
    }, [allDocuments]);

    const memberName = useMemo(() => {
        return kycMember?.firstName ? `${kycMember.firstName} ${kycMember.lastName}` : kycMember?.lastName || '-';
    }, [kycMember]);

    const isAllDocumentStatusChanged: boolean = useMemo(() => {
        return allDocuments.every((doc) => doc.status !== APPROVAL_STATUS.PENDING);
    }, [allDocuments]);

    const verifiedDocumentsCount = useMemo(() => {
        return allDocuments.filter((doc) => doc.status !== APPROVAL_STATUS.PENDING).length;
    }, [allDocuments]);

    const closeModal = () => {
        setIsVisible(false);
        setKYCMember(undefined);
    };

    const onSubmit = async () => {
        const allDocumentsApproved = allDocuments.every((doc) => doc.status === APPROVAL_STATUS.APPROVED);
        if (allDocumentsApproved) {
            await handleAccept();
        } else {
            await handleReject();
        }
    };

    const handleAccept = async () => {
        const updatedMemberData = { ...kycMember };
        setIsLoading(true);
        try {
            await updateDocumentVerificationStatus(kycMember.userId, endorsementId, true);

            toast?.success(
                <StyledToastWrapper>
                    <Typography variant="medium" weight="medium" color="tertiary">
                        &ldquo;{kycMember.firstName}&rdquo; has been successfully verified.
                    </Typography>
                </StyledToastWrapper>,
                '',
                { variant: 'dark', expires: 5000, closeOnClick: false, showCloseIcon: false }
            );
            updatedMemberData.isKYCDocumentVerified = true;
        } catch (e) {
            toast?.error((e as Error).message);
            setIsLoading(false);
            return;
        }
        updateMemberDetails(updatedMemberData);
        setKYCMember(undefined);
        setIsLoading(false);
    };

    const handleReject = async () => {
        setIsLoading(true);
        try {
            await updateDocumentVerificationStatus(kycMember.userId, endorsementId, false);
            toast?.success(
                <StyledToastWrapper>
                    <Typography variant="medium" weight="medium" color="tertiary">
                        &ldquo;{kycMember.firstName}&rdquo; has been successfully deleted from the endorsement.
                    </Typography>
                </StyledToastWrapper>,
                '',
                { variant: 'dark', expires: 5000, closeOnClick: false, showCloseIcon: false }
            );
        } catch (e) {
            toast?.error((e as Error).message);
            setIsLoading(false);
            return;
        }
        updateMemberDetails(kycMember, true);
        setKYCMember(undefined);
        setIsLoading(false);
    };

    const onLeave = () => {
        setIsVisible(false);
        setIsPartiallyApprovedModalVisible(false);
        setIsCancelModalVisible(false);
    };

    const onFinalCancel = () => {
        if (allDocuments.every((doc) => doc.status === APPROVAL_STATUS.PENDING)) {
            setIsVisible(false);
        } else {
            setIsCancelModalVisible(true);
        }
    };

    const updateDocumentStatus = (id: string, status: APPROVAL_STATUS) => {
        setAllDocuments(allDocuments.map((doc) => {
            if (doc.id === id) {
                return {
                    ...doc,
                    status
                };
            }
            return doc;
        }));
    };

    const onDocumentSelect = (id: string) => {
        setActiveDocument(allDocuments.find((doc) => doc.id === id) || allDocuments[0]);
    };

    const openFile = () => {
        if (activeDocument) window.open(activeDocument.fileUrl, '_blank');
    };

    return (
        <WithThemeProvider>
            <PartiallyApprovedModal
                isVisible={isPartiallyApprovedModalVisible}
                setIsVisible={setIsPartiallyApprovedModalVisible}
                onSubmit={onSubmit}
            />
            <MultiDocumentCancelModal
                isVisible={isCancelModalVisible}
                setIsVisible={setIsCancelModalVisible}
                onSubmit={onLeave}
            />
            <Modal
                isModalClosable={false}
                disablePadding
                isVisible={isVisible &&
                    !isPartiallyApprovedModalVisible &&
                    !isCancelModalVisible
                }
                setIsVisible={closeModal}
            >
                <StyledApprovalModalContent>
                    <StyledApprovalModalHeader>
                        <Typography variant='medium' weight='semiBold'>
                            {memberName}&apos;s KYC Documents
                        </Typography>
                        <StyledApprovalModalCloseIconContainer onClick={closeModal}>
                            <StyledChip>
                                {verifiedDocumentsCount}/{allDocuments.length} Verified
                            </StyledChip>
                            <CrossIcon strokeWidth={0.5}/>
                        </StyledApprovalModalCloseIconContainer>
                    </StyledApprovalModalHeader>
                    <StyledApprovalModalBodyContainer>
                        <StyledApprovalDocumentsContainer>
                            <StyledApprovalDocumentScrollBox>
                                {
                                    allDocuments.map((document: IDocument) => (
                                        <MultiDocumentSingleItem
                                            key={document.id}
                                            document={document}
                                            updateDocumentStatus={updateDocumentStatus}
                                            onDocumentSelect={onDocumentSelect}
                                        />
                                    ))
                                }
                            </StyledApprovalDocumentScrollBox>
                        </StyledApprovalDocumentsContainer>
                        <StyledApprovalMainImageContainer>
                            <StyledApprovalMultiModalBody>
                                <StyledApprovalMutliModalPrimaryImageContainer>
                                    {
                                        isLoading ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                {activeDocument?.type === 'application/pdf' ? (
                                                    <iframe
                                                        src={activeDocument?.fileUrl+'#toolbar=0&navpanes=0'}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                ) : (
                                                    <StyledApprovalMultiModalPrimaryImage
                                                        src={activeDocument?.fileUrl}
                                                        alt="kyc-document"
                                                    />
                                                )}
                                                <StyledApprovalImageShareIcon
                                                    onClick={openFile}
                                                >
                                                    <ExportIcon />
                                                </StyledApprovalImageShareIcon>
                                            </>
                                        )
                                    }
                                </StyledApprovalMutliModalPrimaryImageContainer>
                            </StyledApprovalMultiModalBody>
                            <StyledApprovalMultiModalFooter>
                                <LoopButton
                                    variant='outline'
                                    textColor="primary"
                                    fontWeight="semiBold"
                                    onClick={onFinalCancel}
                                >
                                    Cancel
                                </LoopButton>
                                <LoopButton
                                    variant={!isAllDocumentStatusChanged ? 'disabled' : 'filled'}
                                    fontWeight="semiBold"
                                    onClick={onSubmit}
                                >
                                    Submit
                                </LoopButton>
                            </StyledApprovalMultiModalFooter>
                        </StyledApprovalMainImageContainer>
                    </StyledApprovalModalBodyContainer>
                </StyledApprovalModalContent>
            </Modal>
        </WithThemeProvider>
    );
};

export default MultiDocumentApprovalModal;
