import React, { useState } from 'react';
import { StyledContainer, IconContainer, StyledText, StyledSVG } from './styles';
import { INavigsationItem } from './types';

const NavigationItem: React.FunctionComponent<INavigsationItem> = ({
    children,
    icon = '',
    IconComponent,
    onClick,
    isActive
}) => {
    const [hover, setHover] = useState(false);

    return (
        <StyledContainer
            onClick={onClick}
            isActive={isActive}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <IconContainer>
                {IconComponent ? (
                    <IconComponent isActive={hover || isActive} />
                ) : (
                    <StyledSVG src={icon} $isActive={isActive} />
                )}
            </IconContainer>
            <StyledText>{children}</StyledText>
        </StyledContainer>
    );
};

export default NavigationItem;
