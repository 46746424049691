import styled from 'styled-components';
import { IStyledStepperIcon } from './types';

export const StyledValidationSummaryDetails = styled.div``;

export const StyledStepperIcon = styled.img<IStyledStepperIcon>`
    background: ${({ theme, disabled }) => (disabled ? theme.colors.disableFill : theme.colors.coverYellow)};
    border-radius: 50%;
    width: 40px;
    aspect-ratio: 1;
    padding: ${({ disabled }) => (disabled ? '7px 0 4px 5px' : '7.5px')};
`;

export const StyledSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 56px;
    flex: 1;
`;

export const StyledSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(p) => p.theme.colors.defaultBorder};
    padding-bottom: 16px;
`;

export const StyledHeaderTitle = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const StyledFreshdeskMessageCTA = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    span:empty {
        display: none;
    }
`;

export const StyledFreshdeskMessage = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 28px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
    background: ${(p) => p.theme.colors.surface}; ;
`;

export const StyledFreshdeskMessagePre = styled.pre`
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
`;

export const StyledFreshdeskCheckmarkIcon = styled.img`
    width: 40px;
    aspect-ratio: 1;
    padding: 7.5px;
    background-color: ${(p) => p.theme.colors.turquoise};
    border-radius: 50%;
`;

export const StyledEndorsementsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyleModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const StyledCommentIcon = styled.div`
    width: 48px;
    height: 48px;
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    margin-bottom: 14px;
`;

export const StyledInputWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
`;

export const StyledButtonWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;

export const StyledSpacingWrapper = styled.div`
    margin-top: 8px;
`;

export const StyledAttachMessage = styled.div`
    padding: 8px 12px;
    border-radius: 4px;
    background: ${(p) => p.theme.colors.surface};
`;

export const StyledExcelDownloadContainer = styled.div`
    display: flex;
    padding: 16px 20px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
    background: ${(p) => p.theme.colors.white};
`;

export const StyledExcelContent = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
`;

export const StyledExcelIcon = styled.div`
    display: flex;
    border-radius: 50%;
    aspect-ratio: 1;
    padding: 10px;
    background-color: ${(p) => p.theme.colors.planeGreenLight};
`;

export const StyledExcelDownloadText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const StyledHorizontalButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const StyledTextArea = styled.textarea`
    background-color: ${(p) => p.theme.colors.planeGreenLight};
    border: 1px solid ${(p) => p.theme.colors.primary};
    border-radius: 8px;
    width: 621px;
    height: 184px;
    padding: 12px 20px;
    margin: 32px 0px 32px 0px;
    font-size: 14px;
`;

export const StyledButtonAlignment = styled.div`
    width: 100%;
`;

export const StyledButtonSpacing = styled.div`
    margin-right: 12px;
    width: 100%;
`;
