import { IAdapterData } from '../../../../../../redux/types';
import { IUserSlice } from './types';

export const initialAdapterState: IAdapterData = {
    data: null,
    loading: false,
    error: null
};
export const initialState = {
    currentUser: null,
    userData: {
        ...initialAdapterState
    },
    userMobile: {
        ...initialAdapterState
    },
    userProfile: {
        ...initialAdapterState
    },
    isUserAuthenticated: {
        ...initialAdapterState,
        data: false
    }
} as IUserSlice;
