import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100%;
    padding: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
export const StyledLogo = styled.img`
    max-width: 167px;
    height: auto;
`;

export const StyledParaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 105px;
`;
export const StyledHighlight = styled.span`
    color: ${(p) => p.theme.colors.primary};
`;
export const StyledTitle = styled.div(
    (p) => `
    font-size: 100px;
    font-style: normal;
    font-weight: bold;
    line-height: 124px;
    margin-bottom: 65px;
    color: ${p.theme.colors.black};
`
);
export const StyledHeading = styled.h3(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.title};
    margin: 0;
`
);
