export interface IAddCDAccountModal {
    isVisible: boolean;
    setIsVisible(isVisible: boolean): void;
}

export interface ICDAccountValidations {
    isMandatory: boolean;
    label: string;
    name: string;
    type: string;
    inputType: string;
    display: boolean;
    error: string;
    regex?: RegExp;
}

export const cdAccountValidations: ICDAccountValidations[] = [
    {
        isMandatory: true,
        label: 'Select Company',
        name: 'companyId',
        type: 'dropdown',
        inputType: 'string',
        display: true,
        error: 'Please select a company'
    },
    {
        isMandatory: true,
        label: 'Select Insurer',
        name: 'insurer',
        type: 'dropdown',
        inputType: 'string',
        display: true,
        error: 'Please select an insurer'
    },
    {
        isMandatory: true,
        label: 'Select Policies',
        name: 'policies',
        type: 'dropdown',
        inputType: 'string',
        display: true,
        error: 'Please select atleast one policy'
    },
    {
        isMandatory: true,
        label: 'Enter CD Account ID',
        name: 'accountNumber',
        type: 'input',
        inputType: 'string',
        display: true,
        error: 'Please enter a valid cd account id',
        regex: /^[a-zA-Z0-9]*$/
    },
    {
        isMandatory: true,
        label: 'Enter Inception Amount',
        name: 'balance',
        type: 'input',
        inputType: 'number',
        display: true,
        error: 'Please enter a valid inception amount',
        regex: /^-?[0-9]{1,9}(?:\.[0-9]{1,4})?$/
    },
    {
        isMandatory: true,
        label: 'Notes',
        name: 'paymentProof',
        type: 'blob',
        inputType: 'string',
        display: true,
        error: 'Please upload payment proof'
    }
];

export interface CDAccountFormInput {
    accountNumber: { data: string; error: string };
    companyId: { data: string; error: string };
    insurer: { data: string; error: string };
    balance: { data: string; error: string };
    policies: {
        data: {
            id: string;
            value: string;
            label: string;
        }[];
        error: string;
    };
    paymentProof?: { data: string; error: string };
}
