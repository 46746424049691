import React from 'react';
import { useTheme } from 'styled-components';
import { StyledButton } from './styles';
import { ILabelButton } from './types';

const LabelButton: React.FunctionComponent<ILabelButton> = ({ children, onClick, color, isInverted = false }) => {
    const theme = useTheme();
    return (
        <StyledButton onClick={onClick} $color={color || theme.colors.primary} $isInverted={isInverted}>
            {children}
        </StyledButton>
    );
};

export default LabelButton;
