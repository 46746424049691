import styled from 'styled-components';

export const StyledTable = styled.div`
    display: table;
    width: 100%;
    border-radius: 5px;
`;

export const StyledTableRow = styled.div(
    (p) => `
    display: table-row;
    width: 100%;
    cursor: pointer;
    color: ${p.style?.color || p.theme.colors.primaryTransparent};
    &:hover ${StyledTableData} {
        background-color: ${p.style?.color || p.theme.colors.primaryTransparent};
        color: ${p.theme.colors.black};
    }
`
);
export const StyledTableError = styled.div(
    (p) => `
    caption-side: bottom; 
    display: table-caption; 
    text-align: center; 
    background-color: ${p.theme.colors.error};
    color: ${p.theme.colors.white};
    padding: 12px;
`
);
export const StyledTableLoading = styled.div<{ $backgroundColor?: string; }>(
    (p) => `
    caption-side: bottom; 
    display: table-caption; 
    text-align: center; 
    background-color: ${p.$backgroundColor ?? p.theme.colors.white};
    padding: 24px;
`
);
export const StyledTableHeading = styled.div<{ $index: boolean; $align: 'left' | 'center' | 'right' }>(
    (p) => `
    display: table-cell;
    background-color: ${p.theme.colors.primary};
    font-size: ${p.theme.fontSizes.label};
    color: ${p.style?.color || p.theme.colors.white};
    padding: 15px 18px;
    vertical-align:middle;
    font-weight: bold;
    text-align: ${p.$align};
    ${(p.$index && 'width: 60%;') || ''};
`
);
export const StyledTableData = styled.div<{ $index: boolean; $align: 'left' | 'center' | 'right' }>(
    (p) => `
    background-color: ${p.theme.colors.white};
    display: table-cell;
    vertical-align:middle;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    padding: 18px;
    border-bottom: 1px solid  ${p.theme.colors.primary};
    text-align: ${p.$align};
    ${(p.$index && 'width: 60%;') || ''}
`
);
export const StyledTableMessage = styled.div`
  padding: 16px;
  width: 100%;
  text-align: center;
`;
