import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IBulkUploadData, IBulkUploadExcelData } from './types';
import { formatGender, formatRelationship } from '../../../utils/constants/Endorsement/PossibleValue';

export const formatExcelDatesForBulkUpload = (excelMemberData: IBulkUploadExcelData): IBulkUploadData => {
    const dateFormatUtils = new DateFormatUtils();
    return {
        userId: excelMemberData['User ID'],
        employee_id: excelMemberData['Employee ID'],
        relationship_to_account_holders: formatRelationship(excelMemberData['Relationship to Account Holder']),
        name: excelMemberData['Name'],
        slabId: excelMemberData['Slab ID'],
        data_addition: '',
        mobile: excelMemberData['Mobile'],
        email_address: excelMemberData['Email Address'],
        company: '',
        gender: formatGender(excelMemberData['Gender']),
        ctc: excelMemberData['CTC'],
        enrolment_due_date: excelMemberData['Enrolment Due Date (DD/MMM/YYYY)']
            ? dateFormatUtils.excelToBackendDateFormat(excelMemberData['Enrolment Due Date (DD/MMM/YYYY)'])
            : '',
        date_of_birth: dateFormatUtils.excelToBackendDateFormat(excelMemberData['Date of Birth (DD/MMM/YYYY)']),
        policy_start_date: dateFormatUtils.excelToBackendDateFormat(
            excelMemberData['Coverage Start Date (DD/MMM/YYYY)']
        ),
        policy_end_date: '',
        isException: (excelMemberData['Exception'] || '').toLowerCase()
    };
};

export const validateRegistrationData = (registrationData: Record<string, unknown>[]): Error| undefined => {
    if (registrationData?.length < 1) {
        return new Error('No member data found in the sheet');
    }
};
