import React from 'react';
import { ChevronDownIcon } from '../../../assets/img';
import { StyledSelect, StyledOption } from './styles';
import { IOption, ISelect } from './types';

const Select: React.FunctionComponent<ISelect> = ({ name, value, onChange, children, ...rest }) => {
    return (
        <StyledSelect
            $src={ChevronDownIcon}
            name={name}
            value={value}
            onChange={onChange}
            {...rest}
        >
            {children}
        </StyledSelect>
    );
};

const SelectOption: React.FunctionComponent<IOption> = ({ value, children }) => {
    return <StyledOption value={value}>{children}</StyledOption>;
};

export { Select, SelectOption };
