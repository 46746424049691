import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchActivePoliciesList } from '../../../adapters';
import { ICompanyData } from '../../../redux/slices/CompanySlice/types';
import { IPolicy } from '../../../redux/slices/PolicySlice/types';
import { FormError, FormLabel, Input, Loader, Modal, Select, SelectOption } from '../../atoms';
import MultiSelectAll from '../../atoms/MultiselectCheckboxes/MultiSelectAll';
import { constructPolicyOptions, createLegerService, fetchStateData } from './utils';
import {
    StyledButton,
    StyledButtonRow,
    StyledError,
    StyledFilter,
    StyledModalContainer,
    StyledMultiSelectWrapper,
    StyledRowAlignment
} from './styles';
import { CDAccountFormInput, IAddCDAccountModal } from './types';
import { resetCrudState } from '../../../redux/slices/CDAccountSlice';

const AddCDAccountModal: React.FunctionComponent<IAddCDAccountModal> = ({ isVisible, setIsVisible }) => {
    /** Define initial form state */
    const initialFormState: CDAccountFormInput = {
        accountNumber: { data: '', error: '' },
        companyId: { data: '', error: '' },
        insurer: { data: '', error: '' },
        balance: { data: '', error: '' },
        policies: {
            data: [],
            error: ''
        }
    };

    const dispatch = useDispatch();
    const { companyList, cdAccountCrud } = fetchStateData();
    const [company, setCompany] = React.useState<ICompanyData | null>(null);
    const [cdAccountEntryObject, setCdAccountEntryObject] = React.useState<CDAccountFormInput>(initialFormState);
    const [policy, setPolicy] = React.useState<{ id: string; value: string; label: string }[]>([]);
    const [insurerList, setInsurerList] = React.useState<string[]>([]);
    const [policyList, setPolicyList] = React.useState<{ id: string; value: string; label: string }[]>([]);
    const [activePolicyList, setActivePolicyList] = React.useState<{ policies: IPolicy[] } & { loading: boolean }>({
        policies: [],
        loading: true
    });

    const fetchActivePolicies = async (id: string) => {
        try {
            const activePolicyListResponse = (await fetchActivePoliciesList(id)).data;
            setActivePolicyList({ policies: [...activePolicyListResponse], loading: false });
        } catch (e) {
            // TODO: Handle error state
        }
    };
    const setInsurers = async () => {
        let insurers: string[] = [];
        activePolicyList.policies.map((policy) => {
            insurers.push(policy.insurerName);
        });
        insurers = insurers.filter((item, pos) => insurers.indexOf(item) == pos);
        setInsurerList(insurers);
    };

    const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const comp = e.target.value;
        setActivePolicyList({ policies: [], loading: true });
        setCdAccountEntryObject({ ...initialFormState, companyId: { data: comp, error: '' } });

        setCompany((companyList.data || []).filter((c) => c.id === comp)[0]);
        /** Fetch only the active policy list */
        fetchActivePolicies(comp);
    };
    const handleInsurerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const insurer = e.target.value;
        setCdAccountEntryObject({
            ...cdAccountEntryObject,
            insurer: { data: insurer, error: '' },
            policies: {
                data: [],
                error: ''
            }
        });
        setPolicy([]);
        /** Re-render the policy list on every insurer selection */
        const policyOptions = constructPolicyOptions(activePolicyList, insurer);
        setPolicyList(policyOptions);
    };
    const handleBalanceChange = (
        setCdAccountEntryObject: React.Dispatch<React.SetStateAction<CDAccountFormInput>>,
        cdAccountEntryObject: CDAccountFormInput
    ) => {
        return (e: React.ChangeEvent<HTMLInputElement>) =>
            setCdAccountEntryObject({
                ...cdAccountEntryObject,
                balance: { data: e.target.value, error: '' }
            });
    };
    const hanldeCdAccountIdChange = (
        setCdAccountEntryObject: React.Dispatch<React.SetStateAction<CDAccountFormInput>>,
        cdAccountEntryObject: CDAccountFormInput
    ) => {
        return (e: React.ChangeEvent<HTMLInputElement>) =>
            setCdAccountEntryObject({
                ...cdAccountEntryObject,
                accountNumber: { data: e.target.value, error: '' }
            });
    };
    const handleFormNavigation = (isVisible: boolean) => {
        setIsVisible(isVisible);
        setInsurerList([]);
        setCompany(null);
        dispatch(resetCrudState());
        setCdAccountEntryObject(initialFormState);
    };
    const createLedger = createLegerService(
        cdAccountEntryObject,
        policy,
        dispatch,
        setCdAccountEntryObject,
        handleFormNavigation
    );
    React.useEffect(() => {
        setInsurers();
    }, [activePolicyList]);

    return (
        <Modal
            isVisible={isVisible}
            setIsVisible={(isVisible: boolean) => handleFormNavigation(isVisible)}
            title={`Create a new cd account`}
        >
            <StyledModalContainer data-testid="add-cd-account-modal">
                <StyledRowAlignment>
                    <StyledFilter>
                        <FormLabel isMandatory>Select Company</FormLabel>
                        <Select
                            name="company"
                            onChange={handleCompanyChange}
                            value={cdAccountEntryObject.companyId?.data || ''}
                            data-testid="company-select"
                        >
                            <SelectOption value={''}>Please select</SelectOption>
                            {(companyList.data || []).map((comp, index) => (
                                <SelectOption key={index} value={comp.id}>
                                    {comp.name}
                                </SelectOption>
                            ))}
                        </Select>
                        <FormError>{cdAccountEntryObject.companyId?.error}</FormError>
                    </StyledFilter>
                </StyledRowAlignment>
                {company && (
                    <>
                        {(!activePolicyList.loading && (
                            <>
                                <StyledRowAlignment>
                                    <StyledFilter>
                                        <FormLabel isMandatory>Select Insurer</FormLabel>
                                        <Select
                                            name="insurer"
                                            onChange={handleInsurerChange}
                                            value={cdAccountEntryObject.insurer?.data || ''}
                                            data-testid="insurer"
                                        >
                                            <SelectOption value={''}>Please select</SelectOption>
                                            {(insurerList || []).map((insurer, index) => (
                                                <SelectOption key={index} value={insurer}>
                                                    {insurer}
                                                </SelectOption>
                                            ))}
                                        </Select>
                                        <FormError>{cdAccountEntryObject.insurer?.error}</FormError>
                                    </StyledFilter>
                                    <StyledFilter>
                                        <FormLabel isMandatory>Select Policies</FormLabel>
                                        <StyledMultiSelectWrapper>
                                            <MultiSelectAll
                                                options={[...policyList]}
                                                setSelectedOptions={setPolicy}
                                                selectedOptions={policy}
                                            />
                                        </StyledMultiSelectWrapper>
                                        <FormError>{cdAccountEntryObject.policies?.error}</FormError>
                                    </StyledFilter>
                                </StyledRowAlignment>
                                <StyledRowAlignment>
                                    <StyledFilter>
                                        <FormLabel isMandatory>Enter CD Account ID:</FormLabel>
                                        <Input
                                            name="cdAccountId"
                                            placeholder="Enter"
                                            onChange={hanldeCdAccountIdChange(
                                                setCdAccountEntryObject,
                                                cdAccountEntryObject
                                            )}
                                            value={cdAccountEntryObject.accountNumber?.data || ''}
                                            errorText={cdAccountEntryObject.accountNumber?.error || ''}
                                            isDisabled={false}
                                        />
                                        <FormError>{cdAccountEntryObject.accountNumber?.error}</FormError>
                                    </StyledFilter>
                                    <StyledFilter>
                                        <FormLabel isMandatory>Enter Inception Amount (₹)</FormLabel>
                                        <Input
                                            name="amount"
                                            placeholder="Enter"
                                            onChange={handleBalanceChange(
                                                setCdAccountEntryObject,
                                                cdAccountEntryObject
                                            )}
                                            value={cdAccountEntryObject.balance?.data.toString() || ''}
                                            errorText={cdAccountEntryObject.balance?.error || ''}
                                            isDisabled={false}
                                        />
                                        <FormError>{cdAccountEntryObject.balance?.error}</FormError>
                                    </StyledFilter>
                                </StyledRowAlignment>
                                <StyledError>
                                    <FormError>{cdAccountCrud.error}</FormError>
                                </StyledError>
                                <StyledButtonRow>
                                    <StyledButton onClick={createLedger}>
                                        <b>Create ledger</b>
                                    </StyledButton>
                                </StyledButtonRow>
                            </>
                        )) || <Loader />}
                    </>
                )}
            </StyledModalContainer>
        </Modal>
    );
};

export default AddCDAccountModal;
