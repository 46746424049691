import React from 'react';
import { Typography, useTable } from '@loophealth/loop-ui-web-library';
import {
    StyledEmptySpace,
    StyledSpaceBetween,
    StyledValidatorSummaryTable
} from '../styles';
import LivesCostTable from './LivesCostTable';
import {
    formatCurrencyElseDefault,
    getOverallCostingForBucket
} from '../utils';
import { IPartialTable } from '../types';
import { TABLE_TEXTS } from './constants';

export const PartialTable: React.FC<IPartialTable> = ({
    correctEntries,
    incorrectEntries,
    endoEntries,
    totalLives
}) => {
    const { Table, Row, DataCell } = useTable('info');

    const currentEndoBucketFooters = correctEntries.operations.map((op) => {
        const correctTexts =
      TABLE_TEXTS.CORRECT[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return (
            <DataCell colSpan={7} key={op.policyId + op.policyType}>
                <StyledSpaceBetween>
                    <Typography variant="small" weight="medium">
                        {correctTexts.totalCost}&nbsp;{op.policyType}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {formatCurrencyElseDefault(op.policyCost)}
                    </Typography>
                </StyledSpaceBetween>
            </DataCell>
        );
    });

    const incorrectTableFooters = incorrectEntries.operations.map((op) => {
        const incorrectTexts =
      TABLE_TEXTS.INCORRECT[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return (
            <DataCell colSpan={7} key={op.policyId + op.policyType}>
                <StyledSpaceBetween>
                    <Typography variant="small" weight="medium">
                        {incorrectTexts.totalCost}&nbsp;{op.policyType}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {formatCurrencyElseDefault(op.policyCost)}
                    </Typography>
                </StyledSpaceBetween>
            </DataCell>
        );
    });

    const endoTableFooters = endoEntries.operations.map((op) => {
        const endoTexts =
      TABLE_TEXTS.ENDO[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return (
            <DataCell colSpan={7} key={op.policyId + op.policyType}>
                <StyledSpaceBetween>
                    <Typography variant="small" weight="medium">
                        {endoTexts.totalCost}&nbsp;{op.policyType}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {formatCurrencyElseDefault(op.policyCost)}
                    </Typography>
                </StyledSpaceBetween>
            </DataCell>
        );
    });

    const overallCostingOperations = getOverallCostingForBucket(
        endoEntries,
        incorrectEntries
    );

    const overallBucketFooters = overallCostingOperations.map((op) => {
        const overallTexts =
      TABLE_TEXTS.OVERALL[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return (
            <React.Fragment key={op.policyId + op.policyType}>
                <Row>
                    <DataCell colSpan={7}>
                        <StyledSpaceBetween>
                            <Typography variant="small" weight="medium">
                                {overallTexts.totalCost}&nbsp;{op.policyType}
                            </Typography>
                            <Typography variant="small" color="secondary">
                                {formatCurrencyElseDefault(op.policyCost)}
                            </Typography>
                        </StyledSpaceBetween>
                    </DataCell>
                </Row>
                <Row>
                    <DataCell colSpan={op.requiredOrNew ? 4 : 7}>
                        <StyledSpaceBetween>
                            <Typography variant="small" weight="medium">
                                {op.policyType}&nbsp;Current CD Balance&nbsp;(
                                {op.isCdSufficient ? 'Sufficient' : 'Low'})
                            </Typography>
                            <Typography
                                variant="small"
                                color={op.isCdSufficient ? 'secondary' : 'error'}
                            >
                                {op.isCdMissing ? 'Missing' : formatCurrencyElseDefault(op.cdBalance)}
                            </Typography>
                        </StyledSpaceBetween>
                    </DataCell>
                    {!!op.requiredOrNew && (
                        <DataCell colSpan={3}>
                            <StyledSpaceBetween>
                                <Typography variant="small" weight="medium">
                                    {op.policyType}&nbsp;{overallTexts.requiredOrNew}
                                </Typography>
                                <Typography variant="small" color="secondary">
                                    {formatCurrencyElseDefault(op.requiredOrNew)}
                                </Typography>
                            </StyledSpaceBetween>
                        </DataCell>
                    )}
                </Row>
            </React.Fragment>
        );
    });

    return (
        <StyledValidatorSummaryTable>
            <LivesCostTable
                details={correctEntries.operations}
                header={
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            Total New Correct Lives
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {correctEntries.totalLives}/{totalLives}
                        </Typography>
                    </StyledSpaceBetween>
                }
                footers={currentEndoBucketFooters}
            />
            <LivesCostTable
                details={incorrectEntries?.operations || []}
                header={
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            Total New Incorrect Lives
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {incorrectEntries?.totalLives}/{totalLives}
                        </Typography>
                    </StyledSpaceBetween>
                }
                footers={incorrectTableFooters}
            />
            <LivesCostTable
                details={endoEntries.operations}
                header={
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            Total Correct Lives in Endo
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {endoEntries.totalLives}
                        </Typography>
                    </StyledSpaceBetween>
                }
                footers={endoTableFooters}
            />
            <Table>
                <Row>
                    <DataCell colSpan={7}>
                        <StyledEmptySpace />
                    </DataCell>
                </Row>
                {overallBucketFooters}
            </Table>
        </StyledValidatorSummaryTable>
    );
};
