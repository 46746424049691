import {
    Dialog,
    LoopButton,
    LoopModal,
    Modal,
    Typography,
    UploadPillSelection
} from '@loophealth/loop-ui-web-library';
import React, { FC, useEffect, useState } from 'react';
import { IUploadModal } from './types';
import {
    StyledCloseButton,
    StyledCloseIcon,
    StyledErrorContainer,
    StyledFileInputHelper,
    StyledFileLimitAlert,
    StyledFileUploadInput,
    StyledPolicyDocumentInputContainer,
    StyledPolicyUploadContainer,
    StyledUpdateButton
} from './styles';
import {
    CloseIcon,
    ExitIconCircle,
    TrashIconCircle,
    WarningIconRed
} from '../../../assets/img';
import UploadModalFile from './UploadModalFile';

const UploadModal: FC<IUploadModal> = ({
    title,
    subtitle,
    isVisible,
    maxFileSize = 5,
    fileLimit = 1,
    onModalClose,
    filesSelected,
    setFilesSelected,
    onDelete,
    formats,
    onSave,
    onDownload,
    uploadedFiles
}) => {
    const [error, setError] = useState<string>();
    const [uploadingIndex, setUploadingIndex] = useState<number | undefined>(undefined);
    const [deletingIndex, setDeletingIndex] = useState<number | undefined>(undefined);
    const [showDeleteFilePrompt, setShowDeleteFilePrompt] = useState(false);
    const [deleteFileIndex, setDeleteFileIndex] = useState(-1);
    const [showExitConfirmationPrompt, setShowExitConfirmationPrompt] = useState(false);

    const formatBytes = (bytes: number): string => {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const handleSave = async () => {
        if (!filesSelected.length) return;

        for (let i = 0; i < filesSelected.length; i++) {
            setUploadingIndex(i);
            const file = filesSelected[i] as unknown as File;
            await onSave(file.name as string, file, i === filesSelected.length - 1);
        }

        setUploadingIndex(undefined);
        setFilesSelected([]);
    };

    const handleDelete = async (index: number) => {
        setDeleteFileIndex(index);
        setShowDeleteFilePrompt(true);
    };

    const handleDeleteFileConfirmation = async () => {
        setDeletingIndex(deleteFileIndex);
        setShowDeleteFilePrompt(false);
        await onDelete(deleteFileIndex);
        setDeletingIndex(undefined);
    };

    const handleFileRemoval = (removalIndex: number) => {
        setFilesSelected((previousFiles) => previousFiles.filter((_, index) => index !== removalIndex));
    };

    const handleModalClose = () => {
        if (filesSelected.length) setShowExitConfirmationPrompt(true);
        else onModalClose();
    };

    const handleConfirmClose = () => {
        setShowExitConfirmationPrompt(false);
        onModalClose();
    };

    useEffect(() => {
        setError(undefined);
    }, [filesSelected]);

    return (
        <LoopModal
            title={title}
            isVisible={isVisible}
            onClose={handleModalClose}
            isModalClosable={false}
        >
            <Modal
                isVisible={showDeleteFilePrompt}
                setIsVisible={setShowDeleteFilePrompt}
            >
                <Dialog
                    variant="vertical"
                    icon={TrashIconCircle}
                    description="Are you sure you want to delete this file? You'll have to re-upload it."
                    title={`Deleting "${uploadedFiles[deleteFileIndex]}" File?`}
                    primaryButtonText="Yes, Delete"
                    buttonVariant='error'
                    onPrimaryButtonClicked={handleDeleteFileConfirmation}
                    onSecondaryButtonClicked={() => setShowDeleteFilePrompt(false)}
                    secondaryButtonText="Cancel"
                />
            </Modal>

            <Modal
                isVisible={showExitConfirmationPrompt}
                setIsVisible={setShowExitConfirmationPrompt}
            >
                <Dialog
                    variant="vertical"
                    icon={ExitIconCircle}
                    description="Are you sure you want to exit? Because on exiting you'll loose all the progress."
                    title={`You'll loose all the progress!`}
                    primaryButtonText="Yes, Exit"
                    buttonVariant='error'
                    onPrimaryButtonClicked={handleConfirmClose}
                    onSecondaryButtonClicked={() => setShowExitConfirmationPrompt(false)}
                    secondaryButtonText="Cancel"
                />
            </Modal>

            <StyledCloseButton onClick={handleModalClose}>
                <StyledCloseIcon src={CloseIcon} />
            </StyledCloseButton>

            <StyledPolicyUploadContainer>
                <div>
                    {subtitle}
                </div>

                <StyledPolicyDocumentInputContainer>
                    {
                        (filesSelected.length + uploadedFiles.length) < fileLimit &&
                        <>
                            {
                                fileLimit > 1 && <div>
                                    <Typography variant='extraSmall' weight='medium'>
                                        Max {fileLimit} files allowed&nbsp;
                                    </Typography>
                                    <Typography variant='extraSmall' weight='medium' color='secondary'>
                                        ({uploadedFiles.length} of 2 uploaded)
                                    </Typography>
                                </div>
                            }

                            <StyledFileUploadInput>
                                <UploadPillSelection
                                    formats={formats}
                                    onUpload={setFilesSelected}
                                    selected={filesSelected}
                                    onError={(error) => setError(error.message)}
                                    maxFiles={fileLimit - uploadedFiles.length}
                                    maxSize={maxFileSize}
                                    hideSelectedFiles={true}
                                />
                            </StyledFileUploadInput>
                            <StyledFileInputHelper>
                                <Typography variant='extraSmall' color='secondary'>
                                    Supported Formats: {
                                        formats.map(
                                            (format) => format.replace('.', '').toUpperCase()).join(', ')
                                    }
                                </Typography>
                                <Typography variant='extraSmall' color='secondary'>
                                    Maximum Size: {maxFileSize}MB
                                </Typography>
                            </StyledFileInputHelper>
                        </>
                    }


                    {filesSelected.map((file, index) => (
                        <UploadModalFile
                            key={(file.name as string) + index}
                            fileName={file.name as string}
                            fileSize={formatBytes(file.size as number)}
                            onDelete={() => handleFileRemoval(index)}
                            uploading={uploadingIndex === index}
                        />
                    ))}

                    {uploadedFiles.map((file, index) => (
                        <UploadModalFile
                            key={(file) + index}
                            fileName={file}
                            onDelete={async () => await handleDelete(index)}
                            onDownload={async () => await onDownload(index)}
                            deleting={deletingIndex === index}
                        />
                    ))}

                    {
                        filesSelected.length === fileLimit &&
                        <StyledFileLimitAlert>
                            <Typography variant='small' color='emerald'>
                                If you want upload another file delete existing file first
                            </Typography>
                        </StyledFileLimitAlert>
                    }

                    {
                        error &&
                        <StyledErrorContainer>
                            <img src={WarningIconRed} />
                            <Typography variant='small' color='error'>
                                {
                                    error.includes('formats')
                                        ? 'Uploaded file format is not supported. Upload again.'
                                        : error.includes('MB')
                                            ? `File size should not be more than ${maxFileSize}MB. Upload again.`
                                            : error
                                }
                            </Typography>
                        </StyledErrorContainer>
                    }
                </StyledPolicyDocumentInputContainer>

                <StyledUpdateButton>
                    <LoopButton
                        size='medium'
                        variant={filesSelected.length ? 'filled' : 'disabled'}
                        onClick={handleSave}
                        isLoading={uploadingIndex !== undefined}
                    >
                        Save
                    </LoopButton>
                </StyledUpdateButton>
            </StyledPolicyUploadContainer>
        </LoopModal>
    );
};

export default UploadModal;
