import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledDisclaimerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    gap: 16px;
`;

export const StyledRaterDisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
`;

export const StyledRaterDisplay = styled.div<{ $errorState: boolean }>(
    (p) => `display: flex;
    padding: 16px 20px;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    background: ${p.$errorState ? p.theme.colors.errorBG : p.theme.colors.secondaryBG};`
);

export const StyledRaterDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const StyledSVG = styled(SVG)<{ $errorState?: boolean }>(
    (p) =>
        `display: inline-block;
    line-height: 1;
     & path {
        stroke:  ${(p.$errorState && p.theme.colors.error) || 'currentColor'};
    }
    & circle {
        stroke: ${(p.$errorState && p.theme.colors.error) || 'currentColor'};
    }
    stroke-width: 0;`
);

export const StyledButtonRow = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    margin-top: 8px;
`;
