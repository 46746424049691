import styled from 'styled-components';

export const StyledContainer = styled.div<{ $error: boolean; }>`
    border: 2px dotted ${(p) => p.$error && p.theme.colors.error || p.theme.colors.mutedTransparent};
    border-radius: 10px;
    width: 100%;
    height: 150px;
    max-height: 150px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.secondary};
    &:hover {
        border: 2px dotted ${(p) => p.theme.colors.primary};
    }
`;
export const StyledMetaContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

export const StyledSubtitle = styled.span(
    (p) => `
    margin-top: 5px;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
`
);

export const StyledClickText = styled.span(
    (p) => `
    color: ${p.theme.colors.primary};
    font-size: ${p.theme.fontSizes.label};
    text-transform: uppercase;
    font-weight: 600;
`
);
export const StyledOrText = styled.span(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 3px;
`
);
