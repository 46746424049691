import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { WarningIcon } from '../../../../assets/img';
import {
    StyledRaterDisplay,
    StyledSVG,
    StyledRaterDetails,
    StyledRaterDisplayWrapper,
    StyledButtonRow
} from '../styles';
import { IErrorState } from '../types';

const ErrorState: React.FunctionComponent<IErrorState> = ({ closeModal, handleOnNavigate }) => {
    return (
        <>
            <StyledRaterDisplayWrapper>
                <Typography variant="extraSmall" color="error">
                    Rater not found
                </Typography>
                <StyledRaterDisplay $errorState={true}>
                    <StyledSVG src={WarningIcon} height={24} width={24} $errorState={true} />
                    <StyledRaterDetails>
                        <Typography variant="small">Upload rater to proceed with changing status</Typography>
                    </StyledRaterDetails>
                </StyledRaterDisplay>
            </StyledRaterDisplayWrapper>
            <StyledButtonRow>
                <LoopButton variant="secondary" onClick={closeModal}>
                    <Typography variant="small" color="emerald" weight="medium">
                        Cancel
                    </Typography>
                </LoopButton>
                <LoopButton variant="filled" onClick={handleOnNavigate}>
                    <Typography variant="small" color="emerald" weight="medium">
                        Upload Rater
                    </Typography>
                </LoopButton>
            </StyledButtonRow>
        </>
    );
};

export default ErrorState;
