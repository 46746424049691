import React from 'react';
import { bulkOffboardUsersAPI } from '../../../adapters';
import { ICompanyData } from '../../../redux/slices/CompanySlice/types';
import { excelDownload } from '../../../utils/common';
import { EXCEL_TEMPLATES } from '../../../utils/constants/TemplateFilepaths';
import { useConfirmationModalContext } from '../../hooks/modalConfirmationContext';
import { useToast } from '../../hooks/useToast';
import { IUploadPill } from '../../atoms/UploadPill/types';
import ExcelUploader from '../ExcelUploadPill';
import { getMemberListFromJson, transformOffboardMembersForUI } from './utils';
import { isValidationSummaryEnabled } from '../../../utils/featureFlags';
import format from './ErrorExcelDownload/format';
import downloadOffboardUsersErrorSheet from './ErrorExcelDownload';
import { ExcelUtils } from '@loophealth/loop-ui-web-library';

const BulkOffboardMembers: React.FunctionComponent<{
    company: ICompanyData;
    enableEndorsementCheck: boolean;
}> = ({ company, enableEndorsementCheck }) => {
    const toast = useToast();
    const modalContext = useConfirmationModalContext();

    const handleFailedMembers = (failedMembers: Record<string, unknown>[], toastId?: string | undefined) => {
        const fileName = `Offboard-${company.name}-${new Date().toLocaleString()}`;
        if (isValidationSummaryEnabled) {
            downloadOffboardUsersErrorSheet(format(failedMembers), fileName);
        } else {
            const allFailedMembers = [...failedMembers.map(transformOffboardMembersForUI)];
            excelDownload(allFailedMembers, fileName);
        }
        if (toastId) {
            toast?.removeToast(toastId);
        }
        toast?.displayToast(
            'error',
            'Some Members could not be offboarded',
            'An excel file with details will be download will begin shortly...',
            10000
        );
    };

    const handleBulkOffboardUsers = async (membersList: Record<string, unknown>[]) => {
        const toastId = toast?.displayToast('warning', 'uploading...', 'This might take some time.', null);
        try {
            const result = await bulkOffboardUsersAPI(company.id, membersList, enableEndorsementCheck);
            const { failure = [] } = result;
            if (failure.length > 0) {
                handleFailedMembers(failure, toastId);
            } else {
                if (toastId) {
                    toast?.removeToast(toastId);
                }
                toast?.displayToast('success', 'Members were offboarded Successfully', '', 10000);
            }
        } catch (e) {
            if (toastId) {
                toast?.removeToast(toastId);
            }
            toast?.displayToast('error', 'Failed to Upload!', (e as Error).message, 8000);
        }
    };
    const onUpload: IUploadPill['onUpload'] = async (files) => {
        try {
            const excelUtils = new ExcelUtils();
            const json = await excelUtils.excelToJson(files[0] as unknown as Blob);
            const sheet0 = Object.keys(json)[0];
            const excelJSON = json[sheet0];
            const memberList = getMemberListFromJson(excelJSON);
            if (memberList.length === 0) {
                toast?.displayToast('error', 'The Excelsheet has no members', '', 8000);
            } else {
                const isConfirmed = await modalContext.showConfirmation({
                    title: 'Are you sure?',
                    message: 'The uploaded member(s) will be off-boarded.',
                    yesLabel: 'Upload',
                    noLabel: 'Cancel'
                });
                isConfirmed && handleBulkOffboardUsers(memberList);
            }
        } catch (e) {
            toast?.displayToast('error', 'Failed to upload!', (e as Error).message);
            return;
        }
    };
    return (
        <ExcelUploader onUpload={onUpload} templateUrl={EXCEL_TEMPLATES.OFFBOARD_MEMBERS} title="OFFBOARDING MEMBERS" />
    );
};

export default BulkOffboardMembers;
