import theme from '../../../theme';
import { IOption } from '../../../components/atoms/MultiselectCheckboxes/type';
import { IStatus } from '../../../redux/slices/EndorsementSlice/types';
import {
    IDropdownValue,
    IRawException
} from '../../../components/containers/EndorsementExceptionsModal/types';
import { IRelationship } from '../../../adapters/types';

export const ENDORSEMENT_STATUS_CHIP_COLOR: Record<IStatus, string> = {
    'DRAFT': theme.colors.consultViolet,
    'IN_PROGRESS': theme.colors.coverYellow,
    'SENT_TO_INSURER': theme.colors.turquoise,
    'ENDO_DECLINED': theme.colors.planRed,
    'ENDO_COMPLETE': theme.colors.coverUpGreen,
    'READY_FOR_TPA': theme.colors.coverYellow,
    'RECEIVED_UHIDS': theme.colors.coverUpGreen,
    'ENDO_RECEIVED': theme.colors.primary
};
export const ENDORSEMENT_STATUS_TEXT_COLOR: Record<IStatus, string> = {
    'DRAFT': theme.colors.white,
    'IN_PROGRESS': theme.colors.primary,
    'SENT_TO_INSURER': theme.colors.primary,
    'ENDO_DECLINED': theme.colors.white,
    'ENDO_COMPLETE': theme.colors.white,
    'READY_FOR_TPA': theme.colors.white,
    'RECEIVED_UHIDS': theme.colors.white,
    'ENDO_RECEIVED': theme.colors.white
};
const ENDORSEMENT_STATUS_TEXT_LABEL: Record<IStatus, string> = {
    'DRAFT': 'Draft',
    'IN_PROGRESS': 'In Progress',
    'SENT_TO_INSURER': 'Sent to Insurer',
    'ENDO_DECLINED': 'Endo Declined',
    'ENDO_COMPLETE': 'Endo Complete',
    'READY_FOR_TPA': 'Ready for TPA',
    'RECEIVED_UHIDS': 'Received UHIDs',
    'ENDO_RECEIVED': 'Endo Received'
};
export const POLICY_TYPE_LABEL_MAP = {
    GMC: 'GMC',
    GPA: 'GPA',
    GTL: 'GTL',
    COVID: 'Covid',
    SUPER_TOP_UP: 'Super top-up'
};

export const ENDO_NO_LABEL_MAP = {
    1: 'Endo 1',
    2: 'Endo 2',
    3: 'Endo 3',
    4: 'Endo 4'
};

export const getStatusLabel = (status: IStatus): string => ENDORSEMENT_STATUS_TEXT_LABEL[status];

// Only show the banner until amount is not deducted-
const preProcessStates = ['DRAFT', 'IN_PROGRESS'];

// state before CD amount is deducted, need to check if CD Balance is sufficient-
const preProcessStatesForLowCDBalance = ['IN_PROGRESS'];

export const isPreProcessState = (status: IStatus): boolean => preProcessStates.includes(status);

export const toDisableCTAForEndorsementStates =
    (status: IStatus): boolean => preProcessStatesForLowCDBalance.includes(status);

export const policyTypeSelectOptions: IOption[] =
    Object.entries(POLICY_TYPE_LABEL_MAP).map(([key, value], id) => ({ id, label: value, value: key }));

export const endoSelectOptions: IOption[] =
    Object.entries(ENDO_NO_LABEL_MAP).map(([key, value], id) => ({ id, label: value, value: key }));

export const ENDORSEMENT_FILTER_OPTIONS_MAP = {
    policyType: {
        label: 'Policy Type',
        options: policyTypeSelectOptions
    },
    endoNumber: {
        label: 'Endo Number',
        options: endoSelectOptions
    }
};

export const defaultFilterState =
    Object.keys(ENDORSEMENT_FILTER_OPTIONS_MAP).reduce((obj, key) => ({ ...obj, [key]: '' }), {});

export type iEndorsementFilter = 'policyType' | 'endoNumber';

export const getFilterOptions = (value: iEndorsementFilter, includesAll = true)
    : { label: string, options: IOption[] } => {
    let result = ENDORSEMENT_FILTER_OPTIONS_MAP[value];
    if (includesAll) {
        result = { ...result, options: [{ id: 'all', label: 'All', value: undefined }, ...result.options] };
    }
    return result;
};

export const getEndorsementExceptionValues = (data: IRawException[]): IDropdownValue[] => {
    return data.map((item: IRawException) => ({
        value: item.id,
        name: item.name
    }));
};

export const formatRelationship = (
    relation: string | undefined
): IRelationship => {
    const relationship = (relation || '').toLowerCase().trim();
    switch (relationship) {
    case 'mother':
    case 'father':
    case 'parent':
        return 'parent';
    case 'mother-in-law':
    case 'mother in law':
    case 'father in law':
    case 'father-in-law':
    case 'parent-in-law':
        return 'parent-in-law';
    case 'son':
    case 'daughter':
    case 'child':
    case 'kid':
        return 'child';
    case 'wife':
    case 'husband':
    case 'spouse':
        return 'spouse';
    case 'self':
    case 'employee':
        return 'self';
    default:
        return relationship as IRelationship;
    }
};

export const formatGender = (genderString: string | undefined): string => {
    const gender = (genderString || '').toLowerCase().trim();
    switch (gender) {
    case 'm':
    case 'male':
        return 'male';
    case 'f':
    case 'female':
        return 'female';
    default:
        return gender;
    }
};
