import React, { useEffect, useState } from 'react';
import { Chip, ChipContainer, StyledContainer, StyledEmailContainer } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IStakeHolder } from './types';
import { fetchCompanyDetails, fetchUsersByParamAPI } from '../../../adapters';
import { IUserData } from '../../../adapters/types';

const StakeholdersList: React.FC<IStakeHolder> = ({ hrEmails, csmEmails }) => {
    const [hrEmailMap, setHrEmailMap] = useState<Map<string, string>>(new Map());
    const [csmEmailMap, setCsmEmailMap] = useState<Map<string, string>>(new Map());

    const fetchUsersByEmail = async (emails: string[], identifier: string) => {
        const promises: Promise<IUserData[]>[] = [];
        emails.forEach((email: string) => promises.push(fetchUsersByParamAPI('email', email)));
        const res = await Promise.all(promises);

        const newMap = new Map<string, string>();
        for (const user of res) {
            const companyName = (await fetchCompanyDetails(user?.[0].employer)).data.company.name;
            newMap.set(user?.[0].email, companyName);
        }

        if (identifier === 'hrEmail') {
            setHrEmailMap(newMap);
        }
        if (identifier === 'csmEmail') {
            setCsmEmailMap(newMap);
        }
    };

    const hrEmailArrayFromMap = [...hrEmailMap.entries()];
    const csmEmailArrayFromMap = [...csmEmailMap.entries()];

    useEffect(() => {
        if (hrEmails?.length) {
            fetchUsersByEmail(hrEmails, 'hrEmail');
        }
        if (csmEmails?.length) {
            fetchUsersByEmail(csmEmails, 'csmEmail');
        }
    }, [hrEmails, csmEmails]);

    return (
        <StyledContainer>
            <Typography variant="medium" color="secondary" weight="semiBold">
                The automatic reporting emails would be addressed to HR(s). CSM(s) will be added in cc
            </Typography>
            <StyledEmailContainer>
                <ChipContainer>
                    {hrEmailArrayFromMap.map(([email, companyName]) => {
                        return <Chip key={email}>{`${email} | ${companyName}`}</Chip>;
                    })}
                </ChipContainer>
                <ChipContainer>
                    {csmEmailArrayFromMap.map(([email, companyName]) => {
                        return <Chip key={email}>{`${email} | ${companyName}`}</Chip>;
                    })}
                </ChipContainer>
            </StyledEmailContainer>
        </StyledContainer>
    );
};

export default StakeholdersList;
