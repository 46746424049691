import { Form, Toast, Typography } from '@loophealth/loop-ui-web-library';
import { addDays, differenceInDays, isAfter, isBefore, max, min, subDays } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { EnrolmentCalendarIcon } from '../../../../../assets/img';
import ConfigStepHeader from './ConfigStepHeader';
import { StyledDatePickerContainer, StyledStepsContainer } from './styles';
import { IDateCycleSelectionComponentProps } from './types';

const DateCycleSelectionComponent: React.FC<IDateCycleSelectionComponentProps> = ({
    memberRegistrationStartDate,
    memberRegistrationEndDate,
    endoCycleStartDate,
    endoCycleEndDate,
    setMemberRegistrationStartDate,
    setMemberRegistrationEndDate,
    setEndoCycleStartDate,
    setEndoCycleEndDate,
    setDateError,
    selectedPolicies
}) => {
    const toast = Toast.useToast();

    const minMaxDate = useMemo(() => {
        let minDate: Date | undefined = selectedPolicies?.[0]?.policyStartDate;
        let maxDate: Date | undefined = selectedPolicies?.[0]?.policyEndDate;

        if (!selectedPolicies.length) {
            return { minDate, maxDate };
        }

        if (minDate && maxDate) {
            selectedPolicies.forEach((selectedPolicy) => {
                if (selectedPolicy.policyStartDate && minDate) {
                    minDate = isBefore(selectedPolicy?.policyStartDate, minDate)
                        ? selectedPolicy.policyStartDate
                        : minDate;
                }
                if (selectedPolicy.policyEndDate && maxDate) {
                    maxDate = isAfter(selectedPolicy.policyEndDate, maxDate) ? selectedPolicy.policyEndDate : maxDate;
                }
            });
        }
        return { minDate, maxDate };
    }, [selectedPolicies]);

    const validations = () => {
        const today: Date = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        setDateError(false);

        if (memberRegistrationStartDate && memberRegistrationEndDate) {
            // RD1 < RD2
            if (isAfter(memberRegistrationStartDate, memberRegistrationEndDate)) {
                setDateError(true);
                return toast?.error(
                    'Member Registration Start date cannot be greater than Member Registration End date'
                );
            }

            // today - RD1 <= 45
            if (differenceInDays(today, memberRegistrationStartDate) > 45) {
                setDateError(true);
                return toast?.error('Member Registration start date cannot be more than 45 days ago from today');
            }

            // RD2 - RD1 <= 30
            if (differenceInDays(memberRegistrationEndDate, memberRegistrationStartDate) > 30) {
                setDateError(true);
                return toast?.error(
                    'Member Registration end date cannot be more than 30 days later than the start date'
                );
            }
        }

        if (endoCycleStartDate) {
            // ED1 >= today
            console.log(endoCycleStartDate, today);
            if (isBefore(endoCycleStartDate, subDays(today, 1))) {
                setDateError(true);
                return toast?.error('Enrolment Cycle start date should be a future date');
            }
        }

        if (endoCycleStartDate && memberRegistrationStartDate) {
            // ED1 >= RD1
            if (isBefore(endoCycleStartDate, memberRegistrationStartDate)) {
                setDateError(true);
                return toast?.error(
                    'Enrolment Cycle start date should start after member registration start date',
                    'Error occurred'
                );
            }
        }

        if (endoCycleEndDate && endoCycleStartDate) {
            // ED1 < ED2
            if (isAfter(endoCycleStartDate, endoCycleEndDate)) {
                setDateError(true);
                return toast?.error('Enrolment Cycle Start date cannot be greater than Enrolment Cycle End date');
            }

            // ED2 - ED1 <= 30
            if (differenceInDays(endoCycleEndDate, endoCycleStartDate) > 30) {
                setDateError(true);
                return toast?.error('Enrolment Cycle end date cannot be more than 30 days later than the start date');
            }
        }

        if (memberRegistrationEndDate && endoCycleEndDate) {
            // ED2 >= RD2
            if (isBefore(endoCycleEndDate, memberRegistrationEndDate)) {
                setDateError(true);
                return toast?.error(
                    'Member registration end date cannot be before Enrolment cycle end date',
                    'Error occurred'
                );
            }
        }

        // ED1 > RD1
        if (endoCycleStartDate && endoCycleEndDate && memberRegistrationStartDate && memberRegistrationEndDate) {
            if (isBefore(endoCycleStartDate, memberRegistrationStartDate)) {
                setDateError(true);
                return toast?.error(
                    'Enrolment Cycle end date should be greater than Member Registration End date',
                    'Error occurred'
                );
            }
            if (minMaxDate.minDate && minMaxDate.maxDate) {
                const { minDate, maxDate }: { minDate: Date | undefined; maxDate: Date | undefined } = minMaxDate;
                // All 4 between start and enddates of all the policies selected
                if (isBefore(memberRegistrationStartDate, minDate)) {
                    setDateError(true);
                    return toast?.error('Member registration start date cannot be before the policy start date');
                }

                if (isBefore(endoCycleStartDate, minDate)) {
                    setDateError(true);
                    return toast?.error('Enrolment Cycle start date cannot be before the policy start date');
                }

                if (isAfter(memberRegistrationEndDate, maxDate)) {
                    setDateError(true);
                    return toast?.error('Member registration end date cannot exceed the policy end date');
                }

                if (isAfter(endoCycleEndDate, maxDate)) {
                    setDateError(true);
                    return toast?.error('Enrolment Cycle end date cannot exceed the policy end date');
                }
            }
        }
    };

    useEffect(() => {
        validations();
    }, [
        memberRegistrationStartDate,
        memberRegistrationEndDate,
        endoCycleStartDate,
        endoCycleEndDate,
        selectedPolicies
    ]);
    return (
        <StyledStepsContainer>
            <ConfigStepHeader
                icon={EnrolmentCalendarIcon}
                title="Step 2: Setup dates"
                description="Add start and end date for member registration and enrolment cycle"
                isRequired={true}
            />
            <Typography variant="medium" weight="medium">
                Member registration duration
            </Typography>
            <Typography variant="small" weight="medium">
                What should be the duration during which added employees will be made part of this enrolment?
            </Typography>
            <StyledDatePickerContainer>
                <Form.DatePicker
                    minDate={max([subDays(new Date(), 45), minMaxDate.minDate ?? subDays(new Date(), 45)])}
                    value={memberRegistrationStartDate}
                    onChange={setMemberRegistrationStartDate}
                />
                <Form.DatePicker
                    maxDate={addDays(memberRegistrationStartDate ?? new Date(), 30)}
                    value={memberRegistrationEndDate}
                    onChange={setMemberRegistrationEndDate}
                />
            </StyledDatePickerContainer>
            <Typography variant="medium" weight="medium">
                Enrolment cycle duration
            </Typography>
            <Typography variant="small" weight="regular">
                For what duration should the enrolment portal be live?
            </Typography>
            <StyledDatePickerContainer>
                <Form.DatePicker
                    minDate={max([memberRegistrationStartDate ?? new Date(), new Date()])}
                    maxDate={max([addDays(new Date(), 30), addDays(memberRegistrationStartDate || new Date(), 30)])}
                    value={endoCycleStartDate}
                    onChange={setEndoCycleStartDate}
                />
                <Form.DatePicker
                    value={endoCycleEndDate}
                    minDate={min([endoCycleStartDate ?? new Date(), new Date()])}
                    maxDate={addDays(endoCycleStartDate ?? new Date(), 30)}
                    onChange={setEndoCycleEndDate}
                />
            </StyledDatePickerContainer>
        </StyledStepsContainer>
    );
};
export default DateCycleSelectionComponent;
