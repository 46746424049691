import React from 'react';
import useFormInput from '../../hooks/useFormInput';
import MergeMembers from '../../containers/MergeMembers';
import Input from '../Input';
import { StyledContainer, StyledTitle } from './styles';
import { IPageHeader } from './types';
import { isMergeMembersEnabled } from '../../../utils/featureFlags';

const PageHeader: React.FunctionComponent<IPageHeader> = ({
    children,
    name = '',
    placeholder = '',
    list = null,
    setSearchResults,
    setSearchText
}) => {
    const input = useFormInput('');
    const [searchableList, setSearchableList] = React.useState<string[] | null>(null);
    React.useEffect(() => {
        if (setSearchText) {
            setSearchText(input.value);
            handleSearch(input.value);
        }
    }, [input.value]);
    React.useEffect(() => {
        if (list && list.length) setSearchableList(list.map((item) => JSON.stringify(item)));
    }, [list]);
    const handleSearch = (searchValue: string) => {
        if (searchableList && list && list.length && setSearchResults) {
            const result = searchableList.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            setSearchResults(result.map((i) => JSON.parse(i)));
        }
    };
    return (
        <StyledContainer>
            <StyledTitle>{children}</StyledTitle>
            {((list || []).length && (
                <Input
                    name={name}
                    placeholder={placeholder}
                    {...input}
                    onChange={(e) => {
                        input.onChange(e);
                    }}
                />
            )) || <></>}
            {name === 'search-member' && isMergeMembersEnabled ? <MergeMembers />:<></>}
        </StyledContainer>
    );
};

export default PageHeader;
