import React from 'react';
import theme from '../../../theme';
import { AlertIcon, ToastSuccess, WarningIconRed } from '../../../assets/img';

export const BannerToastBackgroundColor: Record<IBannerToastType, string> = {
    SUCCESS: theme.colors.coverUpGreen,
    ERROR: theme.colors.errorFill,
    WARNING: theme.colors.coverYellow
};
export const BannerToastTextColor: Record<IBannerToastType, IBannerToastTextColors> = {
    SUCCESS: 'emerald',
    ERROR: 'error',
    WARNING: 'coverYellow'
};
export const BannerToastIcon: Record<IBannerToastType, string> = {
    SUCCESS: ToastSuccess,
    ERROR: WarningIconRed,
    WARNING: AlertIcon
};

export type IBannerToastType = 'SUCCESS' | 'ERROR' | 'WARNING'
export type IBannerToastTextColors = 'emerald' | 'error' | 'coverYellow'

export interface IBannerToastProps {
    type: IBannerToastType
    children?: React.ReactNode;
}
