import React from 'react';
import { Button, PageHeader, Loader } from '../../../../atoms';
import { StyledInputContainer } from '../GlobalMemberSearch/styles';
import { StyledContainer } from './styles';
import { getSkfEnabled, invokeSKFOnboardingScript } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import ExcelJs from 'exceljs';
import { excelDownloadUsingBlob } from '../../../../../utils/common';
import { SKF_ONBOARDED_MEMBERS_HEADERS } from './constant';

const generateExcelReport = async (fileName: string, data: any) => {
    const workbook = new ExcelJs.Workbook();

    const onboardedSheet = workbook.addWorksheet('Successfully Onboarded');

    if (data.usersOnboarded.length) {
        onboardedSheet.columns = SKF_ONBOARDED_MEMBERS_HEADERS;

        onboardedSheet.addRows(
            data.usersOnboarded.map((user: any, index: number) => ({
                srNo: index + 1,
                ...user
            }))
        );
    }

    const notOnboardedSheet = workbook.addWorksheet('Not Onboarded');

    if (data.usersNotOnboarded.length) {
        notOnboardedSheet.columns = SKF_ONBOARDED_MEMBERS_HEADERS;

        notOnboardedSheet.addRows(
            data.usersNotOnboarded.map((user: any, index: number) => ({
                srNo: index + 1,
                ...user
            }))
        );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const file = new File([buffer], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    excelDownloadUsingBlob(file, fileName);
};

const PreValidationAndHrAddition: React.FunctionComponent = () => {
    const toast = useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);
    const [isSkfEnabled, setIsSkfEnabled] = React.useState(false);

    const getSkfEnabledForUser = async () => {
        try {
            const { data } = await getSkfEnabled();

            if (data.hasOwnProperty('isEnabled') && data.isEnabled === true) {
                setIsSkfEnabled(true);
            } else {
                setIsSkfEnabled(false);
            }
        } catch (err) {
            setIsSkfEnabled(false);
        }
    };

    React.useEffect(() => {
        getSkfEnabledForUser();
    }, []);

    const onSkfOnboardingScriptInvoke = async () => {
        setIsPersistanceLoading(true);

        const result = await invokeSKFOnboardingScript();
        await generateExcelReport(`Onboarding_${new Date().getTime()}.xlsx`, result.data);
        if (result.data.usersOnboarded.length > 0) {
            const employeeIDs: any = [];
            result.data.usersOnboarded.map((user: any) => {
                employeeIDs.push(user.employeeId);
            });
            toast?.displayToast('success', 'Successfully onboarded users', 'Employee Id : ' + employeeIDs.join(', '));
        }
        if (result.data.usersNotOnboarded.length > 0) {
            const employeeIDs: any = [];
            result.data.usersNotOnboarded.map((user: any) => {
                employeeIDs.push(user.employeeId);
            });
            toast?.displayToast(
                'error',
                'User already exist with same employeeId or data format is wrong',
                'Employee Id : ' + employeeIDs.join(',')
            );
        }

        if (result.data.usersOnboarded.length == 0 && result.data.usersNotOnboarded.length == 0) {
            toast?.displayToast('error', 'Please check excel sheet we dont have any data in it', '');
        }
        setIsPersistanceLoading(false);
    };
    const url = process.env.REACT_APP_GS_SKFONBOARDING;
    return (
        <StyledContainer>
            <div>
                <PageHeader>SKF Onboarding script</PageHeader>
            </div>
            <StyledInputContainer>
                {isPersistanceLoading ? (
                    <Loader />
                ) : (
                    <Button type="primary" onClick={onSkfOnboardingScriptInvoke} disabled={!isSkfEnabled}>
                        Invoke SKF onboarding script
                    </Button>
                )}
            </StyledInputContainer>
            <div>
                (<b>NOTE : </b>Above button will invoke SKF onboarding script which will read data from{' '}
                <a href={url} target="_blank" rel="noreferrer">
                    excel
                </a>{' '} and insert into <b>customEnrolmentUser</b> collection)
            </div>
        </StyledContainer>
    );
};

export default PreValidationAndHrAddition;
