import React, { useState } from 'react';
import { Dialog, Modal, Toast } from '@loophealth/loop-ui-web-library';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { IProps } from './types';
import { sendConfirmationEmailAPI } from '../../../adapters';

import { MailEnvelopeGreenIcon } from '../../../assets/img';

const SendConfirmationEmailsModal: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const toast = Toast.useToast();

    const [isSendingEmail, setIsSendingEmail] = useState(false);

    if (!isOpen) return null;

    const enrolmentId = params.get('id') as string;

    const handleSendConfirmationEmail = async () => {
        try {
            setIsSendingEmail(true);
            const { status } = await sendConfirmationEmailAPI(enrolmentId);
            if (status === 204) {
                toast?.success(null, 'Confirmation emails sent!', {
                    expires: 3000,
                    closeOnClick: true,
                    showCloseIcon: true
                });
                setIsOpen(false);
                navigate(-1);
            } else {
                throw new Error();
            }
        } catch (error) {
            toast?.error('Failed to send confirmation emails');
        } finally {
            setIsSendingEmail(false);
        }
        setIsOpen(false);
    };
    return (
        <Modal isVisible={isOpen} setIsVisible={setIsOpen}>
            <Dialog
                variant="vertical"
                icon={MailEnvelopeGreenIcon}
                description="The enrolment period has ended. Sending these emails will confirm all enrolments and
                                    no further edits will be possible."
                title={'Send Enrolment Confirmation Emails?'}
                primaryButtonText="Send Confirmation Emails"
                onPrimaryButtonClicked={handleSendConfirmationEmail}
                onSecondaryButtonClicked={() => setIsOpen(false)}
                secondaryButtonText="Cancel"
                isLoading={isSendingEmail}
            />
        </Modal>
    );
};

export default SendConfirmationEmailsModal;
