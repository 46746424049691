import { convertDateToIST } from '../../../../../utils/dateFormatService';

export const excelDateToJSDate = (serial: number): Date => {
    const utcDays = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400;
    const dateInfo = new Date(utcValue * 1000);

    const fractionalDay = serial - Math.floor(serial) + 0.0000001;

    let totalSeconds = Math.floor(86400 * fractionalDay);

    const seconds = totalSeconds % 60;

    totalSeconds -= seconds;

    const hours = Math.floor(totalSeconds / (60 * 60));
    const minutes = Math.floor(totalSeconds / 60) % 60;

    return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
};
export const formatMemberData = (
    members: Record<string, unknown>[],
    type: 'ADDITIONS' | 'EDITIONS' | 'DELETIONS'
): Record<string, unknown>[] => {
    let totalNetPremium = 0;
    let totalNetPremiumWithGST = 0;
    const fMembers = members.map((member) => {
        const formattedMember = formatMember(
            {
                'Employee ID': member.employeeId,
                'Name': member.name,
                'Relationship': member.relationship,
                'Age': member.age,
                'Old Age': member.oldAge || undefined,
                'Old Sum Insured': member.oldSumInsured,
                'New Age': member.newAge,
                'New Sum Insured': member.newSumInsured,
                'Sum Insured': member.sumInsured,
                'Coverage Start Date': member.policyStartDate
                    ? convertDateToIST(member.policyStartDate as string)
                    : undefined,
                'Coverage End Date': member.policyEndDate
                    ? convertDateToIST(member.policyEndDate as string)
                    : undefined,
                'Premium': member.premium,
                'Pro Rata Days': member.proRataDays,
                'Net Premium': member.netPremium,
                'Net Premium (GST 18%)': member.netPremiumWithGST,
                'Error': member.error
            },
            type
        );
        totalNetPremium += (formattedMember['Net Premium'] as number) || 0;
        totalNetPremiumWithGST += (formattedMember['Net Premium (GST 18%)'] as number) || 0;
        return formattedMember;
    });
    fMembers.push(formatMember({
        'Employee ID': 'TOTAL',
        'Name': '',
        'Relationship': '',
        'Age': '',
        'Old Age': '',
        'Old Sum Insured': '',
        'New Age': '',
        'New Sum Insured': '',
        'Sum Insured': '',
        'Coverage Start Date': '',
        'Coverage End Date': '',
        'Premium': '',
        'Pro Rata Days': '',
        'Net Premium': totalNetPremium,
        'Net Premium (GST 18%)': totalNetPremiumWithGST,
        'Error': ''
    }, type));
    return fMembers;
};

const formatMember = (member: Record<string, unknown>, type: 'ADDITIONS' | 'EDITIONS' | 'DELETIONS') => {
    if (type === 'ADDITIONS') {
        delete member['Old Age'];
        delete member['Old Sum Insured'];
        delete member['New Age'];
        delete member['New Sum Insured'];
        delete member['Coverage End Date'];
    }
    if (type === 'DELETIONS') {
        delete member['Old Age'];
        delete member['Old Sum Insured'];
        delete member['New Age'];
        delete member['New Sum Insured'];
        delete member['Coverage Start Date'];
    }
    if (type === 'EDITIONS') {
        delete member['Age'];
        delete member['Sum Insured'];
        delete member['Coverage End Date'];
    }
    return member;
};
