import { IEndoResultData } from '../../pages/SPAContainer/pages/ValidationSummary/types';
import { ITableEntry } from '../ValidationSummaryTable/types';
import { getOverallCostingForBucket } from '../ValidationSummaryTable/utils';
import { IDefaultFreshdeskMessage, IEndoResult } from './types';

/* eslint-disable max-len */
const DEFAULT_FRESHDESK_MESSAGES: Record<
  IEndoResult,
  IDefaultFreshdeskMessage
> = {
    CORRECT: {
        sufficient: `1. Member data is successfully validated and correct lives are uploaded on Genome.
2. The DNF check is completed.
3. CD balance is sufficient to process this endorsement and data is ready to be sent to the Insurer.
4. Closing this ticket. Please refer to active tickets for more information -`,
        sufficientWithClaims: `1. Member data is successfully validated and correct lives are uploaded on Genome.
2. The DNF check is completed.
3. Refund cost of <ongoingClaimsLives> lives = 0, since ongoing or settled claims are found for the self or dependents
4. CD balance is sufficient to process this endorsement and data is ready to be sent to the Insurer.
5. Closing this ticket. Please refer to active tickets for more information -`,
        insufficient: `1. Member data is successfully validated and correct lives are uploaded on Genome.
2. The DNF check is completed.
3. CD balance is low, please ask HR to send the funds required to complete the endorsement.
4. Closing this ticket. Please refer to active tickets for more information -`,
        insufficientWithClaims: `1. Member data is successfully validated and correct lives are uploaded on Genome.
2. The DNF check is completed.
3. Refund cost of <ongoingClaimsLives> lives = 0, since ongoing or settled claims are found for the self or dependents
4. CD balance is low, please ask HR to send the funds required to complete the endorsement.
5. Closing this ticket. Please refer to active tickets for more information -`
    },
    INCORRECT: {
        sufficient: `1. Member data is validated and we have found some errors, please ask HR to send us the correct data.
2. CD balance is sufficient to process this endorsement (Cost is subject to change based on updated correct data).`,
        insufficient: `1. Member data is validated and we have found some errors, please ask HR to send us the correct data.
2. CD balance is low, please ask HR to send the funds required to complete the endorsement. (Cost is subject to change based on updated correct data).`
    },
    PARTIAL: {
        sufficient: `1. Member data is successfully validated and -
\ta. Correct lives are uploaded on Genome.
\tb. We have found some errors, please ask HR to send us the correct data.
2. CD balance is sufficient to process this endorsement (Cost is subject to change based on updated correct data).
3. For correct data, please refer to active tickets for more information -`,
        sufficientWithClaims: `1. Member data is successfully validated and -
\ta. Correct lives are uploaded on Genome.
\tb. We have found some errors, please ask HR to send us the correct data.
2. Refund cost of <ongoingClaimsLives> lives = 0, since ongoing or settled claims are found for the self or dependents
3. CD balance is sufficient to process this endorsement (Cost is subject to change based on updated correct data).
4. For correct data, please refer to active tickets for more information -`,
        insufficient: `1. Member data is successfully validated and -
\ta. Correct lives are uploaded on Genome.
\tb. We have found some errors, please ask HR to send us the correct data.
2. CD balance is low, please ask HR to send the funds required to complete the endorsement. (Cost is subject to change based on updated correct data).
3. For correct data, please refer to active tickets for more information -`,
        insufficientWithClaims: `1. Member data is successfully validated and -
\ta. Correct lives are uploaded on Genome.
\tb. We have found some errors, please ask HR to send us the correct data.
2. Refund cost of <ongoingClaimsLives> lives = 0, since ongoing or settled claims are found for the self or dependents
3. CD balance is low, please ask HR to send the funds required to complete the endorsement. (Cost is subject to change based on updated correct data).
4. For correct data, please refer to active tickets for more information -`
    }
};

export const getDefaultFdMessage = (
    type: IEndoResult,
    isCdBalanceSufficient: boolean,
    endoResult: IEndoResultData
): string => {
    const { endorsements: endorsementsOverview, correctEntries } = endoResult;
    const totalPendingClaimsUsers =
    type === 'INCORRECT'
        ? 0
        : endorsementsOverview.reduce((prev, endo) => {
            const currentOngoingClaimsUser =
            endo.endorsement.memberData?.filter(
                (member) =>
                    member.operationType === 'DELETE' &&
                member.anyOngoingOrSettledClaim &&
                correctEntries.OFFBOARD.find(
                    (correctOffboardEntry) =>
                        correctOffboardEntry.userId === member.userId
                )
            ) || [];
            return prev + currentOngoingClaimsUser.length;
        }, 0);

    const messageType = isCdBalanceSufficient
        ? totalPendingClaimsUsers
            ? 'sufficientWithClaims'
            : 'sufficient'
        : totalPendingClaimsUsers
            ? 'insufficientWithClaims'
            : 'insufficient';

    let message = DEFAULT_FRESHDESK_MESSAGES[type][messageType] || '';
    message = message.replace(
        '<ongoingClaimsLives>',
        totalPendingClaimsUsers.toString()
    );

    if (type !== 'INCORRECT') {
        let counter = 97;
        message = endorsementsOverview
            .map((endorsementOverview) => ({
                ticket: endorsementOverview.endorsement.supportTicketId,
                policyType: endorsementOverview.endorsement.policyType
            }))
            .reduce((prev, current) => {
                return `${prev}\n\t${String.fromCharCode(counter++)}. ${
                    current.policyType
                } active ticket number ${current.ticket}.`;
            }, message);
    }
    return message;
};

export const checkAllCdSufficient = (
    endoEntries: ITableEntry,
    incorrectEntries: ITableEntry
): boolean => {
    const overallEntries = getOverallCostingForBucket(
        endoEntries,
        incorrectEntries
    );
    return overallEntries.every((entry) => entry.isCdSufficient);
};

export const fileSizeToString = (size: number): string => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let bytes = size;
    let i;

    for (i = 0; bytes >= 1024 && i < 4; i++) {
        bytes /= 1024;
    }

    return bytes.toFixed(2) + ' ' + units[i];
};
