import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux';
import {
    fetchCompletedEndorsementList,
    fetchPendingEndorsementList
} from '../../../../../../redux/slices/EndorsementSlice/thunk';
import { IEndorsementsSelector } from '../../../../../../redux/slices/EndorsementSlice/types';

export const usePendingEndorsements = (isCompletedEndorsements: boolean): IEndorsementsSelector => {
    const dispatch = useDispatch();
    const endorsements = useSelector((state: ReduxState) => state.endorsementSlice.pendingEndorsements);
    useEffect(() => {
        dispatch(fetchPendingEndorsementList());
    }, [isCompletedEndorsements]);

    return endorsements;
};
export const useCompletedEndorsements = (isCompletedEndorsements: boolean): IEndorsementsSelector => {
    const dispatch = useDispatch();
    const endorsements = useSelector((state: ReduxState) => state.endorsementSlice.completedEndorsements);
    useEffect(() => {
        dispatch(fetchCompletedEndorsementList());
    }, [isCompletedEndorsements]);

    return endorsements;
};
