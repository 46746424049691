import React, { useContext, useRef, useState } from 'react';
import { Button, Modal } from '../atoms';
import styled from 'styled-components';

const ModalFooter = styled.div`
    display: flex;
    gap: 30px;
    align-items: stretch;
`;
const ModalContainer = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: column;
    max-width: 100%;
    width: 500px;
`;

type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide
    };
};

type ModalDataType = {
    title: string;
    message?: string | JSX.Element;
    yesLabel: string;
    noLabel: string;
};
const defaultModalData: ModalDataType = {
    title: 'Are you sure?',
    yesLabel: 'Yes',
    noLabel: 'No'
};

type ModalContextType = {
    showConfirmation: (modalData: ModalDataType) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
    children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (props) => {
    const { show, setShow, onHide } = useModalShow();
    const [content, setContent] = useState<ModalDataType | null>(defaultModalData);
    const resolver = useRef<any>();

    const handleShow = (modalData: ModalDataType): Promise<boolean> => {
        setContent(modalData);
        setShow(true);
        return new Promise(function(resolve) {
            resolver.current = resolve;
        });
    };

    const modalContext: ModalContextType = {
        showConfirmation: handleShow
    };

    const handleOk = () => {
        resolver.current && resolver.current(true);
        onHide();
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        onHide();
    };

    return (
        <ConfirmationModalContext.Provider value={modalContext}>
            {props.children}

            {content && (
                <Modal isVisible={show} setIsVisible={setShow} title={content.title}>
                    <ModalContainer>
                        {!!content.message && <label>{content.message}</label>}
                        <ModalFooter>
                            <Button type="errorModalButton" onClick={handleCancel} fullWidth>
                                {content.noLabel}
                            </Button>
                            <Button type="primaryModalButton" onClick={handleOk} fullWidth>
                                {content.yesLabel}
                            </Button>
                        </ModalFooter>
                    </ModalContainer>
                </Modal>
            )}
        </ConfirmationModalContext.Provider>
    );
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useModalShow, useConfirmationModalContext };

export default ConfirmationModalContextProvider;
