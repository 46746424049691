import React from 'react';
import { fetchUsersByCompanyAPI, fetchUsersByPolicyAPI } from '../../../adapters';
import { IUserData } from '../../pages/SPAContainer/pages/MemberDetails/types';
import { HookResponse } from '../types';

interface IUserByPolicy extends IUserData {
    isException: boolean;
    policyId: string;
    policyEndDate: Date;
    policyStartDate: Date;
    policyNickName: string;
    uhid: string;
    sumInsured: string;
    endoCycle: string;
}

const useFetchUsersByPolicy = ({
    companyId,
    policyId
}: {
    companyId: string | null;
    policyId: string | null;
}): HookResponse<IUserByPolicy[]> => {
    const [users, setUsers] = React.useState<IUserByPolicy[]>([]);
    const [isUsersLoading, setIsUsersLoading] = React.useState(true);
    const [usersError, setUsersError] = React.useState('');
    // search for members from DB based on policy selection
    React.useEffect(() => {
        if (!companyId) {
            return;
        }
        if (policyId === null) {
            setIsUsersLoading(true);
            fetchUsersByCompanyAPI(companyId)
                .then((res) => {
                    const users = (res as unknown as Record<string, unknown>[]).map((user) => ({
                        ...user,
                        dob: new Date((user.dob as Record<'_seconds', number>)?._seconds * 1000)
                    })) as IUserByPolicy[];
                    setUsers(users);
                    setIsUsersLoading(false);
                    setUsersError('');
                })
                .catch((e) => {
                    setUsers([]);
                    setIsUsersLoading(false);
                    setUsersError((e as Error).message);
                });
        } else {
            setIsUsersLoading(true);
            fetchUsersByPolicyAPI(companyId, policyId)
                .then((res) => {
                    const users = (res as unknown as Record<string, unknown>[]).map((user) => ({
                        ...user,
                        dob: new Date(user.dob as string),
                        policyEndDate: new Date(user.policyEndDate as string),
                        policyStartDate: new Date(user.policyStartDate as string),
                        active: new Date() <= new Date(user.policyEndDate as string) ? true : false
                    })) as IUserByPolicy[];
                    setUsers(users);
                    setIsUsersLoading(false);
                    setUsersError('');
                })
                .catch((e) => {
                    setUsers([]);
                    setIsUsersLoading(false);
                    setUsersError((e as Error).message);
                });
        }
    }, [companyId, policyId]);
    return {
        data: users,
        error: usersError,
        isLoading: isUsersLoading
    };
};

export default useFetchUsersByPolicy;
