import React from 'react';
import { StyledConfigTitleContainer, StyledStepHeaderContainer } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';

interface IEnrolmentConfigHeaderProps {
    title: string
    description: string
    icon: string
    isRequired: boolean
}

const ConfigStepHeader: React.FC<IEnrolmentConfigHeaderProps> = ({ title, description, icon, isRequired }) => {
    return (
        <StyledStepHeaderContainer>
            <img src={icon}/>
            <StyledConfigTitleContainer>
                <Typography variant='title3' weight='medium'>{title}
                    {isRequired && < Typography variant="title3" color='error'>*</Typography>}
                </Typography>
                <Typography variant='small' color='secondary'>
                    {description}
                </Typography>
            </StyledConfigTitleContainer>
        </StyledStepHeaderContainer>
    );
};
export default ConfigStepHeader;
