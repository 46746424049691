import styled from 'styled-components';

export const StyledBackdateChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.white};
  padding: 48px 56px;
  overflow: scroll;
`;

export const StyledBackdateHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`;

export const StyledBackdateHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const StyledBackdateBodyWrapper = styled.div`
  padding: 0 75px;
  flex: 1;
  width: 100%;
  `;

export const StyledBackdateBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(p) => p.theme.colors.defaultBorder};
  border-bottom: 1px solid ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledBackdateBodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  align-items: center;
  width: 100%;
`;

export const StyledUpdatedDateWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.planeGreenLight};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;
