import React from 'react';
import { IDisabledPolicyProps } from './types';
import {
    StyledEnrolmentPolicyContainer,
    StyledInsurerLogo,
    StyledPolicyContainer,
    StyledPolicyDetailsContainer,
    StyledPolicyInfoContainer
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { StyledColumn } from '../../atoms';

const DisabledPolicyComponent: React.FC<IDisabledPolicyProps> = ({ policyType, insurer, policyId, nickName, icon }) => {
    return (
        <StyledEnrolmentPolicyContainer>
            <StyledPolicyInfoContainer>
                <StyledPolicyContainer>
                    <StyledPolicyDetailsContainer>
                        <StyledInsurerLogo src={icon} />
                        <StyledColumn>
                            <Typography variant="medium" weight="medium">
                                {nickName} ({policyType})
                            </Typography>
                            <Typography variant="extraSmall" color="secondary">
                                {insurer}
                            </Typography>
                        </StyledColumn>
                    </StyledPolicyDetailsContainer>
                </StyledPolicyContainer>
                <Typography variant="small" color="secondary">
                    Policy ref ID:{' '}
                    <Typography variant="small" weight="medium">
                        {policyId}
                    </Typography>
                </Typography>
            </StyledPolicyInfoContainer>
        </StyledEnrolmentPolicyContainer>
    );
};

export default DisabledPolicyComponent;
