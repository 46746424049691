import styled from 'styled-components';
import theme from '../../../../../theme';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 20px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: white;
`;
export const StyledForm = styled.div`
    max-width: 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 24px;
`;

export const StyledSpacing = styled.div`
    padding: 20px;
`;
export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;
export const StyledCompanyDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const StyledImageUploadContainer = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const StyledUploadPillWrapper = styled.div`
    width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const StyledConfimBtnWrapper = styled.div`
    margin: 10px;
    width: 40%;
    cursor: pointer;
`;
export const StyledErrorWrapper = styled.div`
    width: 100%;
`;
export const StyledImage = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    background-color: ${(p) => p.theme.colors.primary};
`;
export const StyledImgRemContainer = styled.div`
    position: absolute;
    bottom: 0;
    margin: 8px;
    border: 1px solid white;
    border-radius: 6px;
`;

export const StyledNameHelperText = styled.div`
    margin-top: 5px;
`;

export const StyledNameError = styled.div`
    margin-bottom: 21px;
`;

export const StyledNicknameGuideline = styled.div`
    padding: 16px;
    border-radius: 12px;
    margin-top: 10px;
    width: 100%;
    background: ${theme.colors.surface};
`;

export const StyledNicknameGuidelineHeader = styled.header`
    border-bottom: ${theme.colors.defaultBorder};
`;

export const StyledNicknameGuidelineHeaderTitle = styled.div`
    display: flex;
    gap: 5px;
`;

export const StyledNicknameGuidelineHeaderDosAndDonts = styled.div`
    gap: 20px;
    display: flex;
    flex-direction: column;
`;

export const StyledNicknameGuidelineDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.defaultBorder};
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const StyledNicknameGuidelineHeaderDosAndDontsItem = styled.div`
    gap: 8px;
    display: flex;
    flex-direction: column;
`;

export const StyledDosAndDontsItems = styled.div`
    gap: 5px;
    display: flex;
    align-items: flex-start;

    & > span > span {
        font-weight: 500;
    }
`;

export const StyledNotice = styled.section`
    width: 100%;
    padding: 16px 32px;
    border-radius: 10px;
    background-color: ${theme.colors.planeGreenLight};
    display: flex;
    align-items: flex-start;
    gap: 12px;
`;

export const StyledAlertData = styled.div`
    gap: 8px;
    display: flex;
    flex-direction: column;
`;

export const StyledSpacer = styled.div`
    height: 24px;
`;
