import styled from 'styled-components';

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContainer = styled.div`
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 440px;
    border-radius: 16px;
    background: white;
    padding: 16px 0;
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    padding: 24px 0 16px 0;
    margin: 0 24px;

    & > div {
        display: flex;
        align-items: center;
        gap: 12px;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 24px 0 24px;
`;

export const ModalContentSecondStep = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 32px;
    padding: 32px 24px 32px 24px;
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const DurationChangeDateContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
`;

export const PreviousDateContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    background: #f8f9fa;
`;

export const NewDateContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    background: ${(p) => p.theme.colors.planeGreenLight};
`;

export const StyledGrayCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px 12px 16px;
    background: ${(p) => p.theme.colors.surface};
    border-radius: 8px;
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
`;

export const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 24px 0 24px;
    margin-bottom: 32px;
`;

export const StyledLabelAndDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px 0 24px;
    border-top: 1px solid #cbd6d3;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const TwoButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BackButtonContainer = styled.div`
    width: fit-content;
    min-width: 60px;
`;

export const ConfirmButtonContainer = styled.div`
    width: fit-content;
    min-width: 120px;
`;
