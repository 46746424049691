import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledTable = styled.table`
  background-color: #fff;
  table-layout: fixed;
  width: 100%;
  min-width: 501px;
  border-collapse: collapse;
`;
export const StyledTh = styled.th`
  border-collapse: collapse;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  background-color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.fontSizes.subHeading};
  color: ${(p) => p.theme.colors.white};
`;
export const StyledTrHead = styled.tr`
  border-bottom: 1px solid rgba(89, 89, 89, 0.2);
`;
export const StyledTrBody = styled.tr<{$hasError: boolean}>`
  border-bottom: 1px solid rgba(89, 89, 89, 0.2);
  background-color: ${(p) => p.$hasError ? p.theme.colors.errorFill : '#FCFCFC'};

  :hover {
    cursor: pointer;
    background: rgba(2, 95, 76, 0.05);
    box-shadow: 0 7px 10px rgba(42, 78, 70, 0.15);
    border-bottom: 1px solid rgba(42, 78, 70, 0.15);
  }
`;
export const StyledTd = styled.td`
  border-collapse: collapse;
  padding: 16px;
  text-align: left;
  color: ${(p) => p.theme.colors.beyondGrey};
  word-break: break-word;

  div, span {
    width: 100%;
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledColHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledHeaderIcon = styled(SVG)`
  cursor: pointer;
  margin-left: 8px;

  & path {
    stroke: ${(p) => p.theme.colors.white};
  }
`;
