import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {
    fetchChangeRequests,
    fetchAllChangeRequests,
    getCompanyWiseChangeRequestStats,
    bulkMoveCRToNextState
} from './thunks';
import { getFireBasePaginatedData } from '../../../utils/constants/FirebasePagination';

const changeRequestSlice = createSlice({
    name: 'changeRequestSlice',
    initialState,
    reducers: {
        setSelectedChangeRequests(state, { payload }) {
            state.selectedChangeRequests = payload;
        },
        updateChangeRequestsAfterMoveCR(state, { payload }) {
            state.fetchChangeRequests.data = payload;
            state.bulkMoveCRToNextState.crList = [];
        },
        resetFetchAllChangeRequestsData(state) {
            state.fetchAllChangeRequests.data = initialState.fetchAllChangeRequests.data;
        },
        resetState(state) {
            state.fetchChangeRequests = initialState.fetchChangeRequests;
            state.fetchAllChangeRequests = initialState.fetchAllChangeRequests;
            state.selectedChangeRequests = initialState.selectedChangeRequests;
            state.getCompanyWiseChangeRequestStats = initialState.getCompanyWiseChangeRequestStats;
            state.bulkMoveCRToNextState = initialState.bulkMoveCRToNextState;
        }
    },
    extraReducers: {
        [fetchChangeRequests.pending as any]: (state) => {
            state.fetchChangeRequests.loading = true;
        },
        [fetchChangeRequests.rejected as any]: (state, {
            error,
            meta: {
                arg: { pageSize, nextPageId: argNextPageId, prevPageId: argPrevPageId }
            }
        }) => {
            const { newMetaData } = getFireBasePaginatedData({
                pageSize,
                argNextPageId,
                argPrevPageId,
                data: [],
                nextPrevSelector: 'id',
                prevPageNo: state.fetchChangeRequests.metaData.pageNo ?? 0
            });
            state.fetchChangeRequests.error = error;
            state.fetchChangeRequests.loading = false;
            state.fetchChangeRequests.metaData = newMetaData;
        },
        [fetchChangeRequests.fulfilled as any]: (state, {
            payload,
            meta: {
                arg: { pageSize, nextPageId: argNextPageId, prevPageId: argPrevPageId }
            }
        }) => {
            const { newData, newMetaData } = getFireBasePaginatedData({
                pageSize,
                argNextPageId,
                argPrevPageId,
                data: payload.data,
                nextPrevSelector: 'id',
                prevPageNo: state.fetchChangeRequests.metaData.pageNo ?? 0
            });
            state.fetchChangeRequests.error = null;
            state.fetchChangeRequests.loading = false;
            state.fetchChangeRequests.data = newData;
            state.fetchChangeRequests.metaData = newMetaData;
        },
        [fetchAllChangeRequests.pending as any]: (state) => {
            state.fetchAllChangeRequests.loading = true;
        },
        [fetchAllChangeRequests.rejected as any]: (state, { error }) => {
            state.fetchAllChangeRequests.error = error;
            state.fetchAllChangeRequests.loading = false;
        },
        [fetchAllChangeRequests.fulfilled as any]: (state, { payload }) => {
            state.fetchAllChangeRequests.error = null;
            state.fetchAllChangeRequests.loading = false;
            state.fetchAllChangeRequests.data = payload.data;
        },
        [getCompanyWiseChangeRequestStats.pending as any]: (state) => {
            state.getCompanyWiseChangeRequestStats.loading = true;
        },
        [getCompanyWiseChangeRequestStats.rejected as any]: (state, { error }) => {
            state.getCompanyWiseChangeRequestStats.error = error;
            state.getCompanyWiseChangeRequestStats.loading = false;
        },
        [getCompanyWiseChangeRequestStats.fulfilled as any]: (state, { payload }) => {
            state.getCompanyWiseChangeRequestStats.error = null;
            state.getCompanyWiseChangeRequestStats.loading = false;
            state.getCompanyWiseChangeRequestStats.data = payload;
        },
        [bulkMoveCRToNextState.pending as any]: (state) => {
            state.bulkMoveCRToNextState.loading = true;
        },
        [bulkMoveCRToNextState.rejected as any]: (state, { error }) => {
            state.bulkMoveCRToNextState.error = error;
            state.bulkMoveCRToNextState.loading = false;
        },
        [bulkMoveCRToNextState.fulfilled as any]: (state, { payload }) => {
            const prevCRState = payload.crList?.[0]?.state;
            let newSelectedChangeRequests = initialState.selectedChangeRequests;
            if (prevCRState && state.selectedChangeRequests.length !== payload.crList.length) {
                newSelectedChangeRequests = state.selectedChangeRequests
                    .filter(({ status }) => status === prevCRState);
            }
            state.bulkMoveCRToNextState.error = null;
            state.bulkMoveCRToNextState.loading = false;
            state.bulkMoveCRToNextState.data = payload.data;
            state.bulkMoveCRToNextState.crList = payload.crList;
            state.selectedChangeRequests = newSelectedChangeRequests;
        },
        'common/cleanup': (state) => {
            state.fetchChangeRequests = initialState.fetchChangeRequests;
            state.fetchAllChangeRequests = initialState.fetchAllChangeRequests;
            state.selectedChangeRequests = initialState.selectedChangeRequests;
            state.getCompanyWiseChangeRequestStats = initialState.getCompanyWiseChangeRequestStats;
            state.bulkMoveCRToNextState = initialState.bulkMoveCRToNextState;
        }
    }
});
export default changeRequestSlice.reducer;
export const {
    setSelectedChangeRequests,
    updateChangeRequestsAfterMoveCR,
    resetFetchAllChangeRequestsData,
    resetState
} = changeRequestSlice.actions;
