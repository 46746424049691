import React from 'react';
import { Dispatch } from 'redux';
import { addCdBalanceAdjustment } from '../../../adapters';
import { fetchCDAccountList } from '../../../redux/slices/CDAccountSlice/thunk';
import { ICDLedger } from '../../../redux/slices/CDAccountSlice/types';
import { uploadFileToStorage } from '../../../utils/common';
import { CDLedgerEntryFormInput, cdLedgerValidations } from './types';

export const validateData = (
    data: Record<string, unknown>,
    setCdLedgerEntryObject: React.Dispatch<React.SetStateAction<CDLedgerEntryFormInput>>
): Record<string, unknown> => {
    Object.keys(data).map((key: string) => {
        const fieldObject = cdLedgerValidations.filter((fieldObj) => fieldObj.name == key)[0];
        let error = fieldObject.isMandatory
            ? (data[key] as { data: any; error: string }).data?.toString().length
                ? ''
                : fieldObject.error
            : '';
        if (!error) {
            error = fieldObject.regex
                ? fieldObject.regex?.test((data[key] as { data: any; error: string }).data)
                    ? ''
                    : fieldObject.error
                : '';
        }
        const value = (data[key] as { data: any; error: string }).data;
        data[key] = {
            data: value,
            error: error
        };
    });
    /** Set the form data along with validation errors */
    setCdLedgerEntryObject({ ...data as unknown as CDLedgerEntryFormInput });
    return data;
};

export const createLegerEntryService = async (
    cdLedgerEntryObject: CDLedgerEntryFormInput,
    cdAccountId: string,
    addedBy: string,
    dispatch: Dispatch<any>,
    setApiError: (error: string) => void,
    setIsVisible: (isModalVisible: boolean) => void
): Promise<void> => {
    /** Create Request body */
    const ledgerEntry = {
        cdAccountId: cdAccountId,
        addedBy: addedBy.trim(),
        message: cdLedgerEntryObject.notes.data.trim(),
        transactionType: cdLedgerEntryObject.transactionType.data,
        endorsementEntityId: cdLedgerEntryObject.endorsementId.data || '',
        amount: cdLedgerEntryObject.premium.data ? parseFloat(cdLedgerEntryObject.premium.data) : 0,
        createdAt: cdLedgerEntryObject.date.data.toString(),
        source: 'manual',
        paymentProof: cdLedgerEntryObject.paymentProof?.data ? [cdLedgerEntryObject.paymentProof?.data] : []
    };
    try {
        await addCdBalanceAdjustment(ledgerEntry as ICDLedger);
        dispatch(fetchCDAccountList());
        setIsVisible(false);
    } catch (e) {
        setApiError((e as { message: string }).message);
        return;
    }
};

export const initialFormState = (type: string): CDLedgerEntryFormInput => {
    const state: CDLedgerEntryFormInput = {
        endorsementId: { data: '', error: '' },
        premium: { data: '', error: '' },
        notes: { data: '', error: '' },
        date: { data: new Date(), error: '' },
        transactionType: { data: type == 'payment' || 'policyBookingAmount' ? 'credit' : '', error: '' }
    };
    if (type === 'payment') {
        state.paymentProof = { data: '', error: '' };
    }
    // if (type === 'policyBookingAmount') {
    //     state
    // }
    return state;
};
export const uploadPaymentProofFile = async (
    files: Record<string, unknown>[],
    setPaymentProof: React.Dispatch<React.SetStateAction<{ file: Blob; url: string } | null>>,
    setCdLedgerEntryObject: React.Dispatch<React.SetStateAction<CDLedgerEntryFormInput>>,
    cdLedgerEntryObject: CDLedgerEntryFormInput
): Promise<void> => {
    const url = URL.createObjectURL(files[0] as unknown as Blob);
    setPaymentProof({
        file: files[0] as unknown as Blob,
        url
    });
    const fileName = await uploadFileToStorage(files[0], 'cdPaymentProof');
    setCdLedgerEntryObject({
        ...cdLedgerEntryObject,
        paymentProof: {
            data: fileName,
            error: ''
        }
    });
};

export const getCDBalanceModalData = (
    transactionType: 'payment' | 'adjustment' | 'policyBookingAmount' | ''
): [string, string] => {
    let title = '';
    let subTitle = '';
    switch (transactionType) {
    case 'payment': {
        title = 'Add deposit entry';
        subTitle = 'A deposit entry should be made when the HR has added money to the CD account';
    } break;
    case 'adjustment': {
        title = 'Add adjustment entry';
        subTitle =
            `An adjustment entry should be created to adjust the CD Balance for inaccuracies, 
             add entries corresponding to non-EB 
             policies linked to this account, add net cost of endo if rater is unsupported, etc`;
    } break;
    case 'policyBookingAmount': {
        title = 'Add policy booking amount entry';
        subTitle =
            'A policy booking amount entry should be made when the booking amount for a newly issued policy is made.';
    } break;
    default:
        break;
    }
    return [title, subTitle];
};
