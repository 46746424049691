import React from 'react';
import {
    StyledAccountNumber,
    StyledCDAccountCellContainer,
    StyledLinkedPoliciesLabel,
    StyledPolicyInfoContainer, StyledPolicyListContainer,
    StyledPolicyTypeLabel
} from './styles';
import RadioButton from '../../atoms/RadioButton';
import { ICDAccountCellProps, IPolicyInfo } from './types';

export const CDAccountCell: React.FunctionComponent<ICDAccountCellProps> = ({
    data,
    accountNumber,
    policyData,
    selectedCDAccount,
    setSelectedCDAccount
}) => {
    const PolicyInfo = (props: IPolicyInfo) => {
        return (
            <StyledPolicyInfoContainer>
                <StyledPolicyTypeLabel>{props.policyType}</StyledPolicyTypeLabel>
                <span>Policy No. {props.policyNumber}</span>
            </StyledPolicyInfoContainer>
        );
    };
    return <StyledCDAccountCellContainer>
        <StyledAccountNumber>
            <RadioButton name={''} isSelected={data.id === selectedCDAccount?.id}
                onClick={() => setSelectedCDAccount(data)}
                value={true}/>
            <span>Account Number: {accountNumber}</span>
        </StyledAccountNumber>
        <StyledLinkedPoliciesLabel>LINKED POLICIES ↓</StyledLinkedPoliciesLabel>
        <StyledPolicyListContainer>
            {policyData.map((data, index) =>
                <PolicyInfo key={index} policyType={data.policyType} policyNumber={data.policyNumber}/>)}
        </StyledPolicyListContainer>
    </StyledCDAccountCellContainer>;
};
