import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    margin-bottom: 16px;
    width: 100%;
    border: 2px solid ${(p) => p.theme.colors.defaultBorder};
    border-radius: 16px;
`;

export const StyledCardChild = styled.div`
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-start; */
    padding: 0px;
    gap: 8px;
`;

export const FieldRowCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

export const AccountDetailsRowCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
`;

export const StyledHeaderActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    padding: 22px 0px 22px 0px;
`;

export const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 48px;
    text-align: center;
`;

export const StyledEditSVG = styled(SVG)`
    & path {
        fill: none;
    }
`;

export const Spacer = styled.div<{ height: number }>`
    height: ${({ height }) => `${height}px`};
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledEmptyStateContainer = styled.div`
    display: table-caption;
    caption-side: bottom;
`;

export const StyledUploadCDButton = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
`;

export const StyledPolicyFilterContainer = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 16px;

    &::before {
        content: 'a';
        color: transparent;
        flex: 1;
        height: 1px;
        border-top: 1px solid ${(p) => p.theme.colors.defaultBorder};
    }

    & > span {
        margin-right: -24px;
    }

    & > div {
        width: 200px;
        max-width: 300px;
        & > :first-child {
            border: none;
            padding: 0px;
            margin: 0;
        }
        & > :nth-child(2) {
            margin-top: 5px;
        }
    }

`;
