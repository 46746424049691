import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const StyledTableContainer = styled.div`
    max-width: 95%;
    padding-bottom: 20px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const StyledDownloadImage = styled(SVG)`
    cursor: pointer;
    border: 1px solid transparent;
    margin-left: 5px;
    border-radius: 8px;
    transform: scale(1.2);
    &:hover {
        border: 1px solid ${(p) => p.theme.colors.primary};
    }
`;
export const StyledLabelContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
`;
export const StyledMargin = styled.span`
    margin-right: 15px;
`;
export const PlusButtonContainer = styled.div``;

export const StyledTitle = styled.h3`
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: ${(p) => p.theme.fontSizes.title};
    margin: 0;
`;
export const StyledTitleCaption = styled.span`
    font-size: ${(p) => p.theme.fontSizes.label};
    color: ${(p) => p.theme.colors.muted};
`;
export const StyledMetaRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
export const StyledSubTitle = styled.div`
    font-size: ${(p) => p.theme.fontSizes.landing};
`;

export const StyledDescription = styled.div`
  margin-top: 20px;
`;
