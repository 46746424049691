/* eslint-disable no-useless-catch */
import { Firestore } from './provider';

const db = Firestore;

export const getRolePermissions = async (roles: string[]): Promise<string[]> => {
    try {
        const permissions: string[] = [];
        const dbRoles: any = [];
        const query = await db.collection('roles').get();
        query.docs.map((doc) => {
            dbRoles.push(doc.data());
        });
        roles.map((role) => {
            const permission = dbRoles.filter((r: any) => r.id === role)[0].permissions;
            permissions.push(...permission);
        });
        return permissions;
    } catch (e) {
        throw e;
    }
};

export const getAppPermissionID = async (value: string): Promise<string> => {
    try {
        const query = await db.collection('IAM').where('value', '==', value).get();
        return query.docs[0].data().id;
    } catch (e) {
        throw e;
    }
};

export const getUserData = async (key: string, value: string): Promise<Record<string, unknown> | null> => {
    const data: any[] = [];
    try {
        // NOTE: Method type like how the user going to login e.g. using phone or email.
        const res = await db.collection('user').where('active', '==', true).where(key, '==', value).get();
        if (res.docs.length) {
            res.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            return (data && data.length && data[0]) || null;
        }
        return null;
    } catch (error) {
        throw error;
    }
};
