import styled from 'styled-components';

export const StyledUploadRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const StyledModalContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
export const StyledModalText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    line-height: 25px;
`
);
export const StyledQuestion = styled.div((p) => `
    font-size: ${p.theme.fontSizes.subHeading};
    margin-top: 25px;
    font-weight: 600;
`);
export const StyledAnswerWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;
export const StyledButton = styled.button<{ $type: 'primary' | 'error' }>`
    cursor: pointer;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 6px;
    width: 100%;
    background-color: ${(p) => (p.$type === 'primary' ? p.theme.colors.primary : p.theme.colors.error)};
    color: ${(p) => p.theme.colors.white};
    font-size ${(p) => p.theme.fontSizes.body};
    font-weight: 600;
    text-transform: uppercase;
`;
export const StyledButtonRow = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledButtonWrapper = styled.div`
    min-width: 45%;
`;
export const StyledUploadPillWrapper = styled.div`
    width: 300px;
`;

export const StyledDownloadPillWrapper = styled.button`
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    padding: 20px;
    margin: 30px 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.primary};
    font-weight: bold;
    &:hover {
        color: ${(p) => p.theme.colors.primary};
        background-color: ${(p) => p.theme.colors.white};
        border: 1px solid ${(p) => p.theme.colors.primary};
    }
`;
