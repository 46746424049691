import styled from 'styled-components';

export const StyledDeleteCdAccountMenu = styled.div`
    position: relative;
    & span:empty {
        display: none;
    }
    & > button img{
        width: auto;
    }
`;

export const StyledMenuContainer = styled.div`
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    width: max-content;
    border-radius: 8px;
    overflow: hidden;
`;

export const StyledMenu = styled.div`
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const StyledMenuItem = styled.div`
    display: flex;
    gap: 8px;
    padding: 12px 20px;
    align-items: center;
    cursor: pointer;

    &:hover{
        background-color: ${(p) => p.theme.colors.errorFill};
    }
`;

export const StyledDelinkModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 380px;
    padding-top: 18px;
`;

export const StyledDelinkModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
`;

export const StyledDelinkModalHeaderText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    text-align: center;
    text-wrap: balance;
`;

export const StyledDelinkLogo = styled.img`
    width: 48px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.errorFill};
    padding: 12px;
`;

export const StyledDelinkModalCTA = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;


