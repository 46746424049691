import React from 'react';
import {
    StyledContainer,
    StyledCDAccountList
} from './styles';
import { CDAccountCell } from './CDAccountCell';
import { ILinkCDAccountCardProps } from './types';
import InsuranceNameCard from '../InsuranceNameCard';
import { ICDAccount } from '../../../redux/slices/CDAccountSlice/types';

export const LinkCDAccountCard: React.FunctionComponent<ILinkCDAccountCardProps> = ({
    insurerLogo,
    insurerName,
    selectedCDAccount,
    setSelectedCDAccount,
    cdAccountsList,
    setLinkButtonEnabled
}) => {
    const handleCDAccountSelection = (data: ICDAccount) => {
        setSelectedCDAccount(data);
        setLinkButtonEnabled(true);
    };

    return <StyledContainer>
        <InsuranceNameCard insurer={insurerName} insurerLogo={insurerLogo}/>
        <StyledCDAccountList>
            {cdAccountsList.map((data, index) =>
                <CDAccountCell key={index}
                    data={data}
                    selectedCDAccount={selectedCDAccount}
                    setSelectedCDAccount={handleCDAccountSelection}
                    accountNumber={data.accountNumber}
                    policyData={data.policyData}/>)}
        </StyledCDAccountList>
    </StyledContainer>;
};
