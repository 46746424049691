import React, { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import useFormInput from '../../../../hooks/useFormInput';
import { getCompanyWiseChangeRequestStats } from '../../../../../redux/slices/ChangeRequestSlice/thunks';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import CompanyTable from './CompanyTable';
import {
    StyledVWContainer,
    StyledRowWrap,
    StyledTitle,
    StyledFilterWrapper,
    StyledMediumWtText,
    StyledInput,
    StyledLoading
} from './styles';
import { TableLoading } from '../../../../atoms';

const ChangeRequests: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyWiseCRSelector = useSelector((state: ReduxState) =>
        state.changeRequestSlice.getCompanyWiseChangeRequestStats);
    const search = useFormInput('');

    useEffect(() => {
        dispatch(getCompanyWiseChangeRequestStats());
    }, []);

    const filteredCompanyList = useMemo(() =>
        !search.debounceValue ? companyWiseCRSelector.data :
            companyWiseCRSelector.data.filter((c) =>
                c?.name?.toLowerCase().includes(search.debounceValue.toLowerCase()))
    , [search.debounceValue, companyWiseCRSelector.data]);

    return (
        <StyledVWContainer>
            <StyledRowWrap>
                <StyledTitle>Endorsement requests</StyledTitle>
                <StyledFilterWrapper>
                    <StyledMediumWtText>Companies</StyledMediumWtText>
                    <StyledInput
                        name='text'
                        placeholder='Search Company name'
                        {...search}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            search.onChange(e);
                        }}
                    />
                </StyledFilterWrapper>
                {companyWiseCRSelector.loading ? (
                    <StyledLoading>
                        <TableLoading backgroundColor="transparent" />
                    </StyledLoading>
                ) : (
                    companyWiseCRSelector.error ? (
                        <StyledLoading>
                            {companyWiseCRSelector.error?.message ?? 'Unable to load'}
                        </StyledLoading>
                    ) : (companyWiseCRSelector.data.length === 0 ? <StyledLoading>No Data</StyledLoading> : (
                        <CompanyTable
                            data={filteredCompanyList}
                            onRowClick={(row) => {
                                const { id, name, status='Closed' } = row;
                                if (status === 'Active') {
                                    navigate(
                                        EXTERNAL_ROUTES.APP + '/' +
                                        INTERNAL_ROUTES.CHANGE_REQUESTS_OF_ONE_COMPANY.route +
                                        '?cid=' + id +
                                        (name ? ('&name=' + name) : '')
                                    );
                                }
                            }}
                        />
                    ))
                )}
            </StyledRowWrap>
        </StyledVWContainer>
    );
};

export default ChangeRequests;
