import React from 'react';
import {
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledNameContainer,
    StyledInsuranceNameAlignment
} from './styles';
import { DefaultInsuranceIcon } from '../../../assets/img';
import { IPolicyIdentity } from './types';
import Label from '../Label';
import { Typography } from '@loophealth/loop-ui-web-library';

const PolicyIdentity: React.FunctionComponent<IPolicyIdentity> = ({
    policyCategory,
    policyImage,
    policyName,
    policyId,
    policyStatus
}) => {
    return (
        <StyledNameContainer>
            <StyledInsuranceImage src={policyImage || DefaultInsuranceIcon} alt={'Insurance Image'} />
            <StyledInsuranceNameContainer>
                <StyledInsuranceNameAlignment>
                    <StyledInsuranceName title={policyName}>
                        <Typography variant='medium' weight='medium'>{policyName}</Typography>
                    </StyledInsuranceName>
                    {policyStatus ? <Label isActive={true}>{policyStatus}</Label> : null}
                </StyledInsuranceNameAlignment>
                <Typography variant='extraSmall' color='secondary'>{policyCategory}</Typography>
                <Typography variant='extraSmall' color='secondary'>{policyId}</Typography>
            </StyledInsuranceNameContainer>
        </StyledNameContainer>
    );
};

export default PolicyIdentity;
