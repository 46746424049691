import { Toast } from '@loophealth/loop-ui-web-library';

type IToastType = 'success' | 'warning' | 'error';

export interface IToastContext {
    displayToast: (type: IToastType, title: string, body: string, expires?: number | null) => string | undefined;
    removeToast: ((id: string) => string);
}
export const useToast = (): IToastContext | null => {
    /* Please don't use this toast, use directly from UI Library */
    const toast = Toast.useToast();
    const displayToast = (type: IToastType, title: string, body: string, expires?: number | null) => {
        switch (type) {
        case 'success': return toast?.success(body, title, expires? { expires: expires }: {});
        case 'warning': return toast?.warning(body, title, expires? { expires: expires }: {});
        case 'error': return toast?.error(body, title, expires? { expires: expires }: {});
        }
    };
    if (toast) {
        return {
            displayToast, removeToast: toast?.remove
        };
    } else return null;
};
