import styled from 'styled-components';

export const StyledSelect = styled.select<{
    $src: string;
    $color: boolean;
    $error: boolean;
}>`
    width: 100%;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${(p) => (p.$error ? p.theme.colors.error : p.$color ? p.theme.colors.muted : p.theme.colors.black)};
    border: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #edeff3 url(${(p) => p.$src}) no-repeat scroll 95% center;
    ${(p) => (p.$error ? `border: 1px solid ${p.theme.colors.error}` : '')}
`;

