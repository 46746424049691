import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithOAuth, clearUserError } from '../../../redux/slices/UserSlice';
import { LoginOAuth } from '../../containers';
import { StyledContent, StyledErrorText, StyledHeading, StyledOAuthContainer, StyledTitle } from './styles';
import { ILoginPage } from './types';
const LoginPage: React.FunctionComponent<ILoginPage> = ({ title, subTitle }) => {
    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state.user.userData);

    const oAuthLogin = () => {
        dispatch(clearUserError());
        dispatch(loginWithOAuth());
    };
    return (
        <StyledContent>
            <StyledTitle>{title}</StyledTitle>
            <StyledHeading>{subTitle}</StyledHeading>
            <StyledOAuthContainer>
                <LoginOAuth signInWithOAuth={oAuthLogin} />
            </StyledOAuthContainer>
            <StyledErrorText>{userData.error && userData.error.message}</StyledErrorText>
        </StyledContent>
    );
};

export default LoginPage;
