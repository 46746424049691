import React, { useEffect, useState } from 'react';
import {
    StyledButtonRow,
    StyledDepositEntryDateRowAlignment,
    StyledModalContainer,
    StyledRowAlignment
} from './styles';
import { FormError, Loader } from '../../atoms';
import { StyledError } from '../../atoms/Forms/styles';
import { CDLedgerEntryFormInput, ICDBalanceEntryProps } from './types';
import { initialFormState, validateData } from './utils';
import { Form, LoopButton } from '@loophealth/loop-ui-web-library';
import { MAX_PREMIUM_AMOUNT } from './index';

const AddPolicyBookingAmount: React.FC<ICDBalanceEntryProps> = ({ isLoading, apiError, onAddEntry }) => {
    const initialState = initialFormState('policyBookingAmount');
    const [cdLedgerEntryObject, setCdLedgerEntryObject] = React.useState<CDLedgerEntryFormInput>(initialState);
    const [isFormDataValid, setFormDataValid] = useState(false);

    useEffect(() => {
        const isAddCDEntryObjValid =
            cdLedgerEntryObject.premium.data &&
            parseFloat(cdLedgerEntryObject.premium.data) > 0 &&
            cdLedgerEntryObject.notes.data.trim();
        setFormDataValid(!!isAddCDEntryObjValid);
    }, [cdLedgerEntryObject]);

    const onDateSelect = async (date: Date) => {
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, date: { data: date, error: '' } });
    };
    const handlePremiumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const premium = e.target.value;
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, premium: { data: premium, error: '' } });
    };
    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const notes = e.target.value;
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, notes: { data: notes, error: '' } });
    };
    const onCreate = async () => {
        const validatedData = validateData(
            { ...cdLedgerEntryObject } as Record<string, unknown>,
            setCdLedgerEntryObject
        );
        /** If even a single field has an error return the function */
        if (
            Object.keys(validatedData).filter((key) => (validatedData[key] as { data: any; error: string })?.error)
                .length
        ) {
            return;
        }
        const bookingAmount = cdLedgerEntryObject.premium.data;
        cdLedgerEntryObject.premium.data = '0';
        cdLedgerEntryObject.notes.data =
            'Policy Booking Amount: ' + bookingAmount + ', NOTES: ' + cdLedgerEntryObject.notes.data;
        onAddEntry(cdLedgerEntryObject);
    };
    return (
        <StyledModalContainer data-testid="add-cd-balance-modal">
            <StyledRowAlignment>
                <Form.FormField id="premium" label="Amount added (₹)" required={true}>
                    <Form.AmountInput
                        max={MAX_PREMIUM_AMOUNT}
                        value={cdLedgerEntryObject.premium.data}
                        placeholder="Enter Here"
                        onChange={handlePremiumChange}
                        error={cdLedgerEntryObject.premium.error}
                    />
                </Form.FormField>
                <Form.FormField id="notes" label="Notes" description="Why is this payment being added" required={true}>
                    <Form.Input
                        value={cdLedgerEntryObject.notes.data}
                        placeholder="Enter Here"
                        onChange={handleNotesChange}
                        error={cdLedgerEntryObject.notes.error}
                    />
                </Form.FormField>
            </StyledRowAlignment>
            <StyledDepositEntryDateRowAlignment>
                <Form.FormField id="entry-date" label="Entry Date" required={true}>
                    <Form.DatePicker value={cdLedgerEntryObject.date.data} onChange={onDateSelect} />
                </Form.FormField>
            </StyledDepositEntryDateRowAlignment>
            {apiError && (
                <StyledError>
                    <FormError>{apiError}</FormError>
                </StyledError>
            )}
            <StyledButtonRow>
                <LoopButton variant={isFormDataValid ? 'filled' : 'disabled'} onClick={onCreate}>
                    {isLoading ? <Loader /> : 'Add entry'}
                </LoopButton>
            </StyledButtonRow>
        </StyledModalContainer>
    );
};
export default AddPolicyBookingAmount;
