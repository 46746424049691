import React from 'react';
import useFormInput from '../../hooks/useFormInput';
import { useToast } from '../../hooks/useToast';
import { Input } from '../../atoms';
import { StyledMargin } from '../CompanyDirectoryRow/styles';
import { MergeButton, StyledContainer } from './styles';

const MergeMembers: React.FunctionComponent = () => {
    const toast = useToast();
    const sourceId = useFormInput('');
    const destinationId = useFormInput('');

    const mergeMembers = () => {
        if (sourceId.value == '' && destinationId.value == '') {
            sourceId.setErrorText(`Source Id can't be empty`);
            destinationId.setErrorText(`Destination Id can't be empty`);
            return;
        }
        if (sourceId.value == '') {
            sourceId.setErrorText(`Source Id can't be empty`);
            return;
        }
        if (destinationId.value == '') {
            destinationId.setErrorText(`Destination Id can't be empty`);
            return;
        }

        // call api and handle api error
        toast?.displayToast('warning', 'In Progress', 'Development is in progress. Can take a while...', 4000);
    };

    return (
        <StyledContainer>
            <StyledMargin>
                <Input
                    name={'sourceId'}
                    placeholder={'Source User Id'}
                    {...sourceId}
                    onChange={(e) => {
                        sourceId.onChange(e);
                    }}
                />
            </StyledMargin>
            <StyledMargin>
                <Input
                    name={'destinationId'}
                    placeholder={'Destination User Id'}
                    {...destinationId}
                    onChange={(e) => {
                        destinationId.onChange(e);
                    }}
                />
            </StyledMargin>
            <MergeButton onClick={mergeMembers}>Merge</MergeButton>
        </StyledContainer>
    );
};

export default MergeMembers;
