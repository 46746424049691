import styled from 'styled-components';

export const StyledTableActions = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-width: 350px;
`;
export const StyledSubTitle = styled.span(
    (p) => `
    color: ${p.theme.colors.muted};
`
);

export const StyledMargin = styled.span`
    margin-right: 15px;
`;

export const StyledStatusLabel = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const StyledCompanyName = styled.div(
    (p) => `
    font-weight: bold;
    font-size: ${p.theme.fontSizes.subHeading};
    text-transform: uppercase;
`
);
