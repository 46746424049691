import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { RaterIcon } from '../../../../assets/img';
import {
    StyledRaterDisplay,
    StyledSVG,
    StyledRaterDetails,
    StyledRaterDisplayWrapper,
    StyledButtonRow
} from '../styles';
import { ISuccessState } from '../types';

const SuccessState: React.FunctionComponent<ISuccessState> = ({ closeModal, handleOnChangeStatus, isLoading }) => {
    return (
        <>
            <StyledRaterDisplayWrapper>
                <Typography variant="extraSmall" color="secondary">
                    Rater uploaded
                </Typography>
                <StyledRaterDisplay $errorState={false}>
                    <StyledSVG src={RaterIcon} height={40} width={40} />
                    <StyledRaterDetails>
                        <Typography variant="small" weight="medium">
                            RATER
                        </Typography>
                        <Typography variant="extraSmall">.xls format</Typography>
                    </StyledRaterDetails>
                </StyledRaterDisplay>
            </StyledRaterDisplayWrapper>
            <StyledButtonRow>
                <LoopButton variant="secondary" onClick={closeModal}>
                    <Typography variant="small" color="emerald" weight="medium">
                        No
                    </Typography>
                </LoopButton>
                <LoopButton variant="filled" onClick={handleOnChangeStatus} isLoading={isLoading}>
                    <Typography variant="small" color="emerald" weight="medium">
                        Yes!
                    </Typography>
                </LoopButton>
            </StyledButtonRow>
        </>
    );
};

export default SuccessState;
