import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  gap: 28px;
`;

export const StyledCDAccountInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #F0F3F3;
  border-radius: 12px;
  padding: 18px 22px;
  gap: 24px;
`;
export const StyledCompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: 8px;
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.beyondGrey};

  span {
    opacity: 0.7;
    font-size: 10px;
  }
`;
