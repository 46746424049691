import styled from 'styled-components';

export const StyledToastWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;
export const SuccessToastText = styled.div<{ fullWidth?: boolean }>`
    width: ${(p) => p.fullWidth ? '100%' : '80%'};
`;
export const SuccessToastButton = styled.div`
    width: 20%;
`;
