import React from 'react';
import {
    StyledPreviewImage,
    StyledImgRemContainer,
    StyledPreviewContainer,
    StyledPreviewImageName,
    StyledPreviewPDF
} from './styles';
import { LabelButton } from '../index';
import theme from '../../../theme';
import { IUploadPreview } from './types';


const UploadPreview: React.FunctionComponent<IUploadPreview> = ({
    previewUrl,
    previewName,
    onReUpload

}) => {
    const [isPDFPreview, setIsPDFPreview] = React.useState(false);
    React.useEffect(() => {
        const fileParams = previewName.split('.');
        setIsPDFPreview(fileParams[fileParams.length - 1] === 'pdf');
    }, [previewName]);
    const preview = () => {
        if (isPDFPreview) {
            return <StyledPreviewPDF src={previewUrl} />;
        }
        return <StyledPreviewImage src={previewUrl} />;
    };
    return (
        <StyledPreviewContainer>
            {preview()}
            <StyledPreviewImageName>
                {previewName}
            </StyledPreviewImageName>
            <StyledImgRemContainer>
                <LabelButton onClick={onReUpload} color={theme.colors.primary}>
                    Re-upload
                </LabelButton>
            </StyledImgRemContainer>
        </StyledPreviewContainer>
    );
};
export default UploadPreview;
