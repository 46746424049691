import styled from 'styled-components';
import theme from '../../../theme';

export const StyledContainer = styled.div`
    min-width: 200px;
    height: 50px;
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    overflow: hidden;
`;

export const MergeButton = styled.button`
    height: 44px;
    background-color: ${theme.colors.primary};
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
`;
