const isDummyRow = (member:Record<string, unknown>) => {
    return (member?.['Old UserID'] === 'Any string allowed' &&
        member?.['New UserID'] === 'Any string allowed');
};
const transformMemberToBe = (member: Record<string, unknown>) => {
    return {
        oldUserId: member?.['Old UserID'],
        newUserId: member?.['New UserID']
    };
};
export const getMemberListFromJson = (json: Record<string, unknown>[]):Record<string, unknown>[] => {
    if (isDummyRow(json[0])) {
        return json.slice(1).map(transformMemberToBe);
    }
    return json.map(transformMemberToBe);
};

