import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;
export const StyledPanelContainer = styled.div`
    height: 100%;
    max-width: 592px;
    width: 592px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledContentContainer = styled.div(
    (p) => `
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${p.theme.colors.white};
`
);
