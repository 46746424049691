import React, { useEffect, useMemo, useState } from 'react';
import {
    StyledCheckboxWrapper,
    StyledEndoMemberTable,
    StyledMidtermAlertWrapper,
    StyledMoveToNextEndoContainer,
    StyledSearchWrapper
} from './styles';
import MemberTable from './MemberTable';
import { MemberDataEntity, MemberDataUIEntity } from '../../../../../redux/slices/EndorsementSlice/types';
import { StyledInput } from '../Endorsements/styles';
import useFormInput from '../../../../hooks/useFormInput';
import IndeterminateCheckbox from '../ChangeRequestsOfOneCompany/IndeterminateCheckbox';
import { LoopButton } from '../../../../atoms';
import { MoveEndoLivesModal } from '../../../../containers/MoveEndoLivesModal';
import { useToast } from '../../../../hooks/useToast';
import { fetchReassignMembersAllowedStatesAPI } from '../../../../../adapters';
import { parseResponse } from '../../../../../utils/common';
import { IMemberTableContainer } from './types';
import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import { WarningIconRed } from '../../../../../assets/img';
import MidtermVerifyModal from './MidtermVerifyModal';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';
import { extractPathName, trackClickEvent } from '../../../../../utils/segment/utils';
import EndorsementDocumentApprovalModal from '../../../../containers/EndorsementDocumentApprovalModal';

const MemberTableContainer: React.FunctionComponent<IMemberTableContainer> = ({
    memberData,
    status,
    endorsementId,
    policyId,
    updateEndorsementMembers,
    midTermNonVerifiedEntries,
    kycNonVerifiedEntries
}) => {
    const trackClick = useSegment('click');
    const location = useLocation();
    const search = useFormInput('');
    const toast = useToast();
    const [isMoveEndoLivesModalVisible, setMoveEndoLivesModalVisible] = useState(false);
    const [allowedStates, setAllowedStates] = React.useState<string[]>([]);
    const [selectedLives, onRowSelectionChange] = React.useState<string[]>([]);
    const [isMoveToNextEndoSelected, selectMoveToNextEndo] = React.useState<boolean>(false);
    const [selectedMidtermMember, setSelectedMidtermMember] = React.useState<MemberDataEntity>();
    const [selectedKYCMember, setSelectedKYCMember] = React.useState<MemberDataEntity>();
    const isReassignAllowed = isMoveToNextEndoSelected && allowedStates.includes(status);

    const fetchAllowedEndoStates = async () => {
        const [error, allowedStatesResponse] = await parseResponse(fetchReassignMembersAllowedStatesAPI());
        if (error) {
            toast?.displayToast('error', 'Unable to fetch Reassign allowed endo states', error.toString(), 5000);
            return;
        }
        setAllowedStates(allowedStatesResponse?.data);
    };

    useEffect(() => {
        fetchAllowedEndoStates();
    }, []);

    const filteredMemberList = useMemo(() => {
        return search.debounceValue && memberData
            ? memberData.filter((c: MemberDataEntity) => {
                const memberName = c.firstName ? `${c.firstName} ${c.lastName}` : c.lastName || '-';
                return memberName?.toLowerCase().includes(search.debounceValue.toLowerCase());
            })
            : memberData || [];
    }, [search.debounceValue, memberData]);

    const onMoveToNextEndorsement = () => {
        setMoveEndoLivesModalVisible(true);
    };
    const onMoveLivesSelected = () => {
        selectMoveToNextEndo((isMoveToNextEndoSelected) => !isMoveToNextEndoSelected);
    };

    const handleOnRowClick = (selectedMember: MemberDataUIEntity) => {
        if (isReassignAllowed) return;
        if (!selectedMember.isMidtermNonVerified && !selectedMember.isKYCApplicable) return;
        const member = memberData?.find((entry) => entry.userId === selectedMember.userId);
        if (selectedMember.isMidtermNonVerified) {
            setSelectedMidtermMember(member);
            if (member?.relationship.toLowerCase() === 'spouse') {
                trackClick(trackClickEvent('Verify_Live_gen', location?.pathname, extractPathName(location?.pathname)));
            } else if (member?.relationship.toLowerCase() === 'child') {
                trackClick(
                    trackClickEvent('Verify_Child_gen', location?.pathname, extractPathName(location?.pathname))
                );
            }
        } else if (selectedMember.isKYCApplicable) {
            setSelectedKYCMember(member);
        }
    };

    const updateMemberDetails = (member: MemberDataEntity, toDelete?: boolean) => {
        if (memberData) {
            const updatedMembersData = memberData
                .map((memberData) => (memberData.userId === member.userId ? (toDelete ? null : member) : memberData))
                .filter(Boolean) as MemberDataEntity[];
            updateEndorsementMembers(updatedMembersData);
        }
    };


    return (
        <StyledEndoMemberTable isReassigningState={!!selectedLives.length}>
            <StyledSearchWrapper>
                <StyledInput
                    name="name"
                    placeholder="Search by Member name"
                    {...search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        search.onChange(e);
                    }}
                />
                {allowedStates.includes(status) && (
                    <StyledCheckboxWrapper>
                        <IndeterminateCheckbox onChange={onMoveLivesSelected} checked={isMoveToNextEndoSelected} />
                        Move lives to next endorsement
                    </StyledCheckboxWrapper>
                )}
            </StyledSearchWrapper>
            {!!midTermNonVerifiedEntries && (
                <StyledMidtermAlertWrapper>
                    <Alerts.StatusAlert
                        variant="over"
                        iconSrc={WarningIconRed}
                        text={
                            <Typography variant="small" weight="semiBold" color="error">
                                Verification of {midTermNonVerifiedEntries} mid-term additions is required to move
                                forward with the endorsement
                            </Typography>
                        }
                    />
                </StyledMidtermAlertWrapper>
            )}
            {!!kycNonVerifiedEntries && (
                <StyledMidtermAlertWrapper>
                    <Alerts.StatusAlert
                        variant="over"
                        iconSrc={WarningIconRed}
                        text={
                            <Typography variant="small" weight="semiBold" color="error">
                                Verification of lives is required to move forward with the endorsement.
                            </Typography>
                        }
                    />
                </StyledMidtermAlertWrapper>
            )}
            <MemberTable
                status={status}
                data={filteredMemberList}
                onRowSelectionChange={onRowSelectionChange}
                isReassignAllowed={isReassignAllowed}
                handleOnRowClick={handleOnRowClick}
            />
            {selectedLives.length && isReassignAllowed ? (
                <StyledMoveToNextEndoContainer>
                    <span>
                        {selectedLives.length} {selectedLives.length > 1 ? 'lives' : 'live'} selected
                    </span>
                    <LoopButton type="filled" onClick={onMoveToNextEndorsement} width="auto">
                        Move to next endorsement
                    </LoopButton>
                </StyledMoveToNextEndoContainer>
            ) : null}
            <MoveEndoLivesModal
                setIsVisible={setMoveEndoLivesModalVisible}
                isVisible={isMoveEndoLivesModalVisible}
                selectedLives={selectedLives}
                endorsementId={endorsementId}
            />
            {selectedMidtermMember && (
                <MidtermVerifyModal
                    policyId={policyId}
                    endorsementId={endorsementId}
                    midtermMember={selectedMidtermMember}
                    setMidtermMember={setSelectedMidtermMember}
                    updateMemberDetails={updateMemberDetails}
                />
            )}
            {selectedKYCMember && (
                <EndorsementDocumentApprovalModal
                    policyId={policyId}
                    endorsementId={endorsementId}
                    kycMember={selectedKYCMember}
                    setKYCMember={setSelectedKYCMember}
                    updateMemberDetails={updateMemberDetails}
                />
            )}
        </StyledEndoMemberTable>
    );
};

export default MemberTableContainer;
