import styled from 'styled-components';

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const StyledTitle = styled.h2(
    (p) => `
    font-size: ${p.theme.fontSizes.landing};
    color: ${p.theme.colors.black};
    margin: 0;
    margin-bottom: 15px;
`
);
export const StyledHeading = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.muted};
    margin: 0px;
    font-weight: 300;
`
);
export const StyledOAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const StyledErrorText = styled.span`
    color: ${(p) => p.theme.colors.error};
    margin: 20px 0px;
    margin-bottom: 0;
    font-size: ${(p) => p.theme.fontSizes.label};
    width: 500px;
    text-align: center;
`;
