/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
// import Button from '@atlaskit/button';
import CustomDateRange from './CustomDateRange';
// import { colors } from 'react-select/lib/theme';
import SVG from 'react-inlinesvg';
import { ChevronDownIconNew, CloseIcon } from '../../../assets/img';

const Button = styled.button`
    align-items: center;
    padding: 0;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    min-width: 100px;
    color: #808080;
    font-family: 'Work Sans', 'Inter', Sans-Serif;

    background-color: ${(p) => p.theme.colors.white};
    border: 1px solid #59595926;
    border-radius: 4px;
    box-shadow: none;
    &> span {
        margin: 0;
    }
    /*
    padding: 16px 50px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.coverUpGreen};
    color: ${(p) => p.theme.colors.primary};
    */
`;
const StyledIconAfter = styled.div`
    display: flex;
    padding: 6px;
    color: hsl(0, 0%, 80%);
    transition: color 150ms;
    box-sizing: border-box;
`;

const StyledPlaceholderWrapper = styled.div`
    align-items: center;
    display: grid;
    flex: 1;
    flex-wrap: wrap;
    padding: 2px 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
`;

const StyledPlaceholder = styled.div`
    color: hsl(0, 0%, 50%);
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;

    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    flex: 1 1 auto;
    display: inline-grid;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
`;

export const StyledSVG = styled(SVG)`
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    & path {
        pointer-events: all;
    }
`;

// type State = { isOpen: boolean, value: Object };
type DateRange = { startDate?: Date; endDate?: Date|null; }
export interface IPopoutDatePicker {
    dateCreated: DateRange;
    setDateCreated(value: DateRange):void;
}
const PopoutDatePicker: React.FC<IPopoutDatePicker> = ({ dateCreated, setDateCreated }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen((o) => !o);
    const onSelectChange = (value: any) => {
        toggleOpen();
        setDateCreated(value);
    };
    const isRanged = !!dateCreated.startDate && !!dateCreated.endDate;
    return (
        <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            dateCreated={dateCreated}
            onSelectChange={onSelectChange}
            target={
                <Button
                    // iconAfter={<ChevronDown />}
                    onClick={toggleOpen}
                    // isSelected={isOpen}
                >
                    <StyledPlaceholderWrapper>
                        <StyledPlaceholder>
                            {isRanged ? `Ranged` : 'All'}
                        </StyledPlaceholder>
                    </StyledPlaceholderWrapper>
                    {isRanged ? (
                        <StyledIconAfter onClick={()=>{
                            onSelectChange({
                                startDate: new Date(),
                                endDate: null
                            });
                        }}><StyledSVG src={CloseIcon} /></StyledIconAfter>
                    ) : (<StyledIconAfter>
                        <StyledSVG src={ChevronDownIconNew} />
                    </StyledIconAfter>)}
                </Button>
            }
        />
    );
};

const Menu = (
    props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>
) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 8,
                position: 'absolute',
                zIndex: 2
            }}
            {...props}
        />
    );
};
const Blanket = (
    props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>
) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1
        }}
        {...props}
    />
);
interface IDropdown {
    isOpen: boolean;
    target: React.ReactNode;
    onClose(e: React.MouseEvent): void;
    children?: React.ReactNode;
    dateCreated: DateRange;
    onSelectChange(value: any): void;
}
const Dropdown: React.FC<IDropdown> = ({ isOpen, target, onClose, dateCreated, onSelectChange }) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? (
            <Menu>
                <CustomDateRange
                    startDate={dateCreated.startDate}
                    endDate={dateCreated.endDate}
                    onSelectChange={onSelectChange}
                />
            </Menu>
        ) : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
// const Svg = (p: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
//     <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
// );
// const DropdownIndicator = () => (
//     <div
//         style={{
//             // color: colors.neutral20,
//             height: 24,
//             width: 32
//         }}
//     >
//         <Svg>
//             <path
//                 d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
//                 fill="currentColor"
//                 fillRule="evenodd"
//             />
//         </Svg>
//     </div>
// );

export default PopoutDatePicker;
