/* eslint-disable max-len */
import styled from 'styled-components';
import { IChip } from './types';

export const StyledChip = styled.span<{
    variant: IChip['variant'];
    backgroundColor: IChip['backgroundColor'];
    borderRadius: IChip['borderRadius'];
    color: IChip['color'];
}>(
    (p) => `
    ${p.variant === 'filled' ? `
        background-color: ${p.backgroundColor ? p.backgroundColor : 'rgba(0, 0, 0, 0.08)'};
        border: 0;
    ` : ''}
    ${p.variant === 'outlined' ? `
        border: 1px solid ${p.color ? p.color : ''};
    ` : ''}
    ${p.backgroundColor ? `background-color: ${p.backgroundColor};` : ''}
    ${p.borderRadius ? `border-radius: ${
        typeof p.borderRadius === 'number'? `${p.borderRadius}px` : p.borderRadius};`
        : ''}
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0;
    text-decoration: none;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;
`
);

export const StyledLabel = styled.span<{
    color: IChip['color'];
}>(
    (p) => `
    ${p.color ? `color: ${p.color};` : ''}
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
`
);
