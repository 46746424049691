import { StyledChipContainer, StyledIcon, StyledTextContainer } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IChipProps } from './types';
import React from 'react';
import { CloseIcon } from '../../../../../assets/img';


const EmailChip: React.FC<IChipProps> = ({ email, companyName, handleRemove }) => {
    if (!email) {
        return null;
    }

    return (
        <StyledChipContainer>
            <StyledTextContainer>
                <Typography variant='small' weight='regular'>{ email }</Typography> |
                <Typography variant='small' weight='regular'>{ companyName }</Typography>
            </StyledTextContainer>
            <StyledIcon src={CloseIcon} onClick={() => handleRemove(email)} alt='close' />
        </StyledChipContainer>
    );
};

export default EmailChip;
