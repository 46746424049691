import { LoopBadge, LoopButton, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React, { useState } from 'react';
import {
    StyledCustomTooltipText,
    StyledEditIcon,
    StyledEnrolmentCard,
    StyledEnrolmentLeftSection,
    StyledInfoIcon,
    StyledInfoLabelWrapper,
    StyledInfoSection,
    StyledLoopButtonContainer,
    StyledRightContainer
} from './styles';
import { IEnrolmentReportCard } from './types';

import format from 'date-fns/format';
import { EditIcon, InfoIconGray, LoopEmeraldDownloadIcon } from '../../../../assets/img';
import { badgeType, getPolicyNicknames, getPolicyTypes } from './utils';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../utils/constants/Routes';

const EnrolmentInfoCard: React.FunctionComponent<IEnrolmentReportCard> = ({
    endoCycleStatus,
    startDate,
    endDate,
    onSubmit,
    policyIdAndTypeMap,
    policyIdAndNicknameMap,
    policyIds,
    id
}) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const policyTypes: (string | undefined)[] = getPolicyTypes(policyIdAndTypeMap, policyIds);
    const policyNicknames: (string | undefined)[] = getPolicyNicknames(policyIds, policyIdAndNicknameMap);

    const handleNavigateToEditEnrolment = () => {
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.EDIT_ENROLMENT_CONFIG.route}?id=${id}`);
    };

    return (
        <StyledEnrolmentCard onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <StyledEnrolmentLeftSection>
                <LoopBadge badgeType={badgeType(endoCycleStatus)} variant="overflow" text={endoCycleStatus} />
                <StyledInfoSection>
                    <Typography variant="extraSmall" color="secondary" weight="semiBold">
                        Batch duration
                    </Typography>
                    <Typography variant="small" weight="semiBold">
                        {format(startDate, 'do LLL')} - {format(endDate, 'do LLL')}
                    </Typography>
                </StyledInfoSection>
                <StyledInfoSection>
                    <StyledInfoLabelWrapper $gap="2px">
                        <Typography variant="extraSmall" color="secondary" weight="semiBold">
                            Policies covered
                        </Typography>
                        <Tooltip
                            type="notice"
                            beak="top-center"
                            title="Policy Nicknames"
                            text={
                                <StyledCustomTooltipText>
                                    {policyNicknames?.map((policyNickname) =>
                                        policyNickname ? (
                                            <Typography key={policyNickname} variant="extraSmall" color="tertiary">
                                                {policyNickname}
                                            </Typography>
                                        ) : null
                                    )}
                                </StyledCustomTooltipText>
                            }
                        >
                            <StyledInfoIcon src={InfoIconGray} />
                        </Tooltip>
                    </StyledInfoLabelWrapper>

                    <Typography variant="small" weight="semiBold">
                        {policyTypes?.filter(Boolean).join(', ')}
                    </Typography>
                </StyledInfoSection>
            </StyledEnrolmentLeftSection>
            <StyledRightContainer>
                <StyledLoopButtonContainer>
                    <LoopButton
                        onClick={onSubmit}
                        borderColor="#025F4C"
                        textColor="emerald"
                        variant="outline"
                        iconSrc={LoopEmeraldDownloadIcon}
                        iconOrder="right"
                    >
                        Download Report
                    </LoopButton>
                </StyledLoopButtonContainer>
                <StyledEditIcon
                    $hover={hover && endoCycleStatus !== 'Ended'}
                    src={EditIcon}
                    onClick={handleNavigateToEditEnrolment}
                />
            </StyledRightContainer>
        </StyledEnrolmentCard>
    );
};

export default EnrolmentInfoCard;
