export const insurerFormatList = [
    'Care Health Insurance',
    'ICICI Lombard',
    'ICICI Prudential Life Insurance',
    'Go Digit',
    'Magma HDI General Insurance Co Ltd',
    'The Oriental Insurance Co Ltd',
    'The New India Assurance Co Ltd'
];

export const showInsurerFormatListForProvidedPolicy: Record<string, string[]> =
  {
      'Care Health Insurance': ['GMC', 'GPA'],
      'ICICI Lombard': ['GMC', 'GPA'],
      'ICICI Prudential Life Insurance': ['GTL'],
      'Go Digit': ['GMC', 'GPA', 'GTL'],
      'Magma HDI General Insurance Co Ltd': ['GMC', 'GPA'],
      'The Oriental Insurance Co Ltd': ['GMC', 'GPA'],
      'The New India Assurance Co Ltd': ['GMC', 'GPA']
  };

export const DEFAULT_FRESHDESK_ENDO_MESSAGE = {
    SUFFICIENT: `1. CD balance is sufficient to process this endorsement and data is ready to be sent to the Insurer.`,
    INSUFFICIENT: `1. CD balance is low, please ask HR to send the funds required to complete the endorsement.`
};
