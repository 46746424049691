import styled, { css, keyframes } from 'styled-components';
import { StylesConfig } from 'react-select';
import theme from '../../../../../theme';
import SVG from 'react-inlinesvg';
import { StyledRow } from '../../../../atoms';

export const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 48px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
`;

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 50px 0 24px;
`;
export const StyledTitleWrapper = styled.div`
    font-size: ${(p) => p.theme.fontSizes.title};
    color: ${(p) => p.theme.colors.black};
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-family: 'Work Sans', sans-serif;

    img {
        cursor: pointer;
    }

    h4 {
        margin: 0;
    }
`;
export const StyledFilterWrapper = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;
`;
export const StyledMultiSelectWrapper = styled.span`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-size: ${(p) => p.theme.fontSizes.body};
`;
export const StyledFilterLabel = styled.div`
    margin-block-end: 10px;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.beyondGrey};
`;
export const singleSelectStyles: StylesConfig = {
    control: (styles) => ({ ...styles, minWidth: '250px', backgroundColor: theme.colors.white, textAlign: 'start' })
};
export const StyledEndorsementStatusWrapper = styled.div`
    grid-area: summary;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
`;
export const StyledEndorsementStatus = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
`;
export const StyledStatus = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    font-size: ${(p) => p.theme.fontSizes.subHeading};
    font-family: 'Work Sans', sans-serif;

    span {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        color: ${(p) => p.theme.colors.primary};
    }
`;
export const StyledNextStatus = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    span {
        white-space: pre;
    }
`;
export const StyledSeparator = styled.div`
    display: flex;
    border: 0.5px solid ${(p) => p.theme.colors.beyondGrey};
    height: 48px;
    width: 0;
`;
export const StyledExceptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 20px 24px;
    border-top: 1px solid ${(p) => p.theme.colors.defaultBorder};
    border-collapse: collapse;
    flex-wrap: wrap;
`;
export const StyledExceptionChip = styled.div`
    background-color: ${(p) => p.theme.colors.errorFill};
    color: ${(p) => p.theme.colors.errorText};
    padding: 6px 14px;
    border-radius: 50px;
    font-size: ${(p) => p.theme.fontSizes.body};
`;
export const EndorsementDetailsWrapper = styled.div`
    display: grid;
    grid-template-areas:
        'refresh-members-card refresh-members-card refresh-members-card'
        'summary summary summary'
        'endorsement cdBalance notes'
        'endoExpandableData endoExpandableData endoExpandableData'
        'endoMemberTable endoMemberTable endoMemberTable';
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin: 24px 0;
`;
export const StyledEndorsementOverviewContainer = styled.div`
    grid-area: endorsement;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 30px 20px 0;
`;
export const StyledCDBalanceOverviewContainer = styled(StyledEndorsementOverviewContainer)`
    grid-area: cdBalance;
    padding-bottom: 20px;
`;
export const StyledNotesOverviewContainer = styled(StyledEndorsementOverviewContainer)`
    grid-area: notes;
    padding: 30px 0 0;
`;
export const StyledEndorsementHeader = styled(StyledRow)`
    align-items: center;
    justify-content: flex-start;
`;
export const StyledEndorsementTableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    letter-spacing: 0.2px;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.black};
    text-align: start;

    span {
        text-align: end;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    a {
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        color: ${(p) => p.theme.colors.primary};
    }
`;

export const StyledCDBalanceTableRow = styled(StyledRow)`
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 10px;
    text-align: start;
    gap: 24px;
`;
export const StyledCDBalanceCorrectionRow = styled(StyledCDBalanceTableRow)`
    margin-bottom: 6px;
    margin-top: 6px;
`;
export const StyledCDBalanceHeader = styled(StyledRow)`
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
export const StyledCDBalanceAmountWrapper = styled(StyledRow)`
    gap: 12px;
    align-items: center;
`;
export const StyledNotesListContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 200px;
    padding: 0 20px;
    text-align: start;
    font-size: ${(p) => p.theme.fontSizes.body};
`;
export const StyledNotesItem = styled.span`
    display: flex;
    flex-direction: column;
    text-align: start;
    background: #f6f6f6;
    padding: 12px 14px;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.beyondGrey};
    font-family: 'Work Sans', sans-serif;
`;
export const StyledNotesHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
`;
export const StyledNotesEdit = styled.div`
    display: flex;
    flex-direction: row;
    letter-spacing: 0.2px;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    gap: 2px;
    color: ${(p) => p.theme.colors.primary};
`;
export const StyledEndorsementExpandableContainer = styled.div`
    grid-area: endoExpandableData;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 20px 30px;
`;
export const StyledEndoMemberTable = styled.div<{isReassigningState:boolean}>`
    grid-area: endoMemberTable;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    ${(p) => p.isReassigningState && 'padding-bottom: 30px;'}
`;

export const StyledLivesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const StyledEndorsementLives = styled.span`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.title};
    font-weight: 600;
    margin-right: 30px;
    font-family: 'Work Sans', sans-serif;
`;
export const StyledLiveBalance = styled.div`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-family: 'Work Sans', sans-serif;

    span {
        font-size: 20px;
        font-weight: 600;
        margin: 0 8px;
    }
`;
export const StyledLivesSeparator = styled(StyledSeparator)`
    height: 20px;
    margin: 0 24px;
`;
export const StyledDownloadDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`;
export const StyledDownloadDataButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 13px;
    padding: 14px 27px;
    letter-spacing: 0.2px;
    gap: 8px;
    cursor: pointer;
    font-size: ${(p) => p.theme.fontSizes.subHeading};
    color: ${(p) => p.theme.colors.primary};
    border: 1.5px solid ${(p) => p.theme.colors.primary};
    background-color: ${(p) => p.theme.colors.white};
    font-family: 'Work Sans', sans-serif;
`;
export const StyledSVG = styled.img<{ isExpanded: boolean }>`
    cursor: pointer;
    transform: ${(p) => (p.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
export const StyledDownloadSVG = styled(SVG)`
    cursor: pointer;

    & path {
        stroke: ${(p) => p.theme.colors.primary};
    }
`;

export const StyledSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding: 16px;
    width: 100%;
`;

export const StyledCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;
export const StyledMoveToNextEndoContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  padding: 18px 48px;
  width: 100%;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.06);
  background: ${(p) => p.theme.colors.white};
`;
export const StyledEditSVG = styled(SVG)`
  cursor: pointer;
  margin-left: 8px;
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 0 24px;
  max-width: 800px;
`;

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 32px;
`;

export const StyledFreshdeskMessageWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  gap: 24px;
  background: ${(p) => p.theme.colors.surface};
`;

export const StyledFreshdeskMessageEditor = styled.div`
    display: flex;
    gap: 20px;
`;

export const StyledModalCTA = styled.div`
  display: flex;
  gap: 12px;
  padding: 0 32px;
`;

export const StyledDashedSpacer = styled.div<{$margin?: string}>`
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  border-bottom: 1px dashed ${(p) => p.theme.colors.defaultBorder};
  margin: ${({ $margin = '' }) => $margin};
`;

export const StyledLedgerButtonWrapper = styled.div`
  margin-top: 16px;
`;

export const StyledToastWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const StyledRefreshSVG = styled.img<{ isRecalculating: boolean }>`
    cursor: pointer;
    transition: transform 0.3s ease;
    /* Rotation on hover */
    &:hover {
        transform: rotate(180deg);
    }

    /* Scale down on active/click */
    &:active {
        transform: scale(0.5);
    }

    /* Animation during recalculating */
    ${(props) =>
        props.isRecalculating &&
        css`
            animation: ${spin} 0.75s linear infinite;
        `}
    pointer-events: ${(props) => (props.isRecalculating ? 'none' : 'auto')};
`;

export const StyledRefreshLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    & > div {
      z-index: -1;
    }
`;

export const StyledMidtermAlertWrapper = styled.div`
  width: 100%;
  padding: 8px 16px 24px;
`;

export const StyledMidtermModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMidtermModalHeader = styled.div`
  padding: 28px;
  border-bottom: 2px solid ${(p) => p.theme.colors.primary};
`;

export const StyledMidtermModalFooter = styled.div`
  padding: 28px;
  border-top: 1px solid ${(p) => p.theme.colors.defaultBorder};
  display: flex;
  gap: 16px;
`;

export const StyledMidtermModalBody = styled.div`
  padding: 28px;
  height: 400px;
  min-width: 500px;
  position: relative;
`;

export const StyledMarriageCertificateContainer = styled.div`
  height: 100%;
  max-height: 600px;
  max-width: 500px;
`;

export const StyledMarriageCertificate = styled.img`
  height: 100%;
  max-width: 100%;
`;

export const StyledOpenIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 16px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.defaultBorder};
  padding: 4px;
  border-radius: 8px;
  width: 32px;
  aspect-ratio: 1;
  cursor: pointer;
`;

export const StyledLastStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledApprovalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(p) => p.theme.colors.defaultBorder};
  gap: 10px;

  & > button {
    width: fit-content;
  }
`;

export const StyledApprovalIconContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const StyledApprovalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledDocumentIcon = styled.div`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.turquoise};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDnfCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDnfCheckTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledDnfCheckCTA = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledDnfCheckButton = styled.div<{$isSelected: boolean}>`
  padding: 8px 16px;
  border-radius: 30px;
  cursor: pointer;
  border: ${(p) => p.$isSelected? 'none' : `1px solid ${p.theme.colors.defaultBorder}`};
  background-color: ${(p) => p.$isSelected? p.theme.colors.primary: 'none'};
`;

export const StyledRejectModalContent = styled.div`
  border-radius: 8px;
  border: 0.5px solid ${(p) => p.theme.colors.defaultBorder};
  background-color: ${(p) => p.theme.colors.surface};
  padding: 12px;
`;
