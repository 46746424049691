import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 56px;
    width: 100%;
    max-width: 100%;
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const StyledMenuIcon = styled.img`
    margin-right: 10px;
`;

export const StyledHighlight = styled.span`
    color: ${(p) => p.theme.colors.primary};
`;

export const StyledLeftPanel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;
export const StyledName = styled.div(
    (p) => `
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${p.theme.fontSizes.heading};
    font-weight: 500;
`
);
