import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import { Chip, ChipCloseIconContainer } from './styles';
import theme from '../../../theme';
import { CrossIcon } from '../../../assets/icons/CrossIcon';
import { IDropdownValue } from './types';

const ExceptionChipElement: React.FC<{
    exception: IDropdownValue;
    onRemove: (exception: IDropdownValue) => void;
}> = ({
    exception,
    onRemove
}) => {
    return (
        <Chip>
            <Typography variant="extraSmall" color="emerald" weight="semiBold">{exception.name}</Typography>
            <ChipCloseIconContainer onClick={() => onRemove(exception)}>
                <CrossIcon color={theme.colors.primary} height={18} width={18} strokeWidth={1.2}/>
            </ChipCloseIconContainer>
        </Chip>
    );
};

export default ExceptionChipElement;
