import styled from 'styled-components';

export const StyledRefreshBackdatedMembersCard = styled.div<{
  $isSticked: boolean;
}>`
  grid-area: refresh-members-card;
  position: sticky;
  top: 0px;
  & > div {
    align-items: center;
    width: ${(p) => p.$isSticked? '100vw': 'auto'};
    transform: translateX(${(p) => p.$isSticked ? '-48px' : '0'});
    & > img {
      width: 48px;
      height: 48px;
    }
  }
`;

export const StyledBackdatedTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  padding: 4px 12px;
`;
