import React from 'react';
import { StyledContainer } from './styles';

interface IPrimaryPill {
    title: string;
    children?: React.ReactNode;
}
const PrimaryPill: React.FunctionComponent<IPrimaryPill> = ({ title, children }) => {
    return (
        <StyledContainer>
            <h3>{title.toUpperCase()}</h3>
            {children}
        </StyledContainer>
    );
};

export default PrimaryPill;
