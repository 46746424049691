import React from 'react';
import { DefaultInsuranceIcon } from '../../../assets/img';
import {
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledInsuranceTitle,
    StyledNameContainer
} from './styles';
import { IInsuranceNameCard } from './types';

const InsuranceNameCard: React.FunctionComponent<IInsuranceNameCard> = ({ insurer, insurerLogo }) => {
    return (
        <StyledNameContainer>
            <StyledInsuranceImage src={insurerLogo || DefaultInsuranceIcon} alt={'Insurance Image'} />
            <StyledInsuranceNameContainer>
                <StyledInsuranceTitle>{'Insurer'}</StyledInsuranceTitle>
                <StyledInsuranceName>{insurer}</StyledInsuranceName>
            </StyledInsuranceNameContainer>
        </StyledNameContainer>
    );
};

export default InsuranceNameCard;
