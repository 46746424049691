import React, { useState } from 'react';
import { IAddNoteModalProps } from './types';
import { FormGroup, FormLabel, LoopButton } from '../../atoms';
import { StyledAddNoteContainer } from './styles';
import { Modal, Textarea } from '../../atoms';
import { parseResponse } from '../../../utils/common';
import { addEndorsementNote } from '../../../adapters';
import { useToast } from '../../hooks/useToast';
import Loader from '../../atoms/Loader';

export const AddNoteModal: React.FunctionComponent<IAddNoteModalProps> = ({
    isVisible,
    setIsVisible,
    endorsementId,
    updateEndorsementNotes
}) => {
    const toast = useToast();
    const [isLoading, toggleLoading] = useState(false);
    const [note, setNote] = useState('');

    const handleModalClose = (isVisible: boolean) => {
        setIsVisible(isVisible);
        setNote('');
    };
    const onSaveNoteClicked = async () => {
        if (!endorsementId || !note) return;
        toggleLoading(true);
        const [error, addNoteResponse] = await parseResponse(addEndorsementNote(endorsementId, { description: note }));
        toggleLoading(false);
        if (error) {
            toast?.displayToast('error', 'Unable to process', error.toString(), 5000);
            return;
        }
        addNoteResponse?.data && updateEndorsementNotes(addNoteResponse?.data?.notes);
        handleModalClose(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
    };

    return (
        <Modal title={'Add a note to this endorsement'} isVisible={isVisible} setIsVisible={handleModalClose}>
            <StyledAddNoteContainer>
                <FormGroup>
                    <FormLabel>Please add the note here</FormLabel>
                    <Textarea
                        name="note"
                        isError={false}
                        disabled={false}
                        placeholder="Enter here"
                        onChange={handleInputChange}
                        value={note}
                    />
                </FormGroup>
                <LoopButton type={note ? 'filled' : 'disabled'} onClick={onSaveNoteClicked} width="fit-content">
                    {isLoading ? <Loader /> : 'Save Note'}
                </LoopButton>
            </StyledAddNoteContainer>
        </Modal>
    );
};
