import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
`;
export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;
export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
`;
export const ColSm = styled.div`
    @media (min-width: 576px) {
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
`;
export const ColMd = styled.div`
    @media (min-width: 768px) {
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
`;
export const MB6 = styled.div`
    margin-bottom: 25px;
`;
export const MB12 = styled.div`
    margin-bottom: 50px;
`;
export const PX4 = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`;
export const Bold = styled.div`
  font-weight: 600;
`;
export const FlexColAlignStretch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;
