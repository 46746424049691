export const xlsxKeyMaps: Record<'enrolmentReport' | 'nomineeReport', Record<string, string>> = {
    enrolmentReport: {
        companyName: 'Company Name',
        employeeId: 'Employee ID',
        userId: 'User ID',
        name: 'Name',
        relationship: 'Relationship',
        email: 'Email',
        mobile: 'Mobile',
        dob: 'Date of Birth',
        gender: 'Gender',
        customFields: 'Custom Fields',
        policyId: 'Policy ID',
        policyType: 'Policy Type',
        sumInsured: 'Sum Insured',
        annualPremium: 'Annual Premium',
        enrolmentStatus: 'Enrolment Status',
        enrolmentDueDate: 'Enrolment Due Date',
        createdAt: 'Created At',
        updatedAt: 'Updated At'
    },
    nomineeReport: {
        companyName: 'Company Name',
        employeeId: 'Employee ID',
        employeeUserId: 'User ID',
        name: 'Name',
        nomineeRelationship: 'Relationship',
        email: 'Email',
        dateOfBirth: 'Date of Birth',
        gender: 'Gender',
        nomineePercentageSI: 'Nominee % SI',
        policyRefId: 'Policy ID',
        policyType: 'Policy Type',
        sumInsured: 'Sum Insured',
        enrolmentStatus: 'Enrolment Status',
        enrolmentDueDate: 'Enrolment Due Date',
        createdAt: 'Created At',
        updatedAt: 'Updated At'
    }
};

export const remapForExcel = (
    data: Record<string, unknown>[],
    keyMap: Record<string, string>
): Record<string, unknown>[] => {
    return data.map((report) => {
        const newObj: Record<string, unknown> = {};
        const keys = Object.keys(report);
        keys.map((k) => {
            const newKey = keyMap[k];
            if (newKey) {
                let value = report[k];
                if (!value) {
                    value = 'N/A';
                }
                newObj[newKey] = value;
            }
        });
        return newObj;
    });
};
