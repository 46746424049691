import styled from 'styled-components';

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        align-items: flex-start;
    }
`;

export const StyledInsuranceImage = styled.img`
    overflow: hidden;
    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 12px;
`;
export const StyledInsuranceNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
`;
export const StyledInsuranceNameAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
export const StyledInsuranceName = styled.div(
    (p) => `
    max-width: 400px;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.heading};
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 20px;
     @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`
);
export const StyledIDTag = styled.span((p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
`);
export const StyledInsuranceCategory = styled.div(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    margin-top: 4px;
    font-weight: 600;
    text-transform: uppercase;
`
);
