import React from 'react';
import styled from 'styled-components';

export const TabsRoot = styled.div`
    overflow: hidden;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
    display: flex;
`;
interface ITabs {
    className?: string;
    children?: React.ReactNode;
}

const Tabs: React.FunctionComponent<ITabs> = (props) => {
    const {
        className,
        children
    } = props;
    return (
        <TabsRoot className={className}>
            {children}
        </TabsRoot>
    );
};

export default Tabs;
