import React from 'react';
import { getRaterAPI } from '../../../adapters';
import { ErrorContainer, Loader, Modal } from '../../atoms';
import DisplayGMCRater from '../DisplayGMCRater';
import DisplayRPMRater from '../DisplayRPMRater';
import { StyledModalCell, StyledModalHeader, StyledTableContainer } from './styles';
import { convertDateToIST } from '../../../utils/dateFormatService';

interface IShowRaterModal {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    policyId: string;
}
interface IRater {
    policyStartDate: string;
    policyEndDate: string;
    isPerFamily: boolean;
    raterTable: Record<string, Record<string, number>>;
    policyType: string;
}
const ShowRaterModal: React.FunctionComponent<IShowRaterModal> = ({ isVisible, setIsVisible, policyId }) => {
    const [rater, setRater] = React.useState<IRater | null>(null);
    const [raterError, setRaterError] = React.useState('');
    React.useEffect(() => {
        if (isVisible) {
            (async () => {
                try {
                    setRater((await getRater(policyId)) as any);
                } catch (e) {
                    setRaterError('Could not find a valid Rater for the provided policy');
                }
            })();
        }
        return () => {
            setRater(null);
            setRaterError('');
        };
    }, [isVisible]);
    const getRater = async (policyId: string): Promise<IRater> => {
        const response = await getRaterAPI(policyId);
        return (response.data as Record<string, unknown>).rater as IRater;
    };
    if (raterError.length) {
        return (
            <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
                <StyledModalHeader>
                    <ErrorContainer message={raterError} />
                </StyledModalHeader>
            </Modal>
        );
    }
    if (!rater) {
        return (
            <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
                <StyledModalHeader>
                    <Loader />
                </StyledModalHeader>
            </Modal>
        );
    }
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledModalHeader>
                <StyledModalCell>
                    <div>
                        <b>Policy Start Date</b>
                    </div>
                    <div>{convertDateToIST(rater.policyStartDate)}</div>
                </StyledModalCell>
                <StyledModalCell>
                    <div>
                        <b>Policy End Date</b>
                    </div>
                    <div>{convertDateToIST(rater.policyEndDate)}</div>
                </StyledModalCell>
                <StyledModalCell>
                    <div>
                        <b>Premium Type</b>
                    </div>
                    <div>{(rater.isPerFamily && 'Per Family') || 'Per Life'}</div>
                </StyledModalCell>
            </StyledModalHeader>
            <StyledTableContainer>
                {rater.policyType === 'GMC' && <DisplayGMCRater raterTable={rater.raterTable} />}
                {rater.policyType !== 'GMC' && <DisplayRPMRater raterTable={rater.raterTable} />}
            </StyledTableContainer>
        </Modal>
    );
};

export default ShowRaterModal;
