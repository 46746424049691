import React from 'react';
import { StyledContainer, StyledIcon, StyledIconContainer, StyledTextContainer } from './styles';
import { DisabledCheckMarkIcon } from '../../../assets/img';
import { Typography } from '@loophealth/loop-ui-web-library';

const CompletedEnrolmentConfig: React.FC = () => {
    return (
        <StyledContainer>
            <StyledIconContainer>
                <StyledIcon src={DisabledCheckMarkIcon} />
            </StyledIconContainer>
            <StyledTextContainer>
                <Typography variant="medium" weight="bold">
                    All done here!
                </Typography>
                <Typography variant="small" color="secondary" weight="regular">
                    This enrolment is marked as completed. Confirmation emails have been sent, no further edits can be
                    made.
                </Typography>
            </StyledTextContainer>
        </StyledContainer>
    );
};

export default CompletedEnrolmentConfig;
