import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { createCDAccount, fetchCDAccountList, fetchCDAccountListByField, updateCDAccount } from './thunk';
import { ICDAccount } from './types';

const cdAccountSlice = createSlice({
    name: 'cdAccountSlice',
    initialState,
    reducers: {
        resetState(state) {
            state.cdAccountList = initialState.cdAccountList;
            state.searchedCDAccountList = initialState.searchedCDAccountList;
        },
        resetCrudState(state) {
            state.crudCdAccount = initialState.crudCdAccount;
        },
        updateCdAccountList(state, { payload }) {
            const { cdAccount } = payload;
            const currentState = state.cdAccountList.data;

            state.cdAccountList.data = [...(currentState as ICDAccount[]), cdAccount];
        }
    },
    extraReducers: {
        [fetchCDAccountList.pending as any]: (state) => {
            state.cdAccountList.loading = true;
        },
        [fetchCDAccountList.rejected as any]: (state, { error }) => {
            state.cdAccountList.error = error || 'There was an error processing your request';
            state.cdAccountList.loading = false;
            state.cdAccountList.data = null;
        },
        [fetchCDAccountList.fulfilled as any]: (state, { payload }) => {
            state.cdAccountList.error = null;
            state.cdAccountList.loading = false;
            state.cdAccountList.data = payload.cdAccountList.data;
        },
        [fetchCDAccountListByField.pending as any]: (state) => {
            state.searchedCDAccountList.loading = true;
        },
        [fetchCDAccountListByField.rejected as any]: (state, { error }) => {
            state.searchedCDAccountList.error = error || 'There was an error processing your request';
            state.searchedCDAccountList.loading = false;
            state.searchedCDAccountList.data = null;
        },
        [fetchCDAccountListByField.fulfilled as any]: (state, { payload }) => {
            state.searchedCDAccountList.error = null;
            state.searchedCDAccountList.loading = false;
            state.searchedCDAccountList.data = payload.cdAccountList.data;
        },
        [createCDAccount.pending as any]: (state) => {
            state.crudCdAccount.loading = true;
        },
        [createCDAccount.rejected as any]: (state, { error }) => {
            state.crudCdAccount.error = error.message || 'There was an error processing your request';
            state.crudCdAccount.loading = false;
            state.crudCdAccount.data = null;
        },
        [createCDAccount.fulfilled as any]: (state) => {
            state.crudCdAccount.error = null;
            state.crudCdAccount.loading = false;
            state.crudCdAccount.data = null;
        },
        [updateCDAccount.pending as any]: (state) => {
            state.crudCdAccount.loading = true;
        },
        [updateCDAccount.rejected as any]: (state, { error }) => {
            state.crudCdAccount.error = error.message || 'There was an error processing your request';
            state.crudCdAccount.loading = false;
            state.crudCdAccount.data = null;
        },
        [updateCDAccount.fulfilled as any]: (state) => {
            state.crudCdAccount.error = null;
            state.crudCdAccount.loading = false;
            state.crudCdAccount.data = null;
        },
        'common/cleanup': (state) => {
            state.cdAccountList = initialState.cdAccountList;
        }
    }
});
export default cdAccountSlice.reducer;
export const { resetState, resetCrudState, updateCdAccountList } = cdAccountSlice.actions;
