import React from 'react';
import { IValidationSummaryStep } from './types';
import { StyledConnector, StyledInnerCircle, StyledStepBody, StyledStepContainer, StyledStepIcon } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { CheckmarkCircleIcon } from '../../../assets/img';
import SVG from 'react-inlinesvg';

const ValidationSummaryStep: React.FunctionComponent<IValidationSummaryStep> = ({ body, icon, stepNumber }) => {
    return (
        <StyledStepContainer>
            <StyledStepIcon>
                <StyledInnerCircle>
                    {icon ? (
                        <SVG src={CheckmarkCircleIcon} />
                    ) : (
                        <Typography variant="medium" weight="medium" color={'emerald'}>
                            {stepNumber}
                        </Typography>
                    )}
                </StyledInnerCircle>
            </StyledStepIcon>
            <StyledConnector />
            <StyledStepBody>{body}</StyledStepBody>
        </StyledStepContainer>
    );
};

export default ValidationSummaryStep;
