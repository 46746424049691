import { IEndorsement, MemberDataEntity } from '../../../../../redux/slices/EndorsementSlice/types';
import { isIssuanceFlowEnabled } from '../../../../../utils/featureFlags';
import { DeleteEndorsementProps } from '../../../../containers/DeleteEndorsementModal/types';
import { ICDBalanceOverviewProps, ICDBalanceOverviewUIProps, IEndorsementOverview } from './types';

export const generateDeleteEndorsementProps = (
    endorsementDetails: IEndorsement | null,
    isDeleteModalVisible: boolean,
    setDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    endorsementOverviewProps: Omit<IEndorsementOverview, 'fetchEndorsementDetails'>,
    endorsementId: string | null
) => {
    return (): DeleteEndorsementProps => {
        const uniqueLives: MemberDataEntity[] = Array.from(
            new Set((endorsementDetails?.memberData as MemberDataEntity[]).map((item: any) => item.userId))
        );
        return {
            isVisible: isDeleteModalVisible,
            onClose: setDeleteModalVisible,
            companyName: endorsementDetails?.companyName as string,
            endoCycle: endorsementOverviewProps.endorsementCycle,
            noOfLives: uniqueLives.length,
            insurer: endorsementOverviewProps.insurerName,
            id: endorsementId as string
        };
    };
};

export const isDeleteButtonVisible = (hasDeleteAccess: boolean, endorsementDetails: IEndorsement | null): boolean => {
    return isIssuanceFlowEnabled && hasDeleteAccess && endorsementDetails?.status === 'DRAFT';
};

export const isCdBalanceBannerVisible = (
    cdBalanceOverview: ICDBalanceOverviewProps | null,
    cdBalanceOverviewData: ICDBalanceOverviewUIProps,
    isPreProcessStates: boolean
): boolean => {
    return (cdBalanceOverview && !cdBalanceOverviewData.balanceSufficient && isPreProcessStates) as boolean;
};
