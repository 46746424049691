import styled from 'styled-components';

export const StyledCapitalize = styled.div`
    text-transform: capitalize;
`;
export const StyledTableActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledSubTitle = styled.span(
    (p) => `
    white-space: no-wrap;
    color: ${p.theme.colors.muted};
`
);
export const StyledStatusLabel = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const StyledDOBLabel = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
    white-space: nowrap;
`
);

export const StyledTextWrapBreakWord = styled.div`
    overflow-wrap: break-word;
    max-width: 220px;
`;

export const StyledHorizontalWrapper = styled.div`
    display: flex;
    max-width: 220px;
    min-width: 150px;
    height: 36px;
`;

export const StyledHorizontalSpacer = styled.div`
    width: 8px;
`;

export const StyledTextWrapBreakId = styled.div`
    overflow-wrap: break-word;
    min-width: 90px;
`;
