type IBadgeType = 'accent' |
    'success' | 'success2' | 'success3' | 'successDark' | 'error' | 'errorFill' | 'warning' | 'grey';

export const getPolicyTypes = (
    policyIdAndTypeMap: Map<string, string>,
    policyIds: string[] | null | undefined
): (string | undefined)[] => policyIds?.map((policyId) => policyIdAndTypeMap.get(policyId)) ?? [];

export const getPolicyNicknames = (
    policyIds: string[] | null | undefined,
    policyIdAndNicknameMap: Map<string, string>
) : (string | undefined)[] =>
    policyIds?.map((policyId) => policyIdAndNicknameMap.get(policyId)) ?? [];

export const badgeType = (endoCycleStatus: string) : IBadgeType => {
    switch (endoCycleStatus) {
    case 'Live':
    case 'Ready':
    case 'Review':
        return 'success3';
    case 'Ended':
        return 'errorFill';
    default:
        return 'success3';
    }
};
