import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { IStatus, IChangeRequestOptions } from '../../../../../redux/slices/ChangeRequestSlice/types';
import { fetchChangeRequests } from '../../../../../redux/slices/ChangeRequestSlice/thunks';
import { setSelectedChangeRequests } from '../../../../../redux/slices/ChangeRequestSlice';
import ChangeRequestTable from './ChangeRequestTable';
import CRStatusMoveSlider from './CRStatusMoveSlider';
import { PopoutDatePicker, TableLoading } from '../../../../atoms';
import Select, { StylesConfig } from 'react-select';
import { IOption } from '../../../../atoms/MultiselectCheckboxes/type';
import {
    getFilterOptions,
    iChangeRequestsFilter,
    getNextCRPossibleStates,
    defaultFilterState
} from '../../../../../utils/constants/ChangeRequest/PossibleValues';
import { FilterIcon } from '../../../../../assets/img';
import {
    StyledLoading,
    StyledRowWrap,
    StyledRowContainedWrap,
    StyledRow,
    StyledCol,
    StyledFilterIcon,
    StyledRowSide,
    StyledSearchLabel,
    StyledFiltertextWrapper,
    StyledInput,
    StyledFilterLabel,
    StyledMultiSelectWrapper
} from './styles';
import useFormInput from '../../../../hooks/useFormInput';
import {
    IChangeRequest,
    DateRange,
    IFetchChangeRequestsData
} from '../../../../../redux/slices/ChangeRequestSlice/types';
import { STATUSES } from './constants';
import { transformChangeRequestsParams } from '../../../../../utils/constants/ChangeRequest/transforms';

const singleSelectStyles: StylesConfig = {
    control: (styles) => ({ ...styles, minWidth: '100px' })
};

const fieldIncludes = (field?: string, search = '' ) =>
    !!field && !!field?.toLowerCase().includes(search.toLocaleLowerCase());
const unique = (arr: any[]) => arr.filter((v: any, i: number, a: any[]) => a.indexOf(v) === i);

const defaultPageSize = 10;
const defaultCreatedDateRange:DateRange = {
    startDate: new Date(),
    endDate: null
};

interface ChangeRequestTabProps {
    status?: IStatus[];
    actionable?: boolean;
    selectedChangeRequests: IChangeRequest[];
}
const ChangeRequestTab: React.FunctionComponent<ChangeRequestTabProps> = ({
    status = STATUSES,
    actionable = false,
    selectedChangeRequests = []
}) => {
    // hooks
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('cid');
    const dispatch = useDispatch();
    const search = useFormInput('');
    const defaultData = React.useMemo(() => [], []);

    // redux states
    const changeRequests = useSelector((state: ReduxState) => state.changeRequestSlice.fetchChangeRequests);
    const changeRequestData = changeRequests.data;
    const { hasPrev, hasNext, nextPageId, prevPageId } = changeRequests.metaData;

    // local states
    const [selectFilters, setSelectFilters] = React.useState(defaultFilterState);
    const selectFilterValues = Object.values(selectFilters).map((option) => (option as IOption).value);
    const [possibleMoveStates, setPossibleMoveStates] = React.useState<IChangeRequest['status'][]>([]);
    const [dateCreated, setDateCreated] = React.useState<DateRange>(defaultCreatedDateRange);

    const fetchChangeRequestsData = (
        {
            companyId,
            nextPageId,
            prevPageId,
            pageNo=0,
            selectFilters={},
            dateCreated,
            pageSize=defaultPageSize
        }: IFetchChangeRequestsData
    )=> {
        const params = transformChangeRequestsParams({
            companyId,
            nextPageId,
            prevPageId,
            status,
            pageNo,
            selectFilters,
            dateCreated,
            pageSize
        });
        dispatch(fetchChangeRequests(params as IChangeRequestOptions));
    };

    const closeMoveStatusSlider = () => {
        setPossibleMoveStates([]);
        dispatch(setSelectedChangeRequests([]));
    };

    useEffect(() => {
        // on change of tabs, reset filters
        setSelectFilters(defaultFilterState);
        search.setValue('');
        setDateCreated(defaultCreatedDateRange);
        closeMoveStatusSlider();
    }, [
        JSON.stringify(status)
    ]);

    useEffect(() => {
        !!companyId && fetchChangeRequestsData({
            companyId, selectFilters, dateCreated, pageSize: defaultPageSize
        });
    }, [
        companyId,
        ...selectFilterValues,
        dateCreated.startDate, dateCreated.endDate,
        JSON.stringify(status)
    ]);

    const onPageChange = (isNext: boolean) => {
        !!companyId && fetchChangeRequestsData({
            companyId,
            // pageNo: changeRequests?.metaData?.pageNo,
            ...(isNext ? { nextPageId } : { prevPageId }),
            selectFilters,
            pageSize: defaultPageSize,
            dateCreated
        });
    };

    const filteredChangeRequestData = useMemo(() => {
        let result = changeRequestData;
        if (search.value && search.debounceValue) {
            result = result.filter((c) => fieldIncludes(c?.name, search.debounceValue));
        }
        return result;
    }, [search.value, search.debounceValue, changeRequestData]);

    const onRowSelectionChange = (rows: IChangeRequest[]) => {
        dispatch(setSelectedChangeRequests(rows));

        const newnextCRPossibleStates = unique(rows.map((r) => r.status))
            .map((s) => getNextCRPossibleStates(s))
            .flat();
        setPossibleMoveStates(newnextCRPossibleStates as IChangeRequest['status'][]);
    };

    return (
        <>
            {changeRequests.loading ? (
                <StyledLoading>
                    <TableLoading backgroundColor="transparent" />
                </StyledLoading>
            ) : null}
            <StyledRowContainedWrap>
                <StyledRow>
                    <StyledRowSide>
                        <StyledSearchLabel>
                        Members
                            {/* {`Members ${changeRequestData?.length > 0 ? `(${changeRequestData?.length})` : ''}
                            `} */}
                        </StyledSearchLabel>
                        <StyledInput
                            name="text"
                            placeholder="Search Members by Name"
                            {...search}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                search.onChange(e);
                            }}
                        />
                    </StyledRowSide>
                    <StyledRowSide>
                        <StyledFiltertextWrapper>
                            <StyledFilterIcon src={FilterIcon} />
                            <span>&nbsp;Filter by:</span>
                        </StyledFiltertextWrapper>
                        <StyledMultiSelectWrapper>
                            <StyledFilterLabel>Date Created</StyledFilterLabel>
                            <PopoutDatePicker
                                dateCreated={dateCreated}
                                setDateCreated={(dateCreated)=>setDateCreated(dateCreated)}
                            />
                        </StyledMultiSelectWrapper>
                        {Object.entries(selectFilters).map(([key, value], i) => {
                            const { label='', options=[] } = getFilterOptions(key as iChangeRequestsFilter);
                            return (
                                <StyledMultiSelectWrapper key={key}>
                                    <StyledFilterLabel>{label}</StyledFilterLabel>
                                    <Select
                                        styles={singleSelectStyles}
                                        autoFocus={i === 0}
                                        onChange={(val) => !!val && setSelectFilters({ ...selectFilters, [key]: val })}
                                        options={key === 'status' ? options.filter((o) =>
                                            !o.value || status.includes(o.value as IStatus) ) : options}
                                        placeholder="All"
                                        tabSelectsValue={false}
                                        value={value}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </StyledMultiSelectWrapper>
                            );
                        })}
                        {/* <StyledMultiSelectWrapper>
                            <StyledFilterLabel>Date of Joining</StyledFilterLabel>
                            <PopoutDatePicker />
                        </StyledMultiSelectWrapper> */}
                    </StyledRowSide>
                </StyledRow>
            </StyledRowContainedWrap>
            <StyledRowWrap>
                <StyledCol>
                    {actionable && possibleMoveStates.length > 0 ? (
                        <CRStatusMoveSlider
                            validStatuses={status}
                            possibleMoveStates={possibleMoveStates}
                            closeSlider={closeMoveStatusSlider}
                        />
                    ) : <></>}
                    <ChangeRequestTable
                        data={filteredChangeRequestData ?? defaultData}
                        error={changeRequests.error}
                        loading={changeRequests.loading}
                        onRowSelectionChange={onRowSelectionChange}
                        onPageChange={onPageChange}
                        isPrevDisabled={!hasPrev}
                        isNextDisabled={!hasNext}
                        selectedChangeRequests={selectedChangeRequests}
                    />
                </StyledCol>
            </StyledRowWrap>
        </>
    );
};

export default ChangeRequestTab;
