import { CDAccountFormInput } from './types';
import { uploadFileToStorage } from '../../../utils/common';

export const initialState = (): CDAccountFormInput => {
    return {
        accountNumber: { data: '', error: '' },
        balance: { data: '', error: '' },
        paymentProof: { data: '', error: '' }
    };
};

export const uploadPaymentProofFile = async (files: Record<string, unknown>[]): Promise<string> => {
    return uploadFileToStorage(files[0], 'cdPaymentProof');
};
