import styled from 'styled-components';

export const StyledEnrolmentPolicyContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    padding: 16px;
    min-width: 415px;
    box-sizing: border-box;
    align-items: center;
    background-color: ${(p) => p.theme.colors.white};
    cursor: pointer;

    & > input[type='checkbox'] {
        width: 18px;
        height: 18px;
        margin-right: 24px;
        border-radius: 4px;
        border: 1px solid #025f4c;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
    }

    & > input[type='checkbox']:checked {
        background-color: #025f4c;
    }

    & > img {
        margin-right: 24px;
    }
`;
export const StyledPolicyInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
    height: 48px;

    & > span {
        text-align: right;
    }
`;

export const StyledPolicyContainer = styled.div`
    display: flex;
    flex: 1;
`;

export const StyledInsurerLogo = styled.img`
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.consultViolet};
`;
export const StyledPolicyDetailsContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 400px;
`;

export const StyledPolicyDurationContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;
