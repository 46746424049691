import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledSelectedFileItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 24px;
    border: 1px solid ${(p: any) => p.theme?.colors?.supporting?.defaultBorder ?? theme.colors.defaultBorder};
    border-radius: 8px;
`;

export const StyledFileIconContainer = styled.div`
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    background-color: ${(p: any) => p.theme?.colors?.surface?.fillEmerald ?? theme.colors.primary}
`;

export const StyledSelectedFileImageAndTextContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledSelectedFileIcon = styled.img`
    border-radius: 50%;
`;

export const StyledSelectedFileText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    flex: 1;
`;

export const StyledSelectedFileDeleteContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
`;

export const StyledButtonContainer = styled.div`
    width: fit-content;
    display: flex;
    gap: 16px;
`;

export const StyledLoader = styled.div`
  margin-right: 10px;
`;

