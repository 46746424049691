import { IChangeRequestSlice } from './types';

export const initialState: IChangeRequestSlice = {
    fetchChangeRequests: {
        data: [],
        metaData: { pageNo: 0, hasPrev: false, hasNext: false },
        error: null,
        loading: false
    },
    fetchAllChangeRequests: {
        data: [],
        error: null,
        loading: false
    },
    getCompanyWiseChangeRequestStats: {
        data: [],
        error: null,
        loading: false
    },
    bulkMoveCRToNextState: {
        data: { data: { inValidEntries: [] } },
        crList: [],
        error: null,
        loading: false
    },
    selectedChangeRequests: []
};
