import React from 'react';
import useFetchActivePolicyPlans from './useFetchActivePolicyPlans';
import { HookResponse, IPolicyListDropdownItem } from '../types';

const useFetchPolicyDropdownList = (companyId: string | null): HookResponse<IPolicyListDropdownItem[]> => {
    const policyPlans = useFetchActivePolicyPlans(companyId);
    const [policyList, setPolicyList] = React.useState<IPolicyListDropdownItem[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        if (!policyPlans.data) return;
        if (policyPlans.error.length) {
            setPolicyList([]);
            return;
        }
        setIsLoading(true);
        const dropdownList = policyPlans.data.map((v) => ({
            name: {
                id: v.id,
                policyType: v.policyType,
                insurerName: v.insurerName,
                nickName: v.nickName || 'N/A'
            },
            value: v.id
        }));
        setPolicyList(dropdownList);
    }, [policyPlans.data]);
    return {
        data: policyList,
        isLoading: isLoading || policyPlans.isLoading,
        error: policyPlans.error
    };
};

export default useFetchPolicyDropdownList;
