import styled from 'styled-components';

export const StyledStepContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0px;
`;

export const StyledStepIcon = styled.div`
    width: 46px;
    height: 44px;
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
    border-radius: 100px;
    padding: 4px;
`;

export const StyledInnerCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 34px;
    border-radius: 100px;
    background-color: ${(p) => p.theme.colors.primaryBG};
`;

export const StyledStepBody = styled.div`
    width: 100%;
    padding: 16px 20px;
    border: 1px solid ${(p) => p.theme.colors.defaultBorder};
    border-radius: 8px;
`;

export const StyledConnector = styled.div`
    width: 32px;
    height: 1px;
    background-color: ${(p) => p.theme.colors.defaultBorder};
`;
