import React from 'react';
import UploadPill from '../UploadPill';
import UploadPreview from '../UploadPreview';
import { Label, MandatoryIcon, UploadPillContainer } from './styles';
import { LoopUploadPillProps } from './types';

const LoopUploadPill: React.FC<LoopUploadPillProps> = ({
    title,
    subTitle,
    onUpload,
    formats,
    fileName,
    fileUrl,
    onReUpload,
    required = false
}) => {
    return (
        <UploadPillContainer>
            <Label>
                {title}
                {required && <MandatoryIcon>*</MandatoryIcon>}
            </Label>
            <UploadPill title={title} subTitle={subTitle} onUpload={onUpload} formats={formats}>
                {fileName && fileUrl && (
                    <UploadPreview previewUrl={fileUrl} previewName={fileName || ''} onReUpload={onReUpload} />
                )}
            </UploadPill>
        </UploadPillContainer>
    );
};

export default LoopUploadPill;
