import { Form, LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { updateUserData } from '../../../adapters';
import { IUserData } from '../../../adapters/types';
import useFormInput from '../../hooks/useFormInput';
import { useToast } from '../../hooks/useToast';
import { StyledFormContainer, StyledInitialValueCard } from './styles';
import { IMemberDetailsUpdateModalProps } from './types';

const MemberDetailsUpdateModal: React.FunctionComponent<IMemberDetailsUpdateModalProps> = ({
    isOpen, onOpenChange, initialValues
}) => {
    const mobileInput = useFormInput(initialValues.mobile);
    const emailInput = useFormInput(initialValues.email);
    const toast = useToast();
    const handleSubmit = () => {
        const toUpdate: Partial<IUserData> = {};
        if (mobileInput.value != initialValues.mobile) toUpdate.mobile = mobileInput.value;
        if (emailInput.value != initialValues.email) toUpdate.email = emailInput.value;
        updateUserData(initialValues.userId, toUpdate).then(() => {
            toast?.displayToast('success', 'Success', 'User details updated', 4000);
            onOpenChange(false);
        }).catch((err) => {
            toast?.displayToast('error', 'Failed', err.message, 4000);
        });
    };
    return (
        <Modal isVisible={isOpen} setIsVisible={onOpenChange} title="Edit Contact Details">
            <StyledInitialValueCard>
                <Typography variant='medium' weight='semiBold' color='emerald'>
                    {initialValues?.firstName + ' ' + initialValues?.lastName}
                </Typography>
                <br />
                <Typography variant='small' color='secondary'>
                    User ID: {initialValues.userId}
                </Typography>
            </StyledInitialValueCard>
            <StyledFormContainer>
                <Form.FormField id="mobile" label="Mobile">
                    <Form.Input
                        placeholder="9999988888"
                        onChange={(e) => mobileInput.setValue(e.currentTarget.value)}
                        value={mobileInput.value}
                        type="tel"
                    />
                </Form.FormField>
                <Form.FormField id="email" label="Email address">
                    <Form.Input
                        placeholder="user@email.com"
                        onChange={(e) => emailInput.setValue(e.currentTarget.value)}
                        value={emailInput.value}
                        type="email"
                    />
                </Form.FormField>
            </StyledFormContainer>
            <LoopButton variant='filled' onClick={() => handleSubmit()}>Save</LoopButton>
        </Modal>
    );
};

export default MemberDetailsUpdateModal;
