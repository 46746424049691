import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import FreshdeskLivesCostTable from './FreshdeskLivesCostTable';
import { formatCurrencyElseDefault } from '../../utils';
import { IIncorrectTable } from './types';
import { TABLE_TEXTS } from '../constants';
import { spaceBetweenStyle, validatorSummaryTableStyle } from './styles';
import FreshdeskInfoTable from './TableInfo';

export const FreshdeskIncorrectTable: React.FC<IIncorrectTable> = ({ incorrectEntries, totalLives }) => {
    const { DataCell } = FreshdeskInfoTable;

    const incorrectTableFooters = incorrectEntries.operations
        .map((op) => {
            const incorrectTexts = TABLE_TEXTS.INCORRECT[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
            return [
                <DataCell colSpan={7} key={op.policyId + op.policyType}>
                    <div style={spaceBetweenStyle}>
                        <Typography variant="small" weight="medium">
                            {incorrectTexts.totalCost}&nbsp;{op.policyType}
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {formatCurrencyElseDefault(op.policyCost)}
                        </Typography>
                    </div>
                </DataCell>,
                <React.Fragment key={op.policyId + op.policyType + 'cdBalance'}>
                    <DataCell colSpan={op.requiredOrNew ? 4 : 7}>
                        <div style={spaceBetweenStyle}>
                            <Typography variant="small" weight="medium">
                                {op.policyType}&nbsp;Current CD Balance&nbsp;(
                                {op.isCdSufficient ? 'Sufficient' : 'Low'})
                            </Typography>
                            <Typography variant="small" color={op.isCdSufficient ? 'secondary' : 'error'}>
                                {op.isCdMissing ? 'Missing' : formatCurrencyElseDefault(op.cdBalance)}
                            </Typography>
                        </div>
                    </DataCell>
                    {!!op.requiredOrNew && (
                        <DataCell colSpan={3}>
                            <div style={spaceBetweenStyle}>
                                <Typography variant="small" weight="medium">
                                    {op.policyType}&nbsp;{incorrectTexts.requiredOrNew}
                                </Typography>
                                <Typography variant="small" color="secondary">
                                    {formatCurrencyElseDefault(op.requiredOrNew)}
                                </Typography>
                            </div>
                        </DataCell>
                    )}
                </React.Fragment>
            ];
        })
        .flat();

    return (
        <div style={validatorSummaryTableStyle}>
            <FreshdeskLivesCostTable
                details={incorrectEntries?.operations || []}
                header={
                    <div style={spaceBetweenStyle}>
                        <Typography variant="small" weight="medium">
                            Total New Incorrect Lives
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {incorrectEntries?.totalLives}/{totalLives}
                        </Typography>
                    </div>
                }
                footers={incorrectTableFooters}
            />
        </div>
    );
};
