import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchUserPoliciesByUser } from './thunks';

const userPolicySlice = createSlice({
    name: 'userPolicySlice',
    initialState,
    reducers: {
        resetState(state) {
            state.userPolicies = initialState.userPolicies;
        }
    },
    extraReducers: {
        [fetchUserPoliciesByUser.pending as any]: (state) => {
            state.fetchUserPolicies.loading = true;
        },
        [fetchUserPoliciesByUser.rejected as any]: (state, { error }) => {
            state.fetchUserPolicies.error = error;
            state.fetchUserPolicies.loading = false;
        },
        [fetchUserPoliciesByUser.fulfilled as any]: (state, { payload }) => {
            state.fetchUserPolicies.error = null;
            state.fetchUserPolicies.loading = false;
            state.userPolicies.data = [...(payload.userPolicies || [])];
        },
        'common/cleanup': (state) => {
            state.userPolicies = initialState.userPolicies;
        }
    }
});
export default userPolicySlice.reducer;
export const {
    resetState
} = userPolicySlice.actions;
