import { IIdentifiedKycData, IMemberUpload, IRejectedEntries } from '../../../../../redux/slices/ValidatorSlice/types';
import { ITableData, IUpdateData } from './types';

export const formatTableData = (
    uploadedData: Record<string, unknown>[],
    identifiedKyc: IIdentifiedKycData
): ITableData[] => {
    const formatData: ITableData[] = [];
    const setFalseValue = (value: boolean | null | undefined): boolean | null =>
        value === null || value === undefined ? null : value;
    let counter = 0;
    identifiedKyc?.EDIT.map((data) => {
        const userName = uploadedData?.find((member) => member.userId === data.userId);
        const obj: ITableData = {
            'S.No.': counter + 1,
            'Employee ID': data.employeeId,
            'Name': userName?.name as string,
            'Document Received': { isSelected: setFalseValue(data.documentReceived), index: counter },
            'operation': 'EDIT',
            'userId': data.userId
        };
        formatData.push(obj);
        counter++;
    });

    identifiedKyc?.OFFBOARD.map((data) => {
        const obj: ITableData = {
            'S.No.': counter + 1,
            'Employee ID': data.employeeId,
            'Name': `${data.firstName} ${data.lastName}`,
            'Document Received': { isSelected: setFalseValue(data.documentReceived), index: counter },
            'operation': 'OFFBOARD',
            'userId': data.userId
        };
        formatData.push(obj);
        counter++;
    });

    return formatData;
};

export const updateRecords = (
    tableData: ITableData[],
    identifiedKyc: IIdentifiedKycData | null,
    uploadedData: IMemberUpload | null,
    rejectedEntries: IRejectedEntries | null
): IUpdateData => {
    const updatedIdentifiedKyc: IIdentifiedKycData = {
        EDIT: [],
        OFFBOARD: []
    };
    const updatedRejectedEntries = { ...rejectedEntries };
    const incorrectData = tableData.filter((record) => record['Document Received'].isSelected === false);
    const existingEditRecords = [...(updatedRejectedEntries.edit || [])];
    const existingOffboardRecords = [...(updatedRejectedEntries.offboard || [])];
    incorrectData.map((record) => {
        switch (record.operation) {
        case 'EDIT':
            const currentEditRecord = uploadedData
                ?.edit
                .find((data) => data.userId === record.userId);
            if (currentEditRecord && Object.keys(currentEditRecord).length) {
                existingEditRecords.push({
                    ...currentEditRecord,
                    errors: `{\"global\":\"KYC documents not received\"}`
                });
            }
            break;
        case 'OFFBOARD':
            const currentOffboardRecord = uploadedData
                ?.offboard.find((data) => data.userId === record.userId);
            if (currentOffboardRecord && Object.keys(currentOffboardRecord).length) {
                existingOffboardRecords.push({
                    ...currentOffboardRecord,
                    error: `{\"global\":\"KYC documents not received\"}`
                });
            }
        }
    });

    // Update the selected option in identified kyc to preselect the Yes/No option
    // when the user comes back to the kyc screen
    identifiedKyc?.EDIT.map((record) => {
        const isIncorrect = incorrectData.find((data) => data.userId === record.userId);
        const documentsReceived = !!(isIncorrect && Object.keys(isIncorrect).length);
        updatedIdentifiedKyc.EDIT.push({ ...record, documentReceived: !documentsReceived });
    });
    identifiedKyc?.OFFBOARD.map((record) => {
        const isIncorrect = incorrectData.find((data) => data.userId === record.userId);
        const documentsReceived = !!(isIncorrect && Object.keys(isIncorrect).length);
        updatedIdentifiedKyc.OFFBOARD.push({ ...record, documentReceived: !documentsReceived });
    });
    updatedRejectedEntries.add = updatedRejectedEntries.add || [];
    updatedRejectedEntries.edit = existingEditRecords;
    updatedRejectedEntries.offboard = existingOffboardRecords;

    return {
        updatedIdentifiedKyc: updatedIdentifiedKyc,
        updatedRejectedEntries: updatedRejectedEntries as IRejectedEntries
    };
};
