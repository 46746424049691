import styled, { css } from 'styled-components';

export const StyledNavOverlay = styled.div<{ $startLeaveAnimation: boolean }>(
    (p) => `
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    ${
    (p.$startLeaveAnimation &&
            css`
                background-color: rgba(0, 0, 0, 0);
                -webkit-transition: background-color 600ms linear;
                -ms-transition: background-color 600ms linear;
                transition: background-color 600ms linear;
            `) ||
    ''}
`
);
