import React, { useState } from 'react';
import Modal from '../../atoms/Modal';
import { FormGroup, FormLabel, Input, Loader, LoopButton } from '../../atoms';
import { StyledMoveLivesContainer } from './styles';
import Dialog from '../../atoms/Dialog';
import { ChangeStatusSuccessIcon } from '../../../assets/img';
import { parseResponse } from '../../../utils/common';
import { reassignEndorsementMembersAPI } from '../../../adapters';
import { useToast } from '../../hooks/useToast';

interface IMoveEndoLivesModal {
    endorsementId: string;
    selectedLives: string[];
    isVisible: boolean;

    setIsVisible(isVisible: boolean): void;
}

interface IReassignMembersResponse {
    endorsementNumber: string;
    endoAlreadyExisted: boolean;
}

export const MoveEndoLivesModal: React.FunctionComponent<IMoveEndoLivesModal> = ({
    endorsementId,
    selectedLives,
    isVisible,
    setIsVisible
}) => {
    const [note, setNote] = useState('');
    const [noteError, setNoteError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reassignResponse, setReassignResponse] = useState<IReassignMembersResponse | null>(null);
    const toast = useToast();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNoteError('');
        setNote(e.target.value);
    };

    const onSuccess = () => {
        handleModalClose(false);
        window.location.reload();
    };

    const handleModalClose = (isVisible: boolean) => {
        setIsVisible(isVisible);
        setReassignResponse(null);
        setNote('');
        setNoteError('');
    };

    const onMoveLivesClicked = async () => {
        if (!note || !note.trim().length) {
            setNoteError('Please enter a note');
            return;
        }
        setIsLoading(true);

        const requestData = { memberIds: selectedLives, note, endorsementId };
        const [error, reAssignMemberResponse] = await parseResponse(
            reassignEndorsementMembersAPI(endorsementId, requestData)
        );
        if (error) {
            toast?.displayToast('error', 'Unable to process', error.toString(), 5000);
            setIsLoading(false);
            return;
        }
        setReassignResponse(reAssignMemberResponse.data);
        setIsLoading(false);
    };
    const successDescription = reassignResponse
        ? reassignResponse.endoAlreadyExisted
            ? `Endo ${reassignResponse.endorsementNumber} has been updated with ${selectedLives.length} new lives`
            : `Endo ${reassignResponse.endorsementNumber} has been created with status as “Draft”`
        : `Draft endo has been updated with ${selectedLives.length} new lives`;

    return (
        <Modal
            title={reassignResponse ? '' : `Moving ${selectedLives.length} lives to next endorsement`}
            isVisible={isVisible}
            setIsVisible={handleModalClose}
        >
            {!reassignResponse ? (
                <StyledMoveLivesContainer>
                    <span>These lives will be moved the next endorsement with the status “Draft”</span>
                    <FormGroup>
                        <FormLabel>Add Note (Why are these lives being re-assigned)</FormLabel>
                        <Input
                            name="note"
                            placeholder="Enter here"
                            onChange={handleInputChange}
                            value={note}
                            errorText={noteError}
                        />
                    </FormGroup>
                    <LoopButton
                        type={!isLoading ? 'filled' : 'disabled'}
                        onClick={onMoveLivesClicked}
                        width="fit-content"
                    >
                        {isLoading ? <Loader /> : 'Move Lives'}
                    </LoopButton>
                </StyledMoveLivesContainer>
            ) : (
                <Dialog
                    icon={ChangeStatusSuccessIcon}
                    title={`${selectedLives.length} lives successfully moved to next endorsement`}
                    description={successDescription}
                    isLoading={false}
                    primaryButtonText="Done"
                    onPrimaryButtonClicked={onSuccess}
                    isSecondaryButton={false}
                />
            )}
        </Modal>
    );
};
