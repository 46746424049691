import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../atoms/Modal';
import RadioGroup from '../../atoms/RadioGroup';
import {
    StyledCDAccountInfoContainer,
    StyledCompanyDetails,
    StyledContainer
} from './styles';
import VerticalSpacer from '../../atoms/VerticalSpacer';
import { attachCDAccountRadioButtonList, createCDAccountRadioButtonList } from './utils';
import { LinkCDAccountCard } from '../LinkCDAccountCard';
import { IAttachCdAccountModalProps } from './types';
import LoopButton from '../../atoms/LoopButton';
import { ICDAccount } from '../../../redux/slices/CDAccountSlice/types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import Dialog from '../../atoms/Dialog';
import { ChangeStatusSuccessIcon } from '../../../assets/img';
import AttachCDAccountConfirmation from './AttachCDAccountConfirmation';
import AddCDAccountCard from '../AddCDAccountCard';
import { ICreateCDAccountFormFields } from '../AddCDAccountCard/types';

export const AttachCDAccountModal: React.FunctionComponent<IAttachCdAccountModalProps> = ({
    isVisible,
    setIsVisible,
    insurerLogo,
    insurerName,
    companyName,
    companyId,
    policyId,
    policyType,
    refreshPolicyList
}) => {
    const [modalState, setModalState] =
        useState<'ATTACH_CD_ACCOUNT' | 'CONFIRMATION' | 'SUCCESS'>('ATTACH_CD_ACCOUNT');
    const [isLinkCdAccount, setLinkCdAccount] = useState(true);
    const [isAttachCDAccountButtonEnabled, setAttachCDAccountButtonEnabled] = useState(false);

    // Link CD Account - (Selected account state) -
    const [selectedCDAccount, setSelectedCDAccount] = useState<null | ICDAccount>(null);
    const [createCDAccountObj, setCDAccountObj] = useState<null | ICreateCDAccountFormFields>(null);

    const cdAccountList: ICDAccount[] | null =
        (useSelector((state: ReduxState) => state.cdAccountSlice.cdAccountList))?.data;

    const filteredCDAccountList = useMemo(() =>
        cdAccountList ? cdAccountList.filter((data) => data.companyId === companyId &&
                data.insurer === insurerName) : []
    , [cdAccountList]);

    useEffect(() => {
        if (createCDAccountObj) return;
        setLinkCdAccount(filteredCDAccountList.length !== 0);
    }, [filteredCDAccountList]);

    useEffect(() => {
        setAttachCDAccountButtonEnabled(false);
        setSelectedCDAccount(null);
    }, [isLinkCdAccount]);

    const toggleAttachCDAccountType = (value: boolean) => {
        setLinkCdAccount(value);
    };
    const handleModalClose = (isVisible: boolean) => {
        setIsVisible(isVisible);
        setModalState('ATTACH_CD_ACCOUNT');
        setAttachCDAccountButtonEnabled(false);
    };
    const handleLinkCDAccount = () => {
        if (!isAttachCDAccountButtonEnabled) return;
        setModalState('CONFIRMATION');
    };
    const handleAddCDAccount = () => {
        setModalState('CONFIRMATION');
    };
    const onAttachAccountSuccess = () => {
        setModalState('SUCCESS');
    };
    const onSuccess = () => {
        refreshPolicyList();
        handleModalClose(false);
    };
    return (
        <Modal title={modalState === 'ATTACH_CD_ACCOUNT' ? 'Attach a CD Account to this policy' : ''}
            isVisible={isVisible} setIsVisible={handleModalClose}>
            {modalState === 'ATTACH_CD_ACCOUNT' &&
                <StyledContainer>
                    <StyledCDAccountInfoContainer>
                        <StyledCompanyDetails>
                            <span>COMPANY NAME</span>
                            {companyName}
                        </StyledCompanyDetails>
                        <VerticalSpacer/>
                        <StyledCompanyDetails>
                            <span>POLICY DETAILS</span>
                            {`${insurerName} | ${policyType} Policy`}
                        </StyledCompanyDetails>
                    </StyledCDAccountInfoContainer>
                    <RadioGroup
                        radioButtonList={filteredCDAccountList.length ?
                            attachCDAccountRadioButtonList : createCDAccountRadioButtonList}
                        selectedValue={isLinkCdAccount}
                        onButtonClicked={toggleAttachCDAccountType}/>

                    {isLinkCdAccount &&
                        <LinkCDAccountCard insurerLogo={insurerLogo} insurerName={insurerName}
                            setLinkButtonEnabled={setAttachCDAccountButtonEnabled}
                            selectedCDAccount={selectedCDAccount}
                            setSelectedCDAccount={setSelectedCDAccount}
                            cdAccountsList={filteredCDAccountList}/>}

                    {!isLinkCdAccount &&
                        <AddCDAccountCard insurer={insurerName} insurerLogo={insurerLogo}
                            handleFormNavigation={handleAddCDAccount}
                            setCDAccountObj={setCDAccountObj}/>}

                    {isLinkCdAccount && <LoopButton type={isAttachCDAccountButtonEnabled ? 'filled' : 'disabled'}
                        onClick={handleLinkCDAccount} width={'fit-content'}>
                        {isLinkCdAccount ? 'Link selected CD Account to this policy' : 'Create Account'}
                    </LoopButton>}
                </StyledContainer>}

            {modalState === 'CONFIRMATION' &&
                <AttachCDAccountConfirmation isLinkCdAccount={isLinkCdAccount} handleModalClose={handleModalClose}
                    selectedCDAccount={selectedCDAccount} policyId={policyId}
                    companyId={companyId} insurerName={insurerName}
                    createCDAccountObj={createCDAccountObj}
                    onAttachAccountSuccess={onAttachAccountSuccess}/>}

            {modalState === 'SUCCESS' &&
                <Dialog icon={ChangeStatusSuccessIcon}
                    title={isLinkCdAccount ?
                        'CD Account has been successfully linked to this policy!' :
                        'New CD Account has been created and linked to the selected policy'}
                    isLoading={false} primaryButtonText={'Got it!'} isSecondaryButton={false}
                    onPrimaryButtonClicked={onSuccess} description={isLinkCdAccount ?
                        `Insurer CD Account ID:${selectedCDAccount?.accountNumber}`
                        : `CD Account Number: ${createCDAccountObj?.accountNumber}`}/>}
        </Modal>
    );
};
