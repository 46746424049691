import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchAllEndorsementExceptionsAPI,
    fetchCompletedEndorsements,
    fetchPendingEndorsements
} from '../../../adapters';
import { IEndorsements } from './types';

const ENDORSEMENT_ACTION_TYPES = {
    PENDING_ENDORSEMENTS: 'PENDING_ENDORSEMENTS',
    COMPLETED_ENDORSEMENT: 'COMPLETED_ENDORSEMENT',
    FETCH_ALL_ENDORSEMENT_EXCEPTIONS: 'FETCH_ALL_ENDORSEMENT_EXCEPTIONS',
    UPDATE_ENDORSEMENT_EXCEPTIONS_BY_ID: 'UPDATE_ENDORSEMENT_EXCEPTIONS_BY_ID'
};

export const fetchPendingEndorsementList = createAsyncThunk(
    ENDORSEMENT_ACTION_TYPES.PENDING_ENDORSEMENTS,
    async () => {
        try {
            const response = await fetchPendingEndorsements();
            return response.data as IEndorsements;
        } catch (e) {
            throw e;
        }
    }
);
export const fetchCompletedEndorsementList = createAsyncThunk(
    ENDORSEMENT_ACTION_TYPES.COMPLETED_ENDORSEMENT,
    async () => {
        try {
            const response = await fetchCompletedEndorsements();
            return response.data as IEndorsements;
        } catch (e) {
            throw e;
        }
    }
);
export const fetchAllEndorsementExceptions = createAsyncThunk(
    ENDORSEMENT_ACTION_TYPES.FETCH_ALL_ENDORSEMENT_EXCEPTIONS,
    async () => {
        try {
            const response = await fetchAllEndorsementExceptionsAPI();
            return response.data as IEndorsements;
        } catch (e) {
            throw e;
        }
    }
);

