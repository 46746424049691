import styled from 'styled-components';

export const StyledModalHeader = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 500px;
    padding: 40px;
`;
export const StyledModalCell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 50px;
`;
export const StyledTableContainer = styled.div`
    padding: 20px;
`;
