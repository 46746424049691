import React, { useState } from 'react';
import { IRenameEndoProps } from './types';
import LoopModal from '../../atoms/LoopModal';
import { Form, LoopButton } from '@loophealth/loop-ui-web-library';
import { StyledButtonWrapper, StyledFormContainer } from './styles';
import { parseResponse } from '../../../utils/common';
import { renameEndorsementCycleAPI } from '../../../adapters';

const RenameEndoModal: React.FC<IRenameEndoProps> = ({
    isVisible,
    handleCloseModal,
    endorsementId,
    endorsementCycle
}) => {
    const [endorsementCycleName, setEndorsementCycleName] = useState(endorsementCycle || '');
    const [endoRenameError, setEndoRenameError] = useState('');
    const [isLoading, toggleLoading] = useState(false);

    const onEndorsementNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndorsementCycleName(e.target.value);
        endoRenameError && setEndoRenameError('');
    };

    const onEndorsementNameSaved = async () => {
        if (!endorsementId) return;
        const requestData = { endorsementCycle: endorsementCycleName };
        toggleLoading(true);
        const [error] = await parseResponse(
            renameEndorsementCycleAPI(endorsementId, requestData)
        );
        toggleLoading(false);
        if (error) {
            setEndoRenameError(error.toString());
            return;
        }
        handleCloseModal();
    };

    return (
        <LoopModal isVisible={isVisible} onClose={() => handleCloseModal()} title={'Rename Endo cycle'}>
            <StyledFormContainer>
                <Form.FormField id={'endorsementCycle'} label='Enter new name here'>
                    <Form.Input value={endorsementCycleName} onChange={onEndorsementNameChanged}
                        error={endoRenameError}/>
                </Form.FormField>
                <StyledButtonWrapper>
                    <LoopButton variant='filled' isLoading={isLoading} onClick={onEndorsementNameSaved}>
                        Save
                    </LoopButton>
                </StyledButtonWrapper>
            </StyledFormContainer>
        </LoopModal>
    );
};
export default RenameEndoModal;
