import { ICompanySlice } from './types';

export const initialState: ICompanySlice = {
    companyList: {
        data: [],
        error: null,
        loading: false
    },
    createCompany: {
        data: null,
        loading: false,
        error: null
    }
};
