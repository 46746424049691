import React from 'react';
import { RadioButton } from '../index';
import { StyledRadioButtonRow } from './styles';
import { IRadioButtonGroupProps } from './types';


const RadioGroup: React.FunctionComponent<IRadioButtonGroupProps> = ({
    radioButtonList,
    selectedValue,
    onButtonClicked
}) => {
    return (
        <StyledRadioButtonRow>
            {(radioButtonList).map((option, index) => (
                <RadioButton
                    key={index}
                    name={option.name}
                    value={option.value}
                    isSelected={selectedValue}
                    onClick={() => onButtonClicked(option.value)}
                />))}
        </StyledRadioButtonRow>
    );
};
export default RadioGroup;
