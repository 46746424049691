import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { Label, LabelButton, TableData, TableRow } from '../../atoms';
import { StyledCompanyName, StyledMargin, StyledStatusLabel, StyledSubTitle, StyledTableActions } from './styles';
import { ICompanyDirectoryRow } from './types';

const CompanyDirectoryRow: React.FunctionComponent<ICompanyDirectoryRow> = ({ company, index }) => {
    const navigate = useNavigate();
    const navigateToCompany = (id: string) => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.COMPANY_DETAILS.route + '?id=' + id);
    };
    const navigateToMember = (id: string) => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.MEMBER_DETAILS.route + '?id=' + id);
    };
    return (
        <TableRow>
            <TableData>{index + 1}</TableData>
            <TableData index>
                <StyledCompanyName>{company.name}</StyledCompanyName>
                ID: <StyledSubTitle>{company.id || 'N/A'}</StyledSubTitle>
            </TableData>
            <TableData>
                <StyledStatusLabel>
                    <Label isActive={company.active}>{company.active ? 'ACTIVE' : 'INACTIVE'}</Label>
                </StyledStatusLabel>
            </TableData>
            <TableData>
                <StyledTableActions>
                    <StyledMargin>
                        <LabelButton onClick={() => navigateToCompany(company.id)}>Company Details</LabelButton>
                    </StyledMargin>
                    <LabelButton onClick={() => navigateToMember(company.id)}>Member Details</LabelButton>
                </StyledTableActions>
            </TableData>
        </TableRow>
    );
};

export default CompanyDirectoryRow;
