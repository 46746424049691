import React from 'react';
import { IPolicyPlan } from '../../../redux/slices/PolicySlice/types';
import { fetchActivePoliciesByCompanyId } from '../../../adapters';
import { HookResponse } from '../types';

const useFetchActivePolicyPlans = (companyId: string | null): HookResponse<IPolicyPlan[]> => {
    const [policyPlans, setPolicyPlans] = React.useState<IPolicyPlan[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setIsError] = React.useState('');
    React.useEffect(() => {
        if (!companyId) return;
        setIsLoading(true);
        fetchActivePoliciesByCompanyId(companyId)
            .then((res) => {
                setPolicyPlans(res.data as IPolicyPlan[]);
                setIsError('');
                setIsLoading(false);
            })
            .catch((error) => {
                setPolicyPlans([]);
                setIsError((error as Error).message);
                setIsLoading(false);
            });
    }, [companyId]);
    return {
        data: policyPlans,
        error,
        isLoading
    };
};

export default useFetchActivePolicyPlans;
