import React from 'react';
import { StyledSelect } from './styles';
import { IDropdown } from './types';

const Dropdown: React.FunctionComponent<IDropdown> = ({
    className, name, onChange, value, error, src, options, color
}) => {
    return (
        <StyledSelect
            className={className}
            $error={error}
            $color={color}
            $src={src}
            name={name}
            value={value}
            onChange={(e) => {
                onChange(e);
            }}
        >
            <option value="">Select</option>
            {options?.map((option, index) => {
                return (
                    <option key={index} value={option.value}>
                        {option.name}
                    </option>
                );
            })}
        </StyledSelect>
    );
};

export default Dropdown;
