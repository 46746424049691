export interface IPolicyDetails {
    label: string;
    name: string;
}

// TODO: Regex to be added
export const ViewPolicyDetails: IPolicyDetails[] = [
    {
        label: 'Policy Number',
        name: 'policyNumber'
    },
    {
        label: 'Policy Type',
        name: 'policyType'
    },
    {
        label: 'Policy Nickname',
        name: 'nickName'
    },
    {
        label: 'TPA Name',
        name: 'tpaName'
    },
    {
        label: 'Policy Start Date',
        name: 'policyStartDate'
    },
    {
        label: 'Policy End Date',
        name: 'policyEndDate'
    },
    {
        label: 'OPD Coverage',
        name: 'isOPDPolicy'
    },
    {
        label: 'Number Of Employees',
        name: 'numberOfEmployees'
    },
    {
        label: 'Number Of Dependents',
        name: 'numberOfDependents'
    },
    {
        label: 'Total Lives',
        name: 'totalLives'
    },
    {
        label: 'Corporate Name',
        name: 'corporateName'
    },
    {
        label: 'Final Premium',
        name: 'finalPremium'
    }
];
