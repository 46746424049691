import styled from 'styled-components';

export const StyledNavigatorContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    background-color: ${(p) => p.theme.colors.white};
    width: 100%;
`;

export const StyledNavigator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 35px;
`;

export const StyledRightButton = styled.div`
    margin-left: 12px;
`;
