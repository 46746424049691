import { Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../atoms';
import LoopModal from '../../atoms/LoopModal';
import ErrorState from './InternalRaterModalComponents/ErrorState';
import SuccessState from './InternalRaterModalComponents/SuccessState';
import {
    fetchPremiumRater,
    buildSuccessStateProps,
    navigateToPremiumRaterUpload,
    buildErrorStateProps
} from './service';
import { StyledDisclaimerWrapper, StyledModalWrapper } from './styles';
import { IChangeStatusAndReCalculatePremiumsModalProps, IErrorState, ISuccessState } from './types';

const ChangeStatusAndReCalculatePremiumsModal: React.FunctionComponent<IChangeStatusAndReCalculatePremiumsModalProps> =
    ({ isVisible, closeModal, handleOnChangeStatus, isLoading, policyId }) => {
        const navigate = useNavigate();
        const [isRaterLoading, setRaterIsLoading] = React.useState<boolean>(false);
        const [isRaterPresent, setIsRaterPresent] = React.useState<boolean>(false);
        const fetchRater = fetchPremiumRater(setRaterIsLoading, policyId, setIsRaterPresent);

        const successStateComponentProps: ISuccessState = buildSuccessStateProps(
            isLoading,
            closeModal,
            handleOnChangeStatus
        );

        const handleOnNavigate = navigateToPremiumRaterUpload(navigate);

        const errorStateComponentProps: IErrorState = buildErrorStateProps(closeModal, handleOnNavigate);

        React.useEffect(() => {
            fetchRater();
        }, []);
        return (
            <LoopModal isVisible={isVisible} onClose={closeModal} title={'Changing status'}>
                <StyledModalWrapper>
                    <StyledDisclaimerWrapper>
                        {isRaterPresent && (
                            <Typography variant="small" weight="regular" color="primary">
                                Are you sure you want to change the status from{' '}
                                <Typography variant="small" weight="semiBold">
                                    &apos;Drafts&apos;
                                </Typography>{' '}
                                to{' '}
                                <Typography variant="small" weight="semiBold" color="primary">
                                    &apos;In progress&apos;
                                </Typography>
                                ?
                            </Typography>
                        )}
                        <Typography variant="small" weight="regular">
                            Changing status will recalculate the premiums based on the rater uploaded{' '}
                        </Typography>
                    </StyledDisclaimerWrapper>
                    {isRaterLoading ? (
                        <Loader />
                    ) : isRaterPresent ? (
                        <SuccessState {...successStateComponentProps} />
                    ) : (
                        <ErrorState {...errorStateComponentProps} />
                    )}
                </StyledModalWrapper>
            </LoopModal>
        );
    };

export default ChangeStatusAndReCalculatePremiumsModal;
