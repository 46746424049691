import styled from 'styled-components';

export const StyledSelect = styled.select<{
    $src: string;
}>`
    width: 100%;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #edeff3 url(${(p) => p.$src}) no-repeat scroll 95% center;
`;
export const StyledOption = styled.option`
    width: 100%;
    background-color: white;
`;
