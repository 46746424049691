import React, { useEffect, useMemo } from 'react';
import { Loader, LoopBadge, LoopButton } from '@loophealth/loop-ui-web-library';
import { APPROVAL_STATUS } from '../constants';
import {
    StyledApprovalDocumentImage,
    StyledApprovalDocumentImageContainer,
    StyledApprovalDocumentImageOverlay,
    StyledApprovalDocumentListItem,
    StyledApprovalDocumentListItemFooter,
    StyledApprovalImageRibbonWrapper,
    StyledIFrameMirrorWrapper
} from './styles';
import { IMultiDocumentSingleItemProps } from './types';
import theme from '../../../../theme';

const MultiDocumentSingleItem: React.FC<IMultiDocumentSingleItemProps> = ({
    document,
    updateDocumentStatus,
    onDocumentSelect
}: IMultiDocumentSingleItemProps) => {
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(()=>{
        if (!document.fileUrl) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [document]);

    const approveDocument = () => {
        updateDocumentStatus(document.id, APPROVAL_STATUS.APPROVED);
    };

    const rejectDocument = () => {
        updateDocumentStatus(document.id, APPROVAL_STATUS.REJECTED);
    };

    const undoStatus = () => {
        updateDocumentStatus(document.id, APPROVAL_STATUS.PENDING);
    };

    const handleDocumentSelect = () => {
        onDocumentSelect(document.id);
    };

    const ActionButtons = useMemo(() => {
        switch (document.status) {
        case APPROVAL_STATUS.PENDING:
            return (
                <>
                    <LoopButton
                        size="small"
                        variant="outline"
                        textColor="error"
                        fontWeight="semiBold"
                        onClick={rejectDocument}
                    >
                                Reject
                    </LoopButton>
                    <LoopButton
                        size="small"
                        variant='outline'
                        textColor="emerald"
                        borderColor={theme.colors.primary}
                        fontWeight="semiBold"
                        onClick={approveDocument}
                    >
                                Approve
                    </LoopButton>
                </>
            );
        case APPROVAL_STATUS.REJECTED:
            return (
                <LoopButton
                    size="small"
                    variant="outline"
                    textColor="primary"
                    borderColor={theme.colors.defaultBorder}
                    fontWeight="semiBold"
                    onClick={undoStatus}
                >
                            Undo Reject
                </LoopButton>
            );
        case APPROVAL_STATUS.APPROVED:
            return (
                <LoopButton
                    size="small"
                    variant="outline"
                    textColor="primary"
                    borderColor={theme.colors.defaultBorder}
                    fontWeight="semiBold"
                    onClick={undoStatus}
                >
                            Undo Accept
                </LoopButton>
            );
        default:
            return null;
        }
    }, [document.status, approveDocument, rejectDocument, undoStatus]);

    return (
        <StyledApprovalDocumentListItem onClick={handleDocumentSelect}>
            <StyledApprovalDocumentImageContainer>
                <StyledApprovalImageRibbonWrapper>
                    {
                        document.status === APPROVAL_STATUS.REJECTED &&
                            <LoopBadge
                                variant="overflow"
                                badgeType='error'
                                size='small'
                                edgeDirection='right'
                                text='REJECTED'
                            />
                    }
                    {
                        document.status === APPROVAL_STATUS.APPROVED &&
                            <LoopBadge
                                variant="overflow"
                                badgeType='successDark'
                                edgeDirection='right'
                                size='small'
                                text='ACCEPTED'
                            />
                    }
                </StyledApprovalImageRibbonWrapper>
                {
                    isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {document.type === 'application/pdf' ? (
                                <>
                                    <iframe
                                        src={document.fileUrl+'#toolbar=0&navpanes=0'}
                                        width="100%"
                                        height="100%"
                                    />
                                    <StyledIFrameMirrorWrapper
                                        onClick={handleDocumentSelect}
                                    ></StyledIFrameMirrorWrapper>
                                </>
                            ) : (
                                <StyledApprovalDocumentImage
                                    src={document.fileUrl}
                                    alt="kyc-document"
                                />
                            )}
                        </>
                    )
                }
                <StyledApprovalDocumentImageOverlay title={document.title}>
                    {document.title.substring(0, 10) + (document.title.length > 10 ? '...' : '')}
                </StyledApprovalDocumentImageOverlay>
            </StyledApprovalDocumentImageContainer>
            <StyledApprovalDocumentListItemFooter>
                {ActionButtons}
            </StyledApprovalDocumentListItemFooter>
        </StyledApprovalDocumentListItem>
    );
};

export default MultiDocumentSingleItem;
