import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
`;

export const StyledSubContainer = styled.div`
    width: 100%;
    max-height: 100%;
    position: relative;
`;

export const StyledSpacingWrapper = styled.div`
    margin-top: 8px;
    margin-bottom: 32px;
`;

export const StyledChipWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 32px;
    max-width: 455px;
    justify-content: space-between;
`;

export const StyledPillWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 32px;
    max-width: 340px;
    justify-content: space-between;
`;

export const StyledUploadContainer = styled.div<{ $isFileUploaded: boolean }>`
    height: ${(p) => (p.$isFileUploaded ? '' : '162px')};
    padding-bottom: ${(p) => (p.$isFileUploaded ? '40px' : '')};
`;

export const StyledAlignment = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 10px;
`;

export const StyledFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${(p) => p.theme.colors.border};
    background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledButtonContainer = styled.div`
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
`;

export const StyledBodyContainer = styled.div`
    max-height: 100%;
    overflow-y: scroll;
    height: 100%;
    padding: 48px 76px 88px 88px;
    margin-bottom: 40px;
`;

export const StyledTemplateCard = styled.div`
    background-color: ${({ theme }) => theme.colors.surface};
    border-radius: 12px;
    margin: 32px 0px;
    padding: 16px 24px;
`;

export const StyledTemplateAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StyledIconAlignment = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    max-width: 210px;
    justify-content: space-between;
`;

export const StyledImage = styled(SVG)`
    max-height: 100%;
    background-color: ${({ theme }) => theme.colors.surface}; ;
`;

export const StyledSpacing = styled.div`
    margin-top: 32px;
`;

export const StyleEnrolmentPillWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 32px;
    max-width: 265px;
    justify-content: space-between;
`;
