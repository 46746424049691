import { utils, write } from 'xlsx-js-style';
import { ErrorSheetColumnNames, IExcelRows, IExcelSheet, IUserAttributeNames } from './types';

export const downloadErrorExcel = (
    rows: IExcelRows<Record<IUserAttributeNames | 'Errors', unknown>>[],
    fileName: string
): void => {
    const fileLink = errorSheetDownloader([
        {
            sheetName: 'Sheet1',
            rows
        }
    ]);
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = fileName;
    link.click();
    link.remove();
};
const errorSheetDownloader = (
    sheets: IExcelSheet<Record<IUserAttributeNames | 'Errors', unknown>>[]
): string => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const workbook = utils.book_new();
    // for each sheet
    sheets.map((sheet) => {
        // create the header
        const row0 = ErrorSheetColumnNames.map((key) => ({
            v: key,
            t: 's',
            s: {
                font: { bold: true, sz: 14 },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                fill: { fgColor: { rgb: 'E9E9E9' } },
                border: {
                    top: { style: 'thin', color: { rgb: '000000' } },
                    right: { style: 'thin', color: { rgb: '000000' } },
                    left: { style: 'thin', color: { rgb: '000000' } },
                    bottom: { style: 'thin', color: { rgb: '000000' } }
                }
            }
        }));
        const rows: any[] = [row0];
        const BDR = {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        };
        sheet.rows.forEach((row) => {
            const { data, props } = row;
            const transform = ErrorSheetColumnNames.map((key) => ({
                v: data[key] || '',
                t: 's',
                s: {
                    font: { name: 'Courier', sz: 12, color: { rgb: props[key]?.fontColour || '000000' } },
                    alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
                    fill: {
                        fgColor: {
                            rgb: props[key]?.cellColour || 'FFFFFF'
                        }
                    },
                    border: BDR
                }
            }));
            rows.push(transform);
        });
        const worksheet = utils.aoa_to_sheet(rows, {
            cellDates: true,
            dateNF: 'dd mmm yyyy'
        });
        worksheet['!cols'] = [
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 50 }
        ];
        utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
    });
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const exelBlob = new Blob([excelBuffer], { type: fileType });
    return URL.createObjectURL(exelBlob);
};

export default errorSheetDownloader;
