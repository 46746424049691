import {
    AddMemberDataExcelEntity,
    DeleteMemberDataExcelEntity,
    EditMemberDataExcelEntity,
    IEndorsement,
    IEndorsements,
    IEndorsementsUIEntity,
    IStatus,
    MemberDataEntity,
    MemberDataUIEntity
} from '../../../redux/slices/EndorsementSlice/types';
import { capitalizeFirstLetter, getUserName } from '../../common';
import { DATE_FORMAT_DD_MMM_YYY, formatDate, formatDateString } from '../../dateFormatService';
import {
    checkIfKYCApplicable,
    checkIfKYCNonVerified,
    checkIfMidtermNonVerified,
    formatCurrencyElseDefault,
    getEndorsementOperationTypeLabel,
    getEndorsementSourceLabel,
    roundToTwoDigitElseDefault
} from './index';
import {
    ICDBalanceOverviewProps,
    ICDBalanceOverviewUIProps,
    IEndorsementOverview
} from '../../../components/pages/SPAContainer/pages/EndorsementDetails/types';
import { IPolicyType } from '../../../redux/slices/PolicySlice/types';
import moment from 'moment';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const getCoverageStartDate = (policyStartDate: string, dateOfJoining: { _seconds: number }) => {
    if (!policyStartDate && !dateOfJoining) return '-';
    if (!dateOfJoining) return formatDateString(new Date(policyStartDate).toDateString(), DATE_FORMAT_DD_MMM_YYY);
    if (!policyStartDate) return formatDate(dateOfJoining._seconds, DATE_FORMAT_DD_MMM_YYY);
    const policyStart = moment(policyStartDate);
    const doj = moment(dateOfJoining._seconds * 1000);
    return policyStart > doj
        ? formatDateString(new Date(policyStartDate).toDateString(), DATE_FORMAT_DD_MMM_YYY)
        : formatDate(dateOfJoining._seconds, DATE_FORMAT_DD_MMM_YYY);
};

// All fields covered from reference sheet-
export const transformAddMemberDataExcelRow = (d: MemberDataEntity, index: number): AddMemberDataExcelEntity => ({
    'Policy Number': d.policyNumber,
    'S. No.': (index + 1).toString(),
    'Source': d.source ? getEndorsementSourceLabel(d.source) : '-',
    'User ID (Loop ID)': d.userId,
    'Employee ID': d.employeeId,
    'Name': getUserName(d.firstName, d.lastName),
    'Relationship to Employee': d.relationship ? capitalizeFirstLetter(d.relationship) : '-',
    'Date of Birth (DD/MMM/YYYY)': formatDate(d.dob?._seconds, DATE_FORMAT_DD_MMM_YYY),
    'Age(as on DOJ)': d.newAge,
    'Age band': d.ageBand as string,
    'Gender': d.gender as string,
    'Coverage Start Date': getCoverageStartDate(d.policyStartDate, d.doj),
    'Mobile no.': d.mobile as string,
    'Email ID': d.email as string,
    'Sum Insured': roundToTwoDigitElseDefault(d.newSumInsured),
    'CTC': roundToTwoDigitElseDefault(Number(d.ctc)),
    'Rate per Mille': d.rpm?.toString(),
    'Annual Premium': roundToTwoDigitElseDefault(d.raterPremium),
    'Pro Rata Days': d.proRatedDays,
    'Prorated premium': roundToTwoDigitElseDefault(d.netPremium),
    'IGST (18%)': roundToTwoDigitElseDefault(d.igst),
    'CGST (9%)': roundToTwoDigitElseDefault(d.cgst),
    'SGST (9%)': roundToTwoDigitElseDefault(d.sgst),
    'Net prorated premium': roundToTwoDigitElseDefault(d.totalProRatePremium),
    'Requested On(DD/MMM/YYYY)': formatDateString(d.createdAt, DATE_FORMAT_DD_MMM_YYY),
    'Exception': d.isException ? 'Yes' : 'No'
});

// All fields covered from reference sheet-
export const transformDeleteMemberDataExcelRow = (d: MemberDataEntity, index: number): DeleteMemberDataExcelEntity => ({
    'Policy Number': d.policyNumber,
    'S. No.': (index + 1).toString(),
    'Source': d.source ? getEndorsementSourceLabel(d.source) : '-',
    'User ID (Loop ID)': d.userId,
    'Employee ID': d.employeeId,
    'Name': getUserName(d.firstName, d.lastName),
    'Relationship to Employee': d.relationship ? capitalizeFirstLetter(d.relationship) : '-',
    'Date of Birth (DD/MMM/YYYY)': formatDate(d.dob?._seconds, DATE_FORMAT_DD_MMM_YYY),
    'Age (as on Coverage Start Date)': d.newAge,
    'Age band': d.ageBand as string,
    'Gender': d.gender as string,
    'Date of Leaving (DD/MMM/YYYY)': formatDateString(d.dol, DATE_FORMAT_DD_MMM_YYY),
    'Coverage Start Date': getCoverageStartDate(d.policyStartDate, d.doj),
    'Mobile no.': d.mobile as string,
    'Email ID': d.email as string,
    'Sum Insured': roundToTwoDigitElseDefault(d.newSumInsured),
    'CTC': roundToTwoDigitElseDefault(Number(d.ctc)),
    'Annual Premium': roundToTwoDigitElseDefault(d.raterPremium),
    'Pro Rata Days': d.proRatedDays,
    'Prorated premium': roundToTwoDigitElseDefault(d.netPremium),
    'IGST (18%)': roundToTwoDigitElseDefault(d.igst),
    'CGST (9%)': roundToTwoDigitElseDefault(d.cgst),
    'SGST (9%)': roundToTwoDigitElseDefault(d.sgst),
    'Net prorated premium': roundToTwoDigitElseDefault(d.totalProRatePremium),
    'Requested On(DD/MMM/YYYY)': formatDateString(d.createdAt, DATE_FORMAT_DD_MMM_YYY),
    'Exception': d.isException ? 'Yes' : 'No'
});

// Total Difference in Premium field pending -
export const transformEditMemberDataExcelRowTypeEdit = (
    d: MemberDataEntity,
    index: number
): EditMemberDataExcelEntity => ({
    'Policy Number': d.policyNumber,
    'S. No.': (index + 1).toString(),
    'Source': d.source ? getEndorsementSourceLabel(d.source) : '-',
    'User ID (Loop ID)': d.userId,
    'Employee ID': d.employeeId,
    'Name': getUserName(d.firstName, d.lastName),
    'Relationship to Employee': d.relationship ? capitalizeFirstLetter(d.relationship) : '-',
    'Date of Birth (DD/MMM/YYYY)': formatDate(d.dob?._seconds, DATE_FORMAT_DD_MMM_YYY),
    'Gender': d.gender as string,
    'Mobile no.': d.mobile as string,
    'Email ID': d.email as string,
    'Correction Type': d.correctionType as string,
    'Old Data': d.oldData as string,
    'Revised Data': d.revisedData as string,
    'Effective Date (Date of Correction)': formatDateString(d.policyStartDate.toString(), DATE_FORMAT_DD_MMM_YYY),
    'Coverage Start Date': getCoverageStartDate(d.policyStartDate, d.doj),
    'Old Annual Premium': roundToTwoDigitElseDefault(d.oldAnnualPremium),
    'Revised Annual Premium': roundToTwoDigitElseDefault(d.newAnnualPremium),
    'Old Sum Insured': roundToTwoDigitElseDefault(d.oldSumInsured),
    'Revised Sum Insured': roundToTwoDigitElseDefault(d.newSumInsured),
    'Pro Rata Days': d.proRatedDays,
    'Old Premium Prorata': roundToTwoDigitElseDefault(d.oldProRatedAnnualPremium),
    'Revised Premium Pro Rated': roundToTwoDigitElseDefault(d.newProRatedAnnualPremium),
    'Change in Premium': roundToTwoDigitElseDefault(d.netPremium),
    'IGST (18%)': roundToTwoDigitElseDefault(d.igst),
    'CGST (9%)': roundToTwoDigitElseDefault(d.cgst),
    'SGST (9%)': roundToTwoDigitElseDefault(d.sgst),
    'Net Change in Premium': roundToTwoDigitElseDefault(d.totalProRatePremium),
    'Requested On(DD/MMM/YYYY)': formatDateString(d.createdAt, DATE_FORMAT_DD_MMM_YYY),
    'Exception': d.isException ? 'Yes' : 'No'
});

export const transformMemberDataForUI = (data: MemberDataEntity[], status: IStatus): MemberDataUIEntity[] => {
    return data.map((d) => {
        return {
            id: d.id,
            userId: d.userId,
            employeeId: d.employeeId,
            name: getUserName(d.firstName, d.lastName),
            operationType: d.operationType ? getEndorsementOperationTypeLabel(d.operationType) : '-',
            relationship: d.relationship ? capitalizeFirstLetter(d.relationship) : '-',
            source: d.source ? getEndorsementSourceLabel(d.source) : '-',
            createdAt: new DateFormatUtils().formatDateString(d.userCreatedAt),
            updatedAt: new DateFormatUtils().formatDateString(d.createdAt),
            updatedBy: d.updatedBy ? d.updatedBy : '-',
            isMidtermNonVerified: checkIfMidtermNonVerified(d, status),
            isKYCNonVerified: checkIfKYCNonVerified(d, status),
            isKYCApplicable: checkIfKYCApplicable(d, status)
        };
    }).sort(
        (x, y) => Number(y.isMidtermNonVerified) - Number(x.isMidtermNonVerified)
    ).sort(
        (x, y) => Number(y.isKYCNonVerified) - Number(x.isKYCNonVerified)
    );
};

export const transformEndorsementListForUI = (data: IEndorsements[]): IEndorsementsUIEntity[] => {
    return data.map((d) => {
        return {
            id: d.id,
            companyName: d.companyName,
            insurerName: d.insurerName,
            policyType: d.policyType?.toUpperCase(),
            status: d.status,
            nickName: d.nickName,
            endorsementNumber: `Endo ${d.endorsementNumber}`,
            endorsementCycle: d.endorsementCycle,
            livesCount: d.livesCount,
            createdAt: new DateFormatUtils().formatDateString(d.createdAt)
        };
    });
};

export const transformCDBalanceOverviewForUI = (data: ICDBalanceOverviewProps | null): ICDBalanceOverviewUIProps => {
    return {
        cdBalance: formatCurrencyElseDefault(data?.cdBalance),
        balanceSufficient:
            data && data.netCostOfEndorsement !== undefined && data.cdBalance !== undefined
                ? data.netCostOfEndorsement <= data.cdBalance
                : true,
        additionCost: formatCurrencyElseDefault(data?.additionCost),
        deleteCost: formatCurrencyElseDefault(data?.deleteCost),
        editsCost: formatCurrencyElseDefault(data?.editsCost),
        endorsementCost: formatCurrencyElseDefault(data?.endorsementCost),
        netCostOfEndorsement: formatCurrencyElseDefault(data?.netCostOfEndorsement),
        estimatedBalancePostEndo: formatCurrencyElseDefault(data?.estimatedBalancePostEndo)
    };
};
export const transformEndorsementOverviewForUI = (endorsementDetails: IEndorsement | null)
    : Omit<IEndorsementOverview, 'fetchEndorsementDetails'> => {
    return {
        endorsementId: endorsementDetails?.id || '-',
        endorsementNumber: endorsementDetails?.endorsementNumber || 0,
        endorsementCycle: endorsementDetails?.endorsementCycle || '-',
        insurerName: endorsementDetails?.insurerName || '-',
        policyNumber: endorsementDetails?.policyNumber || '-',
        policyId: endorsementDetails?.policyId || '-',
        policyType: (endorsementDetails?.policyType as IPolicyType) || '-',
        nickName: endorsementDetails?.nickName || '-',
        supportTicketId: endorsementDetails?.supportTicketId || '-',
        supportTicketOwner: endorsementDetails?.supportTicketOwner || '-',
        currentStatus: endorsementDetails?.status || 'DRAFT'
    };
};
