import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    gap: 30px;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 550px) {
        gap: 25px;
    }
`;
export const StyledInsuranceDetailContainer = styled.div`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledInsuranceDetailHeading = styled.div(
    (p) => `
    letter-spacing: 0.05em;
    font-weight: 600;
    text-transform: uppercase;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-align: left;
`
);
export const StyledInsuranceDetailContent = styled.div(
    (p) => `
    position: relative;
    margin-top: 7px;
    font-weight: bold;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    text-align: center;
`
);
export const StyledIcons = styled.img`
    overflow: hidden;
    height: 30px;
    width: 30px;
`;

export const StyledIconContainer = styled.div`
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    @media (max-width: 550px) {
        margin: 10px 25px;
    }
`;

export const StyledIconAlignment = styled.div`
    min-width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &:empty{
        display: none;
    }

`;

export const StyledEcardContainer = styled.div(
    (p) => `
    background-color: ${p.theme.colors.primary};
    color: ${p.theme.colors.white};
    position: relative;
    padding: 10px 20px;
    border-radius: 12px;
    font-weight: bold;
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.body};
    text-align: center;
    cursor: pointer;
`
);


export const StyledColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
    flex:1;
    @media (max-width: 550px) {
        gap: 10px;
    }

    & > div{
        width: fit-content;
    }
`;
