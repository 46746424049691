import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { EXTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { Loader, PageHeader } from '../../../../atoms';
import { StyledContainer, StyledSpacing } from './styles';
import { CompanyCard } from '../../../../containers';
import PolicyListCard from '../../../../containers/PolicyListCard';
import { IPolicy } from '../../../../../redux/slices/PolicySlice/types';
import {
    fetchActivePoliciesList,
    fetchExpiredPoliciesList,
    generateEnrolmentReportAPI,
    fetchEnrolmentConfigByCompanyId
} from '../../../../../adapters';
import EnrolmentReportCard from '../../../../containers/EnrolmentReportCard';
import { jsonToExcel } from '../../../../../utils/common';
import { remapForExcel, xlsxKeyMaps } from './xlsxMaps';
import { useToast } from '../../../../hooks/useToast';
import useFetchUserGroup from '../../../../hooks/business-hooks/useFetchUserGroup';
import { isEnrolmentConfigEnabled } from '../../../../../utils/featureFlags';
import { IEnrolmentList } from './types';

const CompanyDetails: React.FunctionComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('id') as string;

    const [activePolicyList, setActivePolicyList] = React.useState<{ policies: IPolicy[] } & { loading: boolean }>({
        policies: [],
        loading: true
    });
    const [expiredPolicyList, setExpiredPolicyList] = React.useState<{ policies: IPolicy[] } & { loading: boolean }>({
        policies: [],
        loading: true
    });
    const [enrolmentList, setEnrolmentList] = React.useState<IEnrolmentList>({
        enrolments: [],
        loading: true
    });

    const fetchActivePolicies = async () => {
        try {
            !activePolicyList.loading && setActivePolicyList({ policies: activePolicyList.policies, loading: true });
            const activePolicyListResponse = (await fetchActivePoliciesList(companyId)).data;
            setActivePolicyList({ policies: [...activePolicyListResponse], loading: false });
        } catch (e) {
            toast?.displayToast('error', '', 'An error occurred, try refreshing the page');
        }
    };

    const fetchExpiredPolicies = async () => {
        try {
            !expiredPolicyList.loading && setActivePolicyList({ policies: expiredPolicyList.policies, loading: true });
            const expiredPolicyListResponse = (await fetchExpiredPoliciesList(companyId)).data;
            setExpiredPolicyList({ policies: [...expiredPolicyListResponse], loading: false });
        } catch (e) {
            toast?.displayToast('error', '', 'An error occurred, try refreshing the page');
        }
    };

    const getEnrolments = async () => {
        try {
            const response = (await fetchEnrolmentConfigByCompanyId(companyId)).data;
            setEnrolmentList({ enrolments: response?.configs ?? [], loading: false });
        } catch (e) {
            toast?.displayToast('error', 'Oops, unable to fetch report', 'Please try again in sometime');
        }
    };

    React.useEffect(() => {
        if (companyId === 'undefined' || companyId === 'null') {
            navigate(EXTERNAL_ROUTES.APP);
        }
        fetchActivePolicies();
        fetchExpiredPolicies();
        if (isEnrolmentConfigEnabled) {
            getEnrolments();
        }
    }, []);

    const selectedCompany = useSelector(
        (state: ReduxState) => state.companySlice.companyList.data?.filter((company) => company.id === companyId)[0]
    );

    const handleAddPolicy = () => {
        navigate('/app/add-policy', {
            state: {
                companyId: companyId ?? ''
            }
        });
    };

    const refreshPolicyList = (isActivePolicyList: boolean) => {
        isActivePolicyList ? fetchActivePolicies() : fetchExpiredPolicies();
    };

    const generateEnrolmentReport = async (fromDate: Date, toDate: Date) => {
        if (selectedCompany) {
            toast?.displayToast('warning', 'Starting to Generate', 'This may take some time.');
            const report = await generateEnrolmentReportAPI(companyId, fromDate, toDate);
            toast?.displayToast('success', 'Downloading Report', 'Your report should start to download automatically');
            const fileLink = jsonToExcel([
                {
                    sheetName: 'Insured Lives',
                    data: remapForExcel(report.enrolmentReport, xlsxKeyMaps.enrolmentReport)
                },
                {
                    sheetName: 'Nominee Info',
                    data: remapForExcel(report.nomineeReport, xlsxKeyMaps.nomineeReport)
                }
            ]);
            const link = document.createElement('a');
            link.href = fileLink;
            link.download = `${selectedCompany.name}`;
            link.click();
            link.remove();
        }
    };
    const userGroup = useFetchUserGroup({ companyId });

    return (
        (selectedCompany && (
            <StyledContainer>
                <PageHeader>Company Details</PageHeader>
                <CompanyCard
                    {...selectedCompany}
                    agentGroup={userGroup?.data?.groupName}
                    isPlacementForm={false}
                    handleAddPolicy={handleAddPolicy}
                />
                <EnrolmentReportCard
                    onSubmit={generateEnrolmentReport}
                    enrolmentList={enrolmentList}
                    activePolicies={activePolicyList.policies}
                    expiredPolicies={expiredPolicyList.policies}
                />
                <PolicyListCard
                    isActive
                    policyList={activePolicyList.policies as IPolicy[]}
                    loading={activePolicyList.loading}
                    companyId={companyId}
                    companyName={selectedCompany.name}
                    refreshPolicyList={refreshPolicyList}
                />
                <PolicyListCard
                    isActive={false}
                    policyList={expiredPolicyList.policies as IPolicy[]}
                    loading={expiredPolicyList.loading}
                    companyId={companyId}
                    companyName={selectedCompany.name}
                    refreshPolicyList={refreshPolicyList}
                />

                <StyledSpacing />
            </StyledContainer>
        )) || <Loader />
    );
};

export default CompanyDetails;
