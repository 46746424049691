import React from 'react';
import { StyledEnrolmentPolicyListingContainer, StyledPolicyScrollContainer } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import DisabledPolicyComponent from '../DisabledPolicyComponent';
import { IPolicyPlan } from '../../../redux/slices/PolicySlice/types';
import usePolicies from '../../hooks/business-hooks/usePolicies';

const SelectedPoliciesList: React.FC<{ policyIds: string[] }> = ({ policyIds }) => {
    const [policyList] = usePolicies(policyIds);

    const activePolicyList = policyList.filter((p: IPolicyPlan) => p.active);

    return (
        <StyledEnrolmentPolicyListingContainer>
            <Typography variant={'small'} color={'secondary'}>
                Open enrolment portal for:
            </Typography>
            <StyledPolicyScrollContainer>
                {activePolicyList?.map((data) => {
                    return (
                        <DisabledPolicyComponent
                            key={data.id}
                            policyId={data.id}
                            policyType={data.policyType}
                            icon={data.insurerLogo}
                            insurer={data.insurerName}
                            nickName={data.nickName}
                        />
                    );
                })}
            </StyledPolicyScrollContainer>
        </StyledEnrolmentPolicyListingContainer>
    );
};

export default SelectedPoliciesList;
