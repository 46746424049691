import { Form, LoopButton } from '@loophealth/loop-ui-web-library';
import React, { useState } from 'react';
import { updateCdAccount } from '../../../adapters';
import { ICDAccount } from '../../../redux/slices/CDAccountSlice/types';
import { parseResponse } from '../../../utils/common';
import LoopModal from '../../atoms/LoopModal';
import { ButtonContainer, Container } from './styles';
import { IEditCdAccountIdModal } from './types';

export const EditCdAccountIdModal: React.FunctionComponent<IEditCdAccountIdModal> = ({
    isVisible,
    setIsVisible,
    id
}) => {
    const [isLoading, toggleLoader] = useState(false);
    const [accountId, setAccountId] = useState('');
    const [apiError, setApiError] = React.useState<string>('');

    const onUpdate = async () => {
        toggleLoader(true);
        const [error] = await parseResponse(updateCdAccount({ accountNumber: accountId, id } as unknown as ICDAccount));
        toggleLoader(false);
        if (error) {
            setApiError(error.toString());
        } else {
            setIsVisible(false);
            setAccountId('');
            window.location.reload();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setApiError('');
        setAccountId(value);
    };

    const handleCloseModal = () => {
        setIsVisible(false);
        setApiError('');
        setAccountId('');
    };
    return (
        <LoopModal isVisible={isVisible} onClose={handleCloseModal} title={'Change Account ID'}>
            <Container>
                <Form.FormField id="accountId" label="Enter new ID here">
                    <Form.Input
                        value={accountId}
                        placeholder="Ex: LBW-OUT"
                        onChange={handleInputChange}
                        error={apiError}
                    />
                </Form.FormField>
                <ButtonContainer>
                    <LoopButton
                        variant={accountId != null && accountId != '' ? 'filled' : 'disabled'}
                        onClick={onUpdate}
                        isLoading={isLoading}
                    >
                        Save
                    </LoopButton>
                </ButtonContainer>
            </Container>
        </LoopModal>
    );
};
