import React from 'react';
import {
    StyledChip,
    StyledLabel
} from './styles';
import { IChip } from './types';

const Chip: React.FunctionComponent<IChip> = ({
    label='',
    variant='filled',
    color='#bdbdbd',
    backgroundColor,
    borderRadius='1rem',
    onClick
}) => {
    return <StyledChip color={color} variant={variant} backgroundColor={backgroundColor} borderRadius={borderRadius}
        onClick={onClick}>
        <StyledLabel color={color}>{label}</StyledLabel>
    </StyledChip>;
};

export default Chip;
