import React from 'react';
import { TableRow, TableData } from '../../atoms';
import { ICDAccountRow, ICDEntry } from './types';

const CDAccountRow: React.FunctionComponent<ICDAccountRow> = ({ cdAccount, onClick, ...rest }) => {
    const cdAccountEntryKeys: (keyof ICDEntry)[] = [
        'accountNumber',
        'companyName',
        'policyType',
        'insurer',
        'balance',
        'updatedAt'
    ];
    return (
        <TableRow onClick={() => onClick(cdAccount.id)} {...rest}>
            {cdAccountEntryKeys.map((key, index) => (
                <TableData key={index}>
                    <b>{cdAccount[key]}</b>
                </TableData>
            ))}
        </TableRow>
    );
};

export default CDAccountRow;
