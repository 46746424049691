import { deletePolicyDocument, downloadPolicyDocument, uploadPolicyDocument } from '../../../../../adapters';
import { downloadSignedFile, parseResponse, uploadSignedFile } from '../../../../../utils/common';

export const uploadPolicyDocumentFile = async (policyId: string, fileName: string, body: Blob): Promise<void> => {
    try {
        const [error, response] = await parseResponse(uploadPolicyDocument(policyId, fileName));
        if (error) throw error;

        const data = response.data;
        await uploadSignedFile(data.signedURL, data.method, body);
    } catch (error) {
        throw error;
    }
};

export const downloadPolicyDocumentFile = async (policyId: string, fileName: string): Promise<void> => {
    try {
        const [error, response] = await parseResponse(downloadPolicyDocument(policyId, fileName));
        if (error) throw error;

        const data = response.data;
        await downloadSignedFile(data.signedURL, data.method, fileName);
    } catch (error) {
        throw error;
    }
};

export const deletePolicyDocumentFile = async (policyId: string, fileIdentifier: string): Promise<void> => {
    try {
        const [error] = await parseResponse(deletePolicyDocument(policyId, fileIdentifier));
        if (error) throw error;
    } catch (error) {
        throw error;
    }
};
