import styled from 'styled-components';
import { StyledRow } from '../GridSystem/styles';
import { BannerToastBackgroundColor, IBannerToastType } from './types';

export const StyledBannerContainer = styled(StyledRow)<{ type: IBannerToastType }>`
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
  align-items: center;
  background-color: ${(p) => BannerToastBackgroundColor[p.type]};
`;

