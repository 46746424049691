import React from 'react';
import { CorrectTable } from './tables/CorrectTable';
import { IncorrectTable } from './tables/IncorrectTable';
import { PartialTable } from './tables/PartialTable';
import { IValidatorSummaryTable } from './types';
import { FreshdeskCorrectTable } from './tables/freshdeskTables/FreshdeskCorrectTable';
import { FreshdeskIncorrectTable } from './tables/freshdeskTables/FreshdeskIncorrectTable';
import { FreshdeskPartialTable } from './tables/freshdeskTables/FreshDeskPartialTable';

const ValidatorSummaryTable: React.FC<IValidatorSummaryTable> = ({
    type,
    endoResult,
    endoEntries,
    correctTableEntries,
    totalLivesCount,
    isFreshdeskTable = false
}) => {
    switch (type) {
    case 'CORRECT':
        return !isFreshdeskTable ? (
            <CorrectTable
                totalLives={totalLivesCount}
                endoEntries={endoEntries}
                correctEntries={correctTableEntries}
            />
        ) : (
            <FreshdeskCorrectTable
                totalLives={totalLivesCount}
                endoEntries={endoEntries}
                correctEntries={correctTableEntries}
            />
        );
    case 'INCORRECT':
        return !isFreshdeskTable ? (
            <IncorrectTable incorrectEntries={endoResult.incorrectTableEntries} totalLives={totalLivesCount} />
        ) : (
            <FreshdeskIncorrectTable
                incorrectEntries={endoResult.incorrectTableEntries}
                totalLives={totalLivesCount}
            />
        );
    case 'PARTIAL':
        return !isFreshdeskTable ? (
            <PartialTable
                correctEntries={correctTableEntries}
                incorrectEntries={endoResult.incorrectTableEntries}
                totalLives={totalLivesCount}
                endoEntries={endoEntries}
            />
        ) : (
            <FreshdeskPartialTable
                correctEntries={correctTableEntries}
                incorrectEntries={endoResult.incorrectTableEntries}
                totalLives={totalLivesCount}
                endoEntries={endoEntries}
            />
        );
    }
};

export default ValidatorSummaryTable;
