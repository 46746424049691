import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { SearchIcon } from '../../../../../assets/img';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 48px;
`;
export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 55px 0 30px;
`;
export const StyledTitle = styled.h3`
  font-size: ${(p) => p.theme.fontSizes.title};
  color: ${(p) => p.theme.colors.black};
  margin: 0;
`;
export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
export const StyledLink = styled.span`
  font-size: ${(p) => p.theme.fontSizes.subHeading};
  color: ${(p) => p.theme.colors.primary};
  text-decoration-line: underline;
  letter-spacing: 0.2px;
  font-weight: 500;
`;
export const StyledIcon = styled.img`
  overflow: hidden;
  height: 12px;
  margin-left: 15px;
`;
export const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const StyledInput = styled.input`
  border: none;
  width: 350px;
  border-radius: 8px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${(p) => p.theme.colors.beyondGrey};
  font-size: ${(p) => p.theme.fontSizes.subHeading};
  padding: 12px 18px 12px 50px;
  background: #F0F0F0 url(${SearchIcon}) no-repeat 20px center;
  background-size: 18px 18px;
`;
export const StyledDownloadContainer = styled.div`
  border-radius: 10px;
  padding: 12px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  background: ${(p) => p.theme.colors.coverUpGreen};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.primary};
`;
export const StyledDownloadImage = styled(SVG)`
  border: 1px solid transparent;
  border-radius: 8px;

  & path {
    stroke: ${(p) => p.theme.colors.primary};
  }
`;
export const StyledTableLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100vw;
  padding: 50px;
`;
