import * as Sentry from '@sentry/react';

export const initializeSentry = (): void => {
    if (process.env.REACT_APP_ENV === 'production' && !!process.env.REACT_APP_SENTRY_URL) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_URL,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 1.0,
            environment: process.env.REACT_APP_ENV,
            // having localhost won't harm in production, it will only support local development
            tracePropagationTargets: ['localhost', process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL as string]
        });
    }
};
