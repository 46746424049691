import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { initializeSegment } from './utils/segment/segment';
import { initializeSentry } from './utils/sentry';

initializeSentry();
initializeSegment();
ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <App />
            </ReduxProvider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
