import React from 'react';
import { StyledForm, StyledErrorContainer } from './styles';
import { IDynamicField, IRadioValue } from './types';
import { isValidDate } from '../../../../utils/common';
import { Form, Typography } from '@loophealth/loop-ui-web-library';

const DynamicField: React.FunctionComponent<IDynamicField> = ({
    field,
    options,
    value,
    formChanges,
    validationError
}) => {
    const [formValue, setFormValue] = React.useState<
        string |
        number |
        Date |
        boolean |
        null |
        IRadioValue
    >(value || null);

    React.useEffect(() => {
        setFormValue(value || null);
    }, [value]);

    React.useEffect(() => {
        if (formValue !== value) {
            formChanges({ [`${field.name}`]: formValue });
        }
    }, [formValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValue(e.target.value);
    };
    const handleSelectChange = (value: string) => {
        setFormValue(value);
    };

    const handleDateChange = (e: Date) => {
        setFormValue(e);
    };

    const handleRadioButtonClick = (e: IRadioValue) => {
        setFormValue(e);
    };

    return (
        <StyledForm>
            <Form.FormField required={field.isMandatory} label={field.label} id={field.label}>
                {field.type === 'input' ? (
                    <Form.Input
                        name="text"
                        placeholder="Enter"
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        value={(formValue as unknown as string) || ''}
                        error={validationError}
                        disabled={field.isDisabled}
                    />
                ) : field.type === 'dropdown' ? (
                    <Form.Dropdown
                        listItems={options || []}
                        onClick={handleSelectChange}
                        placeholder='Select'
                        selectedItem={(formValue as unknown as string) || ''}
                        error={validationError}
                    />
                ) : field.type === 'date' ? (
                    <Form.DatePicker
                        value={isValidDate(formValue) ? (formValue as Date) || new Date() : new Date()}
                        onChange={handleDateChange}
                    />
                ) : (
                    options && <Form.RadioButton
                        radioItems={options.map((option) => ({
                            id: option.value,
                            value: option.name
                        }))}
                        selectedItem={formValue as unknown as IRadioValue}
                        onClick={handleRadioButtonClick}
                    />
                )}
                {validationError && field.type !== 'input' && field.type !== 'dropdown' ? (
                    <StyledErrorContainer>
                        <Typography variant='small' color='error'>
                            {validationError}
                        </Typography>
                    </StyledErrorContainer>
                ) : null}
            </Form.FormField>
        </StyledForm>
    );
};

export default DynamicField;
