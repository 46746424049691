import styled from 'styled-components';

export const StyledButton = styled.button<{ $color: string; $isInverted: boolean }>(
    (p) => `
    padding: 10px;
    border: 1px solid ${p.$color};
    color: ${p.$isInverted ? p.theme.colors.white : p.$color};
    background-color: ${p.$isInverted ? p.$color : p.theme.colors.white};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    display: flex;
    &:hover {
        background-color: ${p.$isInverted ? p.theme.colors.white : p.$color};
        color: ${p.$isInverted ? p.$color : p.theme.colors.white};
    }
`
);
