import { ISlab } from '../../../redux/slices/PolicySlice/types';

export const slabObject: Record<string, ISlab> = {
    gmc: {
        slabId: '',
        preAndPostNatalExpenses: 0,
        maternityBenefitsLimit: '',
        familyDefinition: '',
        corporateBufferAilmentRestriction: '',
        icuRoomRent: '',
        coPayment: '',
        corporateBufferRestriction: '',
        ambulanceCharges: '',
        sumInsured: 0,
        corporateBuffer: 0,
        roomRentLimit: ''
    },
    gpa: {
        slabId: '',
        medicalExpenses: '',
        funeralExpenses: '',
        carriageOfDeadBody: '',
        repatriationOfMortalRemains: '',
        temporaryTotalDisability: '',
        ambulanceCharges: '',
        educationBenefit: '',
        sumInsured: 0,
        familyDefinition: ''
    },
    gtl: {
        slabId: '',
        sumInsured: 0,
        familyDefinition: ''
    },
    covid: {
        slabId: '',
        sumInsured: 0,
        familyDefinition: '',
        ambulanceCharges: ''
    }
};
