import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import {
    deleteCDAccount,
    deleteCDStatement,
    downloadCDStatement,
    ledgerExport,
    uploadCDStatement
} from '../../../../../adapters';
import { ReduxState } from '../../../../../redux';
import { ICDAccount, ICDLedger, ICdAccountPolicyData } from '../../../../../redux/slices/CDAccountSlice/types';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import {
    parseResponse,
    showApiSuccessToast,
    showApiFailureToast,
    uploadSignedFile,
    downloadSignedFile
} from '../../../../../utils/common';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { IToastContext } from '../../../../hooks/useToast';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';

export const fetchStateData = (
    ledgerId: string
): {
    cdAccount: ICDAccount | undefined;
    company: ICompanyData | undefined;
    ledgerInitialState: {
        data: never[];
        loading: boolean;
    };
} => {
    const cdAccount = useSelector(
        (state: ReduxState) =>
            state.cdAccountSlice.cdAccountList.data?.filter((cdAccount) => cdAccount.id === ledgerId)[0]
    );
    const company = useSelector(
        (state: ReduxState) =>
            state.companySlice.companyList.data?.filter((companyDoc) => companyDoc.id === cdAccount?.companyId)[0]
    );
    const ledgerInitialState = {
        data: [],
        loading: true
    };
    return { cdAccount, company, ledgerInitialState };
};

export const navigateBack = (navigate: NavigateFunction): void => {
    navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.CD_ACCOUNTS.route);
};

export const handleDownload = async (
    ledgerId: string,
    email: string,
    toast: IToastContext | null,
    setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
    try {
        setIsDownloading(true);
        const [error, response] = await parseResponse(ledgerExport(ledgerId, email));
        setIsDownloading(false);
        if (response.data.mailStatus) {
            showApiSuccessToast(
                toast,
                'An email has been sent to your registered email address with the requested data'
            );
        } else {
            showApiFailureToast(toast, `There was an error while exporting data - ${error.toString()}`);
        }
    } catch (e: any) {
        setIsDownloading(false);
        showApiFailureToast(toast, `There was an error while exporting data - ${e.toString()}`);
    }
};

export const uploadCDStatementFile = async (cdAccountId: string, fileName: string, body: Blob): Promise<void> => {
    try {
        const [error, response] = await parseResponse(uploadCDStatement(cdAccountId, fileName));
        if (error) throw error;

        const data = response.data;
        await uploadSignedFile(data.signedURL, data.method, body);
    } catch (error) {
        throw error;
    }
};

export const downloadCDStatementFile = async (cdAccountId: string, fileName: string): Promise<void> => {
    try {
        const [error, response] = await parseResponse(downloadCDStatement(cdAccountId));
        if (error) throw error;

        const data = response.data;
        await downloadSignedFile(data.signedURL, data.method, fileName);
    } catch (error) {
        throw error;
    }
};

export const deleteCDStatementFile = async (cdAccountId: string, fileIdentifier: string): Promise<void> => {
    try {
        const [error] = await parseResponse(deleteCDStatement(cdAccountId, fileIdentifier));
        if (error) throw error;
    } catch (error) {
        throw error;
    }
};

export const deleteCDAccountById = async (cdAccountId: string): Promise<void> => {
    try {
        const [error] = await parseResponse(deleteCDAccount(cdAccountId));
        if (error) throw error;
    } catch (error) {
        throw error;
    }
};

export const getPolicyFilters = (
    linkedPolicies: ICdAccountPolicyData[] | undefined, ledgerEntires: ICDLedger[])
: IDropdownItem[] => {
    if (!linkedPolicies) return [];
    const types = new Set(linkedPolicies.map((entry) => entry.policyType));
    const entries = new Set(ledgerEntires.map((entry) => entry.policyType));
    return Array.from(entries).filter((entry) => types.has(entry) || entry === '')
        .map((type) => ({ name: type || 'Other', value: type }))
        .sort((a, b) => a.name.localeCompare(b.name));
};
