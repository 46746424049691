import styled from 'styled-components';

export const StyledValidatorSummaryTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const StyledSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledEmptySpace = styled.div`
  display: inline-block;
`;
