import { FirebaseAuth } from '../../adapters/provider';
import { IToken } from './types';
const useToken = (): IToken => {
    const generateToken = async (): Promise<string> => {
        return await new Promise((resolve, reject) => {
            FirebaseAuth.onAuthStateChanged(async (user) => {
                if (user) {
                    const token = await user.getIdToken();
                    resolve(token);
                }
                reject(new Error('Unable to generate token!'));
            });
        });
    };

    const tokenIsExpired = (message: string): boolean => {
        if (message === 'Failed to verify user') {
            return true;
        }
        return false;
    };

    return {
        generateToken,
        tokenIsExpired
    };
};

export default useToken;
