import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {
    fetchAllEndorsementExceptions,
    fetchCompletedEndorsementList,
    fetchPendingEndorsementList
} from './thunk';

const endorsementSlice = createSlice({
    name: 'endorsementSlice',
    initialState,
    reducers: {
        updateCurrentEndorsementExceptions(state, action) {
            state.currentEndorsementExceptions.data = action.payload;
        },
        resetState(state) {
            state.pendingEndorsements = initialState.pendingEndorsements;
            state.completedEndorsements = initialState.completedEndorsements;
        }
    },
    extraReducers: {
        [fetchPendingEndorsementList.pending as any]: (state) => {
            state.pendingEndorsements.loading = true;
        },
        [fetchPendingEndorsementList.rejected as any]: (state, { error }) => {
            state.pendingEndorsements.error = error || 'There was an error processing your request';
            state.pendingEndorsements.loading = false;
            state.pendingEndorsements.data = null;
        },
        [fetchPendingEndorsementList.fulfilled as any]: (state, { payload }) => {
            state.pendingEndorsements.error = null;
            state.pendingEndorsements.loading = false;
            state.pendingEndorsements.data = payload;
        },
        [fetchCompletedEndorsementList.pending as any]: (state) => {
            state.completedEndorsements.loading = true;
        },
        [fetchCompletedEndorsementList.rejected as any]: (state, { error }) => {
            state.completedEndorsements.error = error || 'There was an error processing your request';
            state.completedEndorsements.loading = false;
            state.completedEndorsements.data = null;
        },
        [fetchCompletedEndorsementList.fulfilled as any]: (state, { payload }) => {
            state.completedEndorsements.error = null;
            state.completedEndorsements.loading = false;
            state.completedEndorsements.data = payload;
        },

        [fetchAllEndorsementExceptions.pending as any]: (state) => {
            state.allExceptions.loading = true;
        },
        [fetchAllEndorsementExceptions.rejected as any]: (state, { error }) => {
            state.allExceptions.error = error || 'There was an error processing your request';
            state.allExceptions.loading = false;
            state.allExceptions.data = [];
        },
        [fetchAllEndorsementExceptions.fulfilled as any]: (state, { payload }) => {
            state.allExceptions.error = null;
            state.allExceptions.loading = false;
            state.allExceptions.data = payload || [];
        },
        'common/cleanup': (state) => {
            state.pendingEndorsements = initialState.pendingEndorsements;
            state.completedEndorsements = initialState.completedEndorsements;
        }
    }
});
export default endorsementSlice.reducer;
export const { resetState, updateCurrentEndorsementExceptions } = endorsementSlice.actions;
