import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchChangeRequestsAPI,
    fetchCompanyWiseChangeRequestStats,
    postBulkMoveCRToNextState
} from '../../../adapters';
import {
    IChangeRequestOptions,
    ICompanyWiseCRStat,
    IcrListItem,
    IOperationTypeAPI,
    IOperationType
} from './types';

const CHANGE_REQUESTS_SLICE_ACTION_TYPES = {
    FETCH_CHANGE_REQUESTS: 'FETCH_CHANGE_REQUESTS',
    FETCH_ALL_CHANGE_REQUESTS: 'FETCH_ALL_CHANGE_REQUESTS',
    COMPANY_WISE_CHANGE_REQUESTS_STATS: 'COMPANY_WISE_CHANGE_REQUESTS_STATS',
    BULK_MOVE_CR_TO_NEXT_STATE: 'BULK_MOVE_CR_TO_NEXT_STATE'
};

const CHANGE_REQUESTS_UI_OPERATION_TYPE_MAP = {
    CREATE: 'ADD',
    READ: 'EDIT',
    UPDATE: 'EDIT',
    DELETE: 'DELETE',
    BULK_CREATE: 'ADD',
    BULK_UPDATE: 'EDIT'
};
export const convertUiOperationType = (op: IOperationTypeAPI): IOperationType =>
    CHANGE_REQUESTS_UI_OPERATION_TYPE_MAP[op] as IOperationType;

const CHANGE_REQUESTS_BE_OPERATION_TYPE_MAP = {
    ADD: ['BULK_CREATE', 'CREATE'],
    EDIT: ['READ', 'UPDATE', 'BULK_UPDATE'],
    DELETE: ['DELETE']
};
export const convertBeOperationType = (op: IOperationType): IOperationTypeAPI[] =>
    CHANGE_REQUESTS_BE_OPERATION_TYPE_MAP[op] as IOperationTypeAPI[];

const transformChangeRequests = (changeRequests: any[]) => (
    changeRequests.map((c) => ({
        ...c,
        operationType: convertUiOperationType(c.operationType)
    }))
);
export const fetchChangeRequests = createAsyncThunk(
    CHANGE_REQUESTS_SLICE_ACTION_TYPES.FETCH_CHANGE_REQUESTS,
    async (filterChangeRequestOptions: IChangeRequestOptions) => {
        const { pageSize=10, nextPageId, prevPageId, pageNo, ...rest } = filterChangeRequestOptions;
        const newPageSize = !!nextPageId || !!prevPageId || pageNo === 0 ? (pageSize+1) : pageSize;
        try {
            const data:any = await fetchChangeRequestsAPI({
                pageSize: newPageSize, nextPageId, prevPageId, ...rest
            });
            return { data: transformChangeRequests(data?.changeRequests) };
        } catch (e) {
            throw e;
        }
    }
);

export const fetchAllChangeRequests = createAsyncThunk(
    CHANGE_REQUESTS_SLICE_ACTION_TYPES.FETCH_ALL_CHANGE_REQUESTS,
    async (filterChangeRequestOptions: IChangeRequestOptions) => {
        try {
            const data:any = await fetchChangeRequestsAPI(filterChangeRequestOptions);
            return { data: transformChangeRequests(data?.changeRequests) };
        } catch (e) {
            throw e;
        }
    }
);

const transformCompanyWiseCRStats = (data: any) =>
    data?.data?.map((stat: any) => ({
        ...stat,
        status: stat.activeCrCount > 0 ? 'Active' : 'Closed'
    })) as ICompanyWiseCRStat[];
export const getCompanyWiseChangeRequestStats = createAsyncThunk(
    CHANGE_REQUESTS_SLICE_ACTION_TYPES.COMPANY_WISE_CHANGE_REQUESTS_STATS,
    async () => {
        try {
            const data = await fetchCompanyWiseChangeRequestStats();
            return transformCompanyWiseCRStats(data);
        } catch (e) {
            throw e;
        }
    }
);

export const bulkMoveCRToNextState = createAsyncThunk(
    CHANGE_REQUESTS_SLICE_ACTION_TYPES.BULK_MOVE_CR_TO_NEXT_STATE,
    async (crList: IcrListItem[]) => {
        try {
            const data = await postBulkMoveCRToNextState(crList);
            return { data, crList };
        } catch (e) {
            throw e;
        }
    }
);
