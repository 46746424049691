import React from 'react';
import { StyledCard, StyledFormGroup, StyledFormLabel, StyledError, StyledErrorText, StyledFormField } from './styles';
import { IFormLabel } from './types';
import DynamicField from './DynamicField';

export const Card: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
    return <StyledCard>{children}</StyledCard>;
};

export const FormGroup: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
    return <StyledFormGroup>{children}</StyledFormGroup>;
};

export const FormLabel: React.FunctionComponent<IFormLabel> = ({ children, isMandatory = false }) => {
    return (
        <StyledFormLabel>
            {children}
            {(isMandatory && <StyledError>*</StyledError>) || <></>}
        </StyledFormLabel>
    );
};
export const FormError: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => (
    <StyledErrorText>{children}</StyledErrorText>
);

export const FormValue: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
    return <StyledFormField>{children}</StyledFormField>;
};

export { DynamicField };
