import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { deleteEndorsement } from '../../../adapters';
import { parseResponse, showApiFailureToast } from '../../../utils/common';
import { useToast } from '../../hooks/useToast';
import LoopModal from '../../atoms/LoopModal';
import DeleteEndorsementSuccessState from './DeleteEndorsementSuccess';
import { StyledButtonRow, StyledModalWrapper, StyledRow, StyledRowElement } from './styles';
import { DeleteEndorsementProps, DeleteEndorsementSuccessStateProps } from './types';

const DeleteEndorsement: React.FunctionComponent<DeleteEndorsementProps> = ({
    isVisible,
    onClose,
    companyName,
    endoCycle,
    insurer,
    noOfLives,
    id
}) => {
    const [isSuccessStateVisible, setSuccessStateVisible] = React.useState(false);
    const [successStateProps, setSuccessStateProps] = React.useState<DeleteEndorsementSuccessStateProps | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const toast = useToast();
    const handleOnDeleteEndo = async () => {
        setIsLoading(true);
        const [error, success] = await parseResponse(deleteEndorsement(id));
        setIsLoading(false);
        if (error) {
            showApiFailureToast(toast, error.toString());
            return;
        }

        const successStateProps = {
            memberCount: success.data.memberCount as number,
            endoCycle: success.data.endorsement.endorsementCycle as string,
            insurer,
            companyName
        };

        setSuccessStateProps(successStateProps as DeleteEndorsementSuccessStateProps);
        onClose(false);
        setSuccessStateVisible(true);
    };
    return (
        <>
            <LoopModal
                isVisible={isVisible}
                onClose={() => onClose(false)}
                title={'Deleting Endo'}
                subtitle={'Are you sure you want to delete this endo?'}
            >
                <StyledModalWrapper>
                    <StyledRow>
                        <StyledRowElement>
                            <Typography variant="extraSmall" color="secondary">
                                Company
                            </Typography>
                            <Typography variant="small" weight="medium" color="primary">
                                {companyName}
                            </Typography>
                        </StyledRowElement>
                    </StyledRow>
                    <StyledRow>
                        <StyledRowElement>
                            <Typography variant="extraSmall" color="secondary">
                                Endo cycle
                            </Typography>
                            <Typography variant="small" weight="medium" color="primary">
                                {endoCycle}
                            </Typography>
                        </StyledRowElement>
                        <StyledRowElement>
                            <Typography variant="extraSmall" color="secondary">
                                No. of Lives
                            </Typography>
                            <Typography variant="small" weight="medium" color="primary">
                                {noOfLives}
                            </Typography>
                        </StyledRowElement>
                    </StyledRow>
                    <StyledRow>
                        <StyledRowElement>
                            <Typography variant="extraSmall" color="secondary">
                                Insurer
                            </Typography>
                            <Typography variant="small" weight="medium" color="primary">
                                {insurer}
                            </Typography>
                        </StyledRowElement>
                    </StyledRow>
                </StyledModalWrapper>
                <StyledButtonRow>
                    <LoopButton variant="secondary" onClick={() => onClose(false)}>
                        <Typography variant="medium" color="emerald" weight="medium">
                            Cancel
                        </Typography>
                    </LoopButton>
                    <LoopButton variant="error" onClick={handleOnDeleteEndo} isLoading={isLoading}>
                        <Typography variant="medium" color="tertiary" weight="medium">
                            Delete
                        </Typography>
                    </LoopButton>
                </StyledButtonRow>
            </LoopModal>
            <DeleteEndorsementSuccessState
                {...successStateProps}
                isVisible={isSuccessStateVisible}
                onClose={setSuccessStateVisible}
            />
        </>
    );
};

export default DeleteEndorsement;
