import React, { ChangeEvent, useEffect, useState } from 'react';
import { StyledButtonRow, StyledModalContainer, StyledRowAlignment } from './styles';
import { FormError, Loader } from '../../atoms';
import { StyledError } from '../../atoms/Forms/styles';
import { initialFormState, validateData } from './utils';
import { CDLedgerEntryFormInput, ICDBalanceEntryProps } from './types';
import { Form, LoopButton as Button } from '@loophealth/loop-ui-web-library';
import { MAX_PREMIUM_AMOUNT } from './index';

const AdjustCDLedgerEntryComponent: React.FunctionComponent<ICDBalanceEntryProps> = ({
    isLoading,
    apiError,
    onAddEntry
}) => {
    const initialState = initialFormState('adjustment');
    const [cdLedgerEntryObject, setCdLedgerEntryObject] = React.useState<CDLedgerEntryFormInput>(initialState);
    const [isFormDataValid, setFormDataValid] = useState(false);

    useEffect(() => {
        const isAddCDEntryObjValid =
            cdLedgerEntryObject.premium.data && parseFloat(cdLedgerEntryObject.premium.data) > 0 &&
            cdLedgerEntryObject.notes.data &&
            cdLedgerEntryObject.transactionType.data;
        setFormDataValid(!!isAddCDEntryObjValid);
    }, [cdLedgerEntryObject]);

    const onDateSelect = async (date: Date) => {
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, date: { data: date, error: '' } });
    };
    const onTransactionTypeChanged = (type: string) => {
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, transactionType: { data: type, error: '' } });
    };
    const onAmountChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, premium: { data: event.target.value, error: '' } });
    };
    const onNotesTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setCdLedgerEntryObject({ ...cdLedgerEntryObject, notes: { data: event.target.value, error: '' } });
    };

    const onCreate = async () => {
        const validatedData = validateData(
            { ...cdLedgerEntryObject } as Record<string, unknown>,
            setCdLedgerEntryObject
        );
        /** If even a single field has an error return the function */
        if (
            Object.keys(validatedData).filter((key) => (validatedData[key] as { data: any; error: string })?.error)
                .length
        ) {
            return;
        }
        delete cdLedgerEntryObject.paymentProof;
        onAddEntry(cdLedgerEntryObject);
    };
    return (
        <StyledModalContainer>
            <StyledRowAlignment>
                <Form.FormField id="entry-date" label="Entry Date" required={true}>
                    <Form.DatePicker value={cdLedgerEntryObject.date.data} onChange={onDateSelect} />
                </Form.FormField>
                <Form.FormField id={'transaction-type'} label="Transaction Type" required={true}>
                    <Form.Dropdown
                        listItems={[
                            { name: 'Debit', value: 'debit' },
                            { name: 'Credit', value: 'credit' }
                        ]}
                        selectedItem={cdLedgerEntryObject.transactionType.data}
                        placeholder="Select Here"
                        onClick={onTransactionTypeChanged}
                    />
                </Form.FormField>
            </StyledRowAlignment>
            <StyledRowAlignment>
                <Form.FormField id="premium" label="Amount added (₹)" required={true}>
                    <Form.AmountInput max={MAX_PREMIUM_AMOUNT}
                        value={cdLedgerEntryObject.premium.data}
                        placeholder="Enter Here"
                        onChange={onAmountChanged}
                        error={cdLedgerEntryObject.premium.error}
                    />
                </Form.FormField>

                <Form.FormField id="notes" label="Notes" description="Why is this payment being added" required={true}>
                    <Form.Input
                        value={cdLedgerEntryObject.notes.data}
                        placeholder="Enter Here"
                        onChange={onNotesTextChanged}
                        error={cdLedgerEntryObject.notes.error}
                    />
                </Form.FormField>
            </StyledRowAlignment>
            {apiError && (
                <StyledError>
                    <FormError>{apiError}</FormError>
                </StyledError>
            )}
            <StyledButtonRow>
                <Button variant={isFormDataValid ? 'filled' : 'disabled'} onClick={onCreate}>
                    {isLoading ? <Loader /> : 'Add entry'}
                </Button>
            </StyledButtonRow>
        </StyledModalContainer>
    );
};
export default AdjustCDLedgerEntryComponent;
