import { Loader, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo } from 'react';
import {
    StyledApprovalImageShareIcon,
    StyledApprovalModalBody,
    StyledApprovalModalCloseIconContainer,
    StyledApprovalModalContent,
    StyledApprovalModalFooter,
    StyledApprovalModalHeader,
    StyledApprovalModalPrimaryImage,
    StyledApprovalModalPrimaryImageContainer
} from './styles';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { CrossIcon } from '../../../../assets/icons/CrossIcon';
import { ExportIcon } from '../../../../assets/icons/ExportIcon';
import { IEAModalProps } from '../types';
import { getSignedBlob } from '../../../../utils/constants/Endorsement';
import { updateDocumentVerificationStatus } from '../../../../adapters';
import SingleDocumentCancelModal from './SingleDocumentCancelModal';

const SingleDocumentApprovalModal: React.FunctionComponent<IEAModalProps> = ({
    endorsementId,
    kycMember,
    setKYCMember,
    updateMemberDetails
}) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = React.useState(false);
    const [file, setFile] = React.useState<Blob|null>();
    const toast = Toast.useToast();

    useEffect(() => {
        if (kycMember) {
            setIsVisible(true);
            setIsLoading(true);
            try {
                getSignedBlob(kycMember.kycDocuments[0]).then((file) => {
                    setFile(file);
                    setIsLoading(false);
                });
            } catch (e) {
                toast?.error((e as Error).message);
                setIsVisible(false);
            }
        } else {
            setIsVisible(false);
        }
    }, [kycMember]);

    const fileUrl = useMemo(() => {
        return file ? URL.createObjectURL(file) : '';
    }, [file]);

    const memberName = useMemo(() => {
        return kycMember?.firstName ? `${kycMember.firstName} ${kycMember.lastName}` : kycMember?.lastName || '-';
    }, [kycMember]);

    const closeModal = () => {
        setKYCMember(undefined);
    };

    const onSubmit = async () => {
        const updatedMemberData = { ...kycMember };
        setIsLoading(true);
        try {
            await updateDocumentVerificationStatus(kycMember.userId, endorsementId, true);
            toast?.success(`“${kycMember.firstName}” has been successfully verified.`);
            updatedMemberData.isKYCDocumentVerified = true;
        } catch (e) {
            toast?.error((e as Error).message);
            setIsLoading(false);
            return;
        }
        updateMemberDetails(updatedMemberData);
        setKYCMember(undefined);
        setIsLoading(false);
    };


    const handleReject = async () => {
        setIsLoading(true);
        try {
            await updateDocumentVerificationStatus(kycMember.userId, endorsementId, false);
            toast?.success(`“${kycMember.firstName}” has been successfully deleted from the endorsement.`);
            updateMemberDetails(kycMember, true);
        } catch (e) {
            toast?.error((e as Error).message);
            return;
        } finally {
            setIsLoading(false);
        }
        setKYCMember(undefined);
    };


    const openImage = () => {
        window.open(fileUrl, '_blank');
    };

    return (
        <WithThemeProvider>
            <SingleDocumentCancelModal
                isVisible={isCancelModalVisible}
                setIsVisible={setIsCancelModalVisible}
                onSubmit={handleReject}
            />
            <Modal
                isModalClosable={false}
                disablePadding
                isVisible={isVisible && !isCancelModalVisible}
                setIsVisible={closeModal}
            >
                <StyledApprovalModalContent>
                    <StyledApprovalModalHeader>
                        <Typography variant='medium' weight='semiBold'>{memberName}&apos;s KYC Document</Typography>
                        <StyledApprovalModalCloseIconContainer onClick={closeModal}>
                            <CrossIcon strokeWidth={0.5}/>
                        </StyledApprovalModalCloseIconContainer>
                    </StyledApprovalModalHeader>
                    <StyledApprovalModalBody>
                        <StyledApprovalModalPrimaryImageContainer>
                            {
                                isLoading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {file?.type?.includes('application/pdf') ? (
                                            <iframe
                                                src={fileUrl+'#toolbar=0&navpanes=0'}
                                                width="100%"
                                                height="100%"
                                                style={{ border: '-1px' }}
                                            />
                                        ) : (
                                            <StyledApprovalModalPrimaryImage src={fileUrl} alt="kyc-document" />
                                        )}
                                        <StyledApprovalImageShareIcon onClick={openImage}>
                                            <ExportIcon />
                                        </StyledApprovalImageShareIcon>
                                    </>
                                )
                            }
                        </StyledApprovalModalPrimaryImageContainer>
                    </StyledApprovalModalBody>
                    <StyledApprovalModalFooter>
                        <LoopButton
                            variant='outline'
                            textColor="error"
                            fontWeight="semiBold"
                            onClick={() => setIsCancelModalVisible(true)}
                        >
                            Reject
                        </LoopButton>
                        <LoopButton
                            variant='filled'
                            fontWeight="semiBold"
                            onClick={onSubmit}
                        >
                            Accept
                        </LoopButton>
                    </StyledApprovalModalFooter>
                </StyledApprovalModalContent>
            </Modal>
        </WithThemeProvider>
    );
};

export default SingleDocumentApprovalModal;
