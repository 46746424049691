import styled from 'styled-components';
export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 20px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyledSpacing = styled.div`
    padding: 20px;
`;

export const StyledCompanyName = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.title};
    font-weight: bold;
    text-transform: uppercase;
    text-align: center; 
    margin: auto 0;
`
);

export const StyledZeroState = styled.div(
    (p) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    align-items: center;
    color: ${p.theme.colors.muted};
`
);

export const StyledAddPolicyAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 25px;
    margin-bottom: 10px;
    flex-wrap: wrap;
`;
