import styled, { css, keyframes } from 'styled-components';

const SlideInAnimate = keyframes`
    0% { left: -300px; }
    100% { left: 0; }
`;
const SlideOutAnimate = keyframes`
    0%  { left: 0px; }
    100% { left: -300px; }
`;
export const StyledContainer = styled.div<{ $startLeaveAnimation: boolean }>(
    (p) => css`
        z-index: 1000;
        position: absolute;
        height: 100vh;
        width: 300px;
        min-width: 250px;
        background-color: ${p.theme.colors.white};
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: auto;
        -webkit-animation: ${SlideInAnimate} 0.5s forwards;
        animation: ${SlideInAnimate} 0.5s forwards;
        ${(p.$startLeaveAnimation &&
            css`
                -webkit-animation: ${SlideOutAnimate} 0.5s forwards;
                animation: ${SlideOutAnimate} 0.5s forwards;
            `) ||
        ''}
    `
);
export const StyledImage = styled.img`
    max-width: 140px;
    height: auto;
    cursor: pointer;
    margin: 25px;
`;

export const NavigationItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 35px 0px;
    max-width: 230px;
`;
