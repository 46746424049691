import styled from 'styled-components';

export const StyledPolicyUploadContainer = styled.section`
    width: 550px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const StyledCloseButton = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 0;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary};
`;

export const StyledCloseIcon = styled.img`
    width: 100%;
    height: 100%;
`;

export const StyledPolicyDocumentInputContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledFileUploadInput = styled.div`
    height: 175px;
`;

export const StyledFileInputHelper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledUpdateButton = styled.div`
    width: fit-content;
    margin-left: auto;
`;

export const StyledFileLimitAlert = styled.div`
    gap: 12px;
    padding: 13px 16px;
    border-radius: 8px;
    background-color: ${(p: any) => p.theme.colors.hover.surfaceOne};
`;

export const StyledErrorContainer = styled.div`
    display: flex;
    align-items: start;
    gap: 8px;
    padding: 16px 32px 16px 24px;
    border-radius: 10px;
    background-color: ${(p: any) => p.theme.colors.surface.fillRed}1A;

    & > span {
        margin-top: 2px;
    }
`;
