import React from 'react';
import { createPortal } from 'react-dom';
import { StyledButton } from './styles';
import { IFloatingPlusButton } from './types';

const FloatingPlusButton: React.FunctionComponent<IFloatingPlusButton> = ({ onClick }) => {
    const buttonRef = React.useRef<HTMLDivElement | null>(null);
    const [invokeRender, setInvokeRender] = React.useState(false);
    React.useEffect(() => {
        if (!buttonRef.current) {
            buttonRef.current = document.createElement('div');
        }
        if (buttonRef.current) {
            // create a root node
            let rootElem = document.getElementById('button-plus-root-loop-health');
            if (!rootElem) {
                rootElem= document.createElement('div');
                rootElem.setAttribute('id', 'button-plus-root-loop-health');
            }
            // inserts rootElem as last element of body
            if (document.body.lastElementChild) {
                document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
            }
            // adds the modal reference to the root
            rootElem.appendChild(buttonRef.current);
            setInvokeRender(true);
        }
        return () => {
            document.getElementById('button-plus-root-loop-health')?.remove();
        };
    }, []);
    return (
        buttonRef.current &&
        invokeRender &&
        createPortal(<StyledButton onClick={onClick}>+</StyledButton>, buttonRef.current) || <></>
    );
};

export default FloatingPlusButton;
