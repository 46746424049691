import React from 'react';
import { AddCalendarDisabledIcon, MessageDisabledIcon } from '../../../assets/img';
import { Card, CardBody, Container, ButtonContainer } from './styles';
import { Typography, LoopButton } from '@loophealth/loop-ui-web-library';
import { IExtendEnrolmentConfigProps } from './types';

const configObject = (
    handleExtendEnrolmentConfig: () => void,
    handleSendEmail: () => void,
    completed: boolean | undefined,
    isCompletedTwoDaysAgo: boolean
) => [
    {
        id: 1,
        icon: AddCalendarDisabledIcon,
        title: 'Extend Enrolment',
        description: 'Change the due date to extend this enrolment',
        onClick: handleExtendEnrolmentConfig,
        buttonText: 'Extend Enrolment',
        isEnabled: true
    },
    {
        id: 2,
        icon: MessageDisabledIcon,
        title: 'Send confirmation Emails',
        description: 'Send confirmation emails to complete enrolment',
        onClick: handleSendEmail,
        buttonText: 'Send confirmation Emails',
        isEnabled: completed && !isCompletedTwoDaysAgo
    }
];

const ActivePolicies: React.FC<IExtendEnrolmentConfigProps> = ({
    handleExtendEnrolmentConfig,
    handleSendEmail,
    completed,
    isCompletedTwoDaysAgo
}) => {
    const configData = configObject(handleExtendEnrolmentConfig, handleSendEmail, completed, isCompletedTwoDaysAgo);
    return (
        <Container>
            {configData.map(
                (config) =>
                    config.isEnabled && (
                        <Card key={config.id}>
                            <img src={config.icon} height={'32px'} width={'32px'} />
                            <CardBody>
                                <Typography variant="small" color="secondary" weight="semiBold">
                                    {config.title}
                                </Typography>
                                <Typography variant="small" color="secondary" weight="regular">
                                    {config.description}
                                </Typography>
                            </CardBody>
                            <ButtonContainer>
                                <LoopButton
                                    onClick={config.onClick}
                                    variant="outline"
                                    textColor="emerald"
                                    borderColor="#025F4C"
                                >
                                    {config.buttonText}
                                </LoopButton>
                            </ButtonContainer>
                        </Card>
                    )
            )}
        </Container>
    );
};

export default ActivePolicies;
