import styled from 'styled-components';
export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;

export const StyledCompanyIdentiyContainer = styled.div`
    margin: 10px 10px;
`;

export const StyledCompanyAllignmentContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    height: 50%;
`;

export const StyledSpacing = styled.div`
    padding: 20px;
`;
export const StyledCompanyNameRow = styled.div`
    width: 100%;
    display: flex;
    margin: 10px 10px;
`;
export const StyledCompanyName = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    font-weight: bold;
    text-transform: uppercase;
`
);
export const StyledCompanyOverview = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 20px 0px;
`;

export const StyledMetaContainer = styled.div<{ $isPlacementForm: boolean }>`
    ${(p) =>
        p.$isPlacementForm
            ? `display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 0px;`
            : `
    max-width: 33%;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;`};

    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 5px 0px;
    }
`;

export const StyledKey = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
`;

export const StyledValue = styled.div<{ $isPlacementForm: boolean }>(
    (p) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: ${p.$isPlacementForm ? `0px 10px` : `5px 0px`};
    color: ${p.theme.colors.muted};
    @media (max-width: 768px) {
        margin: 0px 20px;
        align-items: flex-start;
    }
`
);
export const StyledPillText = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};

`
);

export const StyledAddPolicyAllignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 10.5%;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
