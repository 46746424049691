import { combineReducers } from 'redux';
import {
    ApplicationStateSlice,
    CompanySlice,
    UserPoliciesSlice,
    ChangeRequestSlice,
    CDAccountSlice,
    endorsementSlice,
    ValidatorSlice
} from '../slices';
import { UserSlice } from '../../components/pages/LoginContainer/redux/slices';
import { PolicySlice } from '../slices';
const rootReducer = combineReducers({
    applicationState: ApplicationStateSlice,
    companySlice: CompanySlice,
    user: UserSlice,
    policySlice: PolicySlice,
    userPoliciesSlice: UserPoliciesSlice,
    changeRequestSlice: ChangeRequestSlice,
    cdAccountSlice: CDAccountSlice,
    endorsementSlice,
    validatorSlice: ValidatorSlice
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
