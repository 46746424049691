import moment from 'moment/moment';
import { IFirebaseTimestamp } from '../redux/types';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_DD_MMM_YYY = 'DD/MMM/YYYY';
export const DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';

export const formatDate = (seconds: number, dateFormat?: string): string => {
    return moment(seconds * 1000).format(dateFormat || DEFAULT_DATE_FORMAT);
};

export const formatDateString = (dateString: string, dateFormat?: string): string => {
    return moment(new Date(dateString)).format(dateFormat || DEFAULT_DATE_FORMAT);
};

export const convertDateToIST = (date: string): string => {
    return new Date(date)
        .toLocaleString(undefined, {
            timeZone: 'Asia/Kolkata'
        })
        .split(',')[0];
};

export const convertFirebaseTimestampToDate = (date: IFirebaseTimestamp): Date | null => {
    const timestamp = date?._seconds;
    if (timestamp) {
        return new Date(timestamp * 1000);
    }
    return null;
};
