import { IExcelCellProps, IExcelDownloadFormat, IUserAttributeNames, KeyErrorNameMap } from './types';

const addOrEditFormattor = (errorData: Record<string, unknown>[]): IExcelDownloadFormat => {
    const formattedData: IExcelDownloadFormat = [];
    errorData.map((v: Record<string, unknown>) => {
        const errors = JSON.parse(v.errors as string);
        const errorKeys = Object.keys(errors);
        if (!errorKeys.length) {
            return;
        }
        const keysWithErrors: IUserAttributeNames[] = [];
        const errorStr = errorKeys.reduce((str, k) => {
            const key = KeyErrorNameMap[k];
            const value = errors[k];
            if (key !== 'Global' && key !== 'MidTerm') keysWithErrors.push(key);
            return str + `${key} - ${value} \n`;
        }, '');
        const cellProps: Record<string, IExcelCellProps> = {};
        keysWithErrors.map((k) => {
            cellProps[k] = {
                cellColour: 'DA9694'
            };
        });
        cellProps['Errors'] = {
            fontColour: 'FF0000'
        };
        formattedData.push({
            data: {
                'User ID': v.userId,
                'Employee ID': v.employee_id,
                'Relationship to Account Holder': v.relationship_to_account_holders,
                'Name': v.name,
                'Slab ID': v.slabId,
                'Mobile': v.mobile,
                'Email Address': v.email_address,
                'Gender': v.gender,
                'CTC': v.ctc,
                'Enrolment Due Date': v.enrolment_due_date,
                'Date of Birth': v.date_of_birth,
                'Coverage Start Date': v.policy_start_date,
                'Exception': v.isException,
                'Errors': errorStr || ''
            },
            props: cellProps
        });
    });
    return formattedData;
};


const offboardFormattor = (
    errors: Record<string, unknown>[]
): { data: Record<string, unknown>; props: Record<string, IExcelCellProps> }[] => {
    const colNameMap: Record<string, string> = {
        userId: 'User ID',
        relationship: 'Relationship to Account Holder',
        dateOfLeaving: 'Date of Leaving (DD/MMM/YYYY)',
        error: 'Errors',
        global: 'Global'
    };
    const formattedErrors: { data: Record<string, unknown>; props: Record<string, IExcelCellProps> }[] = [];
    errors.map((v) => {
        const errors = JSON.parse(v.error as string);
        const errorKeys = Object.keys(errors);
        const keys = Object.keys(v);
        const newObj: Record<string, unknown> = {};
        keys.map((k) => {
            newObj[colNameMap[k]] = v[k];
        });
        const keysWithErrors: string[] = [];
        const errorStr = errorKeys.reduce((str, k) => {
            const key = colNameMap[k];
            const value = errors[k];
            if (key !== 'Global') keysWithErrors.push(key);
            return str + `${key} - ${value} \n`;
        }, '');
        newObj['Errors'] = errorStr;
        const cellProps: Record<string, IExcelCellProps> = {};
        keysWithErrors.map((k) => {
            cellProps[k] = {
                cellColour: 'DA9694'
            };
        });
        cellProps['Errors'] = {
            fontColour: 'DA9694'
        };
        formattedErrors.push({
            data: newObj,
            props: cellProps
        });
    });
    return formattedErrors;
};

export default {
    add: addOrEditFormattor,
    edit: addOrEditFormattor,
    offboard: offboardFormattor
};
