import styled from 'styled-components';

export const Label = styled.label`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.beyondGrey};
    order: 0;
    flex-grow: 0;
    margin-bottom: 10px;
`;

export const MandatoryIcon = styled.span`
    margin-left: 4px;
    color: red;
`;

export const UploadPillContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
