import { LoopButton, Modal, Shadow, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useRef } from 'react';
import { DeleteErrorIcon, MoreVerticalIcon } from '../../../assets/img';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    StyledDeleteCdAccountMenu,
    StyledDelinkLogo,
    StyledDelinkModalCTA,
    StyledDelinkModalContainer,
    StyledDelinkModalHeader,
    StyledDelinkModalHeaderText,
    StyledMenu,
    StyledMenuContainer,
    StyledMenuItem
} from './styles';
import { IDeleteCdAccountMenu } from './types';
import { useDispatch } from 'react-redux';
import { fetchCDAccountList } from '../../../redux/slices/CDAccountSlice/thunk';
import { useNavigate } from 'react-router-dom';
import { deleteCDAccountById } from '../../pages/SPAContainer/pages/CDLedger/utils';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';

const DeleteCdAccountMenu: React.FC<IDeleteCdAccountMenu> = ({ cdAccount }) => {
    const toast = Toast.useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [isCdAccountDeleting, toggleCdAccountDeleting] = React.useState(false);
    const toggleMenuOpen = () => setMenuOpen((prev) => !prev);

    // Listener to close the menu drawer when clicked outside of the drawer
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isMenuOpen]);

    const handleModalOpen = () => {
        setModalOpen(true);
        toggleMenuOpen();
    };

    const handleModalClose = () => !isCdAccountDeleting && setModalOpen(false);

    const onDelinkFromPoliciesClick = async () => {
        if (isCdAccountDeleting) return;
        toggleCdAccountDeleting(true);
        await deleteCDAccountById(cdAccount.id);
        toast?.success(`The CD account has been delinked from its policies and we've emailed the data we cleared to 
        product-engineeringescalations@loophealth.com.`);
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.CD_ACCOUNTS.route);
        dispatch(fetchCDAccountList());
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && isMenuOpen && !menuRef.current.contains(event.target as Node)) {
            toggleMenuOpen();
        }
    };

    return (
        <StyledDeleteCdAccountMenu>
            <LoopButton variant="outline" onClick={toggleMenuOpen} iconSrc={MoreVerticalIcon} />
            {isMenuOpen && (
                <StyledMenuContainer ref={menuRef}>
                    <Shadow variant="small">
                        <WithThemeProvider>
                            <StyledMenu>
                                <StyledMenuItem onClick={handleModalOpen}>
                                    <img src={DeleteErrorIcon} />
                                    <Typography variant="small" weight="medium" color="error">
                                        Delink & Delete CD account
                                    </Typography>
                                </StyledMenuItem>
                            </StyledMenu>
                        </WithThemeProvider>
                    </Shadow>
                </StyledMenuContainer>
            )}
            <Modal isVisible={modalOpen} setIsVisible={handleModalClose}>
                <WithThemeProvider>
                    <StyledDelinkModalContainer>
                        <StyledDelinkModalHeader>
                            <StyledDelinkLogo src={DeleteErrorIcon} />
                            <StyledDelinkModalHeaderText>
                                <Typography variant="large" weight="semiBold">
                                    Delink & Delete CD Account!
                                </Typography>
                                <Typography variant="small" color="secondary">
                                    CD account will get delink from all the policies it is linked to and will also erase
                                    its data
                                </Typography>
                            </StyledDelinkModalHeaderText>
                        </StyledDelinkModalHeader>
                        <StyledDelinkModalCTA>
                            <LoopButton
                                variant="error"
                                size="medium"
                                onClick={onDelinkFromPoliciesClick}
                                isLoading={isCdAccountDeleting}
                            >
                                Delink & Delete CD Account
                            </LoopButton>
                            <LoopButton
                                variant={isCdAccountDeleting ? 'disabled' : 'outline'}
                                size="medium"
                                onClick={handleModalClose}
                            >
                                Cancel
                            </LoopButton>
                        </StyledDelinkModalCTA>
                    </StyledDelinkModalContainer>
                </WithThemeProvider>
            </Modal>
        </StyledDeleteCdAccountMenu>
    );
};

export default DeleteCdAccountMenu;
