import React from 'react';
import { Dialog, Modal } from '@loophealth/loop-ui-web-library';
import { IPartiallyApprovedModalProps } from './types';
import { LeaveIcon } from '../../../../assets/img';
import { StyledCancelModalContent } from './styles';

const MultiDocumentCancelModal: React.FC<IPartiallyApprovedModalProps> = ({
    isVisible,
    setIsVisible,
    onSubmit
}: IPartiallyApprovedModalProps) => {
    const closeModal = () => {
        setIsVisible(false);
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <Dialog
                variant="vertical"
                icon={LeaveIcon}
                buttonVariant="error"
                description="Hold on! Leaving now will erase your progress, and have to start over."
                title={'Leaving without submitting?'}
                primaryButtonText="Yes, Leave"
                onPrimaryButtonClicked={onSubmit}
                onSecondaryButtonClicked={closeModal}
                secondaryButtonText="Go Back"
            >
                <StyledCancelModalContent></StyledCancelModalContent>
            </Dialog>
        </Modal>
    );
};

export default MultiDocumentCancelModal;
