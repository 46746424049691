import { Form, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useMemo, useState } from 'react';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    ChipsContainer,
    ExceptionsModalBody,
    ExceptionsModalCloseIconContainer,
    StyledExceptionsModalContent,
    ExceptionsModalFooter,
    ExceptionsModalHeader
} from './styles';
import { getEndorsementExceptionValues } from '../../../utils/constants/Endorsement/PossibleValue';
import { CrossIcon } from '../../../assets/icons/CrossIcon';
import { useSelector } from 'react-redux';
import {
    selectAllEndorsementExceptions,
    selectCurrentEndorsementExceptions
} from '../../../redux/slices/EndorsementSlice/selectors';
import { useSearchParams } from 'react-router-dom';
import ExceptionChipElement from './ExceptionChipElement';
import { updateEndorsementExceptionsByIdAPI } from '../../../adapters';
import { SuccessToast } from '../ToastMessage';
import { IDropdownValue, IEndorsementExceptionsModalProps } from './types';

const EndorsementExceptionsModal: React.FunctionComponent<IEndorsementExceptionsModalProps> = ({
    isVisible,
    setIsVisible,
    currentStatus,
    supportTicketId,
    updateEndorsementExceptions
}) => {
    const [selectedValues, setSelectedValues] = React.useState<IDropdownValue[]>([]);
    const [searchParams] = useSearchParams();
    const endorsementId = searchParams.get('id');
    const allExceptions = useSelector(selectAllEndorsementExceptions);
    const currentEndorsementExceptions = useSelector(selectCurrentEndorsementExceptions);
    const toast = Toast.useToast();
    const [isLoading, setIsLoading] = useState(false);

    const exceptions = useMemo(() => {
        return getEndorsementExceptionValues(
            allExceptions.filter((e) => e.endoStatus ? e.endoStatus === currentStatus : true)
        );
    }, [allExceptions, currentStatus]);

    React.useEffect(() => {
        setSelectedValues(getEndorsementExceptionValues(currentEndorsementExceptions));
    }, [currentEndorsementExceptions, isVisible]);

    const closeModal = () => {
        !isLoading && setIsVisible(false);
    };

    const onRemove = (exception: IDropdownValue) => {
        setSelectedValues(selectedValues.filter((v) => v.value !== exception.value));
    };

    const onFreshDeskTicketClicked = () => {
        if (!supportTicketId) return;
        window.open(`https://loophealth.freshdesk.com/a/tickets/${supportTicketId}`);
    };

    const onSubmit = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            const exceptionIds = selectedValues.map((v) => v.value);
            await updateEndorsementExceptionsByIdAPI(
                endorsementId || '', exceptionIds);
            if (supportTicketId) {
                toast?.success(
                    <SuccessToast
                        text="We've notified dependent team on freshdesk for exceptions.."
                        buttonLabel='Open Freshdesk'
                        buttonOnClick={onFreshDeskTicketClicked}
                    />,
                    '',
                    { variant: 'dark', expires: 0, closeOnClick: false, showCloseIcon: true }
                );
            }
            updateEndorsementExceptions(exceptionIds);
            setIsVisible(false);
        } catch (e) {
            toast?.error((e as Error).message);
        } finally {
            setIsLoading(false);
        }
    };

    const isFormValid = useMemo(() => (currentEndorsementExceptions.length !== 0 || selectedValues.length > 0) && (
        currentEndorsementExceptions.map((o) => o.id).sort().join() !== selectedValues.map((o) => o.value).sort().join()
    ), [currentEndorsementExceptions, selectedValues]);

    const handleSelect = (valueList: string[]) => {
        const newValues: IDropdownValue[] = exceptions.filter((v) => valueList.includes(v.value));
        if (newValues) {
            setSelectedValues(newValues);
        }
    };

    return (
        <Modal
            isModalClosable={false}
            disablePadding
            isVisible={isVisible}
            setIsVisible={closeModal}
            overflow='visible'
        >
            <WithThemeProvider>
                <StyledExceptionsModalContent>
                    <ExceptionsModalHeader>
                        <Typography variant='medium' weight='semiBold'>
                            {currentEndorsementExceptions.length > 0 ? 'Edit' : 'Add'} Exception
                        </Typography>
                        <ExceptionsModalCloseIconContainer onClick={closeModal}>
                            <CrossIcon strokeWidth={0.5}/>
                        </ExceptionsModalCloseIconContainer>
                    </ExceptionsModalHeader>
                    <ExceptionsModalBody>
                        <Form.MultiSelectDropdown
                            handleChange={handleSelect}
                            placeholder='Select exception'
                            listItems={exceptions}
                            selectedItems={selectedValues.map((v) => v.value)}
                            closeOnSelect={false}
                        />
                        {
                            selectedValues.length > 0 && (
                                <ChipsContainer>
                                    {selectedValues.map((exception) => (
                                        <ExceptionChipElement
                                            key={exception.value}
                                            exception={exception}
                                            onRemove={onRemove}
                                        />
                                    ))}
                                </ChipsContainer>
                            )
                        }
                    </ExceptionsModalBody>
                    <ExceptionsModalFooter>
                        <LoopButton variant='outline' onClick={closeModal}>Cancel</LoopButton>
                        <LoopButton
                            variant={isFormValid ? 'filled' : 'disabled'}
                            onClick={onSubmit}
                            isLoading={isLoading}
                        >
                            Submit
                        </LoopButton>
                    </ExceptionsModalFooter>
                </StyledExceptionsModalContent>
            </WithThemeProvider>
        </Modal>
    );
};
export default EndorsementExceptionsModal;
