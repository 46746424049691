import { IAddNicknameModalProps } from './types';
import React, { useEffect, useState } from 'react';
import { StyledAddNickNameContainer, StyledLoopButton } from './styles';
import { alphaNumericRegex, parseResponse, showApiFailureToast, showApiSuccessToast } from '../../../utils/common';
import { assignNickName } from '../../../adapters';
import { useToast } from '../../hooks/useToast';
import { Form, LoopButton } from '@loophealth/loop-ui-web-library';
import LoopModal from '../../atoms/LoopModal';

export const AddNicknameModal: React.FunctionComponent<IAddNicknameModalProps> = ({
    isVisible,
    handleCloseModal,
    policyId,
    definedNickName
}) => {
    const toast = useToast();
    const [isLoading, toggleLoading] = useState(false);
    const [error, setError] = useState(false);
    const [nickName, setNickName] = useState(definedNickName || '');
    useEffect(() => {
        if (definedNickName) {
            isValidInput(definedNickName) ? setError(false) : setError(true);
            setNickName(definedNickName);
        }
    }, [definedNickName, isVisible]);

    const onSaveNickNameClicked = async () => {
        if (!nickName) return;
        toggleLoading(true);
        const [error, assignNickNameResponse] = await parseResponse(assignNickName(policyId, nickName.trim()));
        toggleLoading(false);
        if (error || assignNickNameResponse.status != 200) {
            showApiFailureToast(toast, `${assignNickNameResponse.message}`);
            return;
        }
        showApiSuccessToast(toast, assignNickNameResponse.message);
        handleCloseModal();
        window.location.reload();
    };

    const isValidInput = (text: string) => {
        const isValid = alphaNumericRegex.test(text);
        return isValid;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNickName(e.target.value);
        isValidInput(e.target.value) ? setError(false) : setError(true);
    };

    const onModalClose = () => {
        handleCloseModal();
        setError(false);
    };

    return (
        <LoopModal
            title={`${definedNickName ? 'Edit' : 'Add'} nickname to policy`}
            isVisible={isVisible}
            onClose={onModalClose}
        >
            <StyledAddNickNameContainer>
                <Form.FormField id={`nickNameInput`} label={'Enter new name here'}>
                    <Form.Input
                        placeholder="Ex. Blue Collar Policy"
                        onChange={handleInputChange}
                        value={nickName}
                        error={error ? 'Only Alphanumeric values are allowed' : ''}
                    />
                </Form.FormField>
                <StyledLoopButton>
                    <LoopButton
                        variant={nickName && !error ? 'filled' : 'disabled'}
                        onClick={onSaveNickNameClicked}
                        isLoading={isLoading}
                        size="small"
                    >
                        Save
                    </LoopButton>
                </StyledLoopButton>
            </StyledAddNickNameContainer>
        </LoopModal>
    );
};
