/* eslint-disable max-len */
import styled from 'styled-components';
import { Dropdown, Tabs } from '../../../../atoms';
import { SearchIcon } from '../../../../../assets/img';

export const StyledVWContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FBFBFB;
    font-family: "Work Sans", "Inter", Sans-Serif;
    overflow-y: auto;
`;
export const StyledRowContainedWrap = styled.div`
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
`;
export const StyledRowContainedWrapSpaceBw = styled.div`
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;
export const StyledRowWrap = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 25px;
    position: relative;
`;
export const StyledActionWrapper = styled.div`
    position: absolute;
    top: 0;
    lect: 0;
    background: rgb(179 209 200 / 15%);
    width: 100%;
    min-height: 34.5px;
    padding: 10px 0;
`;
export const StyledSuccessWrapper = styled.div`
    position: relative;
    background: rgb(173 202 196 / 30%);
    width: 100%;
    min-height: 34.5px;
    padding: 10px 0;
`;
export const StyledFailWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255 128 128 / 30%);
    width: 100%;
    min-height: 34.5px;
    padding: 10px 0;
`;
// StyledTitle
export const StyledTitle = styled.h3`
    font-size: ${(p) => p.theme.fontSizes.title};
    color: ${(p) => p.theme.colors.beyondGrey};
    margin: 0;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const StyledLoading = styled.div`
    width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    top: inherit;
    height: -webkit-fill-available;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.white};
    z-index: 1;
`;
export const StyledTableMessage = styled.td`
    width: 100vw;
    min-height: 100px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.white};
`;
export const StyledAbsLoading = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.white};
    z-index: 1;
    min-height: 54.5px;
`;
export const StyledRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px;
`;
export const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-block: 30px;
`;
export const StyledRowSide = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
`;
export const StyledTitleIcon = styled.img`
    overflow: hidden;
    height: 24px;
    width: 24px;
    cursor: pointer;
`;
export const StyledFilterIcon = styled.img`
    overflow: hidden;
    height: 16px;
    width: 16px;
    cursor: pointer;
    position: relative;
    top: 2px;
`;
export const StyledTabs = styled(Tabs)`
    padding-left: 18px;
    gap: 24px;
`;
export const StyledTab = styled.div<{
    active: boolean;
}>`
    padding: 10px 0;
    cursor: pointer;
    color: ${(p) => p.active ? p.theme.colors.primary : p.theme.colors.beyondGrey};
    ${(p) => p.active ? `border-bottom: 1px solid ${p.theme.colors.primary};` : ''}
`;
export const StyledDownloadBtn = styled.button`
    padding: 16px 50px;
    border-radius: 8px;
    font-family: "Work Sans", "Inter", Sans-Serif;
    border: none;
    background: ${(p) => p.theme.colors.coverUpGreen};
    color: ${(p) => p.theme.colors.primary};
    &:disabled {
        /* background: ${(p) => p.theme.colors.disabledGrey}; */
        /* color: ${(p) => p.theme.colors.black}; */
        opacity: .5;
    }
`;
export const StyledSearchLabel = styled.span`
    font-weight: 500;
    letter-spacing: 0.2px;
    /* min-width: 127px; */ 
    /* font-size: ${(p) => p.theme.fontSizes.body}; */
`;

export const StyledCloseIcon = styled.img`
    overflow: hidden;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
`;
export const StyledChipButtonWrapper = styled.span`
    display: flex;
    gap: 10px;
    margin: 0 15px;
`;
export const StyledButtonWrapper = styled.span`
    display: flex;
    gap: 10px;
`;
export const StyledBtn = styled.button`
    padding: 8px 13px;
    border-radius: 10px;
    font-family: "Work Sans","Inter",Sans-Serif;
    border: none;
    background: ${(p) => p.theme.colors.coverUpGreen};
    color: ${(p) => p.theme.colors.primary};
    &:disabled {
        opacity: .5;
    }
`;
export const StyledInvertedBtn = styled.button`
    padding: 8px 13px;
    border-radius: 10px;
    font-family: "Work Sans","Inter",Sans-Serif;
    border: 1.5px solid ${(p) => p.theme.colors.primary};
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.primary};
    &:disabled {
        opacity: .5;
    }
`;
export const StyledAnchorBtn = styled.button`
    color: #025F4C;
    text-decoration-line: underline;
    background: inherit;
    border: none;
    font-size: 16px;
`;
export const StyledInput = styled.input`
    border: none;
    width: 240px;
    border-radius: 8px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.beyondGrey};
    padding: 12px 18px 12px 40px;
    background: #F0F0F0 url(${SearchIcon}) no-repeat 13px center;
    background-size: 18px 18px;
`;
export const StyledFiltertextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    position: relative;
    top: 12px;
`;
export const StyledDropdown = styled(Dropdown)`
    background-color: #FFFFFF;
    border: 1px solid rgba(89, 89, 89, 0.15);
    border-radius: 4px;
    padding: 12px 18px;
`;
export const StyledMultiSelectWrapper = styled.span`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-size: ${(p) => p.theme.fontSizes.body};
`;

export const StyledFilterLabel = styled.div`
    margin-block-end: 10px;
    font-size: ${(p) => p.theme.fontSizes.label};
`;
