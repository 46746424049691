import styled from 'styled-components';

export const StyledUploadedDocument = styled.div`
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface};

  > span {
    flex: 1;
    width: 100%;
  }
`;

export const StyledFileIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  object-fit: contain;
`;

export const StyledLoader = styled.div`
  margin-right: 10px;
`;
