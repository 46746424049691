import React, { useState } from 'react';
import { IChangeEndorsementStatusModalProps, IEndorsementStatusUpdateResponse } from './types';
import Modal from '../../atoms/Modal';
import { ChangeStatusIcon, ChangeStatusSuccessIcon } from '../../../assets/img';
import { getStatusLabel } from '../../../utils/constants/Endorsement/PossibleValue';
import { updateEndorsementStatusAPI } from '../../../adapters';
import { parseResponse } from '../../../utils/common';
import { useToast } from '../../hooks/useToast';
import Dialog from '../../atoms/Dialog';
import ChangeStatusAndReCalculatePremiumsModal from '../ChangeStatusAndReCalculatePremiumsModal';
import { isIssuanceFlowEnabled } from '../../../utils/featureFlags';

const ChangeEndorsementStatusModal: React.FunctionComponent<IChangeEndorsementStatusModalProps> = ({
    endorsementId,
    isVisible,
    currentStatus,
    nextStatus,
    setIsVisible,
    updateEndorsementStatus,
    policyId
}) => {
    const [isLoading, toggleLoading] = useState(false);
    const [isStateUpdated, setIsStateUpdated] = useState(false);
    const [updatedState, setUpdatedState] = useState({});
    const [isRecalculationModalVisible, setIsRecalculationModalVisible] = useState(false);
    const currentStateLabel = getStatusLabel(currentStatus);
    const nextStateLabel = getStatusLabel(nextStatus);
    const toast = useToast();
    const handleOnChangeStatus = async () => {
        if (isStateUpdated) {
            closeModal();
            return;
        }
        if (!endorsementId) return;
        const requestData = { endorsementStatus: nextStatus };
        toggleLoading(true);
        const [error, updatedStateResponse] = await parseResponse(
            updateEndorsementStatusAPI(endorsementId, requestData)
        );
        toggleLoading(false);
        if (error) {
            toast?.displayToast('error', 'Unable to process', error.toString(), 5000);
            return;
        }
        if (updatedStateResponse.data?.status === nextStatus) {
            setUpdatedState(updatedStateResponse.data);
            setIsStateUpdated(true);
            setIsRecalculationModalVisible(false);
        }
    };

    const closeModal = () => {
        isStateUpdated && updateEndorsementStatus(updatedState as IEndorsementStatusUpdateResponse);
        setIsVisible(false);
        setIsStateUpdated(false);
    };

    const closeRecalculationModal = () => {
        setIsRecalculationModalVisible(false);
        setIsVisible(false);
    };
    if (isIssuanceFlowEnabled) {
        React.useEffect(() => {
            const isCurrentStatusDraft = currentStatus === 'DRAFT';
            const premiumModalVisible = isCurrentStatusDraft && isVisible;
            setIsRecalculationModalVisible(premiumModalVisible);
        }, [isVisible]);
    }

    const title = isStateUpdated
        ? `Status has been changed from ${currentStateLabel} to ${nextStateLabel}`
        : `Are you sure you want to change the status from ${currentStateLabel} to ${nextStateLabel}?`;

    return (
        <>
            <Modal isVisible={isVisible && !isRecalculationModalVisible} setIsVisible={closeModal}>
                <Dialog
                    icon={isStateUpdated ? ChangeStatusSuccessIcon : ChangeStatusIcon}
                    title={title}
                    isLoading={isLoading}
                    primaryButtonText={isStateUpdated ? 'Back to Home' : 'Yes, Change status'}
                    onPrimaryButtonClicked={handleOnChangeStatus}
                    isSecondaryButton={true}
                    secondaryButtonText={isStateUpdated ? 'Dismiss' : 'No, Cancel'}
                    description=""
                    onSecondaryButtonClicked={closeModal}
                />
            </Modal>
            <ChangeStatusAndReCalculatePremiumsModal
                isVisible={isRecalculationModalVisible}
                closeModal={closeRecalculationModal}
                handleOnChangeStatus={handleOnChangeStatus}
                isLoading={isLoading}
                policyId={policyId}
            />
        </>
    );
};

export default ChangeEndorsementStatusModal;
