import React from 'react';
import { Dialog, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { IPartiallyApprovedModalProps } from './types';
import { NotesIcon } from '../../../../assets/img';
import { StyledAcceptedRow, StyledPartiallyModalContent, StyledRejectedRow } from './styles';

const PartiallyApprovedModal: React.FC<IPartiallyApprovedModalProps> = (props: IPartiallyApprovedModalProps) => {
    const { isVisible, setIsVisible, onSubmit } = props;

    const closeModal = () => {
        setIsVisible(false);
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <Dialog
                variant="vertical"
                icon={NotesIcon}
                description=""
                title={'Before you submit, here’s a summary of document you have verified'}
                primaryButtonText="Yes, Submit"
                onPrimaryButtonClicked={onSubmit}
                onSecondaryButtonClicked={closeModal}
                secondaryButtonText="Go Back"
            >
                <StyledPartiallyModalContent>
                    <StyledAcceptedRow>
                        <Typography variant="small" weight="semiBold">Accepted</Typography>
                        <Typography variant="small" weight="bold" color="emerald">02</Typography>
                    </StyledAcceptedRow>
                    <StyledRejectedRow>
                        <Typography variant="small" weight="semiBold">Rejected</Typography>
                        <Typography variant="small" weight="bold" color="error">03</Typography>
                    </StyledRejectedRow>
                </StyledPartiallyModalContent>
            </Dialog>
        </Modal>
    );
};

export default PartiallyApprovedModal;
