import React from 'react';
import {
    StyledContainer,
    StyledLogo,
    StyledHeading,
    StyledParaContainer,
    StyledTitle
} from './styles';
import { LoopLogo } from '../../../assets/img';

const LoginPanel: React.FunctionComponent = () => {
    return (
        <StyledContainer>
            <StyledParaContainer>
                <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} />
                <StyledTitle>
                    Genome
                </StyledTitle>
                <StyledHeading>
                    A better way to manage companies, policies &amp; members.
                </StyledHeading>
                {/* <StyledPara>
                    As you have been approved for your policy, you must now
                    simply enrol to bring your coverage into effect
                </StyledPara> */}
            </StyledParaContainer>
        </StyledContainer>
    );
};

export default LoginPanel;
