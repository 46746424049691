export const EXCEL_TEMPLATES = {
    ADD_MEMBERS: 'Template/(Template)-ADD%20Bulk%20Register.xlsx',
    EDIT_MEMBERS: 'Template/(Template)-EDIT%20Bulk%20Register.xlsx',
    AUTOGENERATE: 'Template/(Template)-Autogenerate%20Ecards.xlsx',
    OFFBOARD_MEMBERS: 'Template/(Template)-Member%20Offboarding%20Template.xlsx',
    GENOME_ACCESS: 'Template/(Template)-Genome%20Access.xlsx',
    VALIDATOR: 'Template/(Template)-VALIDATOR.xlsx',
    DATA_DISCREPANCY: 'Template/(Template)%20-%20Data%20Discrepancy%20Sheet.xlsx',
    PREMIUM_RATERS: 'Template/(Template)%20-%20Raters.zip'
};
