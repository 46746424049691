import styled from 'styled-components';

export const StyledContainer = styled.div`
    padding: 30px 56px 30px;
    width: 100%;
    height: 100vh;
`;
export const StyledTitle = styled.h3`
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: ${(p) => p.theme.fontSizes.title};
`;
export const StyledSubTitle = styled.span(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    margin: 15px 0px;
`
);
export const StyledPadding = styled.div`
    margin-bottom: 25px;
`;

export const StyledZeroState = styled.div(
    (p) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    align-items: center;
    text-align: center;
    color: ${p.theme.colors.error};
`
);
export const StyledUploadContainer = styled.div`
  padding-bottom: 40px;
`;
