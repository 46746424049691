import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 892px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px 30px;
        width: 100%;
    }
`;
