import styled from 'styled-components';
import { StyledRowContainer } from '../../containers/PolicyCard/styles';

export const StyledBannerContainer = styled(StyledRowContainer)<{ mode: string }>`
    background: ${(p) => (p.mode === 'error' ? 'rgba(255, 128, 128, 0.15)' : p.theme.colors.surfaceOne)};
    border-radius: 0 0 16px 16px;
    margin: 0;
    align-items: center;
    padding: 14px 20px 14px 25px;
`;

export const StyledBannerTitleComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.body};
`;
export const StyledActionText = styled.span`
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary};
    font-size: ${(p) => p.theme.fontSizes.body};
`;
