import React from 'react';
import { StyledButton } from './styles';
import { IButtonProps } from './types';

const LoopButton: React.FunctionComponent<IButtonProps> = ({
    children,
    type,
    width,
    onClick
}) => {
    return <StyledButton onClick={onClick} buttonType={type} width={width}
        disabled={type === 'disabled'}>
        {children}
    </StyledButton>;
};

export default LoopButton;
