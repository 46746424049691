import React from 'react';
import { excelToJson, parseResponse, showApiFailureToast, excelDownload } from '../../../utils/common';
import { EXCEL_TEMPLATES } from '../../../utils/constants/TemplateFilepaths';
import { useToast } from '../../hooks/useToast';
import { ColMd, PX4, Modal, RadioButton, Row } from '../../atoms';
import { StyledError } from '../../atoms/Forms/styles';
import ExcelUploadPill from '../ExcelUploadPill';
import {
    StyledModalContainer,
    StyledModalText,
    StyledButton,
    StyledButtonRow,
    StyledButtonWrapper,
    StyledQuestion,
    StyledAnswerWrapper
} from './styles';
import { IBulkUploadData, IBulkUploader } from './types';
import { uploadUHIDsAPI, bulkAddMembersAPI, bulkEditMembersAPI } from '../../../adapters';
import { ExcelUtils } from '@loophealth/loop-ui-web-library';
import { formatExcelDatesForBulkUpload, validateRegistrationData } from './utils';
import { isIssuanceFlowEnabled, isValidationSummaryEnabled } from '../../../utils/featureFlags';
import { downloadErrorExcel } from './ErrorExcelDownload/DownloadErrorExcel';
import formatErrorSheet from './ErrorExcelDownload/FormatErrorSheet';

const BulkUploader: React.FunctionComponent<IBulkUploader> = ({ company, policy, enableEndorsementCheck }) => {
    const toast = useToast();
    const [showModal, setShowModal] = React.useState(false);
    const [mode, setMode] = React.useState<'' | 'ADD' | 'EDIT'>('');
    const [enableEnrolementCheck, setEnableEnrolmentCheck] = React.useState<null | boolean>(
        isIssuanceFlowEnabled ? null : true
    );
    const [enableEnrolementErrorMessage, setEnableEnrolementErrorMessage] = React.useState('');

    const excelToUpload = React.useRef<IBulkUploadData[]>([]);

    const onConfirmAddMembers = async (files: Record<string, unknown>[]) => {
        try {
            setMode('ADD');
            const excelUtils = new ExcelUtils();
            const json = await excelUtils.excelToJson(files[0] as unknown as Blob);
            const sheet0 = Object.keys(json)[0];
            const excelData = json[sheet0];
            const validationError = validateRegistrationData(excelData);
            if (validationError) {
                throw validationError;
            }
            excelToUpload.current = excelData.map((memberData: any) => formatExcelDatesForBulkUpload(memberData));
            setShowModal(true);
        } catch (e) {
            toast && toast.displayToast && toast.displayToast('error', 'Failed to upload!', (e as Error).message);
            return;
        }
    };
    const onConfirmEditMembers = async (files: Record<string, unknown>[]) => {
        try {
            setMode('EDIT');
            const excelUtils = new ExcelUtils();
            const json = await excelUtils.excelToJson(files[0] as unknown as Blob);
            const sheet0 = Object.keys(json)[0];
            const excelData = json[sheet0];
            const validationError = validateRegistrationData(excelData);
            if (validationError) {
                throw validationError;
            }
            excelToUpload.current = excelData.map((memberData: any) => formatExcelDatesForBulkUpload(memberData));
            setShowModal(true);
        } catch (e) {
            toast && toast.displayToast && toast.displayToast('error', 'Failed to upload!', (e as Error).message);
            return;
        }
    };
    const onAutoGenerateECardsUpload = (files: Record<string, unknown>[]) => {
        try {
            const toastId = toast?.displayToast(
                'warning',
                `Processing e-card request`,
                'This might take some time.',
                null
            );
            excelToJson(files[0] as unknown as Blob, async (json) => {
                if (json.length) {
                    const errorData = await uploadUHIDsAPI(policy.id, json);
                    const fileName = `AUTOGENERATE-${company.name}-${
                        policy.insurerName
                    }-${new Date().toLocaleString()}`;
                    excelDownload(errorData, fileName);
                    if (toastId) toast?.removeToast(toastId);
                    toast?.displayToast(
                        'success',
                        'E-Cards Generation Request Accepted!',
                        'Generating valid e-cards, this may take some time..',
                        10000
                    );
                } else {
                    toast?.displayToast('error', 'Failed to upload!', 'No records found in the excel!');
                }
            });
        } catch (e) {
            toast && toast.displayToast && toast.displayToast('error', 'Failed to upload!', (e as Error).message);
            return;
        }
    };
    const onAddMembersUpload = async (membersList: IBulkUploadData[]) => {
        if (enableEnrolementCheck === null) {
            setEnableEnrolementErrorMessage('Please select one option atleast!');
            return;
        }
        setEnableEnrolementErrorMessage('');
        setShowModal(false);
        const toastId = toast?.displayToast('warning', 'Starting to register', 'This might take some time.', null);
        if (membersList.length) {
            const [error, errorData] = await parseResponse(
                bulkAddMembersAPI(policy.id, membersList, company.id, enableEnrolementCheck, enableEndorsementCheck)
            );
            if (error) {
                if (toastId) toast?.removeToast(toastId);
                showApiFailureToast(toast, error.toString());
                return;
            }
            const fileName = `ADD-${company.name}-${policy.insurerName}-${new Date().toLocaleString()}`;
            if (isValidationSummaryEnabled) {
                downloadErrorExcel(formatErrorSheet(errorData), fileName);
            } else {
                excelDownload(errorData, fileName);
            }
            if (toastId) toast?.removeToast(toastId);
            toast?.displayToast(
                'success',
                'Members Registered Successfully',
                'Your download will begin shortly...',
                10000
            );
        }
    };
    const onEditMembersUpload = async (membersList: IBulkUploadData[]) => {
        if (enableEnrolementCheck === null) {
            setEnableEnrolementErrorMessage('Please select one option atleast!');
            return;
        }
        setEnableEnrolementErrorMessage('');
        setShowModal(false);
        const toastId = toast?.displayToast('warning', 'Starting to register', 'This might take some time.', null);
        if (membersList.length) {
            const [error, errorData] = await parseResponse(
                bulkEditMembersAPI(policy.id, membersList, company.id, enableEnrolementCheck, enableEndorsementCheck)
            );
            if (error) {
                showApiFailureToast(toast, error.toString());
                return;
            }
            const fileName = `EDIT-${company.name}-${policy.insurerName}-${new Date().toLocaleString()}`;
            if (isValidationSummaryEnabled) {
                downloadErrorExcel(formatErrorSheet(errorData), fileName);
            } else {
                excelDownload(errorData, fileName);
            }
            if (toastId) toast?.removeToast(toastId);
            toast?.displayToast(
                'success',
                'Members Registered Successfully',
                'Your download will begin shortly...',
                10000
            );
        }
    };
    const handleRegistrationUpload = async (mode: 'ADD' | 'EDIT' | '') => {
        if (excelToUpload.current) {
            if (mode === 'ADD') {
                onAddMembersUpload(excelToUpload.current);
            } else if (mode === 'EDIT') {
                onEditMembersUpload(excelToUpload.current);
            }
        }
    };

    return (
        <>
            <Row>
                <ColMd>
                    <PX4>
                        <ExcelUploadPill
                            onUpload={onConfirmAddMembers}
                            templateUrl={EXCEL_TEMPLATES.ADD_MEMBERS}
                            title="ADD MEMBERS"
                        />
                    </PX4>
                </ColMd>
                <ColMd>
                    <PX4>
                        <ExcelUploadPill
                            onUpload={onConfirmEditMembers}
                            templateUrl={EXCEL_TEMPLATES.EDIT_MEMBERS}
                            title="EDIT MEMBERS"
                        />
                    </PX4>
                </ColMd>
                <ColMd>
                    <PX4>
                        <ExcelUploadPill
                            onUpload={onAutoGenerateECardsUpload}
                            templateUrl={EXCEL_TEMPLATES.AUTOGENERATE}
                            title="AUTOGENERATE E-CARDS"
                        />
                    </PX4>
                </ColMd>
            </Row>
            {/* Below modal is for ADD and EDIT only */}
            <Modal isVisible={showModal} setIsVisible={setShowModal} title={`Are you sure?`}>
                <StyledModalContainer>
                    <StyledModalText>
                        <b>Member(s) Found:</b> {excelToUpload.current.length}
                    </StyledModalText>
                    <StyledModalText>
                        <b>Company:</b> {company.name}
                    </StyledModalText>
                    <StyledModalText>
                        <b>Operation:</b> {`Bulk ${mode}`}
                    </StyledModalText>
                    <StyledModalText>
                        <b>Insurer:</b> {policy.insurerName}
                    </StyledModalText>
                </StyledModalContainer>
                <StyledQuestion>Do you want to enable the Enrolment Portal for these users?</StyledQuestion>
                <StyledAnswerWrapper>
                    <RadioButton
                        isSelected={enableEnrolementCheck}
                        name="Yes"
                        onClick={() => setEnableEnrolmentCheck(true)}
                        value={true}
                    />
                    <RadioButton
                        isSelected={enableEnrolementCheck}
                        name="No"
                        onClick={() => setEnableEnrolmentCheck(false)}
                        value={false}
                    />
                </StyledAnswerWrapper>
                <StyledError>{enableEnrolementErrorMessage}</StyledError>
                <StyledButtonRow>
                    <StyledButtonWrapper>
                        <StyledButton onClick={() => setShowModal(false)} $type={'error'}>
                            Cancel
                        </StyledButton>
                    </StyledButtonWrapper>
                    <StyledButtonWrapper>
                        <StyledButton onClick={() => handleRegistrationUpload(mode)} $type={'primary'}>
                            Upload
                        </StyledButton>
                    </StyledButtonWrapper>
                </StyledButtonRow>
            </Modal>
        </>
    );
};

export default BulkUploader;
