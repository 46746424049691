import { IExcelCellProps, IExcelDownloadFormat, IUserAttributeNames, KeyErrorNameMap } from './types';

const formatErrorSheet = (errorData: Record<string, unknown>[]): IExcelDownloadFormat => {
    const formattedData: IExcelDownloadFormat = [];
    errorData.map((v: Record<string, unknown>) => {
        const errors = JSON.parse(v.errors as string);
        const errorKeys = Object.keys(errors);
        if (!errorKeys.length) {
            return;
        }
        const keysWithErrors: IUserAttributeNames[] = [];
        const errorStr = errorKeys.reduce((str, k) => {
            const key = KeyErrorNameMap[k];
            const value = errors[k];
            if (key !== 'Global') keysWithErrors.push(key);
            return str + `${key} - ${value} \n`;
        }, '');
        const cellProps: Record<string, IExcelCellProps> = {};
        keysWithErrors.map((k) => {
            cellProps[k] = {
                cellColour: 'DA9694'
            };
        });
        cellProps['Errors'] = {
            fontColour: 'FF0000'
        };
        formattedData.push({
            data: {
                'User ID': v.userId,
                'Employee ID': v.employee_id,
                'Relationship to Account Holder': v.relationship_to_account_holders,
                'Name': v.name,
                'Slab ID': v.slabId,
                'Mobile': v.mobile,
                'Email Address': v.email_address,
                'Gender': v.gender,
                'CTC': v.ctc,
                'Enrolment Due Date': v.enrolment_due_date,
                'Date of Birth': v.date_of_birth,
                'Coverage Start Date': v.policy_start_date,
                'Exception': v.isException,
                'Errors': errorStr || ''
            },
            props: cellProps
        });
    });
    return formattedData;
};

export default formatErrorSheet;
