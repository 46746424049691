import styled from 'styled-components';

export const StyledCompanyDetails = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    font-weight: bold;
    margin-top: 5px;
    width: 95%;
`
);

export const StyledCompanyDetailsValue = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    font-weight: normal;
    margin-left: 5px;
`
);

export const StyledCompanyDetailsAllignment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 8.5%;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
