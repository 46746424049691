import React from 'react';
import { Table, TableData, TableRow } from '../../atoms';

interface IDisplayRater {
    raterTable: Record<string, Record<string, number>>;
}
const DisplayGMCRater: React.FunctionComponent<IDisplayRater> = ({ raterTable }) => {
    const [ageBands, setAgeBands] = React.useState<string[]>([]);
    const [siBands, setSIBands] = React.useState<number[]>([]);
    React.useEffect(() => {
        const ageBands = Object.keys(raterTable);
        setAgeBands(
            ageBands.sort((a, b) => {
                const [ageA1] = a.split('-');
                const [ageB1] = b.split('-');
                return parseInt(ageA1) - parseInt(ageB1);
            })
        );
        const siBands = Object.keys(raterTable[ageBands[0]]);
        setSIBands(siBands.map((v) => parseInt(v)).sort((a, b) => a - b));
    }, [raterTable]);
    const getRaterHeader = () => {
        return (
            <TableRow>
                <TableData>
                    <b>Rater Table</b>
                </TableData>
                {siBands.map((si, index) => (
                    <TableData key={index}>
                        <b>{si}</b>
                    </TableData>
                ))}
            </TableRow>
        );
    };
    const getRaterRow = (ageBand: string) => {
        const row = raterTable[ageBand];
        const sumInsured = Object.keys(row);
        return (
            <TableRow key={ageBand}>
                <TableData>
                    <b>{ageBand}</b>
                </TableData>
                {sumInsured.map((sumInsured, index) => (
                    <TableData key={index}>{(raterTable[ageBand][sumInsured]).toFixed(2)}</TableData>
                ))}
            </TableRow>
        );
    };
    const getRaterBody = () => {
        return ageBands
            .map((ageBand) => getRaterRow(ageBand));
    };
    return (
        <Table>
            {getRaterHeader()}
            {getRaterBody()}
        </Table>
    );
};

export default DisplayGMCRater;
