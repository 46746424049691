import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Title, Card, Select, SelectOption, SubTitle, MB6 } from '../../../../atoms';
import { ReduxState } from '../../../../../redux';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import BulkOffboardMembers from '../../../../containers/BulkOffboardMembers';
import { isIssuanceFlowEnabled } from '../../../../../utils/featureFlags';
import { BulkOffboardPreferenceComponent } from '../../../../containers/BulkUploadPreference';
import { IUploadPreference } from '../../../../containers/BulkUploadPreference/types';

const OffboardMembers: React.FunctionComponent = () => {
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList);
    const [company, setCompany] = React.useState<ICompanyData | null>(null);
    const [uploadPreference, setUploadPreference] = React.useState<IUploadPreference | null>(null);

    const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const comp = e.target.value;
        const newCompany = companyList.data?.find((c: ICompanyData) => c.id === comp);
        if (newCompany) {
            setCompany(newCompany);
        }
    };

    return (
        <Container>
            <Title>OFFBOARD MEMBERS</Title>
            <Card>
                <Container>
                    <Row>
                        <SubTitle>Select Company:</SubTitle>
                        <Select
                            name="company"
                            onChange={handleCompanyChange}
                            value={company?.id || ''}
                            data-testid="select-company"
                        >
                            <SelectOption value={''}>Please select</SelectOption>
                            {companyList.data?.map((comp: ICompanyData, index: number) => (
                                <SelectOption key={index} value={comp.id}>
                                    {comp.name}
                                </SelectOption>
                            ))}
                        </Select>
                    </Row>
                    <MB6 />
                </Container>
            </Card>
            {company && isIssuanceFlowEnabled && (
                <BulkOffboardPreferenceComponent
                    setPreference={setUploadPreference}
                    uploadPreference={uploadPreference}
                />
            )}
            {(((company && uploadPreference && isIssuanceFlowEnabled) || (company && !isIssuanceFlowEnabled)) && (
                <BulkOffboardMembers company={company} enableEndorsementCheck={uploadPreference === 'ENDO'} />
            )) || <></>}
        </Container>
    );
};

export default OffboardMembers;
