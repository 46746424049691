/* eslint-disable max-len */
import styled from 'styled-components';
import { SearchIcon } from '../../../../../assets/img';

export const StyledVWContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FBFBFB;
    font-family: "Work Sans", "Inter", Sans-Serif;
    overflow-y: auto;
`;
export const StyledRowWrap = styled.div`
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
`;
export const StyledTitle = styled.h3`
    font-size: ${(p) => p.theme.fontSizes.title};
    color: ${(p) => p.theme.colors.beyondGrey};
    margin-block: 30px;
`;
export const StyledMediumWtText = styled.span`
    font-weight: 500;
    letter-spacing: 0.2px;
    /* font-size: ${(p) => p.theme.fontSizes.body}; */
`;
export const StyledFilterWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    margin-block: 40px;
`;
export const StyledInput = styled.input`
    border: none;
    width: 350px;
    border-radius: 8px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.beyondGrey};
    padding: 12px 18px 12px 40px;
    background: #F0F0F0 url(${SearchIcon}) no-repeat 13px center;
    background-size: 18px 18px;
`;
export const StyledLoading = styled.div`
    width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    top: inherit;
    height: -webkit-fill-available;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;
