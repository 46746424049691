import React from 'react';
import { Dialog, Modal } from '@loophealth/loop-ui-web-library';
import { DeleteIconWithBg } from '../../../assets/img';
import { DeleteEndorsementSuccessStateProps } from './types';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';

const DeleteEndorsementSuccessState: React.FunctionComponent<DeleteEndorsementSuccessStateProps> = ({
    isVisible,
    onClose,
    companyName,
    endoCycle,
    insurer
}) => {
    const navigate = useNavigate();
    const handleClose = () => {
        onClose(false);
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.ENDORSEMENTS.route}`);
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={handleClose}>
            <Dialog
                variant="vertical"
                title={'Endo deleted'}
                description={`${endoCycle} for ${companyName} insured by ${insurer} is deleted`}
                icon={DeleteIconWithBg}
                primaryButtonText="Okay"
                onPrimaryButtonClicked={() => handleClose()}
            />
        </Modal>
    );
};

export default DeleteEndorsementSuccessState;
