import React from 'react';
import { StyledActionText, StyledBannerContainer, StyledBannerTitleComponent } from './styles';
import { IBannerProps } from './types';

const Banner: React.FunctionComponent<IBannerProps> = ({ isActionable, action, onAction, mode, children }) => {
    return (
        <StyledBannerContainer mode={mode}>
            <StyledBannerTitleComponent>{children}</StyledBannerTitleComponent>
            {isActionable ? <StyledActionText onClick={onAction}>{action}</StyledActionText> : null}
        </StyledBannerContainer>
    );
};
export default Banner;
