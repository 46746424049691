import styled from 'styled-components';
import theme from '../../../theme';

export const StyledEnrolmentReportContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;
export const StyledContainer = styled.div`
    width: 100%;
    padding: 36px;
    display: flex;
    flex-direction: column;
    border: 1px solid #CBD6D3;
    box-shadow: 0px 20px 25px -3px #3741510D;
    box-shadow: 0px 10px 10px -2px #3741510D;
    border-radius: 8px;
`;

export const StyledLoopButtonContainer = styled.div`
    width: 246px;
`;

export const StyledFormContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
`;
export const StyledFormFieldWidth = styled.div`
    width: 400px;
`;

export const StyledHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 36px 0px 0px 0px;
    gap: 24px;
`;

export const StyledHorizontalDivider = styled.div`
    height: 1px;
    min-width: 100%;
    margin: 36px 0px 0px 0px;
    background-color: ${theme.colors.defaultBorder};
`;

export const StyledInfoLabelWrapper = styled.div<{ $gap: string }>(
    (p) => `
    gap: ${p.$gap};
    display: flex;
    align-items: center;
`
);

export const StyledFormHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledArrowIcon = styled.img<{ $rotate: number }>(
    (p) => `
    transform: rotate(${p.$rotate}deg);
    width: 24px;
    cursor: pointer;
`
);

export const StyledEmptyEnrolmentCard = styled.div`
    display: flex;
    width: 100%;
    padding: 24px 0px;
    border: 1px solid ${theme.colors.defaultBorder};
    border-radius: 8px;
    align-items: center;
    background-color: ${theme.colors.surface};
    justify-content: center;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
`;
