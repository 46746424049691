import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`;
export const PlusButtonContainer = styled.div``;

export const StyledTableContainer = styled.div`
    width: 100%;
    padding-bottom: 20px;
`;
