import styled from 'styled-components';

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: fit-content;
  }
`;
