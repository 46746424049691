import { MultipleDocumentIcon, SingleDocumentIcon } from '../../../../../assets/img';

export const NICKNAME_HELPER_DATA = [
    {
        headerIcon: SingleDocumentIcon,
        headerTitle: 'Single Policy',
        format: '[Company name][policy type] Policy',
        example: 'Loop GMC Policy'
    },
    {
        headerIcon: MultipleDocumentIcon,
        headerTitle: 'Multiple GMC Policies',
        format: '[Coverage Group: Location/Designation/etc][policy type] Policy',
        example: 'Blr CXOs GMC Policy'
    }
];
