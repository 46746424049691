import { IOption } from '../../../components/atoms/MultiselectCheckboxes/type';
import { convertBeOperationType } from '../../../redux/slices/ChangeRequestSlice/thunks';
import {
    IFetchChangeRequestsData,
    IFetchChangeRequestsDataForAPI,
    IChangeRequest,
    IChangeRequestExcelForUI,
    IOperationType
} from '../../../redux/slices/ChangeRequestSlice/types';
import { IFirebaseTimestamp } from '../../../redux/types';
import { defaultDateFormat } from '../../common';

const getFormatedDate = (value: IFirebaseTimestamp | undefined) =>
    value?._seconds ? defaultDateFormat(value._seconds) : '-';

export const transformExcelRow = (d: IChangeRequest): IChangeRequestExcelForUI => ({
    id: d.id,
    companyId: d.companyId,
    operationType: d.operationType,
    source: d.source,
    status: d.status,
    policyId: d.policyId,
    name: d.name,
    relationship: d.relationship,
    userId: d.userId,
    updatedBy: d.updatedBy,
    createdBy: d.createdBy,
    createdAt: getFormatedDate(d.createdAt),
    joiningDate: getFormatedDate(d.joiningDate),
    updatedAt: getFormatedDate(d.updatedAt),
    employeeId: d.employeeId,
    mobile: d.mobile,
    email: d.email,
    policyType: d.policyType,
    insurer: d.insurer
});

export const transformChangeRequestsParams = ({
    companyId,
    nextPageId,
    prevPageId,
    status,
    pageNo,
    selectFilters,
    dateCreated,
    pageSize
}: IFetchChangeRequestsData ): IFetchChangeRequestsDataForAPI => {
    let params: IFetchChangeRequestsDataForAPI = {
        companyId,
        status,
        nextPageId,
        prevPageId,
        pageNo,
        pageSize,
        ...((dateCreated?.startDate && dateCreated?.endDate !==null) && {
            dateFrom: Math.round( dateCreated?.startDate?.getTime() / 1000)
        }),
        ...(dateCreated?.endDate && { dateTo: Math.round( dateCreated?.endDate?.getTime() / 1000) })
    };
    if (selectFilters) {
        Object.entries(selectFilters).forEach(([key, option]) => {
            const value = (option as IOption).value;
            if (!!value) {
                if (key === 'operationType') {
                    const opTypeValues = convertBeOperationType(value as IOperationType)?.[0];
                    params = { ...params, 'operationType': opTypeValues };
                } else {
                    params = { ...params, [key]: value };
                }
            }
        });
    }
    return params;
};
