import styled from 'styled-components';

export const StyledFlexColMy4xAuto = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const StyledCol = styled.col`
  width: 190px;
`;
export const StyledWCol = styled.col`
  width: 160px;
`;
export const StyledNCol = styled.col`
  width: 130px;
`;
export const StyledCheckboxColumn = styled.col`
    width: 50px;
`;
