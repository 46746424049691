import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #f8f9fa;
    padding: 24px 32px 32px 32px;
    border-radius: 16px;
    gap: 24px;
`;

export const StyledEmailContainer = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
`;

export const ChipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Chip = styled.div(
    (p) => `
    background: ${p.theme.colors.disableFill};
    border-radius: 1000px;
    padding: 8px 16px 8px 16px;
    font-size: ${p.theme.fontSizes.label}
    font-weight: 400;
    color: #595959;
`
);
