import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const StyledActiveCheck = styled.div<{ $isActive: boolean; $isDisabled: boolean }>(
    (p) => `
    ${p.$isActive ? '' : 'display: none; height: 0;'}
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: ${p.$isDisabled ? p.theme.colors.muted : p.theme.colors.primary};
`
);

export const StyledCheck = styled.div<{ $isActive: boolean; $isDisabled: boolean }>(
    (p) => `
    border: 1px solid ${
    p.$isDisabled ? p.theme.colors.muted : p.$isActive ? p.theme.colors.primary : p.theme.colors.black
};
    background-color: ${p.theme.colors.white};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: relative;
    top: 0;
    left: 0;
    margin: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`
);

export const StyledCheckText = styled.div<{ $isActive: boolean; $isDisabled: boolean }>(
    (p) => `
    color: ${p.$isDisabled ? p.theme.colors.muted : p.$isActive ? p.theme.colors.black : p.theme.colors.muted};
    background-color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.body};
    line-height: 17px;
`
);
