import React from 'react';
import {
    StyledBadgeContainer,
    StyledEndoPolicyDetails,
    StyledEndoPolicyDetailsItem,
    StyledEndoPolicyDetailsSeperator,
    StyledEndoPolicyDetailsWrapper,
    StyledEndorsementCard,
    StyledEndorsementDetails,
    StyledEndorsementDetailsTitle,
    StyledEndorsementDetailsWrapper
} from './styles';
import {
    CardIcon,
    DateFormatUtils,
    LoopBadge,
    LoopButton,
    Typography
} from '@loophealth/loop-ui-web-library';
import { LoopEmeraldDownloadIcon } from '../../../assets/img';
import { IEndorsementCard } from './types';

const EndorsementCard: React.FC<IEndorsementCard> = ({
    endorsement,
    onViewDetailsClick,
    isLowCd
}) => {
    const formattedDate = new DateFormatUtils().formatDate(
        new Date(endorsement?.createdAt || '')
    );

    return (
        <StyledEndorsementCard>
            <StyledEndorsementDetailsWrapper>
                <StyledEndorsementDetails>
                    <StyledBadgeContainer>
                        <LoopBadge
                            badgeType="success3"
                            variant="overflow"
                            text={endorsement?.livesCount + ' Lives'}
                        />
                        {
                            isLowCd && <LoopBadge
                                badgeType="error"
                                variant="overflow"
                                text="Low CD: Awaiting CSM Response"
                            />
                        }
                    </StyledBadgeContainer>
                    <StyledEndorsementDetailsTitle>
                        <Typography variant="medium" weight="semiBold">
              Endo {endorsement?.endorsementNumber}
                        </Typography>
                        <Typography variant="medium" weight="medium" color="secondary">
              Created on: {formattedDate}
                        </Typography>
                    </StyledEndorsementDetailsTitle>
                </StyledEndorsementDetails>
                <div>
                    <LoopButton
                        variant="outline"
                        onClick={() => endorsement && onViewDetailsClick(endorsement?.id)}
                        size="small"
                    >
            View Details
                    </LoopButton>
                </div>
            </StyledEndorsementDetailsWrapper>
            <StyledEndoPolicyDetailsWrapper>
                <CardIcon imageUrl={LoopEmeraldDownloadIcon} />
                <StyledEndoPolicyDetails>
                    <StyledEndoPolicyDetailsItem>
                        <Typography variant="small" color="secondary">
              Insurer Name
                        </Typography>
                        <Typography variant="small" weight="medium">
                            {endorsement?.insurerName}
                        </Typography>
                    </StyledEndoPolicyDetailsItem>
                    <StyledEndoPolicyDetailsSeperator />
                    <StyledEndoPolicyDetailsItem>
                        <Typography variant="small" color="secondary">
              Policy ID
                        </Typography>
                        <Typography variant="small" weight="medium">
                            {endorsement?.policyId}
                        </Typography>
                    </StyledEndoPolicyDetailsItem>
                    <StyledEndoPolicyDetailsSeperator />
                    <StyledEndoPolicyDetailsItem>
                        <Typography variant="small" color="secondary">
              Policy Name
                        </Typography>
                        <Typography variant="small" weight="medium">
                            {endorsement?.policyType}
                        </Typography>
                    </StyledEndoPolicyDetailsItem>
                    <StyledEndoPolicyDetailsSeperator />
                    <StyledEndoPolicyDetailsItem>
                        <Typography variant="small" color="secondary">
              Policy Nickname
                        </Typography>
                        <Typography variant="small" weight="medium">
                            {endorsement?.nickName}
                        </Typography>
                    </StyledEndoPolicyDetailsItem>
                </StyledEndoPolicyDetails>
            </StyledEndoPolicyDetailsWrapper>
        </StyledEndorsementCard>
    );
};

export default EndorsementCard;
