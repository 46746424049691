import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledApprovalModalContent = styled.div`
    width: 700px;
    height: 75vh;
`;
export const StyledApprovalModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 3.6rem;
    border-bottom: 1px solid ${theme.colors.defaultBorder};
`;
export const StyledApprovalModalBodyContainer = styled.div`
    display: flex;
    height: calc(100% - 3.6rem);
`;
export const StyledApprovalDocumentsContainer = styled.div`
    width: 35%;
    height: 100%;
    padding: 1.5rem 0;
    border-right: 1px solid ${theme.colors.defaultBorder};
    background-color: ${theme.colors.surface};
    border-collapse: collapse;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledApprovalMainImageContainer = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
export const StyledApprovalDocumentScrollBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1.5rem;
    height: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #eeeeee;
    }
`;
export const StyledApprovalDocumentListItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    border: 0.5px solid ${theme.colors.defaultBorder};
    &:hover {
        border: 1.5px solid ${theme.colors.primary};
    }
`;
export const StyledApprovalDocumentImageContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`;
export const StyledApprovalDocumentImage = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 12px;
`;
export const StyledApprovalDocumentImageOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    bottom: 0;
    left: 0;
`;
export const StyledApprovalImageRibbonWrapper = styled.div`
    position: absolute;
    top: 7%;
    right: -22px;
`;
export const StyledIFrameMirrorWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
`;
export const StyledApprovalDocumentListItemFooter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
`;
export const StyledApprovalModalBody = styled.div`
    padding: 16px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const StyledApprovalMultiModalBody = styled.div`
    text-align: center;
    width: 100%;
    height: 84%;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
`;
export const StyledApprovalMultiModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    height: 16%;
    padding: 2rem 2rem;
    align-items: center;
    border-top: 1px solid ${theme.colors.defaultBorder};
    gap: 1rem;
`;
export const StyledApprovalMutliModalPrimaryImageContainer = styled.div`
    padding: 1rem;
    height: 100%;
    position: relative;
    width: 100%;
`;
export const StyledApprovalMultiModalPrimaryImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
`;
export const StyledApprovalImageShareIcon = styled.div`
    position: absolute;
    height: 30px;
    width: 30px;
    top: 30px;
    right: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
    opacity: 1;
    &:hover {
        transform: scale(1.2);
        transition: all 0.3s;
    }
`;

export const StyledApprovalModalCloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const StyledChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: ${theme.colors.planeGreenLight};
    color: ${theme.colors.primary};
    border-radius: 50px;
    font-weight: 500;
    font-size: 12px;
    gap: 0.5rem;
    margin-right: 1rem;
`;

export const StyledPartiallyModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 1rem 0;
`;
export const StyledPartiallyModalRow = styled.div`
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
`;
export const StyledAcceptedRow = styled(StyledPartiallyModalRow)`
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    background-color: ${theme.colors.surfaceOne};
`;
export const StyledRejectedRow = styled(StyledPartiallyModalRow)`
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    background-color: ${theme.colors.errorFill};
`;

export const StyledCancelModalContent = styled.div`
    height: 0.5rem;
`;

export const StyledToastWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
