import styled from 'styled-components';
import theme from '../../../theme';

export const StyledInitialValueCard = styled.div`
    padding: 12px 20px;
    background-color: ${theme.colors.planeGreenLight};
    border-radius: 8px;
`;

export const StyledFormContainer = styled.div`
    display: flex;
    gap: 12px;
    margin: 16px 0;
`;
