import styled from 'styled-components';
import { ICardType } from './types';

export const StyledCard = styled.div<{ $type: ICardType }>`
    display: flex;
    margin: 20px 10px;
    background-color: ${(p) => (p.$type === 'primary' ? p.theme.colors.surfaceOne : p.theme.colors.errorFill)};
    border-radius: 12px;
    padding: 32px 24px;
`;

export const StyledDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 100px;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    border-radius: 100px;
    background-color: ${(p) => p.theme.colors.white};
`;
