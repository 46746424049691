import { IOption } from '../../../components/atoms/MultiselectCheckboxes/type';
import {
    ISource, IPolicyType, IStatus, IOperationType
} from '../../../redux/slices/ChangeRequestSlice/types';
import theme from '../../../theme/index';

const STATUS_COLOR_MAP = {
    'PENDING': theme.colors.planRed,
    'IN_PROGRESS': theme.colors.turquoise,
    'ACCEPTED': theme.colors.coverUpGreen,
    'REJECTED': theme.colors.beyondGrey
};
export const getStatusColor = (value?:IStatus):string =>
    value ? STATUS_COLOR_MAP[value] : theme.colors.disabledGrey;
export const whiteColor = theme.colors.white;

const SOURCE_COLOR_MAP = {
    'genome': { color: theme.colors.beyondGrey, backgroundColor: theme.colors.coverUpGreen, label: 'Genome' },
    'enrolment': { color: theme.colors.beyondGrey, backgroundColor: theme.colors.coverYellow, label: 'Enrolment' },
    'hrdesk': { color: theme.colors.white, backgroundColor: theme.colors.consultViolet, label: 'HR Desk' },
    'hrms': { color: theme.colors.white, backgroundColor: theme.colors.turquoise, label: 'HRMS' },
    'topup': { color: theme.colors.white, backgroundColor: theme.colors.planRed, label: 'Top ups' }
};
export const getSourceStyles = (value: ISource)
    :{color:string, backgroundColor:string, label:string} =>
    SOURCE_COLOR_MAP[value] ?? { color: theme.colors.white, backgroundColor: theme.colors.disabledGrey, label: value };

export const CR_NEXT_STATES_MAP = {
    PENDING: ['IN_PROGRESS'],
    IN_PROGRESS: ['ACCEPTED', 'REJECTED'],
    REJECTED: [],
    ACCEPTED: []
};

export const getNextCRPossibleStates = (value:IStatus):string[] =>
    CR_NEXT_STATES_MAP[value] ?? [];

export const CR_PREV_STATES_MAP = {
    PENDING: undefined,
    IN_PROGRESS: 'PENDING',
    REJECTED: 'IN_PROGRESS',
    ACCEPTED: 'IN_PROGRESS'
};
export const getPrevCRPossibleState = (value:IStatus):string | undefined =>
    CR_PREV_STATES_MAP[value];

export const POLICY_TYPE_LABEL_MAP = {
    GMC: 'GMC',
    GPA: 'GPA',
    GTL: 'GTL',
    COVID: 'Covid',
    SUPER_TOP_UP: 'Super top-up'
};
export const getPolicyTypeLabel = (value:IPolicyType):string => POLICY_TYPE_LABEL_MAP[value];

export const policyTypeSelectOptions:IOption[] =
    Object.entries(POLICY_TYPE_LABEL_MAP).map(([key, value], id) => ({ id, label: value, value: key }));

export const STATUS_LABEL_MAP = {
    PENDING: 'Pending',
    IN_PROGRESS: 'In Progress',
    REJECTED: 'Rejected',
    ACCEPTED: 'Accepted'
};
export const getStatusLabel = (value:IStatus):string =>
    STATUS_LABEL_MAP[value] ?? '';
export const statusSelectOptions:IOption[] =
    Object.entries(STATUS_LABEL_MAP).map(([key, value], id) => ({ id, label: value, value: key }));

export const OPERATION_TYPE_LABEL_MAP = {
    ADD: 'Add',
    DELETE: 'Delete',
    EDIT: 'Edit'
    // CREATE: 'Create',
    // READ: 'Read',
    // UPDATE: 'Update',
    // DELETE: 'Delete',
    // BULK_CREATE: 'Bulk Create',
    // BULK_UPDATE: 'Bulk Update'
};
export const getOperationTypeLabel = (value:IOperationType):string =>
    OPERATION_TYPE_LABEL_MAP[value] ?? '';
export const operationTypeOptions:IOption[] =
    Object.entries(OPERATION_TYPE_LABEL_MAP).map(([key, value], id) => ({ id, label: value, value: key }));

export type iChangeRequestsFilter = 'policyType'|'status';
export const CHANGE_REQUEST_FILTER_OPTIONS_MAP = {
    policyType: {
        label: 'Policy Type',
        options: policyTypeSelectOptions
    },
    status: {
        label: 'Status',
        options: statusSelectOptions
    }
    // operationType: {
    //     label: 'Operation Type',
    //     options: operationTypeOptions
    // }
};
export const getFilterOptions = (value:iChangeRequestsFilter, includesAll=true)
    :{ label:string, options:IOption[] } => {
    let result = CHANGE_REQUEST_FILTER_OPTIONS_MAP[value];
    if (includesAll) {
        result = { ...result, options: [{ id: 'all', label: 'All', value: undefined }, ...result.options] };
    }
    return result;
};

export const defaultFilterState =
    Object.keys(CHANGE_REQUEST_FILTER_OPTIONS_MAP).reduce((obj, key) => ({ ...obj, [key]: '' }), {});

// export const defaultFilterState = (status:IStatus[]) =>
// Object.keys(CHANGE_REQUEST_FILTER_OPTIONS_MAP).reduce((obj, key) => ({
//     ...obj, [key]: '', ...(key === 'status' ? {
//         ...obj, options: Object.entries(STATUS_LABEL_MAP)
//             .filter(([key]) => status.includes(key as IStatus))
//             .map(([key, value], id) =>
//                 ({ id, label: value, value: key })
//             )
//     } : {})
// }), {});
