import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import { useToast } from '../../../../hooks/useToast';
import { Card, ErrorContainer, Loader, Select, SelectOption } from '../../../../atoms';
import { BulkUploader, PolicyCard } from '../../../../containers';
import { StyledContainer, StyledPadding, StyledSubTitle, StyledTitle, StyledUploadContainer } from './styles';
import { getPolicyType } from '../../../../../utils/common';
import { fetchActivePoliciesByCompanyId } from '../../../../../adapters';
import { isIssuanceFlowEnabled } from '../../../../../utils/featureFlags';
import { IUploadPreference } from '../../../../containers/BulkUploadPreference/types';
import { BulkRegisterPreferenceComponent } from '../../../../containers/BulkUploadPreference';

const BulkRegister: React.FunctionComponent = () => {
    const { displayToast } = useToast() || {};
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList);
    const [company, setCompany] = React.useState<ICompanyData | null>(null);
    const [policy, setPolicy] = React.useState<IPolicyPlan | null>(null);
    const [policyList, setPolicyList] = React.useState<IPolicyPlan[]>([]);
    const [policyLoading, setPolicyLoading] = React.useState(false);
    const [uploadPreference, setUploadPreference] = React.useState<IUploadPreference | null>(null);
    React.useEffect(() => {
        setPolicy(null);
    }, [company]);
    const handleCompanyChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const comp = e.target.value;
        const newCompany = companyList.data?.find((c: ICompanyData) => c.id === comp);
        if (newCompany) {
            setCompany(newCompany);
            setPolicyList([]);
            setPolicyLoading(true);
            const response = await fetchActivePoliciesByCompanyId(newCompany?.id);
            if (response.data) {
                setPolicyList(response.data);
            }
            setPolicyLoading(false);
        }
    };
    const handlePolicyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const pId = e.target.value;
        const policy = policyList.find((p) => p.id === pId);
        if (policy) {
            if (policy.error && policy.error.length) {
                displayToast && displayToast('error', `Policy Error: ${pId}!`, policy.error);
                return;
            }
            setPolicy(policy);
        }
    };

    return (
        <StyledContainer>
            <StyledTitle>Bulk Register</StyledTitle>
            <Card>
                <StyledSubTitle>Select Company:</StyledSubTitle>
                <Select name="company" onChange={handleCompanyChange} value={company?.id || ''}>
                    <SelectOption value={''}>Please select</SelectOption>
                    {companyList.data?.map((comp: ICompanyData, index: number) => (
                        <SelectOption key={index} value={comp.id}>
                            {comp.name}
                        </SelectOption>
                    ))}
                </Select>
                {(company && policyList.length && (
                    <>
                        <StyledSubTitle>Select Policy:</StyledSubTitle>
                        <Select name="policy" onChange={handlePolicyChange} value={policy?.id || ''}>
                            <SelectOption value={''}>Please select</SelectOption>
                            {policyList.map((pol, index) => (
                                <SelectOption key={index} value={pol.id}>
                                    {pol.error && pol.error.length
                                        ? `(${pol.id + ') (Schema Error)'}`
                                        : `
                                            ${pol.insurerName} (${
                                    getPolicyType(pol.policyType, pol.isTopUpPolicy, pol.isParentalPolicy) ||
                                            'N/A'
                                }) (${pol.id || 'N/A'}) ${pol.nickName ? '(' + pol.nickName + ')' : ''}
                                        `}
                                </SelectOption>
                            ))}
                        </Select>
                    </>
                )) || <></>}
                <StyledPadding/>
                {policyLoading && <Loader/>}
                {company && !policyList.length && !policyLoading && (
                    <ErrorContainer message="No policies found for this company!"/>
                )}
            </Card>
            {company && policy?.id && (
                <StyledUploadContainer>
                    <PolicyCard policyId={policy.id} companyId={company.id}/>
                    {isIssuanceFlowEnabled && <BulkRegisterPreferenceComponent setPreference={setUploadPreference}
                        uploadPreference={uploadPreference}/>}
                    {(!!uploadPreference || !isIssuanceFlowEnabled) && <BulkUploader company={company} policy={policy}
                        enableEndorsementCheck={uploadPreference === 'ENDO'}/>}
                </StyledUploadContainer>
            )}
        </StyledContainer>
    );
};

export default BulkRegister;
