import styled from 'styled-components';

export const StyledStepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    border-radius: 20px;
    gap: 16px;
    background-color: ${(p) => p.theme.colors.secondary};
`;

export const StyledDatePickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-width: 800px;
    width: 80%;
    pointer-events: none;
`;

export const StyledDatePickerWrapper = styled.div`
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.disableFill};
    flex: 1;
`;
