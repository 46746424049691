import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    margin-top: 32px;
    @media (max-width: 768px) {
        padding: 15px 30px;
    }
`;

export const StyledRowAlignment = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    gap: 24px;
`;
export const StyledDepositEntryDateRowAlignment = styled(StyledRowAlignment)`
    width: 48%;
`;

export const StyledButtonRow = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;
