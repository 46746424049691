import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledCardContainer = styled.div<{ $backgroundURL: string }>`
    display: flex;
    flex-direction: column;
    width: 342px;
    height: 220px;
    background-image: url(${(p) => p.$backgroundURL});
    margin-bottom: 24px;
    padding: 24px 20px;
`;

export const StyledCardTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
`;

export const StyledPillContainer = styled.div`
    margin-top: 28px;
`;

export const StyledCardPill = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${(p) => p.theme.colors.white};
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 8px;
`;

export const StyledSVG = styled(SVG)``;

