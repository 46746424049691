import React from 'react';
import {
    StyledPrimaryButton,
    StyledIconButton,
    StyledOAuthButton,
    StyledOAuthIcon,
    StyledOAuthText,
    StyledSecondaryButton
} from './styles';
import { IButton } from './types';

const Button: React.FunctionComponent<IButton> = ({
    type,
    onClick,
    fullWidth = false,
    id = '',
    children,
    innerRef = null,
    iconSrc = '',
    disabled = false
}) => {
    const getButtonType = () => {
        switch (type) {
        case 'primary':
            return (
                <StyledPrimaryButton
                    disabled={disabled}
                    ref={innerRef}
                    onClick={onClick}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledPrimaryButton>
            );
        case 'secondary':
            return (
                <StyledSecondaryButton
                    disabled={disabled}
                    id={id}
                    ref={innerRef}
                    onClick={onClick}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledSecondaryButton>
            );
        case 'icon':
            return (
                <StyledIconButton
                    disabled={disabled}
                    onClick={onClick}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledIconButton>
            );
        case 'OAuth':
            return (
                <StyledOAuthButton
                    disabled={disabled}
                    onClick={onClick}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    <StyledOAuthIcon src={iconSrc} />
                    <StyledOAuthText>{children}</StyledOAuthText>
                </StyledOAuthButton>
            );
        default:
            return (
                <StyledPrimaryButton
                    disabled={disabled}
                    onClick={onClick}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledPrimaryButton>
            );
        }
    };
    return getButtonType();
};

export default Button;
