import React from 'react';
import { Card, Loader } from '../../atoms';
import { StyledAddPolicyAlignment } from '../../pages/SPAContainer/pages/CompanyDetails/styles';
import { StyledCompanyName } from '../CompanyCard/styles';
import CompanyPoliciesCard from '../CompanyPoliciesCard';
import { StyledLoaderContainer, StyledZeroState } from '../PolicyCard/styles';
import { IPolicyListCard } from './types';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const PolicyListCard: React.FunctionComponent<IPolicyListCard> = ({
    isActive,
    policyList,
    loading,
    companyId,
    companyName,
    refreshPolicyList
}) => {
    return (
        <Card>
            {loading ? (
                <StyledLoaderContainer>
                    <Loader />
                </StyledLoaderContainer>
            ) : (
                <>
                    <StyledAddPolicyAlignment>
                        <StyledCompanyName>{isActive ? 'Active' : 'Expired'} Policies</StyledCompanyName>
                    </StyledAddPolicyAlignment>
                    {policyList?.length ? (
                        policyList.map((policy, index) => {
                            return (
                                <CompanyPoliciesCard
                                    key={index}
                                    insurerLogo={policy.insurerLogo}
                                    id={policy.id}
                                    insurerName={policy.insurerName}
                                    policyType={policy.policyType}
                                    policyPlacementStatus={policy.policyPlacementStatus}
                                    isTopUpPolicy={policy.isTopUpPolicy}
                                    isParentalPolicy={policy.isParentalPolicy}
                                    isOPDPolicy={policy.isOPDPolicy}
                                    isCdAccountPresent={!!policy.isCdAccountPresent}
                                    formattedDate={new DateFormatUtils().formatDate(policy.policyEndDate)}
                                    companyId={companyId}
                                    companyName={companyName}
                                    isActive={isActive}
                                    refreshPolicyList={() => refreshPolicyList(isActive)}
                                />
                            );
                        })
                    ) : (
                        <StyledZeroState> No Policies Available</StyledZeroState>
                    )}
                </>
            )}
        </Card>
    );
};

export default PolicyListCard;
