import styled from 'styled-components';

export const StyledPreviewContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  background-color: ${(p) => p.theme.colors.secondary};
`;
export const StyledImgRemContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const StyledPreviewImage = styled.img`
  height: 100%;
  padding: 12px;
  border-radius: 25px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  max-height: 100%;
  max-width: 33%;
`;
export const StyledPreviewPDF = styled.embed`
    height: 100%;
    padding: 12px;
    border-radius: 25px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    max-height: 100%;
    max-width: 33%;
`;
export const StyledPreviewImageName = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
`;
