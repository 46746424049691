import React from 'react';
import { migrateDataOfDuplicateUsers } from '../../../adapters';
import { EXCEL_TEMPLATES } from '../../../utils/constants/TemplateFilepaths';
import { useToast } from '../../hooks/useToast';
import { IUploadPill } from '../../atoms/UploadPill/types';
import ExcelUploader from '../ExcelUploadPill';
import { ExcelUtils } from '@loophealth/loop-ui-web-library';
import { getMemberListFromJson } from './utils';
import { excelDownload } from '../../../utils/common';

const DataDiscrepancy: React.FunctionComponent = () => {
    const toast = useToast();

    const handleFailedMembers = (failedMembers: Record<string, unknown>[], toastId?: string | undefined) => {
        const fileName = `Data-Discrepancy-${new Date().toLocaleString()}`;
        excelDownload(failedMembers, fileName);

        if (toastId) {
            toast?.removeToast(toastId);
        }
        toast?.displayToast(
            'error',
            'Some Members could not be processed',
            'An excel file with details will be download will begin shortly...',
            10000
        );
    };
    const handleUserData = async (membersList: Record<string, unknown>[]) => {
        const toastId = toast?.displayToast('warning', 'uploading...', 'This might take some time.', null);
        try {
            const result = await migrateDataOfDuplicateUsers(membersList);
            const { failedMigrations = [] } = result;
            if (failedMigrations.length > 0) {
                handleFailedMembers(failedMigrations, toastId);
            } else {
                if (toastId) {
                    toast?.removeToast(toastId);
                }
                toast?.displayToast('success', 'Members were processed successfully', '', 10000);
            }
        } catch (e) {
            if (toastId) {
                toast?.removeToast(toastId);
            }
            toast?.displayToast('error', 'Failed to Upload!', (e as Error).message, 8000);
        }
    };
    const onUpload: IUploadPill['onUpload'] = async (files) => {
        try {
            const excelUtils = new ExcelUtils();
            const json = await excelUtils.excelToJson(files[0] as unknown as Blob);
            const sheet0 = Object.keys(json)[0];
            const excelJSON = json[sheet0];
            const memberList = getMemberListFromJson(excelJSON);
            if (memberList.length === 0) {
                toast?.displayToast('error', 'The Excelsheet is probably empty', '', 8000);
            } else {
                await handleUserData(memberList);
            }
        } catch (e) {
            toast?.displayToast('error', 'Failed to upload!', (e as Error).message);
            return;
        }
    };
    return (
        <ExcelUploader onUpload={onUpload} templateUrl={EXCEL_TEMPLATES.DATA_DISCREPANCY} title="DATA DISCREPANCY" />
    );
};

export default DataDiscrepancy;
