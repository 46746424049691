import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { StyledCloseIcon, ModalContent, ModalSubtitle, ModalTitle, ModalWrapper } from './styles';
import { LoopModalProps } from './types';
import { CloseIcon } from '../../../assets/img';

const LoopModal: React.FC<LoopModalProps> = ({ isVisible, onClose, title, subtitle, children }) => {
    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if ((event.target as HTMLElement).classList.contains('modal-wrapper')) {
                onClose();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    if (!isVisible) return null;

    return createPortal(
        <ModalWrapper className="modal-wrapper">
            <ModalContent>
                <StyledCloseIcon onClick={onClose} src={CloseIcon} alt="close add member modal"/>
                {title && <ModalTitle>{title}</ModalTitle>}
                {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
                {children}
            </ModalContent>
        </ModalWrapper>,
        document.body
    );
};

export default LoopModal;
