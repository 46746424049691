import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 340px;
  overflow-y: auto;
`;

export const StyledCDAccountList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  gap: 16px;
`;

export const StyledCDAccountCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  padding: 16px 20px;
  background: #F6F6F6;
`;
export const StyledAccountNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledLinkedPoliciesLabel = styled.span`
  letter-spacing: 0.2px;
  opacity: 0.8;
  font-weight: 500;
  font-size: ${(p) => p.theme.fontSizes.label};
`;
export const StyledPolicyListContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

export const StyledPolicyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  margin-right: 50px;
  align-items: center;
`;
export const StyledPolicyTypeLabel = styled.div`
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: ${(p) => p.theme.fontSizes.subHeading};
  padding-right: 10px;
  border-right: 1px solid #595959;
`;


