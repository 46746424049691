import {
    fetchCdAccountByPolicyId,
    fetchEndorsementByID,
    fetchEstimatesForIncorrectLives
} from '../../../../../adapters';
import { IMemberUpload, IRejectedEntries } from '../../../../../redux/slices/ValidatorSlice/types';
import { IEndoResult } from '../../../../containers/ValidationSummaryDetails/types';
import { IPolicyOperations, ITableEntry } from '../../../../containers/ValidationSummaryTable/types';
import { formatCurrencyElseDefault, textPlaceholder } from '../../../../containers/ValidationSummaryTable/utils';
import {
    ICountData,
    ICountDetails,
    IEndorsementContext,
    IEndorsementWithCdOverview,
    IFinalData,
    IMemberDataToUpload,
    OperationResult,
    ValidatorResult
} from './types';

export const getFinalCorrectData = (
    uploadedData: IMemberUpload | null,
    rejectedEntries: IRejectedEntries | null
): IFinalData => {
    const finalAddData: Record<string, unknown>[] = [];
    const finalEditData: Record<string, unknown>[] = [];
    const finalOffboardData: Record<string, unknown>[] = [];
    uploadedData?.add.map((addRecord) => {
        const rejectedRecord = rejectedEntries?.add.find(
            (rejectedRecord) =>
                rejectedRecord.employeeId === addRecord.employeeId &&
                (rejectedRecord.relationship_to_account_holders as string).toLowerCase() ===
                    (addRecord.relationship_to_account_holders as string).toLowerCase() &&
                (rejectedRecord.name as string).toLowerCase() === (addRecord.name as string).toLowerCase()
        );
        if (!rejectedRecord) finalAddData.push(addRecord);
    });
    uploadedData?.edit.map((addRecord) => {
        const rejectedRecord = rejectedEntries?.edit.find(
            (rejectedRecord) =>
                rejectedRecord.userId === addRecord.userId &&
                (rejectedRecord.relationship_to_account_holders as string).toLowerCase() ===
                    (addRecord.relationship_to_account_holders as string).toLowerCase() &&
                (rejectedRecord.name as string).toLowerCase() === (addRecord.name as string).toLowerCase()
        );
        if (!rejectedRecord) finalEditData.push(addRecord);
    });
    uploadedData?.offboard.map((offRecord) => {
        const rejectedRecord = rejectedEntries?.offboard.find(
            (rejectedRecord) => rejectedRecord.userId === offRecord.userId
        );
        if (!rejectedRecord) finalOffboardData.push(offRecord);
    });

    return { finalAddData, finalEditData, finalOffboardData };
};

export const getCountData = (
    uploadedData: Record<string, unknown>[] | undefined,
    result: OperationResult | undefined,
    rejectedEntries: Record<string, unknown>[] | undefined
): ICountData => {
    const totalCount = uploadedData?.length || 0;
    const incorrectCount = (result?.incorrectCount || 0) + (rejectedEntries?.length || 0);
    const correctCount = totalCount - incorrectCount;
    return {
        totalCount,
        incorrectCount,
        correctCount
    };
};

export const getEndoResultType = (countData: ICountDetails): IEndoResult => {
    const isCorrect =
    countData.ADD.correctCount +
      countData.EDIT.correctCount +
      countData.OFFBOARD.correctCount >
    0;
    const isIncorrect =
    countData.ADD.incorrectCount +
      countData.EDIT.incorrectCount +
      countData.OFFBOARD.incorrectCount >
    0;
    if (isCorrect && isIncorrect) return 'PARTIAL';
    else if (isCorrect) return 'CORRECT';
    return 'INCORRECT';
};

export const getUniqueEndorsementContexts = (
    endorsementContexts: IEndorsementContext[]
) : IEndorsementContext[] => {
    const uniqueEndorsementContext = endorsementContexts.reduce((prev, current) => {
        return {
            ...prev,
            [current.endorsementId]: {
                endorsementId: current.endorsementId,
                endorsementDetails: [
                    ...(prev[current.endorsementId]?.endorsementDetails || []),
                    ...current.endorsementDetails]
            }
        };
    }, {} as Record<string, IEndorsementContext>);
    return Object.values(uniqueEndorsementContext);
};

export const fetchEndorsementsAndCdOverviewById = async (
    endorsementContexts: IEndorsementContext[]
): Promise<IEndorsementWithCdOverview[]> => {
    const uniqueEndorsementContexts = getUniqueEndorsementContexts(endorsementContexts);
    const endorsements: (IEndorsementWithCdOverview | null)[] = await Promise.all(
        uniqueEndorsementContexts.map(async (endorsementContext) => {
            if (!endorsementContext.endorsementId) {
                return null;
            }
            let endorsement = undefined;
            let cdAccount = undefined;
            try {
                const endorsementResponse = await fetchEndorsementByID(endorsementContext.endorsementId);
                endorsement = endorsementResponse.data;
            } catch (e) {
                return null;
            }
            try {
                const cdAccountResponse = await fetchCdAccountByPolicyId(endorsement.policyId);
                cdAccount = cdAccountResponse.data;
            } catch (e) {
                return {
                    endorsement,
                    contextMembers: endorsementContext.endorsementDetails
                };
            }
            return {
                endorsement, contextMembers: endorsementContext.endorsementDetails, cdAccount
            };
        })
    );
    return endorsements.filter((endo): endo is IEndorsementWithCdOverview => Boolean(endo));
};

export const fetchEstimatedCostForIncorrect = async (
    policyID: string | undefined,
    companyId: string | undefined,
    incorrectEntries: IMemberDataToUpload
): Promise<ITableEntry> => {
    const totalIncorrectCount =
    incorrectEntries.ADD.length +
    incorrectEntries.EDIT.length +
    incorrectEntries.OFFBOARD.length;
    if (totalIncorrectCount && policyID && companyId) {
        try {
            const incorrectTableEntry = await fetchEstimatesForIncorrectLives(
                policyID,
                companyId,
                incorrectEntries
            );
            const emptyOperation = {
                lives: 0,
                costOfEndorsement: 0
            };
            const entries: IPolicyOperations[] = await Promise.all(
                Object.keys(incorrectTableEntry).map(async (policyId) => {
                    const {
                        ADD = emptyOperation,
                        EDIT = emptyOperation,
                        policyDetails
                    } = incorrectTableEntry[policyId];
                    const totalCost =
                      ADD.costOfEndorsement +
                      EDIT.costOfEndorsement;
                    let cdAccount = null;
                    try {
                        const cdAccountResponse = await fetchCdAccountByPolicyId(
                            policyID
                        );
                        cdAccount = cdAccountResponse.data;
                    } catch (e) {}
                    const cdBalance = cdAccount?.balance || 0;
                    const isCdSufficient =
                      !!cdAccount && cdBalance - totalCost >= 0;
                    const requiredOrNew = isCdSufficient
                        ? totalCost < 0
                            ? cdBalance + totalCost
                            : 0
                        : totalCost - cdBalance;
                    return {
                        policyId,
                        policyType: policyDetails.policyType,
                        ADD: {
                            lives: textPlaceholder(ADD.lives),
                            costOfEndorsement: formatCurrencyElseDefault( ADD.costOfEndorsement, ADD.lives)
                        },
                        EDIT: {
                            lives: textPlaceholder(EDIT.lives),
                            costOfEndorsement: formatCurrencyElseDefault( EDIT.costOfEndorsement, EDIT.lives)
                        },
                        DELETE: {
                            lives: 'NA',
                            costOfEndorsement: 'NA'
                        },
                        cdBalance,
                        isCdSufficient,
                        isCdMissing: !cdAccount,
                        policyCost: totalCost,
                        requiredOrNew
                    };
                })
            );
            return {
                operations: entries,
                totalLives: totalIncorrectCount
            };
        } catch (err) {}
    }
    return {
        operations: [],
        totalLives: totalIncorrectCount
    };
};

export const getCorrectEndorsedData = (
    membersData: IMemberDataToUpload,
    result: ValidatorResult | null
): IMemberDataToUpload => {
    const { finalAddData: ADD, finalEditData: EDIT, finalOffboardData: OFFBOARD } = getFinalCorrectData(
        {
            add: membersData.ADD,
            edit: membersData.EDIT,
            offboard: membersData.OFFBOARD
        },
        {
            add: result?.ADD.incorrectMembers || [],
            edit: result?.EDIT.incorrectMembers || [],
            offboard: result?.OFFBOARD.incorrectMembers || []
        }
    );
    return {
        ADD,
        EDIT,
        OFFBOARD
    };
};
