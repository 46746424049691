import React from 'react';
import { IGenericIconProps } from './types';

const ExportIcon: React.FC<IGenericIconProps> = ({ width = 14, height = 14, color = '#595959' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9878 2.3694C12.9645 2.37014 12.9413 2.37217 12.9182 2.3755H9.875C9.79218 2.37433
            9.70995 2.38963 9.6331 2.42052C9.55624 2.4514 9.48629 2.49725 9.42731 2.5554C9.36833 2.61356
            9.3215 2.68285 9.28953 2.75926C9.25756 2.83567 9.2411 2.91768 9.2411 3.0005C9.2411 3.08333
            9.25756 3.16533 9.28953 3.24175C9.3215 3.31816 9.36833 3.38745 9.42731 3.4456C9.48629 3.50376
            9.55624 3.54961 9.6331 3.58049C9.70995 3.61138 9.79218 3.62668 9.875 3.6255H11.4912L5.68311
            9.43361C5.62312 9.4912 5.57523 9.56018 5.54224 9.63651C5.50925 9.71284 5.49182 9.79499 5.49098
            9.87814C5.49013 9.96129 5.50588 10.0438 5.53732 10.1208C5.56875 10.1977 5.61522 10.2677 5.67402
            10.3265C5.73282 10.3853 5.80276 10.4318 5.87975 10.4632C5.95673 10.4946 6.03922 10.5104 6.12237
            10.5095C6.20552 10.5087 6.28766 10.4913 6.36399 10.4583C6.44032 10.4253 6.5093 10.3774 6.56689
            10.3174L12.375 4.50929V6.1255C12.3738 6.20832 12.3891 6.29055 12.42 6.36741C12.4509 6.44426 12.4967
            6.51421 12.5549 6.57319C12.6131 6.63217 12.6823 6.67901 12.7588 6.71098C12.8352 6.74294 12.9172
            6.75941 13 6.75941C13.0828 6.75941 13.1648 6.74294 13.2412 6.71098C13.3177 6.67901 13.3869 6.63217
            13.4451 6.57319C13.5033 6.51421 13.5491 6.44426 13.58 6.36741C13.6109 6.29055 13.6262 6.20832 13.625
            6.1255V3.07985C13.6374 2.98987 13.6301 2.89825 13.6036 2.81138C13.577 2.72451 13.5318 2.64448 13.4712
            2.57685C13.4105 2.50923 13.3358 2.45564 13.2524 2.41981C13.1689 2.38398 13.0786 2.36678 12.9878
            2.3694ZM3.625 2.3755C2.94184 2.3755 2.375 2.94234 2.375 3.6255V12.3755C2.375 13.0587 2.94184 13.6255
            3.625 13.6255H12.375C13.0582 13.6255 13.625 13.0587 13.625 12.3755V8.6255C13.6262 8.54269 13.6109
            8.46046 13.58 8.3836C13.5491 8.30675 13.5033 8.2368 13.4451 8.17782C13.3869 8.11883 13.3177 8.072
            13.2412 8.04003C13.1648 8.00807 13.0828 7.9916 13 7.9916C12.9172 7.9916 12.8352 8.00807 12.7588
            8.04003C12.6823 8.072 12.6131 8.11883 12.5549 8.17782C12.4967 8.2368 12.4509 8.30675 12.42 8.3836C12.3891
            8.46046 12.3738 8.54269 12.375 8.6255V12.3755H3.625V3.6255H7.375C7.45782 3.62668 7.54005 3.61138 7.6169
            3.58049C7.69376 3.54961 7.76371 3.50376 7.82269 3.4456C7.88167 3.38745 7.9285 3.31816 7.96047
            3.24175C7.99244 3.16533 8.0089 3.08333 8.0089 3.0005C8.0089 2.91768 7.99244 2.83567 7.96047 2.75926C7.9285
            2.68285 7.88167 2.61356 7.82269 2.5554C7.76371 2.49725 7.69376 2.4514 7.6169 2.42052C7.54005 2.38963
            7.45782 2.37433 7.375 2.3755H3.625Z" fill={color}/>
        </svg>
    );
};

export { ExportIcon };
