import styled from 'styled-components';
import { buttonBackgroundColors, buttonTextColors, IButtonType } from './types';


export const StyledButton = styled.button<{ buttonType: IButtonType, width?: string }>`
  width: ${(p) => p.width || '100%'};
  letter-spacing: 0.2px;
  padding: 12px 24px;
  font-weight: 500;
  border-radius: 13px;
  border: ${(p) => p.buttonType === 'outline' ? `1.5px solid ${p.theme.colors.primary}` : ''};
  cursor: ${(p) => p.buttonType === 'disabled' ? 'not-allowed' : 'pointer'};
  background-color: ${(p) => buttonBackgroundColors[p.buttonType]};
  color: ${(p) => buttonTextColors[p.buttonType]};
  font-size: ${(p) => p.theme.fontSizes.subHeading};
`;
