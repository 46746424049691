import React, { useRef, useState } from 'react';
import { DateRange } from 'react-date-range-ts';
import { MaybeEmptyRange, NotFullyEmptyRange } from 'react-date-range-ts/dist/types';
import 'react-date-range-ts/dist/styles.css';
import 'react-date-range-ts/dist/theme/default.css';

declare type Range = MaybeEmptyRange<Date | null>[] & NotFullyEmptyRange<Date>[];
export interface ICustomDateRange {
    startDate?:Date;
    endDate?:Date|null;
    onSelectChange(value: any):void;
}
const CustomDateRange: React.FC<ICustomDateRange> = ({
    startDate,
    endDate,
    onSelectChange
}) => {
    const now = useRef(new Date());
    const [ranges, setRanges] = useState<Range>([
        {
            startDate: !startDate ? now.current : startDate,
            endDate: !endDate ? null : endDate,
            key: 'selection'
        }
    ]);
    return <DateRange
        dateDisplayFormat={'dd/MM/yyyy'}
        // minDate={subMonths(now.current, 6)}
        maxDate={now.current}
        ranges={ranges}
        weekStartsOn={0}
        moveRangeOnFirstSelection={false}
        onChange={(item) => {
            if (!item || item.selection === undefined) {
                return;
            }
            const { startDate, endDate } = item.selection;
            onSelectChange({ startDate, endDate });
            setRanges([item.selection] as Range);
        }}
    />;
};

export default CustomDateRange;
