import theme from '../../../theme';
import React from 'react';

export type IButtonType =
    'filled' | 'outline' | 'error' | 'disabled'

export interface IButtonProps {
    children?: React.ReactNode;
    width?: string;
    type: IButtonType;
    onClick: (e: React.MouseEvent) => void;
}

export const buttonBackgroundColors: Record<IButtonType, string> = {
    'filled': theme.colors.coverUpGreen,
    'outline': theme.colors.white,
    'error': theme.colors.planRed,
    'disabled': 'rgba(89, 89, 89, 0.1)'
};
export const buttonTextColors: Record<IButtonType, string> = {
    'filled': theme.colors.primary,
    'outline': theme.colors.primary,
    'error': theme.colors.white,
    'disabled': theme.colors.beyondGrey
};
