import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledCompanyCard, StyledContainer, StyledPolicyFormContainer } from './styles';
import { PageHeader } from '../../../../atoms';
import PolicyForm from '../../../../containers/AddPolicy/PolicyForm';
import { CompanyCard } from '../../../../containers';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { IPolicy } from '../../../../../redux/slices/PolicySlice/types';
import { fetchActivePoliciesList } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';

const AddPolicy: FC = () => {
    const toast = useToast();
    const location = useLocation();
    const { companyId } = location.state;
    const [activePolicyList, setActivePolicyList] = React.useState<{ policies: IPolicy[] } & { loading: boolean }>({
        policies: [],
        loading: true
    });
    const selectedCompany = useSelector(
        (state: ReduxState) => state.companySlice.companyList.data?.filter((company) => company.id === companyId)[0]
    );

    const fetchActivePolicies = async () => {
        try {
            !activePolicyList.loading && setActivePolicyList({ policies: activePolicyList.policies, loading: true });
            const activePolicyListResponse = (await fetchActivePoliciesList(companyId)).data;
            setActivePolicyList({ policies: [...activePolicyListResponse], loading: false });
        } catch (e) {
            toast?.displayToast('error', '', 'An error occurred, try refreshing the page');
        }
    };

    useEffect(() => {
        fetchActivePolicies();
    }, []);

    return (
        <StyledContainer>
            <PageHeader>Add a policy</PageHeader>
            <StyledPolicyFormContainer>
                <PolicyForm companyId={companyId} activePolicyList={activePolicyList.policies} />
                <StyledCompanyCard>
                    {selectedCompany && <CompanyCard {...selectedCompany} isPlacementForm={false} />}
                </StyledCompanyCard>
            </StyledPolicyFormContainer>
        </StyledContainer>
    );
};

export default AddPolicy;
