import React, { useMemo } from 'react';
import { LoopButton, Typography, FullScreenLoader, Modal, Dialog, Loader } from '@loophealth/loop-ui-web-library';
import formattor from './ErrorExcelDownload/formattor';
import {
    AddProfileIcon,
    DeleteProfileIcon,
    EditProfileIcon,
    ErrorRetry,
    FourStartIcon,
    GreenBackgroundPattern,
    GreyBackgroundPattern,
    RedBackgroundPattern,
    RotateLeftIcon
} from '../../../../../assets/img';
import {
    StyledContainer,
    StyledTitle,
    StyledTitleContainer,
    StyledBodyContainer,
    StyledLeftContainer,
    StyledRightContainer,
    StyledIcon,
    StyledStepsTitle,
    StyledSeparator,
    StyledBodyWithButtonContainer
} from './styles';
import { ValidationSummaryCard, ValidationSummaryStep } from '../../../../containers';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { unifiedUploadAPI } from '../../../../../adapters';
import { ICountDetails, IEndoResultData, ValidatorResult } from './types';
import { downloadErrorExcel, getErrorExcelFile } from './ErrorExcelDownload/DownloadErrorExcel';
import { resetState } from '../../../../../redux/slices/ValidatorSlice';
import {
    fetchEndorsementsAndCdOverviewById,
    fetchEstimatedCostForIncorrect,
    getCorrectEndorsedData,
    getCountData,
    getFinalCorrectData
} from './utils';
import { showApiFailureToast } from '../../../../../utils/common';
import { useToast } from '../../../../hooks/useToast';
import { isFreshdeskValidatorIntegrated } from '../../../../../utils/featureFlags';
import ValidationSummaryDetails from '../../../../containers/ValidationSummaryDetails';

const ValidationSummary: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const [isLoading, setIsLoading] = React.useState(false);
    const [result, setResult] = React.useState<ValidatorResult | null>(null);
    const [endorsementLink, setEndorsementLink] = React.useState('');
    const [restartModal, setRestartModal] = React.useState<boolean>(false);
    const uploadedData = useSelector((state: ReduxState) => state.validatorSlice.membersToUpload);
    const rejectedEntries = useSelector((state: ReduxState) => state.validatorSlice.rejectedEntries);
    const { finalAddData, finalEditData, finalOffboardData } = getFinalCorrectData(uploadedData, rejectedEntries);
    const dataToUpload = useSelector((state: ReduxState) => ({
        companyId: state.validatorSlice.companyId || '',
        policyId: state.validatorSlice.policyId || undefined,
        enableEnrolmentPortal: state.validatorSlice.enableEnrolmentPortal || false,
        enableEndorsements: state.validatorSlice.withEndorsements,
        membersList: {
            ADD: finalAddData || [],
            EDIT: finalEditData || [],
            OFFBOARD: finalOffboardData || []
        }
    }));
    const [countData, setCountData] = React.useState<ICountDetails>();
    const [endoResult, setEndoResult] = React.useState<IEndoResultData>();
    const [errorFile, setErrorFile] = React.useState<Blob>();

    const incorrectEntries = useMemo(()=> {
        const rejectedAddEntries = rejectedEntries?.add || [];
        const rejectedEditEntries = rejectedEntries?.edit || [];
        const rejectedOffboardEntries = rejectedEntries?.offboard || [];
        return {
            ADD: result ? result.ADD.incorrectMembers.concat(rejectedAddEntries): rejectedAddEntries,
            EDIT: result ? result.EDIT.incorrectMembers.concat(rejectedEditEntries) : rejectedEditEntries,
            OFFBOARD: result ?
                result.OFFBOARD.incorrectMembers.concat(rejectedOffboardEntries) : rejectedOffboardEntries
        };
    }, [result]);

    React.useEffect(() => {
        const addCount = dataToUpload.membersList.ADD.length;
        const editCount = dataToUpload.membersList.EDIT.length;
        const offboardCount = dataToUpload.membersList.OFFBOARD.length;
        const rejectedErrors = rejectedEntries?.edit?.length ||
            rejectedEntries?.add?.length || rejectedEntries?.offboard?.length;
        if (addCount + editCount + offboardCount > 0) {
            setIsLoading(true);
            uploadMembers();
        } else if (rejectedErrors) {
            handleTableData();
        } else {
            navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.VALIDATOR.route}`);
        }
    }, []);

    const navigateToValidator = () => {
        dispatch(resetState());
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
    };

    const handleTableData = async () => {
        const formattedIncorrectMembersData =
            {
                ADD: formattor.add(incorrectEntries.ADD),
                EDIT: formattor.edit(incorrectEntries.EDIT),
                OFFBOARD: formattor.offboard(incorrectEntries.OFFBOARD)
            };
        const errorExcel = getErrorExcelFile(formattedIncorrectMembersData);
        setErrorFile(errorExcel);
        const endorsementContexts = (result?.endorsementContexts || []);
        const endoId = endorsementContexts[0]?.endorsementId || endorsementContexts[1]?.endorsementId;
        if (endoId && endoId.length) {
            setEndorsementLink(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.ENDORSEMENT_DETAILS.route}?id=${endoId}`);
        }

        const countData: ICountDetails = {
            ADD: getCountData(uploadedData?.add, result?.ADD, rejectedEntries?.add),
            EDIT: getCountData(uploadedData?.edit, result?.EDIT, rejectedEntries?.edit),
            OFFBOARD: getCountData(uploadedData?.offboard, result?.OFFBOARD, rejectedEntries?.offboard)
        };
        setCountData(countData);
        const correctEntries = getCorrectEndorsedData(dataToUpload.membersList, result);
        const [endorsementsData, incorrectTableEntries] = await Promise.all([
            fetchEndorsementsAndCdOverviewById(endorsementContexts),
            fetchEstimatedCostForIncorrect(dataToUpload.policyId, dataToUpload.companyId, incorrectEntries)
        ]);
        setEndoResult({
            endorsements: endorsementsData,
            countData: countData,
            correctEntries,
            incorrectTableEntries: incorrectTableEntries
        });
    };

    const handleDownloadErrors = () => {
        if (errorFile) {
            downloadErrorExcel(
                errorFile,
                `Validation-Summary-${new Date().toLocaleString()}`
            );
        }
    };

    React.useEffect(() => {
        if (result !== null) {
            handleTableData();
        }
    }, [result]);

    React.useEffect(() => {
        if (errorFile) {
            handleDownloadErrors();
        }
    }, [errorFile]);

    const uploadMembers = async () => {
        const { companyId, policyId, enableEndorsements, enableEnrolmentPortal, membersList } = dataToUpload;
        try {
            const result = await unifiedUploadAPI(
                companyId,
                policyId,
                enableEnrolmentPortal,
                !!enableEndorsements,
                membersList
            );
            setResult(result);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            navigateToValidator();
            showApiFailureToast(toast, (error as Error).toString());
        }
    };
    const onEndoDetailsClick = () => {
        endorsementLink.length && navigate(endorsementLink);
    };

    const stepBody = (data: string) => {
        return (
            <Typography variant="medium" weight="regular">
                {data}
            </Typography>
        );
    };

    const stepBodyWithButton = (data: string, buttonText: string) => {
        return (
            <StyledBodyWithButtonContainer>
                <Typography variant="medium" weight="regular">
                    {data}
                </Typography>
                {!!endorsementLink.length && (
                    <div>
                        <LoopButton variant={'filled'} size="small" onClick={onEndoDetailsClick}>
                            {buttonText}
                        </LoopButton>
                    </div>
                )}
            </StyledBodyWithButtonContainer>
        );
    };

    const handleValidateNewFile = () => {
        dispatch(resetState());
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
    };

    if (isLoading) {
        return <FullScreenLoader title="Validating your data..." subTitle="We are validating requests data" />;
    }

    return (
        <StyledContainer>
            <StyledTitleContainer>
                <StyledTitle>
                    <Typography variant="title3" weight="medium">
                        Validated Member Data
                    </Typography>
                    <div>
                        <LoopButton
                            variant={'outline'}
                            size="small"
                            onClick={() => setRestartModal(true)}
                            iconOrder="left"
                            iconSrc={RotateLeftIcon}
                        >
                            Validate New File
                        </LoopButton>
                    </div>
                </StyledTitle>
            </StyledTitleContainer>

            <StyledBodyContainer>
                <StyledLeftContainer>
                    {(countData?.ADD.totalCount || 0) > 0 && (
                        <ValidationSummaryCard
                            background={GreyBackgroundPattern}
                            cardType="Add"
                            totalLives={countData?.ADD.totalCount || 0}
                            correctLives={countData?.ADD.correctCount || 0}
                            incorrectLives={countData?.ADD.incorrectCount || 0}
                            icon={AddProfileIcon}
                        />
                    )}

                    {(countData?.EDIT.totalCount || 0) > 0 && (
                        <ValidationSummaryCard
                            background={GreenBackgroundPattern}
                            cardType="Edit"
                            totalLives={countData?.EDIT.totalCount || 0}
                            correctLives={countData?.EDIT.correctCount || 0}
                            incorrectLives={countData?.EDIT.incorrectCount || 0}
                            icon={EditProfileIcon}
                        />
                    )}

                    {(countData?.OFFBOARD.totalCount || 0) > 0 && (
                        <ValidationSummaryCard
                            background={RedBackgroundPattern}
                            cardType="Delete"
                            totalLives={countData?.OFFBOARD.totalCount || 0}
                            correctLives={countData?.OFFBOARD.correctCount || 0}
                            incorrectLives={countData?.OFFBOARD.incorrectCount || 0}
                            icon={DeleteProfileIcon}
                        />
                    )}
                </StyledLeftContainer>

                {isFreshdeskValidatorIntegrated ?
                    <StyledRightContainer>
                        {endoResult ?
                            <ValidationSummaryDetails
                                result={endoResult}
                                handleDownloadErrors={handleDownloadErrors}
                                errorFile={errorFile}
                            /> : <Loader/>}
                    </StyledRightContainer>
                    :
                    <StyledRightContainer>
                        <StyledStepsTitle>
                            <StyledIcon src={FourStartIcon} />
                            <Typography variant="medium" weight="medium">
                            What to do Next
                            </Typography>
                        </StyledStepsTitle>
                        <StyledSeparator />

                        <ValidationSummaryStep
                            icon={true}
                            body={stepBody('We have already uploaded correct lives on Genome')}
                        />

                        <ValidationSummaryStep
                            icon={true}
                            body={stepBody(
                                'Error log file has been auto-downloaded in your device, access it directly from there.'
                            )}
                        />

                        <ValidationSummaryStep
                            stepNumber={3}
                            body={
                                stepBody('Write a summary message on Freshdesk and attach an error log along with it.')
                            }
                        />

                        {(uploadedData?.add.length || 0) + (uploadedData?.edit.length || 0) !== 0 && (
                            <>
                                <ValidationSummaryStep
                                    stepNumber={4}
                                    body={stepBodyWithButton(
                                        'From endo tool, copy endorsement cost and download insurer format data file.',
                                        'Open Endo Tool'
                                    )}
                                />

                                <ValidationSummaryStep
                                    stepNumber={5}
                                    body={stepBody(
                                    // eslint-disable-next-line max-len
                                        'Paste the endorsement cost and attach the insurer format file in the same freshdesk message that you prepared in 3rd step.'
                                    )}
                                />
                            </>
                        )}
                    </StyledRightContainer>
                }
            </StyledBodyContainer>

            <Modal isVisible={restartModal} setIsVisible={setRestartModal}>
                <Dialog
                    variant="vertical"
                    icon={ErrorRetry}
                    description="Starting new means losing your progress and having to put in your details again."
                    title={'Are you sure you want to start fresh?'}
                    primaryButtonText="Yes, Start Fresh"
                    onPrimaryButtonClicked={handleValidateNewFile}
                    onSecondaryButtonClicked={() => setRestartModal(false)}
                    secondaryButtonText="Cancel"
                    buttonVariant="error"
                />
            </Modal>
        </StyledContainer>
    );
};

export default ValidationSummary;
