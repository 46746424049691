import { createAsyncThunk } from '@reduxjs/toolkit';
import { createCompanyAPI, fetchAllCompaniesAPI, uploadCompanyLogoAPI } from '../../../adapters';
import { ICompanyData } from './types';

const COMPANYSLICE_ACTION_TYPES = {
    FETCH_COMPANY_LIST: 'FETCH_COMPANY_LIST',
    CREATE_COMPANY: 'CREATE_COMPANY'
};
export const fetchCompanies = createAsyncThunk(COMPANYSLICE_ACTION_TYPES.FETCH_COMPANY_LIST, async () => {
    const res = await fetchAllCompaniesAPI();
    return {
        companies: (res as unknown as ICompanyData[]).sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    };
});

type ICreateCompanyProps = {
    name: string;
    logoFile?: Blob;
    gstNumber: string;
    panNumber: string;
    regdAddress: string;
    salesforceId: string;
    nickname: string;
    spocName?: string;
    spocEmail?: string;
    spocPhoneNumber?: string;
    navigate(): void;
};
export const createCompany = createAsyncThunk(
    COMPANYSLICE_ACTION_TYPES.CREATE_COMPANY,
    async ({
        gstNumber,
        logoFile,
        name,
        nickname,
        panNumber,
        salesforceId,
        regdAddress,
        spocEmail,
        spocName,
        spocPhoneNumber,
        navigate
    }: ICreateCompanyProps) => {
        const company = {
            gstNumber,
            name,
            spocName,
            spocEmail,
            regdAddress,
            spocPhoneNumber,
            panNumber,
            nickname,
            salesforceId,
            policyPlans: [],
            companyLogo: (logoFile as File)?.name || ''
        };
        const result = await createCompanyAPI(company);
        if (logoFile) await uploadCompanyLogoAPI(logoFile as unknown as Record<string, unknown>);
        if (result?.id) {
            navigate();
        }
        return {
            company: result
        };
    }
);
