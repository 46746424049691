import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledPolicyForm = styled.section`
    flex: 1;
    margin-top: 20px;
`;

export const StyledPolicyInputs = styled.div`
    border: 1px solid ${theme.colors.defaultBorder};
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 48px 80px;
`;

export const StyledPolicySubmitButtonContainer = styled.div`
    border: 1px solid ${theme.colors.defaultBorder};
    border-top: none;
    border-radius: 0 0 16px 16px;
    padding: 24px;
`;

export const StyledPolicySubmitButton = styled.div`
    width: 350px;
    margin: auto;
`;

export const StyledRadioInputGroup = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledRadioButtonContainer = styled.div`
    display: flex;
    > div {
        margin: 0;
        > div {
            margin: 0;
            > div {
                margin-left: 0;
            }
            > div:last-child {
                margin-right: 21px;
            }
        }
    }
`;

export const StyledHorizontalDivider = styled.div`
    height: 1px;
    min-width: 100%;
    background-color: ${theme.colors.defaultBorder};
`;

export const StyledUploadPillContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledUploadPillHelper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTemplateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-radius: 12px;
    background-color: ${theme.colors.surface};
`;

export const StyledTemplateInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledTemplateButton = styled.div`
    width: 90px;
`;

export const StyledTemplateInfoTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledUploadPill = styled.div`
    height: 150px;
`;

export const StyledSelectedFile = styled.div`
    > div {
        padding: 16px 20px;
    }
`;
