import styled from 'styled-components';

export const StyledEndorsementCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.defaultBorder};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledEndorsementDetailsWrapper = styled.div`
  display: flex;
  padding: 16px 16px 20px 16px;
  justify-content: space-between;
  margin: 8px 8px 0;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: ${({ theme }) => theme.colors.surface};
`;

export const StyledEndorsementDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledEndorsementDetailsTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledEndoPolicyDetailsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 32px 24px;
  align-items: start;
`;

export const StyledEndoPolicyDetails = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  align-self: center;
`;

export const StyledEndoPolicyDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledEndoPolicyDetailsSeperator = styled.div`
  height: 40px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.defaultBorder};
`;

export const StyledBadgeContainer = styled.div`
  display: flex;
  gap: 8px;
`;
