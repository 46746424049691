import React from 'react';
import { excelToJson, fileDownload } from '../../../utils/common';
import { useToast } from '../../hooks/useToast';
import { UploadPill } from '../../atoms';
import { excelDateToJSDate } from '../../pages/SPAContainer/pages/PremiumCalculator/utils';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import { EXCEL_TEMPLATES } from '../../../utils/constants/TemplateFilepaths';
interface IRaterUploader {
    policyType: 'GMC' | 'GTL' | 'GPA' | 'COVID';
    policyId: string;
    uploadRater: (rator: Record<string, unknown>) => Promise<void>;
}
const RaterUploader: React.FunctionComponent<IRaterUploader> = ({ policyType, uploadRater, policyId }) => {
    const toast = useToast();
    function downloadZipFile() {
        fileDownload(EXCEL_TEMPLATES.PREMIUM_RATERS);
    }
    const readGMCRater = (file: Blob): Promise<Record<string, unknown>> => {
        return new Promise((res, rej) => {
            excelToJson(file, (excelJSON) => {
                const policyDates = excelJSON[0];
                const pStartDate = policyDates['Policy Start Date'] as number;
                const pEndDate = policyDates['Policy End Date'] as number;
                const premiumType = policyDates['Premium Type'] as string;
                if (!pStartDate) {
                    rej(new Error('Policy Start Date is required!'));
                }
                if (!pEndDate) {
                    rej(new Error('Policy End Date is required!'));
                }
                if (!premiumType) {
                    rej(new Error('Premium Type is required!'));
                }
                const policyStartDate = excelDateToJSDate(pStartDate);
                const policyEndDate = new Date(excelDateToJSDate(pEndDate).setHours(23, 59, 59, 0));
                const isPerFamily = premiumType.toString().trim() === 'Per Family';
                const raterTable: Record<string, Record<string, unknown>> = {};
                excelJSON.map((obj) => {
                    const indentifier = `${obj['Min Age']}-${obj['Max Age']}`;
                    if (indentifier.includes('undefined')) {
                        rej(new Error('Could not find age band for this rator. Please check format of the excel.'));
                    }
                    const ageBandObject: Record<string, number> = {};
                    Object.keys(obj).map((k) => {
                        if (
                            k === 'Policy Start Date' ||
                            k === 'Policy End Date' ||
                            k === 'Min Age' ||
                            k === 'Max Age' ||
                            k === 'Premium Type'
                        ) {
                            return;
                        }
                        ageBandObject[k] = parseFloat(obj[k] as string);
                        if (isNaN(ageBandObject[k])) {
                            rej(new Error('Incorrect Premium Format present in the rater'));
                        }
                    });
                    raterTable[indentifier] = ageBandObject;
                });
                const raterConfig = {
                    policyStartDate,
                    policyEndDate,
                    raterTable,
                    policyId,
                    policyType,
                    isPerFamily
                };
                res(raterConfig);
            });
        });
    };
    const readGTLGPARater = (file: Blob): Promise<Record<string, unknown>> => {
        return new Promise((res, rej) => {
            excelToJson(file, (excelJSON) => {
                const policyDates = excelJSON[0];
                const pStartDate = policyDates['Policy Start Date'] as number;
                const pEndDate = policyDates['Policy End Date'] as number;
                const rpm: number = parseFloat(excelJSON[0]['Rate Per Mille'] as string);
                const premiumType = policyDates['Premium Type'] as string;
                if (!pStartDate) {
                    rej(new Error('Policy Start Date is required!'));
                }
                if (!pEndDate) {
                    rej(new Error('Policy End Date is required!'));
                }
                const policyStartDate = excelDateToJSDate(pStartDate);
                const policyEndDate = new Date(excelDateToJSDate(pEndDate).setHours(23, 59, 59, 0));
                if (!premiumType) {
                    rej(new Error('Premium Type is required!'));
                }
                const isPerFamily = premiumType.toString().trim() === 'Per Family';
                if (!rpm || isNaN(rpm)) {
                    rej(new Error('Could not find Rate Per Mille for this rater. Please check format of the excel.'));
                }
                const raterConfig = {
                    policyStartDate,
                    policyEndDate,
                    rpm,
                    policyId,
                    policyType,
                    isPerFamily
                };
                res(raterConfig);
            });
        });
    };
    const readRater: Record<string, (file: Blob) => Promise<Record<string, unknown>>> = {
        GMC: readGMCRater,
        RPM: readGTLGPARater
    };
    const onFileUpload = async (files: Record<string, unknown>[]) => {
        const file = files[0] as unknown as Blob;
        let ratorType = 'GMC';
        if (policyType !== 'GMC') {
            ratorType = 'RPM';
        }
        try {
            const rator = await readRater[ratorType](file);
            uploadRater(rator);
        } catch (e) {
            toast?.displayToast('error', 'Failed to upload', (e as Error).message);
        }
    };
    return (
        <>
            <UploadPill formats={['.xls', '.xlsx', '.xlsm']} onUpload={onFileUpload} title="Upload Rater" />
            <br />
            <LoopButton onClick={downloadZipFile} variant="filled">
                Download Template
            </LoopButton>
        </>
    );
};

export default RaterUploader;
