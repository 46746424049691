/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import React from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { IOption, IMultiSelect } from './type';

const MultiSelectAll: React.FC<IMultiSelect> = ({
    options,
    setSelectedOptions,
    selectedOptions
}: {
    options: IOption[];
    setSelectedOptions?: React.Dispatch<React.SetStateAction<string | null>>;
    selectedOptions?: any;
}) => {
    function getDropdownButtonLabel({
        placeholderButtonLabel,
        value
    }: {
        placeholderButtonLabel: string;
        value: { value: string }[];
    }) {
        if (value && value.some((o: { value: string }) => o.value === '*')) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    function onChange(this: any, value: any[], event: { action: string; option: { value: string } }) {
        if (event.action === 'select-option' && event.option.value === '*') {
            this.setState(this.options);
        } else if (event.action === 'deselect-option' && event.option.value === '*') {
            this.setState([]);
        } else if (event.action === 'deselect-option') {
            this.setState(value.filter((o: { value: string }) => o.value !== '*'));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
    }

    return (
        <ReactMultiSelectCheckboxes
            options={[{ label: 'All', value: '*' }, ...options]}
            placeholderButtonLabel="Policies"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptions || []}
            onChange={onChange}
            setState={setSelectedOptions}
        />
    );
};

export default MultiSelectAll;
