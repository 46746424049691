import React, { useState } from 'react';
import Dialog from '../../atoms/Dialog';
import { CreateCDAccountIcon, LinkCDAccountIcon } from '../../../assets/img';
import { IAttachCDAccountConfirmationProps } from './types';
import { ICDAccount } from '../../../redux/slices/CDAccountSlice/types';
import { fetchCDAccountList } from '../../../redux/slices/CDAccountSlice/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { createCdAccount, updateCdAccount } from '../../../adapters';
import { parseResponse } from '../../../utils/common';
import { useToast } from '../../hooks/useToast';
import { ReduxState } from '../../../redux';

const AttachCDAccountConfirmation: React.FunctionComponent<IAttachCDAccountConfirmationProps> = ({
    policyId,
    companyId,
    insurerName,
    isLinkCdAccount,
    selectedCDAccount,
    onAttachAccountSuccess,
    createCDAccountObj,
    handleModalClose
}) => {
    const [isAttaching, toggleAttachProcess] = useState(false);
    const dispatch = useDispatch();
    const toast = useToast();
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const addedBy = user ? `${user.firstName} ${user.lastName}` : '';

    const onLinkAccountClicked = async () => {
        if (!selectedCDAccount) return;
        toggleAttachProcess(true);
        const policies = [policyId];
        const requestBody = { ...selectedCDAccount, policies, balance: 0, policyData: selectedCDAccount.policies };
        const [error] = await parseResponse(updateCdAccount(requestBody as unknown as ICDAccount));
        onAttachSuccess(error);
    };

    const onAddAccountClicked = async () => {
        if (!createCDAccountObj) return;
        const cdAccount = {
            accountNumber: createCDAccountObj.accountNumber,
            addedBy: addedBy.trim(),
            companyId: companyId,
            insurer: insurerName,
            balance: createCDAccountObj.balance ? parseFloat(createCDAccountObj.balance) : 0,
            policies: [policyId],
            paymentProof: createCDAccountObj.paymentProof ? [createCDAccountObj.paymentProof] : []
        };
        toggleAttachProcess(true);
        const [error] = await parseResponse(createCdAccount(cdAccount));
        onAttachSuccess(error);
    };
    const onAttachSuccess = (error: Error) => {
        toggleAttachProcess(false);
        if (error) {
            toast?.displayToast('error', 'Unable to process', error.toString(), 5000);
            return;
        }
        dispatch(fetchCDAccountList());
        onAttachAccountSuccess();
    };

    return (
        <Dialog
            icon={isLinkCdAccount ? LinkCDAccountIcon : CreateCDAccountIcon}
            title={
                isLinkCdAccount
                    ? 'Are you sure you want to link this policy to the selected CD Account?'
                    : 'Are you sure you want to create a new CD Account for this policy?'
            }
            description=""
            primaryButtonText={isLinkCdAccount ? 'Yes, Link CD Account' : 'Yes, Create CD Account'}
            onPrimaryButtonClicked={isLinkCdAccount ? onLinkAccountClicked : onAddAccountClicked}
            isSecondaryButton={true}
            secondaryButtonText={'No, Cancel'}
            isLoading={isAttaching}
            onSecondaryButtonClicked={() => handleModalClose(false)}
        />
    );
};
export default AttachCDAccountConfirmation;
