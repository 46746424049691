import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchCompanies, createCompany } from './thunks';

const companySlice = createSlice({
    name: 'companySlice',
    initialState,
    reducers: {
        updateCompanyPolicyList(state, { payload }) {
            const { policyId, companyId } = payload;

            const currentState = state.companyList.data;
            const selectedCompany = currentState?.filter((comp) => comp.id === companyId)[0];
            const filteredCompanies = currentState?.filter((comp) => comp.id !== companyId) || [];

            if (selectedCompany) {
                const updatedPolicyList = (selectedCompany.policyPlans || []).slice();
                updatedPolicyList.push(policyId);

                const updatedCompany = { ...selectedCompany, policyPlans: updatedPolicyList };
                filteredCompanies?.push(updatedCompany);
            }
            state.companyList.data = filteredCompanies;
        },
        resetState(state) {
            state.companyList = initialState.companyList;
        }
    },
    extraReducers: {
        [fetchCompanies.pending as any]: (state) => {
            state.companyList.loading = true;
        },
        [fetchCompanies.rejected as any]: (state, { error }) => {
            state.companyList.error = error;
            state.companyList.loading = false;
            state.companyList.data = null;
        },
        [fetchCompanies.fulfilled as any]: (state, { payload }) => {
            state.companyList.error = null;
            state.companyList.loading = false;
            state.companyList.data = payload.companies;
        },
        [createCompany.pending as any]: (state) => {
            state.createCompany.loading = true;
        },
        [createCompany.rejected as any]: (state, { error }) => {
            state.createCompany.error = error;
            state.createCompany.loading = false;
            state.createCompany.data = null;
        },
        [createCompany.fulfilled as any]: (state, { payload }) => {
            state.createCompany.error = null;
            state.createCompany.loading = false;
            state.companyList.data = [...(state.companyList.data || []), payload.company];
        },
        'common/cleanup': (state) => {
            state.companyList = initialState.companyList;
        }
    }
});
export default companySlice.reducer;
export const { resetState, updateCompanyPolicyList } = companySlice.actions;
