import React from 'react';
import { TableData, TableRow } from '../../atoms';
import { StyledCapitalize } from '../MemberDetailsRow/styles';
import { formatCurrencyElseDefault } from '../../../utils/constants/Endorsement';
import { isEndoToolingEnabled } from '../../../utils/featureFlags';

export const CDLedgerRow: React.FunctionComponent<any> = ({ cdLedgerEntry }) => {
    let cdAccountEntryKeys = [
        'createdAt',
        'id',
        'policyNumber',
        'policyType',
        'transactionType',
        'endorsementNumber',
        'endorsementType',
        'amount',
        'source',
        'addedBy',
        'message'
    ];
    if (!isEndoToolingEnabled) {
        cdAccountEntryKeys = cdAccountEntryKeys.filter((item) => item !== 'addedBy');
    }
    const formatNotes = (value: string): string | React.ReactNode => {
        if (value.includes('Policy Booking Amount')) {
            const [txn, notes] = value.split(',');
            if (txn && notes) {
                const [txnLabel, amount] = txn.split(':');
                const [notesLabel, message] = notes.split(':');
                if (txnLabel && amount && notesLabel && message) {
                    return (
                        <div>
                            <div>
                                <b>{txnLabel}:</b> {formatCurrencyElseDefault(parseInt(amount.trim()))}
                            </div>
                            <div>
                                <b>{notesLabel}:</b> {message || 'N/A'}
                            </div>
                        </div>
                    );
                }
            }
        }
        return value || 'N/A';
    };
    const formatColumn = (key: string): string | React.ReactNode => {
        switch (key) {
        case 'amount':
            return formatCurrencyElseDefault(cdLedgerEntry[key]);
        case 'message':
            return formatNotes(cdLedgerEntry[key]);
        default:
            return cdLedgerEntry[key] || 'N/A';
        }
    };
    const renderCDEntryColumn = () => {
        return cdAccountEntryKeys.map((key, index) => (
            <TableData key={index}>
                <StyledCapitalize>{formatColumn(key)}</StyledCapitalize>
            </TableData>
        ));
    };
    return <TableRow>{renderCDEntryColumn()}</TableRow>;
};
