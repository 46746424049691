import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    width: 500px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px 30px;
    }
`;

export const StyledSubTitle = styled.span(
    (p) => `
    width: 100%;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    margin: 15px 0px;
`
);

export const StyledUploadPillWrapper = styled.div`
    width: 400px;
    margin: 30px 0px 0px 0px;
`;

export const StyledDownloadPillWrapper = styled.button`
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    width: 400px;
    padding: 20px;
    margin: 30px 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.primary};
    font-weight: bold;
    &:hover {
        color: ${(p) => p.theme.colors.primary};
        background-color: ${(p) => p.theme.colors.white};
        border: 1px solid ${(p) => p.theme.colors.primary};
    }
`;

export const StyledFieldContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
`;

export const StyledRowAlignment = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;
