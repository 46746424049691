import React, { useEffect } from 'react';
import { useToast } from '../../../../../hooks/useToast';
import { useDispatch } from 'react-redux';
import { setMembersToUpload } from '../../../../../../redux/slices/ValidatorSlice';
import { IUploadExcel } from '../types';
import BulkExcelUploadWorker from '../../../../../../workers/bulkExcelUpload.worker';

const useUploadExcel = (): IUploadExcel => {
    const toast = useToast();
    const dispatch = useDispatch();
    const [uploadedFile, setUploadedFile] = React.useState<Record<string, unknown>[]>([]);
    const [uploadError, setUploadError] = React.useState<string>('');

    useEffect(() => {
        if (uploadError) {
            toast?.displayToast('error', '', uploadError);
            setUploadError('');
        }
    }, [uploadError]);


    const uploadFile = async (file: Record<string, unknown>[]) => {
        try {
            return new Promise((res) => {
                const uploadWorker = new BulkExcelUploadWorker();
                uploadWorker.postMessage(file);
                uploadWorker.onmessage = (workerResponse) => {
                    if (workerResponse.data.error) {
                        toast &&
                            toast.displayToast &&
                            toast.displayToast(
                                'error',
                                'Empty File Found!',
                                'Could not find any members in the uploaded sheet'
                            );
                        setUploadedFile([]);
                    } else {
                        const formattedData = JSON.parse(workerResponse.data);
                        dispatch(setMembersToUpload(formattedData));
                    }
                    res(true);
                };
            });
        } catch (e) {
            toast && toast.displayToast && toast.displayToast('error', 'Failed to upload!', (e as Error).message);
            return;
        }
    };

    return { setUploadedFile, setUploadError, uploadedFile, uploadFile };
};

export default useUploadExcel;
