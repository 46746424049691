import { Form, LoopButton, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import {
    StyledFormContainer,
    StyledContainer,
    StyledEnrolmentReportContainer,
    StyledFormFieldWidth,
    StyledSectionWrapper,
    StyledHorizontalDivider,
    StyledInfoLabelWrapper,
    StyledFormHeaderWrapper,
    StyledArrowIcon,
    StyledEmptyEnrolmentCard,
    StyledHeaderContainer,
    StyledLoopButtonContainer,
    StyledButtonContainer
} from './styles';
import { IEnrolmentReportCard, ISeeMoreEnrolmentReportProps } from './types';
import { isEnrolmentReportEnabled } from '../../../utils/featureFlags';
import moment from 'moment';
import { ChevronDownIconBG, InfoIconGray, EmeraldAddIcon, ChevronDownIcon } from '../../../assets/img';
import EnrolmentInfoCard from './EnrolmentInfoCard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { differenceInDays, isAfter, isBefore } from 'date-fns';
import { ConfigsEntity } from '../../../redux/slices/PolicySlice/types';

const EnrolmentReportCard: React.FunctionComponent<IEnrolmentReportCard> = ({
    onSubmit,
    enrolmentList,
    activePolicies,
    expiredPolicies
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('id') as string;

    const [fromDate, setFromDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(new Date());
    const [toDateError, setToDateError] = React.useState('');

    const [showDateForm, setShowDateForm] = React.useState(false);
    const [activeListOffset, setActiveListOffset] = React.useState(2);
    const [plannedListOffset, setPlannedListOffset] = React.useState(2);
    const [expiredListOffset, setExpiredListOffset] = React.useState(2);

    const policyIdAndNicknameMap = useMemo(() => {
        const map = new Map();
        activePolicies.forEach((policy) => map.set(policy.id, policy.nickName));
        expiredPolicies.forEach((policy) => map.set(policy.id, policy.nickName));
        return map;
    }, [enrolmentList, activePolicies, expiredPolicies]);

    const policyIdAndTypeMap = useMemo(() => {
        const map = new Map();
        activePolicies.forEach((policy) => map.set(policy.id, policy.policyType));
        expiredPolicies.forEach((policy) => map.set(policy.id, policy.policyType));
        return map;
    }, [enrolmentList, activePolicies, expiredPolicies]);

    const activeList = enrolmentList.enrolments.filter((enrolment) => {
        const today = new Date();
        if (
            isBefore(new Date(enrolment.enrolmentStartDate), today) &&
            isAfter(new Date(enrolment.enrolmentEndDate), today)
        ) {
            return enrolment;
        }
    });

    const expiredList = enrolmentList.enrolments.filter((enrolment) => {
        const today = new Date();
        if (
            isBefore(new Date(enrolment.enrolmentStartDate), today) &&
            isBefore(new Date(enrolment.enrolmentEndDate), today)
        ) {
            return enrolment;
        }
    });

    const plannedList = enrolmentList.enrolments.filter((enrolment) => {
        const today = new Date();
        if (isAfter(new Date(enrolment.enrolmentStartDate), today)) {
            return enrolment;
        }
    });

    const expiredEndoCycleStatus = (list: ConfigsEntity) =>
        differenceInDays(new Date(), new Date(list.enrolmentEndDate)) > 2 ? 'Ended' : 'Review';

    const onFormSubmit = async () => {
        const fromDateMoment = moment(fromDate).startOf('day');
        const toDateMoment = moment(toDate).startOf('day');
        if (fromDateMoment.isSameOrAfter(toDateMoment)) {
            setToDateError('From Date should be prior to To Date.');
            return;
        }
        if (toDateMoment.diff(fromDateMoment, 'days') > 30) {
            setToDateError('Cannot generate report for more than 30 days.');
            return;
        }
        setToDateError('');
        await onSubmit(fromDateMoment.toDate(), toDateMoment.toDate());
    };
    const handleDownloadReport = async (from: string, to: string) => {
        const fromDateMoment = moment(from).startOf('day');
        const toDateMoment = moment(to).startOf('day');
        if (fromDateMoment.isSameOrAfter(toDateMoment)) {
            setToDateError('From Date should be prior to To Date.');
            return;
        }
        if (toDateMoment.diff(fromDateMoment, 'days') > 30) {
            setToDateError('Cannot generate report for more than 30 days.');
            return;
        }
        setToDateError('');
        await onSubmit(fromDateMoment.toDate(), toDateMoment.toDate());
    };

    const redirectToCreatePage = () => {
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.ENROLMENT_CONFIG.route}?id=${companyId}`);
    };
    if (!isEnrolmentReportEnabled) {
        return <></>;
    }
    return (
        <StyledContainer>
            <StyledHeaderContainer>
                <Typography variant="title1" weight="semiBold">
                    Enrolment reports
                </Typography>
                <StyledLoopButtonContainer>
                    <LoopButton size="small" iconSrc={EmeraldAddIcon} variant="filled" onClick={redirectToCreatePage}>
                        Create a new enrolment
                    </LoopButton>
                </StyledLoopButtonContainer>
            </StyledHeaderContainer>
            <StyledSectionWrapper>
                <Typography variant="title2" weight="semiBold">
                    Active enrolments
                </Typography>
                {activeList.length > 0 ? (
                    activeList
                        .slice(0, activeListOffset)
                        .map((list) => (
                            <EnrolmentInfoCard
                                id={list.id}
                                endoCycleStatus={'Live'}
                                startDate={new Date(list.enrolmentStartDate)}
                                endDate={new Date(list.enrolmentEndDate)}
                                onSubmit={() => handleDownloadReport(list.enrolmentStartDate, list.enrolmentEndDate)}
                                key={list.id}
                                policyIdAndTypeMap={policyIdAndTypeMap}
                                policyIdAndNicknameMap={policyIdAndNicknameMap}
                                policyIds={list.policyIds}
                            />
                        ))
                ) : (
                    <StyledEmptyEnrolmentCard>
                        <Typography variant="medium" color="secondary">
                            No live enrolment batches right now
                        </Typography>
                    </StyledEmptyEnrolmentCard>
                )}
                <SeeMoreEnrolmentReport
                    list={activeList}
                    setListOffset={setActiveListOffset}
                    listOffset={activeListOffset}
                />
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <Typography variant="title2" weight="semiBold">
                    Planned enrolments
                </Typography>
                {plannedList.length > 0 ? (
                    plannedList
                        .slice(0, plannedListOffset)
                        .map((list) => (
                            <EnrolmentInfoCard
                                id={list.id}
                                endoCycleStatus={'Ready'}
                                startDate={new Date(list.enrolmentStartDate)}
                                endDate={new Date(list.enrolmentEndDate)}
                                onSubmit={() => handleDownloadReport(list.enrolmentStartDate, list.enrolmentEndDate)}
                                key={list.id}
                                policyIdAndTypeMap={policyIdAndTypeMap}
                                policyIdAndNicknameMap={policyIdAndNicknameMap}
                                policyIds={list.policyIds}
                            />
                        ))
                ) : (
                    <StyledEmptyEnrolmentCard>
                        <Typography variant="medium" color="secondary">
                            No planned enrolment batches right now
                        </Typography>
                    </StyledEmptyEnrolmentCard>
                )}
                <SeeMoreEnrolmentReport
                    list={plannedList}
                    setListOffset={setPlannedListOffset}
                    listOffset={plannedListOffset}
                />
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <Typography variant="title2" weight="semiBold">
                    Completed enrolments ({expiredList.length})
                </Typography>
                {expiredList.length > 0 ? (
                    expiredList
                        .slice(0, expiredListOffset)
                        .map((list) => (
                            <EnrolmentInfoCard
                                id={list.id}
                                endoCycleStatus={expiredEndoCycleStatus(list)}
                                startDate={new Date(list.enrolmentStartDate)}
                                endDate={new Date(list.enrolmentEndDate)}
                                onSubmit={() => handleDownloadReport(list.enrolmentStartDate, list.enrolmentEndDate)}
                                key={list.id}
                                policyIdAndTypeMap={policyIdAndTypeMap}
                                policyIdAndNicknameMap={policyIdAndNicknameMap}
                                policyIds={list.policyIds}
                            />
                        ))
                ) : (
                    <StyledEmptyEnrolmentCard>
                        <Typography variant="medium" color="secondary">
                            No completed enrolment batches right now
                        </Typography>
                    </StyledEmptyEnrolmentCard>
                )}
                <SeeMoreEnrolmentReport
                    list={expiredList}
                    setListOffset={setExpiredListOffset}
                    listOffset={expiredListOffset}
                />
            </StyledSectionWrapper>
            <StyledHorizontalDivider />
            <StyledSectionWrapper>
                <StyledFormHeaderWrapper>
                    <StyledInfoLabelWrapper $gap="8px">
                        <Typography variant="title2" weight="semiBold">
                            Download by date
                        </Typography>

                        <Tooltip
                            type="notice"
                            beak="top-center"
                            title="When to use this"
                            // eslint-disable-next-line max-len
                            text="Use this to download reports for custom dates or for previous enrolment batches run for this company"
                        >
                            <img src={InfoIconGray} />
                        </Tooltip>
                    </StyledInfoLabelWrapper>
                    <StyledArrowIcon
                        src={ChevronDownIconBG}
                        $rotate={showDateForm ? 180 : 0}
                        onClick={() => setShowDateForm(!showDateForm)}
                    />
                </StyledFormHeaderWrapper>
                {showDateForm ? (
                    <StyledEnrolmentReportContainer>
                        <StyledFormContainer>
                            <StyledFormFieldWidth>
                                <Form.FormField label="From:" id="from-date">
                                    <Form.DatePicker maxDate={new Date()} onChange={setFromDate} value={fromDate} />
                                </Form.FormField>
                            </StyledFormFieldWidth>
                            <StyledFormFieldWidth>
                                <Form.FormField label="To:" id="to-date">
                                    <Form.DatePicker
                                        maxDate={new Date()}
                                        error={toDateError}
                                        onChange={setToDate}
                                        value={toDate}
                                    />
                                </Form.FormField>
                            </StyledFormFieldWidth>
                        </StyledFormContainer>
                        <LoopButton variant="filled" onClick={onFormSubmit}>
                            Download
                        </LoopButton>
                    </StyledEnrolmentReportContainer>
                ) : null}
            </StyledSectionWrapper>
        </StyledContainer>
    );
};

const SeeMoreEnrolmentReport: React.FC<ISeeMoreEnrolmentReportProps> = ({ setListOffset, listOffset, list }) => {
    if (!list.length || list.length < listOffset) return null;

    return (
        <StyledButtonContainer onClick={() => setListOffset(listOffset + 2)}>
            <Typography variant="medium" weight="regular">
                See more enrolments
            </Typography>
            <img src={ChevronDownIcon} height={'24px'} width={'24px'} />
        </StyledButtonContainer>
    );
};

export default EnrolmentReportCard;
