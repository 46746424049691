import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../containers';
import { logoutUser } from '../LoginContainer/redux/slices/UserSlice';
import { StyledContainer, StyledOutlet } from './styles';
import { fetchCompanies } from '../../../redux/slices/CompanySlice/thunks';
import { fetchCDAccountList } from '../../../redux/slices/CDAccountSlice/thunk';
import { isCdAccountEnabled } from '../../../utils/featureFlags';
import { ReduxState } from '../../../redux';
import { Toast } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';

const SPAContainer: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const signOut = () => {
        dispatch(logoutUser());
    };
    const goHome = () => {
        navigate('/');
    };
    React.useEffect(() => {
        // fetch list of companies on initial load
        dispatch(fetchCompanies());
        if (isCdAccountEnabled) {
            dispatch(fetchCDAccountList());
        }
    }, []);
    return (
        <Toast.ToastContainer
            maxWidth='800px'
            expires={7000}
            closeOnClick
            variant='light'
        >
            <WithThemeProvider>
                <StyledContainer>
                    <Header goHome={goHome} signout={signOut} name={user?.firstName || ''} />
                    <StyledOutlet>
                        <Outlet />
                    </StyledOutlet>
                </StyledContainer>
            </WithThemeProvider>
        </Toast.ToastContainer>
    );
};

export default SPAContainer;
