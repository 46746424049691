import { IPlacement } from './PlacementDetails';

// TODO: Regex to be added
export const policyTermsConditions: Record<string, IPlacement[]> = {
    gmc: [
        {
            isMandatory: true,
            label: 'Sum Insured Structure',
            name: 'sumInsuredStructure',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Number of slabs in policy',
            name: 'numberOfSlabs',
            type: 'input',
            fullWidth: true,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Sum Insured Approach',
            name: 'sumInsuredApproach',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Age Limit',
            name: 'ageLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: '30 days waiting period',
            name: 'waitingPeriod',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: '1st, 2nd, 3rd, 4th Year Waiting Period',
            name: 'yearWaitingPeriod',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Documents Required',
            name: 'documentsRequired',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Place of Document Submission',
            name: 'documentsType',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Pre Existing Disease Benefit',
            name: 'preExistingDiseaseBenefit',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: '9 Months Waiting Period',
            name: 'nineMonthsWaitingPeriod',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'New Born Baby',
            name: 'newBornBaby',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Pre Hospitalisation Expenses',
            name: 'preHospitalizationExpenses',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Post Hospitalisation Expenses',
            name: 'postHospitalizationExpenses',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Day Care Procedures',
            name: 'dayCareProcedures',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Internal congenital disease',
            name: 'internalCongenitalDisease',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'External congenital disease',
            name: 'externalCongenitalDisease',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Hospitalization arising out of Terrorism',
            name: 'hospitalizationArisingOutOfTerrorism',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Lasik Surgery',
            name: 'lasikSurgery',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Animal / Serpent Attack',
            name: 'animalAttack',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Dental Treatment',
            name: 'dentalTreatment',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'AYUSH Treatment',
            name: 'ayushTreatment',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Sub Limits / Ailment Capping',
            name: 'subLimit',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Hospitalization arising out of psychiatric ailments',
            name: 'hospitalizationArisingOutOfPsychiatricAilments',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Functional Endoscopic Sinus Surgery',
            name: 'functionalEndoscopicSinusSurgery',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Cyber knife treatment / stem cell transplantation',
            name: 'cyberKnifeTreatmentOrStemCellTransplantation',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Cochlear implant treatment',
            name: 'cochlearImplantTreatment',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Oral Chemotherapy',
            name: 'oralChemotherapy',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Home Care Treatment Expenses / Home Quarantine / Isolation',
            name: 'homeCareTreatmentExpenses',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Additions / Deletions Request',
            name: 'additionsOrDeletionsRequest',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Coverage for New Joiners and Dep. / Leavers',
            name: 'coverageForNewJoinersAndDep',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Premium on new joiners and dep. / leavers',
            name: 'premiumOnNewJoinersAndDep',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Claims Intimation',
            name: 'claimsIntimation',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Claims Submission',
            name: 'claimsSubmission',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 1',
            name: 'specialCondition1',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 2',
            name: 'specialCondition2',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 3',
            name: 'specialCondition3',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 4',
            name: 'specialCondition4',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 5',
            name: 'specialCondition5',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        }
    ],
    gpa: [
        {
            isMandatory: true,
            label: 'Sum Insured Structure',
            name: 'sumInsuredStructure',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Number of slabs in policy',
            name: 'numberOfSlabs',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Age Limit',
            name: 'ageLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Accidental Death(AD)',
            name: 'accidentalDeath',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Documents Required',
            name: 'documentsRequired',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Place of Document Submission',
            name: 'documentsType',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Permanent Total Disability (PTD)',
            name: 'permanentTotalDisability',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Permanent Partial Disability (PPD)',
            name: 'permanentPartialDisability',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Terrorism Exclusion Clause',
            name: 'terrorismExclusionClause',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Additions / Deletions Request',
            name: 'additionsOrDeletionsRequest',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Coverage for New Joiners and Dep. / Leavers',
            name: 'coverageForNewJoinersAndDep',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Premium on new joiners and dep. / leavers',
            name: 'premiumOnNewJoinersAndDep',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Claims Intimation',
            name: 'claimsIntimation',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Claims Submission',
            name: 'claimsSubmission',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'AOA Limit',
            name: 'aoaLimit',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 1',
            name: 'specialCondition1',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 2',
            name: 'specialCondition2',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 3',
            name: 'specialCondition3',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 4',
            name: 'specialCondition4',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Special Condition 5',
            name: 'specialCondition5',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        }
    ],
    gtl: [
        {
            isMandatory: true,
            label: 'Sum Insured Structure',
            name: 'sumInsuredStructure',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Number of slabs',
            name: 'numberOfSlabs',
            type: 'input',
            fullWidth: true,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Age Limit',
            name: 'ageLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Documents Required',
            name: 'documentsRequired',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Place of Document Submission',
            name: 'documentsType',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Free Cover Limit',
            name: 'freeCoverLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Suicide Coverage',
            name: 'suicideCoverage',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Active at Work Clause',
            name: 'activeAtWorkClause',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Riders Opted for',
            name: 'ridersOptedFor',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Critical Illness Cover',
            name: 'criticalIllnessCover',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        }
    ],
    covid: [
        {
            isMandatory: true,
            label: 'Sum Insured Structure',
            name: 'sumInsuredStructure',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Number of slabs in policy',
            name: 'numberOfSlabs',
            type: 'input',
            fullWidth: true,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Policy Term',
            name: 'policyTerm',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Age Limit',
            name: 'ageLimit',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Documents Required',
            name: 'documentsRequired',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Place of Document Submission',
            name: 'documentsType',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Room Rent Limit',
            name: 'roomRentLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Initial 15 days waiting period',
            name: 'initialFifteenDaysWaitingPeriod',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Pre-hospitalization Expenses',
            name: 'preHospitalizationExpenses',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Post hospitalization Expenses',
            name: 'postHospitalizationExpenses',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'PPE Kit Charges',
            name: 'ppeKitCharges',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'PED Waiting Period',
            name: 'pedWaitingPeriod',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'PED Means',
            name: 'pedMeans',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Home Care Treatment expenses',
            name: 'homeCareTreatmentExpenses',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Home Care Treatment Coverage',
            name: 'homeCareTreatmentCoverage',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'AYUSH Treatment',
            name: 'ayushTreatment',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Co-morbidity exclusion clause',
            name: 'coMorbidityExclusionClause',
            type: 'dropdown',
            fullWidth: false,
            inputType: 'string',
            display: true
        },

        {
            isMandatory: true,
            label: 'Addition of New Joinee',
            name: 'additionOfNewJoinee',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Deletion of employee',
            name: 'deletionOfEmployee',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        }
    ]
};
