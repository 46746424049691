import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledApprovalModalContent = styled.div`
    width: 500px;
    height: 80vh;
`;
export const StyledApprovalModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 10%;
    border-bottom: 1px solid ${theme.colors.defaultBorder};
`;
export const StyledApprovalModalBody = styled.div`
    padding: 1rem;
    text-align: center;
    width: 100%;
    height: 78%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledApprovalModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 12%;
    border-top: 1px solid ${theme.colors.defaultBorder};
    gap: 2rem;
`;
export const StyledApprovalModalCloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const StyledApprovalModalPrimaryImageContainer = styled.div`
    padding: 1rem;
    height: 100%;
    position: relative;
    width: 100%;
`;
export const StyledApprovalModalPrimaryImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
`;
export const StyledApprovalImageShareIcon = styled.div`
    position: absolute;
    height: 26px;
    width: 26px;
    top: 30px;
    right: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
    opacity: 1;
    &:hover {
        transform: scale(1.3);
        transition: all 0.3s;
    }
`;
export const StyledCancelSingleModalContent = styled.div`
    padding: 12px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    margin: 1rem 0;
    background-color: ${theme.colors.surface};
    border: 0.5px solid ${theme.colors.defaultBorder};
    color: ${theme.colors.secondaryText};
`;
