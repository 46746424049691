import styled from 'styled-components';

export const StyledRadioButtonRow = styled.div(
    (p) => `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
   
    div {
       font-size: ${p.theme.fontSizes.subHeading};
       line-height: 24px;
       color: ${p.theme.colors.beyondGrey};
       letter-spacing: 0.2px;
       font-weight: 600;
    }
`
);
