import React from 'react';
import { IUploadPill } from './types';
import FileSelector from '../FileSelector';
import { StyledContainer, StyledClickText, StyledMetaContainer, StyledOrText, StyledSubtitle } from './styles';

const UploadPill: React.FunctionComponent<IUploadPill> = ({
    title,
    subTitle,
    onUpload,
    formats,
    maxFiles,
    // in MB
    maxSize,
    errorMsg = '',
    children
}) => {
    return (
        <FileSelector onUpload={onUpload} formats={formats} maxFiles={maxFiles} maxSize={maxSize}>
            <StyledContainer $error={!!errorMsg.length}>
                <StyledMetaContainer>
                    <span>{title}</span>
                    <StyledSubtitle>{subTitle}</StyledSubtitle>
                </StyledMetaContainer>
                <StyledMetaContainer>
                    <StyledClickText>Drag &amp; Drop</StyledClickText>
                    <StyledOrText>OR</StyledOrText>
                    <StyledClickText>Click to upload</StyledClickText>
                </StyledMetaContainer>
                {children}
            </StyledContainer>
        </FileSelector>
    );
};

export default UploadPill;
