import React from 'react';
import { StyledRPMContainer } from './styles';

interface IDisplayRater {
    raterTable: Record<string, Record<string, number>>;
}
const DisplayRPMRater: React.FunctionComponent<IDisplayRater> = ({ raterTable }) => {
    const getRPMValue = () => {
        const ageBand = Object.keys(raterTable)[0];
        const sumInsured = Object.keys(raterTable[ageBand])[0];
        return raterTable[ageBand][sumInsured];
    };
    return (
        <StyledRPMContainer>
            <div>
                <b>RPM</b>
            </div>
            <div>{getRPMValue()}</div>
        </StyledRPMContainer>
    );
};

export default DisplayRPMRater;
