import { Typography } from '@loophealth/loop-ui-web-library';
import { format } from 'date-fns';
import React from 'react';
import { EmeraldCheckbox, EmeraldEmptyCheckboxIcon } from '../../../assets/img';
import { StyledColumn } from '../../atoms';
import {
    StyledEnrolmentPolicyContainer,
    StyledInsurerLogo,
    StyledPolicyContainer,
    StyledPolicyDetailsContainer,
    StyledPolicyDurationContainer,
    StyledPolicyInfoContainer
} from './styles';
import { ISelectablePolicyComponentProps } from './types';

const SelectablePolicyComponent: React.FC<ISelectablePolicyComponentProps> = ({
    icon,
    policyType,
    insurer,
    policyId,
    isSelected,
    nickName,
    startDate,
    endDate,
    onSelect
}) => {
    const onPolicySelected: React.MouseEventHandler<HTMLImageElement> = () => {
        onSelect(policyId, !isSelected); // Toggle the isSelected value
    };

    return (
        <StyledEnrolmentPolicyContainer onClick={onPolicySelected}>
            {isSelected ? <img src={EmeraldCheckbox} /> : <img src={EmeraldEmptyCheckboxIcon} />}
            <StyledPolicyInfoContainer>
                <StyledPolicyContainer>
                    <StyledPolicyDetailsContainer>
                        <StyledInsurerLogo src={icon} />
                        <StyledColumn>
                            <Typography variant="medium" weight="medium">
                                {nickName} ({policyType})
                            </Typography>
                            <Typography variant="extraSmall" color="secondary">
                                {insurer}
                            </Typography>
                        </StyledColumn>
                    </StyledPolicyDetailsContainer>
                    <StyledPolicyDurationContainer>
                        <Typography variant="small" weight="regular">
                            Policy duration
                        </Typography>
                        {startDate && endDate && (
                            <Typography variant="extraSmall" weight="regular">
                                {format(new Date(startDate), 'do MMM yyyy ')}-
                                {format(new Date(endDate), ' do MMM yyyy')}
                            </Typography>
                        )}
                    </StyledPolicyDurationContainer>
                </StyledPolicyContainer>
                <Typography variant="small" color="secondary">
                    Policy ref ID:{' '}
                    <Typography variant="small" weight="medium">
                        {policyId}
                    </Typography>
                </Typography>
            </StyledPolicyInfoContainer>
        </StyledEnrolmentPolicyContainer>
    );
};
export default SelectablePolicyComponent;
