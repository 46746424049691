import React from 'react';
import { useTheme } from 'styled-components';
import { TableRow, TableData, LabelButton, Label } from '../../atoms';
import {
    StyledCapitalize,
    StyledDOBLabel,
    StyledMargin,
    StyledStatusLabel,
    StyledSubTitle,
    StyledTableActionsContainer,
    StyledTextWrapBreakId
} from './styles';
import { IGlobalMemberDetailsRow } from './types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { isDeleteMembersEnabled } from '../../../utils/featureFlags';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const GlobalMemberDetailsRow: React.FunctionComponent<IGlobalMemberDetailsRow> = ({
    user,
    index,
    showUserPolicies,
    deleteMember
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const toast = useToast();
    const handlePolicyDetails = () => {
        showUserPolicies(user.userId);
    };
    const handleDeleteMember = () => {
        if (isDeleteMembersEnabled) {
            deleteMember(user);
        }
    };
    const userCompany = useSelector(
        (state: ReduxState) => state.companySlice.companyList.data?.filter((company) => company.id === user.employer)[0]
    );
    const navigateToCompany = () => {
        if (userCompany) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.COMPANY_DETAILS.route + '?id=' + userCompany.id);
        } else {
            toast?.displayToast(
                'error',
                'Could not find company details',
                'Either the company does not exist anymore, or the user is not linked to any company'
            );
        }
    };
    return (
        <TableRow>
            <TableData>{index + 1}</TableData>
            <TableData index>
                <StyledCapitalize>
                    <b>{user.firstName + ' ' + user.lastName}</b>
                </StyledCapitalize>
                userId: <StyledSubTitle>{user.userId}</StyledSubTitle>
                <br />
                company: <StyledSubTitle>{userCompany?.name || 'N/A'}</StyledSubTitle>
            </TableData>
            <TableData>
                <div>Mobile: {user.mobile || <b>N/A</b>}</div>
                <div>Email: {user.email || <b>N/A</b>}</div>
            </TableData>
            <TableData align="center">
                <StyledCapitalize>{user.relationship}</StyledCapitalize>
            </TableData>
            <TableData align="center">
                {user.gender === 'female' ? 'F' : user.gender === 'male' ? 'M' : 'N/A'}
            </TableData>
            <TableData align="center">
                {user.dob && new DateFormatUtils().formatDate(user.dob)}
                <br />
                <StyledDOBLabel>(DD MMM YYYY)</StyledDOBLabel>
            </TableData>
            <TableData align="center">{user.employeeId || 'N/A'}</TableData>
            <TableData align="center">
                <StyledTextWrapBreakId>{user.parentId || '-'}</StyledTextWrapBreakId>
            </TableData>
            <TableData>
                <StyledStatusLabel>
                    <Label isActive={user.active}>{user.active ? 'ACTIVE' : 'INACTIVE'}</Label>
                </StyledStatusLabel>
            </TableData>
            <TableData>
                <StyledTableActionsContainer>
                    <StyledMargin>
                        <LabelButton onClick={handlePolicyDetails} color={theme.colors.primary}>
                            Policy Details
                        </LabelButton>
                    </StyledMargin>
                    <StyledMargin>
                        <LabelButton onClick={navigateToCompany} color={theme.colors.primary}>
                            Company Details
                        </LabelButton>
                    </StyledMargin>
                    {isDeleteMembersEnabled && (
                        <StyledMargin>
                            <LabelButton onClick={handleDeleteMember} color={theme.colors.primary}>
                                Delete Member
                            </LabelButton>
                        </StyledMargin>
                    )}
                </StyledTableActionsContainer>
            </TableData>
        </TableRow>
    );
};

export default GlobalMemberDetailsRow;
