import React from 'react';
import { StyledContainer, StyledBlur, StyledSpinner, StyledPause } from './styles';
import { ILoader } from './types';


const Loader: React.FunctionComponent<ILoader> = ({ inverted = true }) => {
    return (
        <StyledContainer>
            <StyledPause>
                <StyledBlur $inverted={inverted}></StyledBlur>
                <StyledSpinner $inverted={inverted}></StyledSpinner>
            </StyledPause>
        </StyledContainer>
    );
};

export default Loader;
