import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginPage } from './components/pages';
import firebase from 'firebase';
import { ILoginContainer } from './types';
import { StyledContainer, StyledPanelContainer, StyledContentContainer } from './styles';
import { getUserDetails } from './redux/slices/UserSlice';
import { ErrorContainer } from '../../atoms';
import { Loader } from './components/atoms';
import { isUserAuthorised } from '../../../adapters';

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
    }
}
const LoginContainer: React.FunctionComponent<ILoginContainer> = ({ PanelComponent, title, subTitle, redirectTo }) => {
    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state.user.userData);
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    // check for persistance
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                if (!userData.data) {
                    const email = user.email;
                    if (email) {
                        setIsPersistanceLoading(true);
                        dispatch(getUserDetails({ key: 'email', value: email }));
                    }
                }
            }
        });
    }, [firebase]);

    // trigger only if we have aquired userData from firestore
    // 1. When OTP verification is successful
    // 2. When OAuth Login is successful
    React.useEffect(() => {
        if (userData && userData.data) {
            const email = userData.data.email;
            if (email) {
                (async () => {
                    try {
                        const isAuthorised = await isUserAuthorised(
                            userData.data.roles,
                            process.env.REACT_APP_NAME || ''
                        );
                        if (!isAuthorised) {
                            setIsPersistanceLoading(false);
                            setError('User does not have permission to access this application');
                        } else {
                            setError('');
                            redirectTo();
                        }
                    } catch (e) {
                        setIsPersistanceLoading(false);
                        setError('Unable to login user at this time. Please refresh the page and try again.');
                    }
                })();
            } else {
                setIsPersistanceLoading(false);
                setError('Unable to login user at this time. Please refresh the page and try again.');
            }
        }
    }, [history, userData]);

    return (
        <StyledContainer>
            <StyledPanelContainer>
                <PanelComponent />
            </StyledPanelContainer>
            <StyledContentContainer>
                {isPersistanceLoading || userData.loading ? (
                    <Loader />
                ) : (
                    <>
                        <LoginPage title={title} subTitle={subTitle} />
                        {!!error?.length && <ErrorContainer message={error} />}
                    </>
                )}
            </StyledContentContainer>
        </StyledContainer>
    );
};

export default LoginContainer;
