import React from 'react';
import {
    StyledContainer,
    StyledInsuranceDetailHeading,
    StyledInsuranceDetailContent,
    StyledInsuranceDetailContainer,
    StyledIcons,
    StyledIconAlignment,
    StyledIconContainer,
    StyledEcardContainer,
    StyledColumnContainer
} from './styles';
import { IInsuranceCardDetail } from './types';
import { PolicyDetails, DraftPolicy, EditPolicy } from '../../../assets/img';
import { useState } from 'react';
import { fetchECard } from '../../../adapters';
import Loader from '../Loader';
import { useToast } from '../../hooks/useToast';
import { parseResponse, showApiFailureToast } from '../../../utils/common';

const PolicyCardDetail: React.FunctionComponent<IInsuranceCardDetail> = ({
    policyEndDate,
    policyStatus,
    onClick,
    displayEcard,
    slabId,
    sumInsured,
    isActive,
    userId,
    policyId
}) => {
    const toast = useToast();
    const [loading, toggleLoading] = useState(false);
    const handleFetchECard = async () => {
        if (!userId || !policyId) {
            showApiFailureToast(toast, 'Unable to view Ecard');
            return;
        }
        toggleLoading(true);
        const [error, result] = await parseResponse(fetchECard(userId as string, policyId as string));
        if (error) {
            showApiFailureToast(toast, error.toString());
            return;
        }
        const pdfFileData = result.data.pdfFileData;
        const base64Data = pdfFileData.split(',')[1];
        toggleLoading(false);
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:application/octet-stream;charset=utf-16le;base64,' + base64Data;
        downloadLink.download = 'E-card.pdf';
        downloadLink.click();
    };
    return (
        <StyledColumnContainer>
            <StyledContainer>
                <StyledInsuranceDetailContainer>
                    <StyledInsuranceDetailHeading>POLICY END DATE</StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>{policyEndDate}</StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>
                {slabId || sumInsured ? (
                    <>
                        <StyledInsuranceDetailContainer>
                            <StyledInsuranceDetailHeading>SLAB ID</StyledInsuranceDetailHeading>
                            <StyledInsuranceDetailContent>{slabId}</StyledInsuranceDetailContent>
                        </StyledInsuranceDetailContainer>
                        <StyledInsuranceDetailContainer>
                            <StyledInsuranceDetailHeading>SUM INSURED</StyledInsuranceDetailHeading>
                            <StyledInsuranceDetailContent>{sumInsured}</StyledInsuranceDetailContent>
                        </StyledInsuranceDetailContainer>
                    </>
                ) : null}
                <StyledIconAlignment>
                    {policyStatus?.toLowerCase() === 'draft' && (
                        <StyledIconContainer onClick={() => onClick('Edit')}>
                            <StyledIcons src={DraftPolicy} alt={'Draft Policy'} />
                            <StyledInsuranceDetailContent>
                            Review <br /> Placement Slip
                            </StyledInsuranceDetailContent>
                        </StyledIconContainer>
                    )}
                    {!displayEcard && displayEcard !== null && policyStatus?.toLowerCase() === 'handover to ops' && (
                        <>
                            <StyledIconContainer onClick={() => onClick('View')}>
                                <StyledIcons src={PolicyDetails} alt={'Policy Details'} />
                                <StyledInsuranceDetailContent>
                                Policy
                                    <br /> Details
                                </StyledInsuranceDetailContent>
                            </StyledIconContainer>

                            {isActive && (
                                <StyledIconContainer onClick={() => onClick('Edit')}>
                                    <StyledIcons src={EditPolicy} alt={'Edit Policy'} />
                                    <StyledInsuranceDetailContent>
                                    Edit
                                        <br /> Policy
                                    </StyledInsuranceDetailContent>
                                </StyledIconContainer>
                            )}
                        </>
                    )}
                </StyledIconAlignment>
            </StyledContainer>
            {displayEcard && displayEcard !== null && (
                <StyledEcardContainer onClick={handleFetchECard}>
                    {loading ? <Loader inverted={false} /> : 'View E-Card'}
                </StyledEcardContainer>
            )}
        </StyledColumnContainer>
    );
};

export default PolicyCardDetail;
