import { IExcelCellProps } from '../../BulkUploader/ErrorExcelDownload/types';

const format = (
    errors: Record<string, unknown>[]
): { data: Record<string, unknown>; props: Record<string, IExcelCellProps> }[] => {
    const colNameMap: Record<string, string> = {
        userId: 'User ID',
        relationship: 'Relationship to Account Holder',
        dateOfLeaving: 'Date of Leaving',
        error: 'Errors',
        global: 'Global'
    };
    const formattedErrors: { data: Record<string, unknown>; props: Record<string, IExcelCellProps> }[] = [];
    errors.map((v) => {
        const errors = JSON.parse(v.error as string);
        const errorKeys = Object.keys(errors);
        const keys = Object.keys(v);
        const newObj: Record<string, unknown> = {};
        keys.map((k) => {
            newObj[colNameMap[k]] = v[k];
        });
        const keysWithErrors: string[] = [];
        const errorStr = errorKeys.reduce((str, k) => {
            const key = colNameMap[k];
            const value = errors[k];
            if (key !== 'Global') keysWithErrors.push(key);
            return str + `${key} - ${value} \n`;
        }, '');
        newObj['Errors'] = errorStr;
        const cellProps: Record<string, IExcelCellProps> = {};
        keysWithErrors.map((k) => {
            cellProps[k] = {
                cellColour: 'DA9694'
            };
        });
        cellProps['Errors'] = {
            fontColour: 'DA9694'
        };
        formattedErrors.push({
            data: newObj,
            props: cellProps
        });
    });
    return formattedErrors;
};

export default format;
