import React from 'react';
import { IValidationSummaryCard } from './types';
import { StyledCardContainer, StyledCardPill, StyledCardTitle, StyledPillContainer, StyledSVG } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';

const ValidationSummaryCard: React.FunctionComponent<IValidationSummaryCard> = ({
    totalLives,
    icon,
    correctLives,
    incorrectLives,
    background,
    cardType
}) => {
    return (
        <StyledCardContainer $backgroundURL={background}>
            <StyledCardTitle>
                <Typography variant="medium" weight="medium">
                    Total {cardType} Requests
                </Typography>
                <StyledSVG src={icon} />
            </StyledCardTitle>
            <Typography variant="title2" weight="medium" color="consultViolet">
                {totalLives} Lives
            </Typography>

            <StyledPillContainer>
                <StyledCardPill>
                    <Typography variant="small" weight="regular" color="secondary">
                        Correct Lives
                    </Typography>
                    <Typography variant="small" weight="regular" color="secondary">
                        {correctLives}
                    </Typography>
                </StyledCardPill>

                <StyledCardPill>
                    <Typography variant="small" weight="regular" color="secondary">
                        Incorrect Lives
                    </Typography>
                    <Typography variant="small" weight="regular" color="secondary">
                        {incorrectLives}
                    </Typography>
                </StyledCardPill>
            </StyledPillContainer>
        </StyledCardContainer>
    );
};

export default ValidationSummaryCard;
