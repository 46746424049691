/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseCaptcha, FirebaseAuth } from '../../../../../../adapters/provider';
import { getUserData } from '../../../../../../adapters';
import { isDeprecateDBCallsEnabled } from '../../../../../../utils/featureFlags';
export const USER_ACTION_TYPES = {
    LOGIN_WITH_MOBILE: 'userdata/loginWithMobile',
    VERIFY_OTP: 'userData/verifyOTP',
    FETCH_USER: 'userdata/fetchUser',
    UPDATE_USER: 'userdata/updateData',
    LOGIN_WITH_OAUTH: 'userdata/loginWithOAuth'
};

export const loginWithOAuth = createAsyncThunk(
    USER_ACTION_TYPES.LOGIN_WITH_OAUTH,
    async () => {
        const provider = new FirebaseCaptcha.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        if (provider) {
            if (isDeprecateDBCallsEnabled) {
                return await FirebaseAuth.signInWithPopup(provider).then(async (res) => {
                    const email = (res?.additionalUserInfo?.profile as any).email || '';
                    if (email) {
                        const result = await getUserData('email', email);
                        if (result) {
                            return {
                                data: { ...result, loginMethod: 'email' }
                            };
                        }
                    }
                }).catch((err) => {
                    let errorMessage = err.message;
                    // The error from the backend is thrown in this format
                    // HTTP Cloud Function returned an error:
                    // {"error":{"message":"The provided Email Id is not registered in Loop",
                    // "status":"INVALID_ARGUMENT"}}
                    // Extracting the message part of the error using the below code
                    if (errorMessage.indexOf('{') !== -1) {
                        const jsonResponse = errorMessage.substring(errorMessage.indexOf('{'));
                        const errorObject = JSON.parse(jsonResponse);
                        errorMessage = errorObject.error.message;
                    }
                    throw new Error(errorMessage);
                });
            } else {
                return await FirebaseAuth.signInWithPopup(provider).then(async (res) => {
                    const currentUser = FirebaseAuth.currentUser;
                    const email = (res?.additionalUserInfo?.profile as any).email || '';
                    if (email) {
                        try {
                            if (currentUser) {
                                return currentUser
                                    .updateEmail(email)
                                    .then(async () => {
                                        const result = await getUserData('email', email);
                                        if (result) {
                                            return {
                                                data: { ...result, loginMethod: 'email' }
                                            };
                                        } else {
                                            currentUser?.delete();
                                            throw new Error('Invalid Email! Please login using the registered Email');
                                        }
                                    })
                                    .catch((e) => {
                                        throw new Error(e.message);
                                    });
                            }
                        } catch (e) {
                            throw e;
                        }
                    }
                });
            }
        }
    }
);

export const getUserDetails = createAsyncThunk(
    USER_ACTION_TYPES.FETCH_USER,
    async ({ key, value }: { key: string; value: string }) => {
        try {
            const result = await getUserData(key, value );
            if (result) {
                return {
                    data: { ...result, loginMethod: 'mobile' }
                };
            } else {
                throw new Error('Unable to fetch user details');
            }
        } catch (e) {
            throw e;
        }
    }
);
