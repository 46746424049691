import styled from 'styled-components';
import theme from '../../../../theme';

export const StyledEnrolmentCard = styled.div`
    display: flex;
    width: 100%;
    padding: 18px 20px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;

    &:hover {
        box-shadow: 0px -2px 8px 0px #3741510f;
    }
`;

export const StyledEnrolmentLeftSection = styled.div`
    display: flex;
    width: 60%;
    gap: 25px;
    align-items: flex-start;
`;

export const StyledInfoSection = styled.div`
    display: flex;
    gap: 2px;
    flex-direction: column;
`;

export const StyledLoopButtonContainer = styled.div`
    width: 200px;
`;

export const StyledInfoLabelWrapper = styled.div<{ $gap: string }>(
    (p) => `
    gap: ${p.$gap};
    display: flex;
    align-items: flex-start;
`
);
export const StyledInfoIcon = styled.img`
    width: 14px;
`;

export const StyledCustomTooltipText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;

    & > span {
        margin-left: 20px;
        width: fit-content;
        display: list-item;
        list-style-type: disc;
    }
`;

export const StyledEditIcon = styled.img<{ $hover: boolean }>`
    height: 24px;
    width: 24px;

    width: ${(p) => (p.$hover ? '24px' : '0')};
    transition: width 0.2s;
    cursor: pointer;
`;

export const StyledRightContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
