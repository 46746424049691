import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
`;

export const StyledTitleContainer = styled.div`
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    padding: 40px;
`;

export const StyledTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StyledBodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${(p) => p.theme.colors.surface};
`;

export const StyledLeftContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 454px;
    max-width: 454px;
    background-color: ${(p) => p.theme.colors.white};
    padding: 48px 56px;
    border-right: 1px solid ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 30px;
    background-color: ${(p) => p.theme.colors.white};
    padding: 48px 56px;
    border-left: 1px solid ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledIcon = styled(SVG)`
    max-height: 100%;
    background-color: ${({ theme }) => theme.colors.surface}; ;
`;

export const StyledStepsTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 160px;
`;

export const StyledSeparator = styled.div`
    margin-top: 16px;
    margin-bottom: 48px;
    border-bottom: 1px solid ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledBodyWithButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
