import React from 'react';
import { UploadPill, MB6, Button, FlexColAlignStretch } from '../../atoms';
import { IUploadPill } from '../../atoms/UploadPill/types';
import { fileDownload } from '../../../utils/common';

const ExcelUploadPill: React.FunctionComponent<{
    onUpload: (files: Record<string, unknown>[]) => void;
    title: string;
    templateUrl: string;
}> = ({ onUpload, title, templateUrl }) => {
    const downloadTemplate = () => {
        fileDownload(templateUrl);
    };
    return (
        <FlexColAlignStretch>
            <div>
                <UploadPill
                    title={title}
                    subTitle={'Supports (.xls, .xlsx)'}
                    onUpload={onUpload}
                    formats={['.xlsx', '.xls', '.xlsm'] as IUploadPill['formats']}
                    maxFiles={1}
                    maxSize={15}
                />
            </div>
            <MB6 />
            <Button type="secondary" onClick={downloadTemplate}>
                Download Template
            </Button>
        </FlexColAlignStretch>
    );
};

export default ExcelUploadPill;
