import { SEGMENT_ACTIONS } from './SegmentActionConstant';

export const trackClickEvent = (
    action: string,
    pathname: string | null,
    page_category = '',
    { clickProperties, ...otherProperties }: { clickProperties?: Record<string, unknown> } = {}
): { name: string; properties: Record<string, any> } => {
    const currentPageAction = SEGMENT_ACTIONS.CLICK[action];
    const pageTitle = (pathname ?? '').slice(1).replaceAll('/', '_');
    return {
        name: action,
        properties: {
            page_category,
            pageTitle,
            ...(otherProperties || {}),
            ...(currentPageAction || {}),
            ...clickProperties
        }
    };
};

export const extractPathName = (pathname: string): string => {
    return pathname.slice(1).replace(/[/-]/g, '_').toUpperCase();
};
