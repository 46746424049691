import React from 'react';
import { StyledContainer, StyledSpacing } from './styles';
import { Form, LoopButton, LoopRangeDatePicker } from '@loophealth/loop-ui-web-library';
import moment from 'moment';
import { DateRange } from '../../../../../redux/slices/ChangeRequestSlice/types';
import { excelDownloadUsingBlob, parseResponse, showApiFailureToast } from '../../../../../utils/common';
import { fetchHrmsDataByType } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import { DownloadIcon } from '../../../../../assets/img';
import { dropdownOptions } from './types';
import { DATE_FORMAT_MM_DD_YYYY, formatDateString } from '../../../../../utils/dateFormatService';

const CustomHRMS: React.FunctionComponent = () => {
    const toast = useToast();

    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const initialDateRange = { startDate: currentDate, endDate: nextDate };
    const [dateRange, setDateRange] = React.useState(initialDateRange);
    const [isloading, setIsLoading] = React.useState(false);
    const [type, setSelectedType] = React.useState('');
    const maxDate = moment(nextDate).add(60, 'days').toDate();
    const handleDateChange = (range: DateRange) => {
        setDateRange({
            startDate: moment(range.startDate).toDate(),
            endDate: moment(range.endDate).toDate()
        });
    };

    const handleClick = (e: string) => {
        setSelectedType(e);
    };
    const onButtonClicked = async () => {
        const fromDate = formatDateString(dateRange.startDate.toISOString(), DATE_FORMAT_MM_DD_YYYY);
        const toDate = formatDateString(dateRange.endDate.toISOString(), DATE_FORMAT_MM_DD_YYYY);
        setIsLoading(true);
        const [error, hrmsResponse] = await parseResponse(fetchHrmsDataByType(type, fromDate, toDate));
        if (hrmsResponse.ok) {
            const blobData = await hrmsResponse.blob();
            await excelDownloadUsingBlob(blobData);
        } else {
            showApiFailureToast(toast, error.toString());
        }
        setIsLoading(false);
    };

    return (
        <StyledContainer>
            <div>
                <LoopRangeDatePicker
                    onChange={handleDateChange}
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    maxDate={maxDate}
                />
                <StyledSpacing>
                    <Form.Dropdown
                        placeholder="Select Operation"
                        listItems={dropdownOptions}
                        onClick={(e: string) => handleClick(e)}
                        selectedItem={type}
                    />
                </StyledSpacing>
                <LoopButton
                    variant="filled"
                    onClick={onButtonClicked}
                    isLoading={isloading}
                    size="medium"
                    iconSrc={DownloadIcon}
                    iconOrder='right'
                >
                Download
                </LoopButton>
            </div>
        </StyledContainer>
    );
};

export default CustomHRMS;
