import { Loader, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { FC, useState } from 'react';
import { FileIconEmerald } from '../../../../../../assets/img';
import { StyledFileIcon, StyledLoader, StyledUploadedDocument } from './styles';
import { IPolicyDocumentProps } from './types';

const PolicyDocument: FC<IPolicyDocumentProps> = ({
    policyDocument,
    onDownload
}) => {
    const [downloading, setDownloading] = useState(false);

    const handleDownloadPolicyDocument = async () => {
        setDownloading(true);
        await onDownload();
        setDownloading(false);
    };

    return (
        <StyledUploadedDocument key={policyDocument}>
            <StyledFileIcon src={FileIconEmerald} />
            <Typography
                variant='small'
                weight='medium'
            >
                {policyDocument}
            </Typography>
            <div>
                {
                    downloading
                        ? <StyledLoader>
                            <Loader />
                        </StyledLoader>
                        : <LoopButton
                            variant="text"
                            onClick={handleDownloadPolicyDocument}
                            size="small"
                        >
                            Download
                        </LoopButton>
                }
            </div>
        </StyledUploadedDocument>
    );
};

export default PolicyDocument;
