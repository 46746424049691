import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    StyledContainer, StyledHeader, StyledTitle,
    StyledLink, StyledIconContainer, StyledIcon,
    StyledSearchWrapper, StyledInput,
    StyledDownloadContainer, StyledDownloadImage, StyledTableLoaderContainer
} from './styles';
import { ChevronRightIcon, DownloadIcon } from '../../../../../assets/img';
import useFormInput from '../../../../hooks/useFormInput';
import CompanyDataTable from './CompanyDataTable';
import { IEndorsements, IEndorsementsSelector } from '../../../../../redux/slices/EndorsementSlice/types';
import { excelDownload } from '../../../../../utils/common';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { useCompletedEndorsements, usePendingEndorsements } from './hooks/useEndorsements';
import { Loader } from '../../../../atoms';

const Endorsements: React.FunctionComponent = () => {
    const [isCompletedEndorsements, toggleCompletedEndorsements] = useState(false);
    const search = useFormInput('');
    const navigate = useNavigate();
    const endorsements: IEndorsementsSelector = isCompletedEndorsements
        ? useCompletedEndorsements(isCompletedEndorsements) : usePendingEndorsements(isCompletedEndorsements);

    const filteredEndorsementList = useMemo(() => {
        // filteredData = isCompletedEndorsements
        //     ? filteredData.filter((c: IEndorsements) => c.status === 'ENDO_COMPLETE')
        //     : filteredData;
        return search.debounceValue && endorsements.data ? endorsements.data.filter((c: IEndorsements) =>
            c?.companyName?.toLowerCase().includes(search.debounceValue.toLowerCase())) : (endorsements.data || []);
    }
    , [search.debounceValue, endorsements.data, isCompletedEndorsements]);

    const onViewCompletedEndorsements = () => {
        toggleCompletedEndorsements((isCompletedEndorsements) => !isCompletedEndorsements);
    };
    const downloadEndorsementData = () => {
        excelDownload(filteredEndorsementList, 'endorsement-data');
    };
    const onEndorsementClicked = (endorsementId: string) => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.ENDORSEMENT_DETAILS.route +
            '?id=' + endorsementId);
    };

    return (
        <StyledContainer>
            <StyledHeader>
                <StyledTitle>
                    Endorsement requests {filteredEndorsementList && `(${filteredEndorsementList.length})`}
                </StyledTitle>
                <StyledIconContainer onClick={onViewCompletedEndorsements}>
                    <StyledLink data-testid="view-completed-endorsement">
                        View {isCompletedEndorsements ? `Pending` : `Completed`} Endorsements
                    </StyledLink>
                    <StyledIcon src={ChevronRightIcon} alt={'Chevron Icon'}/>
                </StyledIconContainer>
            </StyledHeader>
            <StyledSearchWrapper>
                <StyledInput
                    name='name'
                    placeholder='Search by Company name'
                    {...search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        search.onChange(e);
                    }}
                />
                <StyledDownloadContainer onClick={downloadEndorsementData}>
                    <StyledDownloadImage src={DownloadIcon}/>
                    Download as excel
                </StyledDownloadContainer>
            </StyledSearchWrapper>
            {endorsements.loading && !endorsements.data ? (
                <StyledTableLoaderContainer><Loader/></StyledTableLoaderContainer>
            ) : null}
            {endorsements.data ? (
                <CompanyDataTable data={filteredEndorsementList} onRowClick={onEndorsementClicked}/>
            ) : null}
        </StyledContainer>
    );
};
export default Endorsements;
