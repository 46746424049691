import React from 'react';
import { Typography, useTable } from '@loophealth/loop-ui-web-library';
import { StyledSpaceBetween, StyledValidatorSummaryTable } from '../styles';
import { ICorrectTable } from '../types';
import LivesCostTable from './LivesCostTable';
import { formatCurrencyElseDefault } from '../utils';
import { TABLE_TEXTS } from './constants';

export const CorrectTable: React.FC<ICorrectTable> = ({
    correctEntries,
    endoEntries,
    totalLives
}) => {
    const { DataCell } = useTable('info');

    const currentEndoBucketFooters = correctEntries.operations.map((op) => {
        const correctTexts =
      TABLE_TEXTS.CORRECT[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
        return (
            <DataCell colSpan={7} key={op.policyId + op.policyType}>
                <StyledSpaceBetween>
                    <Typography variant="small" weight="medium">
                        {correctTexts.totalCost}&nbsp;{op.policyType}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {formatCurrencyElseDefault(op.policyCost)}
                    </Typography>
                </StyledSpaceBetween>
            </DataCell>
        );
    });

    const endoTableFooters = endoEntries.operations
        .map((op) => {
            const endoTexts =
        TABLE_TEXTS.ENDO[op.policyCost < 0 ? 'REFUND' : 'REGULAR'];
            return [
                <DataCell colSpan={7} key={op.policyId + op.policyType}>
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            {endoTexts.totalCost}&nbsp;{op.policyType}
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {formatCurrencyElseDefault(op.policyCost)}
                        </Typography>
                    </StyledSpaceBetween>
                </DataCell>,
                <React.Fragment key={op.policyId + op.policyType + 'cdAccount'}>
                    <DataCell colSpan={op.requiredOrNew ? 4 : 7}>
                        <StyledSpaceBetween>
                            <Typography variant="small" weight="medium">
                                {op.policyType}&nbsp;Current CD Balance&nbsp;(
                                {op.isCdSufficient ? 'Sufficient' : 'Low'})
                            </Typography>
                            <Typography
                                variant="small"
                                color={op.isCdSufficient ? 'secondary' : 'error'}
                            >
                                {op.isCdMissing ? 'Missing' : formatCurrencyElseDefault(op.cdBalance)}
                            </Typography>
                        </StyledSpaceBetween>
                    </DataCell>
                    {!!op.requiredOrNew && (
                        <DataCell colSpan={3}>
                            <StyledSpaceBetween>
                                <Typography variant="small" weight="medium">
                                    {op.policyType}&nbsp;{endoTexts.requiredOrNew}
                                </Typography>
                                <Typography variant="small" color="secondary">
                                    {formatCurrencyElseDefault(op.requiredOrNew)}
                                </Typography>
                            </StyledSpaceBetween>
                        </DataCell>
                    )}
                </React.Fragment>
            ];
        })
        .flat();

    return (
        <StyledValidatorSummaryTable>
            <LivesCostTable
                details={correctEntries.operations}
                header={
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            Total New Correct Lives
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {correctEntries.totalLives}/{totalLives}
                        </Typography>
                    </StyledSpaceBetween>
                }
                footers={currentEndoBucketFooters}
            />
            <LivesCostTable
                details={endoEntries.operations}
                header={
                    <StyledSpaceBetween>
                        <Typography variant="small" weight="medium">
                            Total Correct Lives in Endo
                        </Typography>
                        <Typography variant="small" color="secondary">
                            {endoEntries.totalLives}
                        </Typography>
                    </StyledSpaceBetween>
                }
                footers={endoTableFooters}
            />
        </StyledValidatorSummaryTable>
    );
};
