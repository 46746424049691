import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 720px;
  font-weight: 500;
  width: 100%;
  gap: 28px;
`;
export const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-size: ${(p) => p.theme.fontSizes.subHeading};
`;
export const StyledInputFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledUploadFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px
`;
