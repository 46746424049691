import { NavigateFunction } from 'react-router-dom';
import { getRaterAPI } from '../../../adapters';
import { parseResponse } from '../../../utils/common';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { IErrorState, ISuccessState } from './types';

export const fetchPremiumRater = (
    setRaterIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    policyId: string,
    setIsRaterPresent: React.Dispatch<React.SetStateAction<boolean>>
): (() => Promise<void>) => {
    return async () => {
        setRaterIsLoading(true);
        const [error, response] = await parseResponse(getRaterAPI(policyId));
        setRaterIsLoading(false);
        if (error) {
            setIsRaterPresent(false);
        } else if (response?.status !== 200) {
            setIsRaterPresent(false);
        } else {
            setIsRaterPresent(true);
        }
        return;
    };
};

export const navigateToPremiumRaterUpload = (navigate: NavigateFunction): (() => void) => {
    return () => {
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.PREMIUM_RATERS.route}`);
    };
};

export const buildErrorStateProps = (closeModal: () => void, handleOnNavigate: () => void): IErrorState => {
    return {
        closeModal,
        handleOnNavigate
    };
};

export const buildSuccessStateProps = (
    isLoading: boolean,
    closeModal: () => void,
    handleOnChangeStatus: () => void
): ISuccessState => {
    return {
        isLoading,
        closeModal,
        handleOnChangeStatus
    };
};
