import React from 'react';
import { Form, Typography } from '@loophealth/loop-ui-web-library';
import { StyledStepsContainer, StyledDatePickerContainer, StyledDatePickerWrapper } from './styles';
import { IEnrolmentConfigDates } from './types';

const EnrolmentConfigDates: React.FC<IEnrolmentConfigDates> = ({
    memberRegistrationStartDate,
    memberRegistrationEndDate,
    endoCycleStartDate,
    endoCycleEndDate
}) => {
    return (
        <StyledStepsContainer>
            <Typography variant="medium" weight="medium">
                Member registration duration
            </Typography>
            <StyledDatePickerContainer>
                <StyledDatePickerWrapper>
                    <Form.DatePicker borderless value={endoCycleStartDate} onChange={() => ''} />
                </StyledDatePickerWrapper>
                <StyledDatePickerWrapper>
                    <Form.DatePicker borderless value={endoCycleEndDate} onChange={() => ''} />
                </StyledDatePickerWrapper>
            </StyledDatePickerContainer>
            <Typography variant="medium" weight="medium">
                Enrolment cycle duration
            </Typography>
            <StyledDatePickerContainer>
                <StyledDatePickerWrapper>
                    <Form.DatePicker borderless value={memberRegistrationStartDate} onChange={() => ''} />
                </StyledDatePickerWrapper>
                <StyledDatePickerWrapper>
                    <Form.DatePicker borderless value={memberRegistrationEndDate} onChange={() => ''} />
                </StyledDatePickerWrapper>
            </StyledDatePickerContainer>
        </StyledStepsContainer>
    );
};

export default EnrolmentConfigDates;
