import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { fetchUserPoliciesByUser } from '../../../redux/slices/UserPoliciesSlice/thunks';
import { Modal } from '../../atoms';
import PolicyCard from '../PolicyCard';
import { IUserPoliciesModal } from './types';
import { StyledModalContainer } from './styles';

const UserPoliciesModal: React.FunctionComponent<IUserPoliciesModal> = ({
    isVisible,
    setIsVisible,
    userId,
    companyId
}) => {
    const dispatch = useDispatch();
    const userPolicies = useSelector((state: ReduxState) =>
        state.userPoliciesSlice.userPolicies.data?.filter((uPol) => uPol.userId === userId)
    );

    React.useEffect(() => {
        if (isVisible) {
            dispatch(fetchUserPoliciesByUser({ userId }));
        }
    }, [userId]);

    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} title={`User Policies: ${userId}`}>
            <StyledModalContainer>
                {(userPolicies || []).map((uPol, index) => {
                    return (
                        <PolicyCard
                            userId={userId}
                            key={index}
                            policyId={uPol.id}
                            companyId={companyId}
                            displayEcard={true}
                            eCard={uPol.policyEcard || ''}
                            slabId={uPol.slabId || ''}
                            sumInsured={uPol.sumInsured || 0}
                            userPolicyEndDate={uPol.policyEndDate}
                        />
                    );
                })}
            </StyledModalContainer>
        </Modal>
    );
};

export default UserPoliciesModal;
